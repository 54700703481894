<template>
  <div>
    <v-container style="margin-top: 10px">

      <v-row class="mb-5">
        <v-col cols="4">
          <v-select
              v-model="selectedDate"
              :items="dates_calcul"
              item-text="date_calcul"
              menu-props="auto"
              label="Date de calcul"
              hide-details
              prepend-icon="mdi-calendar-month"
              single-line
              v-on:change="reloadParcelles()"
          ></v-select>
        </v-col>
      </v-row>


      <h3>Indicateurs</h3>
      <div style="margin-bottom:20px;">
        <v-row class="mt-3">
          <v-col cols="2">
            <v-card class="info-card" :style="(parcelles_chn_errors.length>0)?'background-color:#f02f2f !important':''">Nb parcelles total : {{parcelles_chn.length}}<br>dont en erreur : {{parcelles_chn_errors.length}}</v-card>
          </v-col>
          <v-col cols="2">
            <v-card class="info-card">Dose préco moyenne<br>{{averageData('dose_preconisee')}}</v-card>
          </v-col>
          <v-col cols="2">
            <v-card class="info-card">Dose préco min.<br>{{minMaxData('dose_preconisee','min')}}</v-card>
          </v-col>
          <v-col cols="2">
            <v-card class="info-card">Dose préco max.<br>{{minMaxData('dose_preconisee','max')}}</v-card>
          </v-col>
          <v-col cols="2">
            <v-card class="info-card">Nabs moyen<br>{{averageData('dernier_azoteaerienabsorbe_apres_assimilation')}}</v-card>
          </v-col>
        </v-row>
        
      </div>

      <h3>Liste des parcelles CHN</h3>
      <div style="margin-bottom:40px;">
      <div style="overflow:hidden;">
        <div style="float:left;margin-bottom: 5px;;">
          <span style="float:left;">Seuil delta N:</span><vue-numeric-input  style="float:left;" v-model="seuil_delta_n" :min="-60" :max="100" :step="2"></vue-numeric-input>
        </div>
        <v-switch
          style="float:right;"
            v-model="isFlag"
            hide-details
            inset
            :label="`Parcelles flaguées : ${isFlag.toString()}`"
          ></v-switch>
        </div>
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchWord"
              append-icon="mdi-magnify"
              label="Recherche par mot clé"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            fixed-header
            height="650"
            :search="searchWord"
            :loading="!dataloaded_parcelles_chn"
            loading-text="Chargement des données...."
            :headers="headers"
            :items="parcelles_chn"
            item-key="uuid_plot"
            :options.sync="optionsTab"
            :page="6"
            class="my-awesome-table elevation-1"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            show-expand
            :single-select="singleSelect"
            show-select
            v-model="selected_plots"
            dense
          >

            <template v-for="h in headers" v-slot:[`header.${h.value}`]="{  }">
              <v-tooltip bottom :key="h.text">
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{h.text}}</span>
                </template>
                <span v-html="h.infobulle"></span>
              </v-tooltip>
            </template>
            <template v-slot:top>
              <v-switch
                v-model="singleSelect"
                label="Single select"
                class="pa-3"
              ></v-switch>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-switch
                  v-model="singleExpand"
                  label="Single expand"
                  class="mt-2"
                ></v-switch>
              </v-toolbar>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                Identifiant parcelle : {{ item.uuid_plot }}
              </td>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="showItem(item)">
                mdi-eye
              </v-icon>
              <v-icon small class="mr-2" @click="showCommentaire(item)">
                mdi-comment-text-outline
              </v-icon>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }" v-if="item.warnings!='[]'">
                  <v-icon small class="mr-2" color="orange" v-bind="attrs" v-on="on">
                    mdi-alert-circle-outline
                  </v-icon>
                </template>
                <span style="width:400px;">{{item.warnings}}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }" v-if="item.errors!='{}'">
                  <v-icon small class="mr-2" color="red" v-bind="attrs" v-on="on">
                    mdi-alert-outline
                  </v-icon>
                </template>
                <span style="width:400px;">{{item.errors}}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.validation`]="{ item }">
              <v-tooltip bottom v-if="item.flag!=null">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="mr-2" v-bind="attrs" v-on="on" :color="getInfoValidation(item,'color')">
                    mdi-flag-variant
                  </v-icon>
                </template>
                <span style="width:400px;" v-html="getInfoValidation(item,'phrase')"></span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.corrections!=null">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="mr-2" v-bind="attrs" v-on="on">
                    mdi-wrench
                  </v-icon>
                </template>
                <span style="width:400px;">Parcelle corrigée le : {{item.corrections}}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.is_error`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }" v-if="item.is_error==true">
                  <v-icon small class="mr-2" color="red" v-bind="attrs" v-on="on">
                    mdi-alert-outline
                  </v-icon>
                </template>
                <span style="width:400px;">Parcelle dont la dernière ligne de calcul est en erreur. Cf tableau des erreurs. La ligne affichée ici correspond au dernier calcul correct.</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.conseil_previous`]="{ item }">
              <div v-if="item.conseil_previous!=null">{{ item.conseil_previous.date_apport_prevu }}<br>[L1 : {{ item.conseil_previous.conseil_jm9.dose }} - {{ item.conseil_previous.conseil_jm9.date}}]<br>[L2 : {{ item.conseil_previous.conseil_jm9.dose }} - {{ item.conseil_previous.conseil_jm9.date}}]</div>
            </template>
            <template v-slot:[`item.conseil_next`]="{ item }">
              <div v-if="item.conseil_next!=null && item.conseil_next.conseil_jm9.dose!=null && item.conseil_next.conseil_jm2.dose==null">{{ item.conseil_next.date_apport_prevu }}<br>[L1 : {{ item.conseil_next.conseil_jm9.dose }} - {{ item.conseil_next.conseil_jm9.date}}]</div>
              <div v-if="item.conseil_next!=null && item.conseil_next.conseil_jm9.dose!=null && item.conseil_next.conseil_jm2.dose!=null">{{ item.conseil_next.date_apport_prevu }}<br>[L1 : {{ item.conseil_next.conseil_jm9.dose }} - {{ item.conseil_next.conseil_jm9.date}}]<br>[L2 : {{ item.conseil_next.conseil_jm2.dose }} - {{ item.conseil_next.conseil_jm2.date}}]</div>
              <div v-if="item.conseil_next!=null && item.conseil_next.conseil_jm9.dose==null && item.conseil_next.conseil_jm2.dose==null">{{ item.conseil_next.date_apport_prevu }}</div>
            </template>
            <template v-slot:[`item.delta_n`]="{ item }">
              <v-chip
                :color="getColorDeltaN(item.delta_n, seuil_delta_n)"
                dark
              >
                {{ getValAbsolueDeltaN(item.delta_n) }}
              </v-chip>
            </template>
            <template v-slot:[`item.spluie_depuis_dernier_apport`]="{ item }">
                {{ Math.round(item.spluie_depuis_dernier_apport) }}
            </template>
          </v-data-table>
        </v-card>
        <v-btn small color="info" class="mt-2 white--text">
          <download-excel :data="parcelles_chn" :fields="excel_fields">
            Export Excel 
            <v-icon small class="mr-2">
              mdi-download
            </v-icon>
        </download-excel>
        </v-btn>
        
        <v-spacer></v-spacer>
        <div v-if="checkDroits('ADMIN','lecture')">
          <!--<v-btn small color="info" class="mt-2 white--text" @click="askCorrection">
            Envoyer une demande de scenarii
          </v-btn>
          <v-btn small color="info" class="mt-2 white--text float-right" @click="getFilesCorrection">
            Importer scenarii du FTP
          </v-btn>-->
          <br><v-btn  small color="info" class="mt-2 white--text" @click="askRecalcul">
            Envoyer une demande de recalcul
          </v-btn> à ne pas utiliser entre 13h et 15h
        </div>
        
        
        <!--v-spacer></v-spacer>
        <v-btn small color="info" class="mt-2 white--text" @click="askHistocorrection">
          Historique des demandes de correction
        </v-btn>
        <v-data-table v-if="affiche_histo_correction==true"
          :headers="headers_histo_correction"
          :items="histo_correction"
          item-key="date"
          class="my-awesome-table elevation-1"
          dense
        >
        </v-data-table>-->
      </div>

      <CommentaireComponent :dialog_init="dialog" :id_plot="selected_plot" :oad="oad" v-on:closeCommentaire="closeCommentaire"/>

      <h3>Graphiques</h3>
      <div style="margin-bottom:20px;margin-top:20px;">
        <v-row>
          <v-col cols="5"><v-chart style="height:420px;" :option="chartOptions"/></v-col>
          <v-col cols="5"><v-chart style="height:420px;" :option="chartOptionsBar"/></v-col>
        </v-row>
      </div>

      <!--
        https://www.nicolaskempf.fr/creez-vos-cartes-interactives-avec-vuejs-et-leaflet/
        https://vue2-leaflet.netlify.app/examples/custom-icons.html
        -->
      <l-map
        :center="carte.center"
        :zoom="carte.zoom"
        class="map"
        ref="map"
        @update:zoom="zoomUpdated"
        @update:center="centerUpdated"
        
      >
        <l-tile-layer
          :url="carte.url"
        >
        </l-tile-layer>
        <l-marker
          v-for="marker in carte.markers"
          :key="marker.id"
          :lat-lng="marker.coordinates"
        >
          <l-icon 
            ref="icon"
            :icon-url="marker.imageUrl"
          >
          </l-icon>
          <l-popup :content="marker.infobulle"/>
        </l-marker>
      </l-map>



      <h3>Liste des parcelles CHN en erreurs</h3>
      <div style="margin-bottom:40px;">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchWordError"
              append-icon="mdi-magnify"
              label="Recherche par mot clé"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :search="searchWordError"
            :loading="!dataloaded_parcelles_chn_errors"
            loading-text="Chargement des données...."
            :headers="headers_errors"
            :items="parcelles_chn_errors"
            item-key="uuid_plot"
            :options.sync="optionsTab"
            :page="6"
            class="my-awesome-table elevation-1"
            :single-expand="singleExpand"
            :expanded.sync="expanded_errors"
            show-expand
            dense
          >

            <template v-slot:top>
              <v-toolbar flat>
                <v-switch
                  v-model="singleExpand"
                  label="Single expand"
                  class="mt-2"
                ></v-switch>
              </v-toolbar>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                Identifiant parcelle : {{ item.uuid_plot }}
              </td>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="showItem(item)">
                mdi-eye
              </v-icon>
              <v-icon small class="mr-2" @click="showCommentaire(item)">
                mdi-comment-text-outline
              </v-icon>
            </template>
            <template v-slot:[`item.delta_n`]="{ item }">
              <v-chip
                :color="getColorDeltaN(item.delta_n, seuil_delta_n)"
                dark
              >
                {{ getValAbsolueDeltaN(item.delta_n) }}
              </v-chip>
            </template>
            <template v-slot:[`item.spluie_depuis_dernier_apport`]="{ item }">
                {{ Math.round(item.spluie_depuis_dernier_apport) }}
            </template>
          </v-data-table>
        </v-card>
      </div>
      </v-container>

  </div>

  
</template>

<script>
import CommentaireComponent from "@/components/global/CommentaireSimple.vue";
import downloadExcel from "vue-json-excel";
import parcellesChnService from "@/service/parcellesChn";
import VChart from "vue-echarts";
import VueNumericInput from 'vue-numeric-input';
import { LMap, LTileLayer, LMarker, LIcon, LPopup } from 'vue2-leaflet';

export default {
  name: "Suivi_CHN",
  components: {
    CommentaireComponent,
    downloadExcel,
    parcellesChnService,
    VChart,
    VueNumericInput,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup
  },
  data() {
    return {
      droits: this.$store.state.user.user.droitsTPC, //this.$store.dispatch("user/fetchUser"), //this.$store.state.user.droitsTPC, //this.$store.state.auth.user.droits,
      oad: 'CHN',
      selected_plot: null,
      dialog: false,
      dataloaded_parcelles_chn: false,
      dataloaded_parcelles_chn_errors: false,
      displayResult: true,
      filter: null,
      isFlag: false,
      searchWord: "",
      searchWordError: "",
      expanded: [],
      expanded_errors: [],
      singleExpand: false,
      singleSelect: false,
      selected_plots: [],
      parcelles_chn: [],
      parcelles_chn_tout: [],
      parcelles_chn_errors: [],
      seuil_delta_n: 20,
      optionsTab: {itemsPerPage: 10,},
      headers: [
        //colonnes non visibles mais permettant de faire une recherche sur le contenu
        { text: 'Code parcelle', value: 'uuid_plot', align: ' d-none' },
        //colonnes visibles
        { text: '', value: 'data-table-expand'},
        { text: "", value: "actions", sortable: false, width: '105px'},
        { text: "", value: "validation", sortable: false, width: '80px'},
        { text: "Erreur", value: "is_error", infobulle:null, filterable: true},
        { text: "Code Airbus", value: "code_airbus", infobulle:null, filterable: true},
        { text: "Nom parcelle", value: "nom_parcelle", infobulle:null, filterable: true },
        { text: "Variété", value: "variete", infobulle:null, filterable: true },
        { text: "Débouché", value: "crop_management", infobulle:null},
        { text: "Date semis", value: "semis_date", infobulle:null, width: '100px', color:'#ccc' },
        { text: "Apport précédent", value: "conseil_previous", infobulle:null, width: '100px', color:'#ccc' },
        { text: "Prochain apport", value: "conseil_next", infobulle:null, width: '100px', color:'#ccc' },
        { text: "Dose préco (kgN/ha)", value: "dose_preconisee", infobulle:null },
        { text: "Apport prévu?", value: "Preco_declenchement", infobulle:null },
        { text: "Delta N assimilé/non assimilé", value: "delta_n", infobulle:'Correspond à la différence entre les données des capteurs et du modèle. Cet indicateur permet d’observer à combien s’élève cette divergence)<br> Calcul : azote aérien absorbé après assimilation - avant assimilation' },
        { text: "Date calcul", value: "calcul_end_timestamp", infobulle:null, width: '110px' },
        { text: "Client", value: "client", infobulle:null },
        { text: "IR", value: "ingenieur_regional", infobulle:null, width: '110px' },
        { text: "Département", value: "departement", infobulle:null},
        { text: "ZAM", value: "zam", infobulle:null},
        { text: "Exploitation", value: "exploitation", width: '110px', infobulle:null},
        { text: "Lieu-dit", value: "lieudit", width: '120px', infobulle:null},
        { text: "Date franchissement INN", value: "date_franchissement_inn_min", infobulle:null },
        { text: "Date début prochaine période favorable", value: "date_debut_prochaine_periode_favorable", infobulle:null, width: '100px' },
        { text: "Date fin prochaine période favorable", value: "date_fin_prochaine_periode_favorable", infobulle:null, width: '100px' },
        { text: "Date acquisition", value: "date_derniere_image_utilisee", infobulle:null, width: '100px' },
        { text: "QN sol (kgN/ha)", value: "qn_sol_prochaine_periode_favorable", infobulle:'Correspond à la quantité d\'azote dans le sol' },
        { text: "Date épuisement Nsol dispo", value: "date_epuisement_n_sol", infobulle:null, width: '100px' },
        { text: "Date dernier apport", value: "date_dernier_apport", infobulle:null, width: '100px' },
        { text: "Dose dernier apport (kgN/ha)", value: "dose_dernier_apport", infobulle:null },
        { text: "Pluie depuis dernier apport (mm)", value: "spluie_depuis_dernier_apport", infobulle:null }   
      ],
      headers_errors: [
        //colonnes non visibles mais permettant de faire une recherche sur le contenu
        { text: 'Code parcelle', value: 'uuid_plot', align: ' d-none' },
        //colonnes visibles
        { text: '', value: 'data-table-expand' },
        { text: "", value: "actions", sortable: false, width: '80px' },
        { text: "Variété", value: "variete", infobulle:null, filterable: true },
        { text: "Débouché", value: "crop_management", infobulle:null},
        { text: "Date semis", value: "semis_date", infobulle:null, width: '100px', color:'#ccc' },
        { text: "Dose préco (kgN/ha)", value: "dose_preconisee", infobulle:null },
        { text: "Delta N assimilé/non assimilé", value: "delta_n", infobulle:'Correspond à la différence entre les données des capteurs et du modèle. Cet indicateur permet d’observer à combien s’élève cette divergence)<br> Calcul : azote aérien absorbé après assimilation - avant assimilation' },
        { text: "Date calcul", value: "calcul_end_timestamp", infobulle:null, width: '110px' },
        { text: "Client", value: "client", infobulle:null },
        { text: "IR", value: "ingenieur_regional", infobulle:null, width: '110px' },
        { text: "Département", value: "departement", infobulle:null},
        { text: "ZAM", value: "zam", infobulle:null},
        { text: "Erreurs", value: "errors", infobulle:null},
      ],
      histo_correction: [],
      affiche_histo_correction: false,
      headers_histo_correction: [
        { text: "Date", value: "date"},
        { text: "Type", value: "type"},
        { text: "Parcelle", value: "parcelle"},
        { text: "Utilisateur", value: "utilisateur"},
      ],
      dates_calcul: [],
      selectedDate: null,
      carte:{
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        center: [ 46.76782, 2.43129 ],
        zoom: 6,
        markers: [],
      },
      excel_fields: {
        uuid_plot:'uuid_plot',
        uuid_user:'uuid_user',
        code_airbus:'code_airbus',
        farmer:'farmer',
        id:'id',
        site_ppv1code:'site_ppv1code',
        client:'client',
        zam:'zam',
        ingenieur_regional:'ingenieur_regional',
        exploitation:'exploitation',
        technician:'technician',
        lieudit:'lieudit',
        latitude:'latitude',
        longitude:'longitude',
        departement:'departement',
        codeespece:'codeespece',
        crop_management:'crop_management',
        variete:'variete',
        bc_varietal:'bc_varietal',
        _semis_date:'_semis_date',
        codebasesol:'codebasesol',
        sol_horizons:'sol_horizons',
        sol_mesures:'sol_mesures',
        sol_profondeur:'sol_profondeur',
        sol_profondeur_drains:'sol_profondeur_drains',
        sol_reserve_utile:'sol_reserve_utile',
        semis_densite:'semis_densite',
        parcelle_irriguee:'parcelle_irriguee',
        date_dernier_apport:'date_dernier_apport',
        cipan:'cipan',
        codeprecedent:'codeprecedent',
        rendementrecolteprecedent:'rendementrecolteprecedent',
        daterecolteprecedent:'daterecolteprecedent',
        destinationresidusrecolteprecedent:'destinationresidusrecolteprecedent',
        precedent:'precedent',
        flag: 'flag.date',
        flag_valide: {
          label: "flag_valide", // Column header for 'child' field in Excel
          // This callback will now get the correct value for 'child'
          callback: (value) => {
            return value.flag_valide ? "Oui" : ""; // Handle truthy/falsy values correctly
          }
        },
        apports_prevus: {
          field: "scheduled_interventions", // Column header for 'child' field in Excel
          // This callback will now get the correct value for 'child'
          callback: (value) => {
            if(value != null && value != ""){
              var tab = JSON.parse(value);
              return tab.map(item => item[0]).join(',');
            } else {
              return "";
            }
          }
        }, 
        apport_precedent_date: {
          field: "conseil_previous", // Column header for 'child' field in Excel
          // This callback will now get the correct value for 'child'
          callback: (value) => {
            return value.date_apport_prevu ;
          }
        },
        apport_precedent_livraison1_dose: {
          field: "conseil_previous", // Column header for 'child' field in Excel
          // This callback will now get the correct value for 'child'
          callback: (value) => {
            return value.conseil_jm9 ? value.conseil_jm9.dose : "";
          }
        },
        apport_precedent_livraison1_date_calcul: {
          field: "conseil_previous", // Column header for 'child' field in Excel
          // This callback will now get the correct value for 'child'
          callback: (value) => {
            return value.conseil_jm9 ? value.conseil_jm9.date : "";
          }
        },
        apport_precedent_livraison2_dose: {
          field: "conseil_previous", // Column header for 'child' field in Excel
          // This callback will now get the correct value for 'child'
          callback: (value) => {
            return value.conseil_jm2 ? value.conseil_jm2.dose : "";
          }
        },
        apport_precedent_livraison2_date_calcul: {
          field: "conseil_previous", // Column header for 'child' field in Excel
          // This callback will now get the correct value for 'child'
          callback: (value) => {
            return value.conseil_jm2 ? value.conseil_jm2.date : "";
          }
        },
        apport_suivant_date: {
          field: "conseil_next", // Column header for 'child' field in Excel
          // This callback will now get the correct value for 'child'
          callback: (value) => {
            return value.date_apport_prevu ;
          }
        },
        apport_suivant_livraison1_dose: {
          field: "conseil_next", // Column header for 'child' field in Excel
          // This callback will now get the correct value for 'child'
          callback: (value) => {
            return value.conseil_jm9 ? value.conseil_jm9.dose : "";
          }
        },
        apport_suivant_livraison1_date_calcul: {
          field: "conseil_next", // Column header for 'child' field in Excel
          // This callback will now get the correct value for 'child'
          callback: (value) => {
            return value.conseil_jm9 ? value.conseil_jm9.date : "";
          }
        },
        apport_suivant_livraison2_dose: {
          field: "conseil_next", // Column header for 'child' field in Excel
          // This callback will now get the correct value for 'child'
          callback: (value) => {
            return value.conseil_jm2 ? value.conseil_jm2.dose : "";
          }
        },
        apport_suivant_livraison2_date_calcul: {
          field: "conseil_next", // Column header for 'child' field in Excel
          // This callback will now get the correct value for 'child'
          callback: (value) => {
            return value.conseil_jm2 ? value.conseil_jm2.date : "";
          }
        },
        corrections: 'corrections',
        milieu_contextualisation:'milieu_contextualisation',
        calcul_start_timestamp:'calcul_start_timestamp',
        calcul_end_timestamp:'calcul_end_timestamp',
        creation_timestamp:'creation_timestamp',
        last_update_timestamp:'last_update_timestamp',
        contexte_chn:'contexte_chn',
        date_derniere_image_utilisee:'date_derniere_image_utilisee',
        dernier_lai_avant_assimilation:'dernier_lai_avant_assimilation',
        dernier_lai_apres_assimilation:'dernier_lai_apres_assimilation',
        LAI_jplus10:'LAI_jplus10',
        dernier_azoteaerienabsorbe_avant_assimilation:'dernier_azoteaerienabsorbe_avant_assimilation',
        dernier_azoteaerienabsorbe_apres_assimilation:'dernier_azoteaerienabsorbe_apres_assimilation',
        dernier_biomasseaerienne_avant_assimilation:'dernier_biomasseaerienne_avant_assimilation',
        dernier_biomasseaerienne_apres_assimilation:'dernier_biomasseaerienne_apres_assimilation',
        azoteaerienabsorbe_jplus10:'azoteaerienabsorbe_jplus10',
        biomasseaerienne_jplus10:'biomasseaerienne_jplus10',
        biomasseaeriennepotentielle_jplus10:'biomasseaeriennepotentielle_jplus10',
        epi1cm_date:'epi1cm_date',
        unnoeud_date:'unnoeud_date',
        deuxnoeuds_date:'deuxnoeuds_date',
        dfp_date:'dfp_date',
        epiaison_date:'epiaison_date',
        floraison_date:'floraison_date',
        findonneesmeteo:'findonneesmeteo',
        findonneesreelles:'findonneesreelles',
        interventions:'interventions',
        Preco_declenchement:'Preco_declenchement',
        dose_preconisee:'dose_preconisee',
        delta_n:'delta_n',
        spluie_depuis_dernier_apport:'spluie_depuis_dernier_apport',
        periode_favorable_etat_5_j:'periode_favorable_etat_5_j',
        date_debut_prochaine_periode_favorable:'date_debut_prochaine_periode_favorable',
        date_fin_prochaine_periode_favorable:'date_fin_prochaine_periode_favorable',
        date_deficit_prochaine_periode_favorable:'date_deficit_prochaine_periode_favorable',
        deficit_prochaine_periode_favorable:'deficit_prochaine_periode_favorable',
        deficit_tolerable_prochaine_periode_favorable:'deficit_tolerable_prochaine_periode_favorable',
        date_epuisement_n_sol:'date_epuisement_n_sol',
        qn_sol_prochaine_periode_favorable:'qn_sol_prochaine_periode_favorable',
        date_franchissement_inn_min:'date_franchissement_inn_min',
        validite:'validite',
        statut:'statut',
        warnings:'warnings',
        module_urgence:'module_urgence',
        numanomalie:'numanomalie',
        libelleanomalie:'libelleanomalie',
        numvalidite:'numvalidite',
        errors:'errors'
      },
      chartOptions: {
        title: {
          text: 'Azote et biomasse'
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          data: ['Données après assimilation', 'Données avant assimilation', 'Données après assimilation sélection', 'Données avant assimilation sélection'],
          y: 'bottom'
        },
        grid: {
          left: '8%',
          right: '4%',
          bottom: '25%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'value',
          name: 'Azote absorbé (KgN/ha)',
          nameLocation: 'center',
          nameGap: 30,
        },
        yAxis: {
          type: 'value',
          name: 'Biomasse (t/ha)',
          nameRotate: 90,
          nameLocation: 'center',
          nameGap: 40,
        },
        series: [
          {
            name: "Données après assimilation",
            symbolSize: 10,
            data: [],
            type: 'scatter',
            tooltip: {
              trigger: "item",
              formatter: function (a) {
                return a.value[0] + '<br>' + a.value[1] + '<br>Id : ' + a.value[2] + '<br>Variété : ' + a.value[3] + '<br>Date semis : ' + a.value[4] + '<br>Client : ' + a.value[5] 
              },
            },
          },
          {
            name: "Données avant assimilation",
            symbolSize: 10,
            data: [],
            type: 'scatter',
            tooltip: {
              trigger: "item",
              formatter: function (a) {
                return a.value[0] + '<br>' + a.value[1] + '<br>Id : ' + a.value[2] + '<br>Variété : ' + a.value[3] + '<br>Date semis : ' + a.value[4] + '<br>Client : ' + a.value[5] 
              },
            },
          },
          {
            name: "Données après assimilation sélection",
            symbolSize: 10,
            data: [],
            type: 'scatter',
            tooltip: {
              trigger: "item",
              formatter: function (a) {
                return a.value[0] + '<br>' + a.value[1] + '<br>Id : ' + a.value[2] + '<br>Variété : ' + a.value[3] + '<br>Date semis : ' + a.value[4] + '<br>Client : ' + a.value[5] 
              },
            },
          },
          {
            name: "Données avant assimilation sélection",
            symbolSize: 10,
            data: [],
            type: 'scatter',
            tooltip: {
              trigger: "item",
              formatter: function (a) {
                return a.value[0] + '<br>' + a.value[1] + '<br>Id : ' + a.value[2] + '<br>Variété : ' + a.value[3] + '<br>Date semis : ' + a.value[4] + '<br>Client : ' + a.value[5] 
              },
            },
          }
        ],
        graphic: [{
          type: 'image', // Graphic element type
            id: 'logo', // Specify which graphic element of the update when updating or deleting graphic elements, if it is not required to be ignored.
            right: 'center', // Position according to the parent element (in the middle)
            bottom: '50%', // According to the parent element (0%), if the value of the bottom is 0, the BOTTOM property value can also be deleted.
            z: 0,  // laminated
            bounding: 'all', // Decide how this graphic element is positioned, the method of calculating its own enclosure
            style: {
                image: require('@/assets/logo-arvalis-color.png'), // Here, it must be noted that attention must be a picture path address at the beginning of HTTPS.
                width: 165,
                height: 32
            }
        }]
      },
      chartOptionsBar: {
        title: {
          text: 'Répartition de la dose préconisée'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: [],
          y: 'bottom'
        },
        grid: {
          left: '8%',
          right: '4%',
          bottom: '25%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value',
          name: 'Nombre de parcelles',
          nameRotate: 90,
          nameLocation: 'center',
          nameGap: 40,
        },
        series: [],
        graphic: [{
          type: 'image', // Graphic element type
            id: 'logo', // Specify which graphic element of the update when updating or deleting graphic elements, if it is not required to be ignored.
            right: 'center', // Position according to the parent element (in the middle)
            bottom: '50%', // According to the parent element (0%), if the value of the bottom is 0, the BOTTOM property value can also be deleted.
            z: 0,  // laminated
            bounding: 'all', // Decide how this graphic element is positioned, the method of calculating its own enclosure
            style: {
                image: require('@/assets/logo-arvalis-color.png'), // Here, it must be noted that attention must be a picture path address at the beginning of HTTPS.
                width: 165,
                height: 32
            }
        }]
      }
    };
  },
  watch: {
    parcelles_chn: function(val) {
      var list_apres_ass=[];
      var list_avant_ass=[];
      val.map(function(data) {
        list_apres_ass.push([data.dernier_azoteaerienabsorbe_apres_assimilation,data.dernier_biomasseaerienne_apres_assimilation,data.uuid_plot,data.variete,data.semis_date]);
        list_avant_ass.push([data.dernier_azoteaerienabsorbe_avant_assimilation,data.dernier_biomasseaerienne_avant_assimilation,data.uuid_plot,data.variete,data.semis_date]);
      });
      this.chartOptions.series[0].data=list_apres_ass; 
      this.chartOptions.series[1].data=list_avant_ass;
      
      var data_graph_bar=this.getDataGraphBar(val);
      this.chartOptionsBar.series = data_graph_bar.series;
      this.chartOptionsBar.xAxis.data = data_graph_bar.doses;
      this.chartOptionsBar.legend.data = data_graph_bar.clients;

      //markers sur carte 
      //+ initialisation variable erreur rempli par la suite (une fois que les parcelles en erreur sont chargées)
      var array = []
      this.parcelles_chn.forEach(element => {
        array.push({
          id:element['uuid_plot'], 
          coordinates:[element['latitude'],element['longitude']], 
          imageUrl:require('@/assets/map_icon_'+this.colorParcelleMap(element['date_debut_prochaine_periode_favorable'],element['date_fin_prochaine_periode_favorable'])+'.png'), 
          infobulle:'Id: '+element['uuid_plot']+'<br>Variété: '+element['variete']+'<br>Débouché: '+element['crop_management']+'<br>Date semis: '+element['semis_date']+'<br>Dose préconisée: '+element['dose_preconisee']+'<br>Client: '+element['client']})
           
        element.is_error = null

        //pour déterminer lesquels des apports précède et suit la date du jour et affichier les infos correspondantes dans le tableau
        const today = new Date();
        // Convert string dates to Date objects for comparison
        const result = element.livraison_conseil.reduce(
            (acc, obj) => {
                const objDate = new Date(obj.date_apport_prevu);

                if (objDate <= today) {
                    // Check for nearest previous date
                    if (!acc.previous_conseil || objDate > new Date(acc.previous_conseil.date_apport_prevu)) {
                        acc.previous_conseil = obj;
                    }
                } else if (objDate > today) {
                    // Check for nearest next date
                    if (!acc.next_conseil || objDate < new Date(acc.next_conseil.date_apport_prevu)) {
                        acc.next_conseil = obj;
                    }
                }

                return acc;
            },
            { previous_conseil: null, next_conseil: null } // Initial accumulator
        );
        element.conseil_previous = result.previous_conseil
        element.conseil_next = result.next_conseil

      });
      this.carte.markers = array

    },
    parcelles_chn_errors: function(val) {
      //ajout si existence d'un calcul en erreur plus récent
      this.parcelles_chn.forEach(element => {
        var error = false
        if(this.parcelles_chn_errors.some(plot => plot.uuid_plot === element.uuid_plot)){
          error = true;
        }
        element.is_error = error
      });
    },
    selected_plots: function(val) {
      var list_apres_ass_selected=[];
      var list_avant_ass_selected=[];
      val.map(function(data) {
        list_apres_ass_selected.push([data.dernier_azoteaerienabsorbe_apres_assimilation,data.dernier_biomasseaerienne_apres_assimilation,data.uuid_plot,data.variete,data.semis_date,data.client]);
        list_avant_ass_selected.push([data.dernier_azoteaerienabsorbe_avant_assimilation,data.dernier_biomasseaerienne_avant_assimilation,data.uuid_plot,data.variete,data.semis_date,data.client]);
      });
      this.chartOptions.series[2].data=list_apres_ass_selected; 
      this.chartOptions.series[3].data=list_avant_ass_selected;
    },
    isFlag: function(val) {
      if(val==false){
        this.parcelles_chn = this.parcelles_chn_tout
      } else {
        this.parcelles_chn = this.parcelles_chn_tout.filter(obj => {return obj.flag !== null;});
      }
    }

  },
  async mounted() {
    this.dates_calcul = await parcellesChnService.getListeDatesCalcul();
    this.dates_calcul.unshift({date_calcul: 'DERNIER CALCUL'});
    this.selectedDate = this.dates_calcul[0].date_calcul;
    //this.parcelles_chn = await parcellesChnService.getListeParcelles(this.selectedDate);
    this.parcelles_chn = await parcellesChnService.getInfosParcelles(this.selectedDate,null);
    this.parcelles_chn_tout = this.parcelles_chn
    this.dataloaded_parcelles_chn = true;
    this.parcelles_chn_errors = await parcellesChnService.getInfosParcellesErreurs(this.selectedDate);
    this.dataloaded_parcelles_chn_errors = true
  },

  methods: {
    showItem(parcelle) {
      // Stockage de la parcelle sélectionnée dans le store
      //this.$store.dispatch("parcelle/setParcelleSelected", parcelle);
      /*this.$router
        .push("/chn/parcelle/" + parcelle.uuid_plot)
        .catch(err => {
          console.log("err", err);
        });*/

      let route = this.$router
        .resolve({ name: 'chn-detail-parcelle', params: { id: parcelle.uuid_plot } });
      window.open(route.href);
    },
    async reloadParcelles() {
      this.dataloaded_parcelles_chn = false;
      this.parcelles_chn = [];
      this.dataloaded_parcelles_chn_errors = false;
      this.parcelles_chn_errors = [];
      //this.parcelles_chn = await parcellesChnService.getListeParcelles(this.selectedDate);
      this.parcelles_chn = await parcellesChnService.getInfosParcelles(this.selectedDate,null);
      this.dataloaded_parcelles_chn = true;
      this.parcelles_chn_errors = await parcellesChnService.getInfosParcellesErreurs(this.selectedDate);
      this.dataloaded_parcelles_chn_errors = true;
    },
    averageData(variable) {
      if (this.parcelles_chn.length === 0) {
        return '';
      }
      return (this.parcelles_chn.
        reduce((sum, v) => sum + v[variable], 0) / this.parcelles_chn.length).toFixed(2);
    },
    minMaxData(variable, type) {
        if (this.parcelles_chn.length === 0) {
          return '';
        }
        if(type=='min'){
          return this.parcelles_chn.reduce((min, p) => p[variable] < min ? p[variable] : min, 1000000);
        } else if(type=='max'){
          return this.parcelles_chn.reduce((max, p) => p[variable] > max ? p[variable] : max, -1000000);
        }
    },
    showCommentaire(item) {
      this.selected_plot=item.uuid_plot
      this.dialog=true
    },
    closeCommentaire() {
      this.dialog=false
      this.selected_plot=null
    },

    getColorDeltaN (val, seuil) {
        /*if (val >= seuil) return 'green'
        else if (val == null) return 'red'
        else return 'red'*/
        if(val<seuil && val>-seuil) return 'green'
        else return 'red'
    },
    getValAbsolueDeltaN (val) {
      var newval = ""
      if(val!=null){
        if(val<0){
          newval = -val.toFixed(2)+' (-)'
        } else {
          newval = val.toFixed(2)+' (+)'
        }
      }


      return newval

    },
    getDataGraphBar(data){
      var list={};
      var list_dose=[]
      var list_clients=[]
      var client=null;
      var dose=null;
      //permet d'obtenir un objet pour chaque client avec un sous objet pour chaque catégorie de dose (avec nombre de parcelles cocernée)
      data.map(function(a) {
        client = a.client
        dose = a.dose_preconisee
        list_dose.push(dose)
        list_clients.push(client)
        if(list[client] == undefined){
          list[client] = {[dose]:1}
        } else {
          if(list[client][dose] == undefined){
            list[client][dose] = 1
          } else {
            list[client][dose] += 1
          }
        }
        
      });
     
      //retravail de l'objet list obtenu pour faire un tableau pour chaque zam avec le nombre de parcelles concernées par toutes les doses existantes
      list_dose = [...new Set(list_dose)]
      list_clients = [...new Set(list_clients)]
      list_dose.sort(function(a, b){return a-b});
      var series = [];
      for (const [key, value] of Object.entries(list)) {
        var d=[]
        list_dose.forEach(function(item){
          if(value[item] !== undefined){
            d.push(value[item])
          } else {
            d.push(0)
          }
        })

        series.push({
          name: key,
          type: 'bar',
          data: d
        })
      }

      return {series: series, doses: list_dose, clients: list_clients}
    },
    zoomUpdated (zoom) {
      this.zoom = zoom;
    },
    centerUpdated (center) {
      this.center = center;
    },
    colorParcelleMap(date_debut,date_fin){
      var today = new Date();
      var debut=new Date(date_debut);
      var fin=new Date(date_fin);
      if(today>=debut && today<fin){
        return 'green'  
      }
      return 'red'
    },
    /*async askHistocorrection(){
      this.affiche_histo_correction=true
      this.histo_correction = await parcellesChnService.getHistoCorrection();
    },*/
    /*async askCorrection(){

      if(this.selected_plots.length>0){
        this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
        var id_plots = []
        this.selected_plots.forEach(element => id_plots.push(element.uuid_plot));
        var results = await parcellesChnService.postDemandeCorrection({plots:id_plots});
        var infos = ''
        results['demande_validation'].forEach((value, key) => {
          if(value!=true) infos += id_plots[key]+'\n'
        })

        this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });

        if(infos!=''){
          alert('Un problème est survenu pour les parcelles suivantes : \n' + infos)
        }

        if(results['microservice']['erreur']!=undefined){
          alert(results['microservice']['erreur'])
        } else {
          alert(results['microservice']['info'])
        }
      } else {
        alert('Veuillez sélectionner des parcelles')
      }
      
    },*/
    async askRecalcul(){

      if(this.selected_plots.length>0){
        this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
        var id_plots = []
        this.selected_plots.forEach(element => id_plots.push(element.uuid_plot));
        var results = await parcellesChnService.postDemandeRecalcul({plots:id_plots});
        this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
        /*var infos = ''
        results['demande_validation'].forEach((value, key) => {
          if(value!=true) infos += id_plots[key]+'\n'
        })

        this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });

        if(infos!=''){
          alert('Un problème est survenu pour les parcelles suivantes : \n' + infos)
        }

        if(results['recalcul']['erreur']!=undefined){
          alert(results['recalcul']['erreur'])
        } else {
          alert(results['recalcul']['info'])
        }*/
      } else {
        alert('Veuillez sélectionner des parcelles')
      }

      },
    /*async getFilesCorrection(){
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      await parcellesChnService.getFilesCorrection();
      this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
    },*/
    checkDroits(droit,type){
      var index = this.droits.findIndex(item => item.code === droit)
      if(index>=0 && this.droits[index][type]==true){
        return true
      } else {
        return false
      }
    },

    getInfoValidation(item,type){
      var color = 'transparent';
      var phrase = '';
      if(item.flag!=null && item.flag_valide==false){
        color = 'red'
        phrase = "Parcelle détectée comme étant à problème le "+item.flag.date+" (stade repère "+item.flag.stade_repere+")";
      } else if(item.flag!=null && item.flag_valide==true){
        color = 'green'
        phrase = "Parcelle corrigée après détection d'un problème le "+item.flag.date+" (stade repère "+item.flag.stade_repere+")";
      }

      if(type=='phrase'){
        return phrase;
      } else {
        return color;
      }
      
    }

  }
};
</script>

<style scoped>
  .map {
    height: 500px !important;
    width: 600px !important;
    position: relative !important;
    margin-bottom: 30px;
    z-index:0;
  }
  .leaflet-container {
      height: 500px;
      width: 600px;
    }
  .leaflet-pane {
      z-index: 0
  }
</style>
