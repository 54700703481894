<template>
  <div>
    <v-container style="margin-top: 10px">

      </v-container>

  </div>

  
</template>

<script>
import downloadExcel from "vue-json-excel";
import parcellesQnService from "@/service/parcellesQn";
import VChart from "vue-echarts";

export default {
  name: "Parametrage_QN",
  components: {
    downloadExcel,
    parcellesQnService,
    VChart
  },
  data() {
    return {
      droits: this.$store.state.user.user.droitsTPC, //this.$store.dispatch("user/fetchUser"), //this.$store.state.user.droitsTPC, //this.$store.state.auth.user.droits,
    };
  },
  watch: {

  },
  async mounted() {
  
  },

  methods: {

  }
};
</script>
