import store from '@/store/index';
import { handleServiceParams, errorDescription } from "@/helpers/serviceParamsHandler";

export default {
    async getInfosParcelles(id=null) {

    var path_param = '';
    if(id != null){
        path_param = id+'/';
    }
    const local_dossier = await
        fetch(`${process.env.VUE_APP_BASE_API}qn/${path_param}results`, {
        method: 'GET',
        headers: {
            //'Content-Type': 'application/json',
            Authorization: `Bearer ${store.getters.tokenValue}`,
            //Accept: 'application/json',
        },
        }).then((response) => {
        if (response.ok) {
            return response.json();
        }
        })
        .then((json) => {
        return json.data;
        })
        .catch((error) => {
            store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
    },

    async saveDemandeCorrections(donneesCorrection) {
        const result = await
        fetch(`${process.env.VUE_APP_BASE_API}qn/corrections`, {
          method: 'POST',
          body: JSON.stringify(donneesCorrection),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${store.getters.tokenValue}`,
            Accept: 'application/json',
          },
        }).then(response => {
          if (!response.ok) {
            throw response;
          }
          return response.json();
        }).then(json => {
          return json.data;
        }).catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        });
        return result;
    },

    async getDemandeCorrections() {
        const result = await
        fetch(`${process.env.VUE_APP_BASE_API}qn/corrections`, {
          method: 'GET',
          headers: {
            //'Content-Type': 'application/json',
            Authorization: `Bearer ${store.getters.tokenValue}`,
            //Accept: 'application/json',
          },
        }).then(response => {
          if (!response.ok) {
            throw response;
          }
          return response.json();
        }).then(json => {
          return json.data;
        }).catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        });
        return result;
    },

    async validationDemandeCorrection(id) {
        const result = await
        fetch(`${process.env.VUE_APP_BASE_API}qn/corrections/${id}/validation`, {
            method: 'POST',
            //body: JSON.stringify(data),
            headers: {
            //'Content-Type': 'application/json',
            Authorization: `Bearer ${store.getters.tokenValue}`,
            //Accept: 'application/json',
            },
        }).then(response => {
            if (!response.ok) {
            throw response;
            }
            return response.json();
        }).then(json => {
            return json.data;
        }).catch((error) => {
            store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        });
        return result;
    },
    async getImage(typeRaster) {

      const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}qn/raster/${typeRaster}`, {
      method: 'GET',
      headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
      },
      }).then((response) => {
      if (response.ok) {
          return response.json();
      }
      })
      .then((json) => {
      return json.data;
      })
      .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
      },
      );
      return local_dossier;
    },
    
};
