<template>
  <div>
    <v-row fill-height>
      <v-col cols="1">
        <menucontextuel :menu="selectMenu()"></menucontextuel>
      </v-col>

      <v-col cols="11">
        <SuiviComponentCHN v-if="showSuiviComponentCHN" />
        <ParcelleComponentCHN v-if="showDetailParcelleComponentCHN" />
        <SuiviComponentQN v-if="showSuiviComponentQN" />
        <ParcelleComponentQN v-if="showDetailParcelleComponentQN" />
        <ParametrageComponentQN v-if="showParametrageComponentQN" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Register from "@/components/auth/Register.vue";
import SuiviComponentCHN from "@/components/chn/Suivi.vue";
import ParcelleComponentCHN from "@/components/chn/Parcelle.vue";
import SuiviComponentQN from "@/components/qn/Suivi.vue";
import ParcelleComponentQN from "@/components/qn/Parcelle.vue";
import ParametrageComponentQN from "@/components/qn/Parametrage.vue";
import Menucontextuel from '../components/Menucontextuel.vue';


export default {
  name: "ferti",
  components: {
    SuiviComponentCHN,
    ParcelleComponentCHN,
    SuiviComponentQN,
    ParcelleComponentQN,
    ParametrageComponentQN,
    Register,
    Menucontextuel
  },
  data() {
    return {
      menu : {
        chn: [],
        qn:[
          {
            id:1,
            chemin:"/ferti/qn/suivi",
            nom:"Tableau de bord",
            icon: "mdi-format-list-text"
          },
          {
            id:2,
            chemin:"/ferti/qn/parametrage",
            nom:"Paramétrage",
            icon: "mdi-tune-vertical"
          }
        ]
      },
      selectedMenu : []
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    showSuiviComponentCHN() {
      return this.currentRouteName == "chn-suivi";
    },
    showDetailParcelleComponentCHN() {
      return this.currentRouteName == "chn-detail-parcelle";
    },
    showSuiviComponentQN() {
      return this.currentRouteName == "qn-suivi";
    },
    showDetailParcelleComponentQN() {
      return this.currentRouteName == "qn-detail-parcelle";
    },
    showValidationComponentQN() {
      return this.currentRouteName == "qn-validation";
    },
    showParametrageComponentQN() {
      return this.currentRouteName == "qn-parametrage";
    },
  },

  methods: {
    returnToSearch() {
      this.$router.go(-1);
    },
    selectMenu(){
      if(this.showSuiviComponentCHN || this.showDetailParcelleComponentCHN){
        return this.menu['chn']
      }
      else if(this.showSuiviComponentQN || this.showDetailParcelleComponentQN || this.showValidationComponentQN || this.showParametrageComponentQN){
        return this.menu['qn']
      }
    }
  }
};
</script>

<style>
</style>
