<template>
  <div>
    <v-container style="margin-top: 10px">
      <br>
      <h3 v-on:click="toggleAjoutCasType">
        {{ !isEditMode ? 'Création ' : 'Modifier' }} cas-type
        <v-icon small class="mr-2" v-if="!ouvrir_ajout_cas_type">
          mdi-chevron-down
        </v-icon>
        <v-icon small class="mr-2" v-else>
          mdi-chevron-up
        </v-icon>
      </h3>
      <section id="form_cas_type" v-if="ouvrir_ajout_cas_type">
        <v-card>
          <v-card-text style="height: auto; margin: 0 auto;">
            <validation-observer ref="observer">
              <!-- FORMULAIRE  -->
              <form @submit.prevent="submitFormAddCasType" id="edit_plot_form" 
                style="display: flex;	flex-direction: column;	flex-wrap: nowrap;justify-content: center; ">
                <v-row style="margin:1rem">
                  <!-- Nom cas type-->
                  <v-col offset="1" cols="4">
                    <label for="name" class="mb-2">Nom cas-type </label>
                  </v-col>
                  <v-col cols="5">
                    <input class="input_field" type="text" id="name" v-model="cas_type_to_create_or_update.name"
                      required style="width: 100% !important; text-align: left;" />
                  </v-col>
                </v-row><!-- FIN Nom cas type-->
                <br>
                <!-- CHOIX STATION - Liste deroulante-->
                <v-row style="margin:1rem">
                  <v-col offset="1" cols="4">
                    <label for="station" class="mb-2">Je choisis une station du réseau </label>
                  </v-col>
                  <v-col cols="5">
                    <v-autocomplete
                      id="station"
                      style="width: 100% !important;"
                      v-model="cas_type_to_create_or_update.station"
                      :items="stations_list"
                      item-value="ind_station"
                      item-text="nom_commune"
                      return-object
                      clearable
                      hide-details
                      @input="onStationSelectedOrChanged"
                      :filter="customFilter"
                    >
                      <!-- Affichage des items dans la liste déroulante -->
                      <template v-slot:item="data">
                        <div>{{ data.item.nom_commune }} - {{ data.item.propriete }}</div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row> <!-- FIN CHOIX STATION - Liste deroulante-->

                <!-- L MAP - SELECT STATION-->
                <v-row class="fill-height" style="margin-top: 1rem;">
                  <v-col offset="1" cols="10" class="d-flex align-center justify-center">
                    <l-map style="width:90% !important" :center="carte.center" :zoom="carte.zoom" class="map" ref="map"
                      @update:zoom="zoomUpdated" @update:center="centerUpdated">
                      <l-tile-layer :url="carte.url"></l-tile-layer>
                      <l-marker v-for="marker in carte.markers" :key="marker.id" :lat-lng="marker.coordinates">
                        <l-icon ref="icon" :icon-url="marker.imageUrl"></l-icon>
                        <l-popup :content="marker.infobulle" />
                      </l-marker>
                    </l-map>
                  </v-col>
                </v-row> <!-- FIN L MAP - SELECT STATION-->

                <v-row style="margin:1rem">
                  <v-col offset="1" cols="10" style="text-align: left; display:flex">
                    <!-- Label externe pour le checkbox -->
                    <v-checkbox id="synchronize-checkbox" v-model="synchroniseStation"></v-checkbox>
                    <label for="synchronize-checkbox">Je synchronise une autre station </label>
                  </v-col>
                </v-row>

                <!-- Champs à afficher uniquement si la checkbox est cochée -->
                <div v-if="synchroniseStation" style="margin: 1rem 0;">
                  <!-- Conteneur principal pour l'alignement -->
                  <v-col offset="1" cols="10" style="display: flex; flex-wrap: nowrap; justify-content: space-around; align-items: flex-end; height: 100%;">
                    <!-- Opérateur Météo -->
                    <div style="min-width: 150px; display: flex; flex-direction: column;">
                      <label for="plot_weather_operator" style="font-size: 13px; margin-bottom: 0.5rem;">Opérateur Météo
                      </label>
                      <v-autocomplete id="plot_weather_operator"
                        v-model="cas_type_to_create_or_update.fournisseurStation" :items="weatherOperators" hide-details
                        item-text="name" item-value="name" class="input_field"
                        style="background-color: white; width: 100%; height:45px" required></v-autocomplete>
                    </div>

                    <!-- Code Station -->
                    <div style="min-width: 100px;display: flex; flex-direction: column;">
                      <label for="plot_station_code" style="font-size: 13px; margin-bottom: 0.5rem;">Code station
                      </label>
                      <input type="text" id="plot_station_code"
                        v-model="cas_type_to_create_or_update.codeStationFournisseur" class="input_field"
                        style="width: 100%; padding: 0.5rem;height:45px; " required />
                    </div>

                    <!-- Bouton Vérifier -->
                    <div style="min-width: 100px; text-align: center; display: flex; align-items: flex-end;">
                      <button type="button" class="add_button input_field"
                        style="width: 100%; font-size: 1rem; color: white; height: 3rem; background-color: #007BFF; border: none; border-radius: 5px;"
                        @click="checkInfosExistIOT" :disabled="isButtonDisabled">
                        Vérifier
                      </button>
                    </div>

                    <!-- Icône de Statut -->
                    <div style="text-align: center; min-width: 20px; display: flex; align-items: flex-end;">
                      <v-icon v-if="checkStatus === 'success'" color="green" class="status-icon">
                        mdi-check-circle-outline
                      </v-icon>
                      <v-icon v-else-if="checkStatus === 'error'" color="red" class="status-icon">
                        mdi-close-circle-outline
                      </v-icon>
                    </div>
                  </v-col>
                </div>

                <!-- Bouton de soumission -->
                <div class="boutons_actions">
                  <button type="submit" :disabled="isFormAddCasTypeInvalid" class="submit-button">
                    <span>{{ isEditMode ? 'Modifier' : 'Enregistrer' }}</span>
                  </button>
                  <button type="button" @click="resetFormCasType" class="reset-button">
                    <v-icon left>mdi-refresh</v-icon> Reset
                  </button>
                </div>
              </form>

            </validation-observer>
          </v-card-text>
        </v-card>
      </section>

      <div>
        <h3 v-on:click="showParcelles = !showParcelles">
          Mes cas-types 
          <span v-if="isAdmin">{{ numberOfCasTypes }}</span>
          <span v-else>({{ numberOfCasTypes }} sur {{ maxCasTypes }})</span>
          <v-icon small class="mr-2" v-if="!showParcelles">
            mdi-chevron-down
          </v-icon>
          <v-icon small class="mr-2" v-else>
            mdi-chevron-up
          </v-icon>
        </h3>

        <div v-if="showParcelles" style="margin-bottom:20px;">
          <template>
              <v-card-title>
                <v-spacer></v-spacer>
              </v-card-title>

              <!-- Champ de recherche invisible -->
              <v-text-field v-model="searchWord" style="display: none;"></v-text-field>

              <!-- Tableau de données MES CAS TYPES-->
              <v-data-table :search="searchWord" :loading="!dataloaded" loading-text="Chargement des données...."
                :headers="headers_cas_type" :items="cas_types_region"
                :footer-props="{'items-per-page-options': [15, 25, 50, 75, 100,-1]}" :items-per-page="-1"
                v-model="arr_select_plot_table" item-key="id" class="my-awesome-table elevation-1" dense fixed-header
                mobile-breakpoint="0">
                <template v-slot:[`item.actions_see_plot`]="{ item }">
                  <v-icon small class="mr-2" @click="showItem(item.id)">
                    mdi-eye
                  </v-icon>
                </template>

                <template v-slot:[`item.parcelle`]="{ item }">
                  {{ item.plot_name }} [{{ item.id }}]
                </template>

                <template v-slot:[`item.risque`]="{ item }">
                  <v-tooltip bottom max-width="350">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small class="mr-2" :color="getColorRisque(item.risque)" v-bind="attrs" v-on="on">
                        {{getIconRisque(item.risque)}}
                      </v-icon>
                    </template>
                    <span v-html="getInfoRisque(item.risque)"></span>
                  </v-tooltip>
                </template>

                <template v-slot:[`item.statut`]="{ item }">
                  <v-tooltip bottom max-width="350">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small class="mr-2" :color="getColorEtatCalcul(item.statut)" v-bind="attrs"
                        v-on="on">
                        {{getIconCalculs(item.statut)}}
                      </v-icon>
                    </template>
                    <span v-html="getInfoEtatCalculs(item.statut)"></span>
                  </v-tooltip>
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-icon style="font-size: 18px; margin-right: .8rem;" small
                    @click="activeEditCasTypeMode(item); filterTableById(item.id)">
                    mdi-pencil
                  </v-icon>
                  <v-icon style="font-size: 18px; margin-left: .8rem;" small
                    @click="confirmDeleteCasType(item);">
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>

              <!-- QUITTER MODIFICATION-->
              <v-btn v-if="isEditMode" color="info" class="mt-4 white--text" @click="cancelModificationMode()">
                <span>Quitter la modification</span>
              </v-btn><br>
              <!--EXPORT XLS-->
    
              <v-btn small color="info" class="mt-2 white--text" @click="exportExcel">
                Export BSV
                <v-icon small class="mr-2">
                  mdi-download
                </v-icon>
              </v-btn>
          </template>

        </div>
      </div>

    </v-container>
  </div>
</template>

<script>
import downloadExcel from "vue-json-excel";
import parcellesMildiouService from "@/service/parcellesMildiou";
import administrationConseillers from "@/service/administrationConseillers";
import { LMap, LTileLayer, LMarker, LIcon, LPopup } from 'vue2-leaflet';
import VChart from 'vue-echarts';
import { getDate, checkIsMobile } from "@/helpers/functions";
import { ValidationObserver } from 'vee-validate';
import ChoixModal from "@/components/modals/ChoixModal.vue";
import ConseillerDetail from "../administration/ConseillerDetail.vue";
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';

export default {
  name: "DetailParcelle_Mildiou",
  props: ['parcelleId'],
  components: {
      downloadExcel,
    parcellesMildiouService,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
    VChart,
    getDate,
    checkIsMobile,
    ValidationObserver,
    ChoixModal,
    LMap
   // CommentaireComponent


  },
  data() {
    return {
      oad: 'MILDIOU',
      selected_plot: null,
      cas_type_export: [],

      synchroniseStation: false,
      iot_exists : null,

      isEditMode:false,
      isMobile: false,

      loadingStations:false,

      searchWord: "",
      cas_types_region: [],
      arr_select_plot_table:[],
      varieties_list:[],

      minDate: '',
      maxDate: '',

      id_plot_selected_to_edit :null,
      showParcelles:true,

      // MAP
      carte:{
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        center: [ 46.76782, 2.43129 ],
        zoom: 6,
        markers: [],
      },

      stations_list : [],
      selected_stationMF:{},

      // Gestion des volets cas type
      ouvrir_ajout_cas_type:false,
      ouvrir_ajout_mes_cas_types:false,

      select_castype_to_delete:[],

      select_plot_to_edit:[],

      cas_type_to_create_or_update: {
        name: '',
        city: '',
        zipcode: '',
        variety: '',
        plantation:'',
        area: null,
        fournisseurStation: '',
        codeStationFournisseur: '',
        nomStation: '',
        latitude: 0,
        longitude: 0,
        organism:'BSV',
        region:null
      },
      weatherOperators:[],
      checkStatus: '', 
      isButtonDisabled: true,
      isVerified:false,
  
      email: this.$store.state.user.user.email,
      droits: this.$store.state.user.user.droitsTPC,
      isAdmin: 
      this.$store.state.user.user.droitsTPC.some(droit => 
          droit.code === 'ADMIN' && droit.lecture && droit.ecriture
      ), 
//      false,
      infos_conseiller  : null,
      dataloaded: false,
      filter: null,
      headers_cas_type: [
        //colonnes non visibles mais permettant de faire une recherche sur le contenu
        { text: 'Nom parcelle', value: 'plot_name', align: ' d-none' },
        { text: 'Id parcelle', value: 'id', align: ' d-none' },
        { text: 'Nom agri', value: 'nom_agri', align: ' d-none' },
        { text: 'Prénom agri', value: 'prenom_agri', align: ' d-none' },
        //colonnes visibles
        { text: "", value: "actions_see_plot", sortable: false},
        { text: 'Agriculteur', value: 'farmer',  align: ' d-none'},
       // { text: "Variété", value: "variete"},
        { text: "Parcelle", value: "parcelle"},
        { text: 'Etat calculs', value: 'statut'},
        { text: 'Risque Mildiou', value: 'risque'},
        { text: "", value: "actions", sortable: false},
      ],
    };
  },

  created(){

    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');

        // Date du jour
    this.maxDate = `${year}-${month}-${day}`;
    
    // 1er mars de l'année en cours
    this.minDate = `${year}-03-01`;

  },

  computed: {
    
      /**
       *  * @returns {boolean} true si un des champs est manquant ou non défini, sinon false
       */
      isFieldsIOTValid() {
        // Retourne true si les champs sont valides, sinon false
        const { fournisseurStation, codeStationFournisseur } = this.cas_type_to_create_or_update;
        return Boolean(fournisseurStation && codeStationFournisseur && fournisseurStation.trim() && codeStationFournisseur.trim());
      },

      isFormAddCasTypeInvalid() {
        let plot = this.cas_type_to_create_or_update;
        let isLimitReached = null;

        if(!this.isEditMode){
          // Vérifie si la limite maximale de types de cas est atteinte en creation de cas type seulement
          isLimitReached = this.isMaxCasTypesReached;
        }
        

        // Si la case "Je synchronise une autre station" est cochée
        if (this.synchroniseStation) {
            let isFournisseurFilled = plot.fournisseurStation && plot.codeStationFournisseur;
            let isIOTCheckSuccessful = this.checkStatus === 'success';

            // Tous les champs requis pour la synchronisation et l'IOT doivent être remplis et validés
            return !plot.name || !isFournisseurFilled || !isIOTCheckSuccessful || isLimitReached;
        }

        // Validation de base : les champs "name" et "station" doivent être remplis
        let isBaseFieldsFilled = plot.name && plot.station;
        return !isBaseFieldsFilled || isLimitReached;
    },



    choixSelected() {
      return this.$store.state.choix.choixSelected;
    },

    numberOfCasTypes() {
      return this.cas_types_region ? this.cas_types_region.length : 0;
    },
    maxCasTypes() {
      if (this.isAdmin) {
        return ''; 
      }
      return this.infos_conseiller  && this.infos_conseiller [0] ? this.infos_conseiller [0].nb_cas_type : 0;
    },

    isMaxCasTypesReached() {
      if (this.isAdmin) {
        return false; //limite des cas-types n'est pas pertinente pour un admi
      }
      // Vérifie si le nombre actuel de cas-types est égal ou supérieur au nombre maximum autorisé
      return this.numberOfCasTypes >= this.maxCasTypes;
    }

  },

  watch: {

    cas_type_to_create_or_update: {
      handler() {
        this.isButtonDisabled = !this.isFieldsIOTValid;
      },
      deep: true 
    },

    synchroniseStation(newValue) {
    if (newValue) {
      this.cas_type_to_create_or_update.station = null; // Vider le champ "Je choisis une station"
      this.updateMapForStation(null);
    }
  },

    choixSelected: function () {
      //appelle la function dont le nom est stocké dans le store
      if (this.choixSelected != "") {
        this[this.choixSelected]();
      }
    }

  },

  async mounted() {   

    this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
    // Vérifier la taille de l'écran une fois la page chargée
    this.isMobile = checkIsMobile();

    this.infos_conseiller  = await administrationConseillers.getInfosConseillerBSV ();

    this.weatherOperators = await parcellesMildiouService.getListesConstructorIOT();
    if(this.weatherOperators){
      // Ajouter l'élément "ARVALIS"
      this.weatherOperators.push({name: 'ARVALIS'});
    }
  
    /////////////////////////////////////////////////////////////////
    // Récupérer les informations des cas types

    if(this.isAdmin){
      this.cas_types_region = await parcellesMildiouService.getCasTypeRegion(null);
    }else {
      this.cas_types_region = await parcellesMildiouService.getCasTypeRegion(this.infos_conseiller[0].region_bsv_mildiou);
    }

    //MAP
    this.loadStations(); 

    this.dataloaded = true;
    this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });

    this.varieties_list = await parcellesMildiouService.getListeVariety(this.$route.params.id);


  },

  
  methods: {

    checkDroits(droit, type) {
      var index = this.droits.findIndex(item => item.code === droit)
      if (index >= 0 && this.droits[index][type] == true) {
        return true
      } else {
        return false
      }
    },

    toggleAjoutCasType() {
      this.ouvrir_ajout_cas_type = !this.ouvrir_ajout_cas_type;
      if (this.isMaxCasTypesReached) {
        this.$store.dispatch('informations/setInfo', {
            message: {
              titre: "Attention",
              description: `Nombre maximal de cas-types atteint (${this.maxCasTypes}) : vous ne pouvez plus en créer de nouveaux.`
            }
          });
        return; 
      }
  },

  async checkInfosExistIOT() {
    try {
      if (!this.isFieldsIOTValid) {
        this.checkStatus = 'error'; // Affiche une erreur si les champs ne sont pas remplis
        this.isButtonDisabled = true; // Désactiver le bouton de vérification si les champs sont invalides
        this.isVerified = false; // Indiquer que la vérification n'a pas été effectuée
        return;
      }

      // Appeler le service pour vérifier l'existence des infos IOT
      this.iot_exists = await parcellesMildiouService.getInfosExistIOT(
        this.cas_type_to_create_or_update.fournisseurStation,
        this.cas_type_to_create_or_update.codeStationFournisseur,
        'bsv'
      );

      // Vérifier si la station existe
      if (this.iot_exists.exists) {
        this.checkStatus = 'success'; // Indiquer que la station existe
        this.isButtonDisabled = false; // Activer le bouton si la vérification est réussie
        this.isVerified = true; // Confirmer que la vérification a été effectuée
      } else {
        this.checkStatus = 'error'; // Indiquer que la station n'existe pas
        this.isButtonDisabled = true; // Désactiver le bouton si la vérification échoue
        this.isVerified = false; // Indiquer que la vérification a échoué
      }
    } catch (error) {
      // Gérer les erreurs en cas de problème réseau ou autre exception
      this.checkStatus = 'error';
      this.isButtonDisabled = true; // Désactiver le bouton en cas d'erreur
      this.isVerified = false; // Indiquer que la vérification a échoué

    }
  },

    chooseStation(selectedStation) {
      // Si la station appartient à METEO FRANCE, on modifie la propriété
      if (selectedStation.propriete === "METEO FRANCE") {
          selectedStation.propriete = "METEO_FRANCE";
          this.selected_stationMF = selectedStation;
      }

      // Mise à jour de l'objet `cas_type_to_create_or_update` avec les informations de la station sélectionnée
      this.cas_type_to_create_or_update.nomStation = selectedStation.nom_usuel;
      this.cas_type_to_create_or_update.latitude = selectedStation.latitude;
      this.cas_type_to_create_or_update.longitude = selectedStation.longitude;
      this.cas_type_to_create_or_update.device_iot = {
          constructor: selectedStation.propriete,
          device_code: selectedStation.ind_station
      };
    },

    async submitFormAddCasType() {

      // LOADER TRUE
      this.$store.dispatch('general/setWaitingOverlay', {loading: true,fullpage: true});
      let message = '';

      // SI STATION MF =>  Envoi d'une nouvelle station MF ds API IOT
      if (this.selected_stationMF.propriete =='METEO_FRANCE') {
        await parcellesMildiouService.postStationMFinIOT(this.selected_stationMF);
      }

      // * Création des propriétés du cas-type
      const currentYear = new Date().getFullYear();
      this.cas_type_to_create_or_update.plantation = `${currentYear}-01-01`;
      this.cas_type_to_create_or_update.variety = 'AUTRE VARIETE SENSIBLE (MILDIOU)';
      this.cas_type_to_create_or_update.region = this.infos_conseiller[0].region_bsv_mildiou;

      // Si la checkbox 'synchronise une autre station' est cochée => utiliser les valeurs des champs en PRIORITE
      if (this.synchroniseStation) {
        if (this.iot_exists && this.iot_exists.data_geographiques) {
            this.cas_type_to_create_or_update.device_iot = {
                constructor: this.cas_type_to_create_or_update.fournisseurStation,
                device_code: this.cas_type_to_create_or_update.codeStationFournisseur,
            };
            this.cas_type_to_create_or_update.longitude = this.iot_exists.data_geographiques.longitude;
            this.cas_type_to_create_or_update.latitude = this.iot_exists.data_geographiques.latitude;
        } else {
            this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
            return; // Arrêtez l'exécution si les données ne sont pas disponibles
        }
    } else { 
        // Si checkbox PAS cochée, utiliser les valeurs de la station sélectionnée dans la MAP
        this.cas_type_to_create_or_update.fournisseurStation = this.selected_stationMF.propriete;
        this.cas_type_to_create_or_update.codeStationFournisseur = this.selected_stationMF.ind_station;
        this.cas_type_to_create_or_update.device_iot = {
                constructor: this.selected_stationMF.propriete,
                device_code: this.selected_stationMF.ind_station,
        };
    }

      // SWITCH SCENARIO MODIFICATION OR CREATION
      if (this.isEditMode) { // MODIFICATION du cas-type
        message = "Modification";
        let id_cas_type = this.id_plot_selected_to_edit;
        await parcellesMildiouService.editOnePlotToApiMildiou(id_cas_type, this.cas_type_to_create_or_update,'bsv')
      } else { // CREATION du cas-type
        message = "Création";
        // * Lancer la création du cas-type et reset du formulaire et affichage des inofs
        await parcellesMildiouService.addOnePlotToApiMildiou(this.cas_type_to_create_or_update,'bsv');
      }

      // Affichage : Reset du formulaire et affichage des infos 
      this.resetPlotForm();
      await this.updateDataAfterAction(message);
    },

    submitFormDeleteCasType() {
      this.closeChoix();
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      let id_plot = this.select_castype_to_delete.id;
      //  Suppression 1 parcelle 
      parcellesMildiouService.deleteOnePlotApiMildiou(id_plot)
        .then(() => {
          let message = "Suppression";
          this.updateDataAfterAction(message);
        })
        .catch(error => console.error("Erreur lors de la suppression du cas type :", error));
    },

    /**
     * Passer en mode EDITION/MODIFICATION sur le cas type cliqué
     */
    async activeEditCasTypeMode(item) {
      try {
        this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: false });

        this.isEditMode = true; // Activer le mode MODIFICATION
        this.id_plot_selected_to_edit = item.id;
        this.select_plot_to_edit = item;
        this.arr_select_plot_table = [item];
        this.ouvrir_ajout_cas_type = true;

        // Récupérer toutes les données nécessaires d'un cas type pour alimenter les champs
        await Promise.all([
          this.loadCasTypeData()
        ]);

        // Désactiver le mode overlay après le chargement complet
        this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
      } catch (error) {
        this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
      }
    },

    cancelModificationMode(){
      this.resetPlotForm()
      this.isEditMode = false;
      this.id_plot_selected_to_edit = null;
      this.select_plot_to_edit = null;
      // Réinitialiser arr_select_plot_table
      this.arr_select_plot_table = this.cas_types_region.slice();  

    },


     /**
     * Obtenir des données du cas type 
     */
     async loadCasTypeData() {
      try {
        const cas_type = await parcellesMildiouService.getOnePlotApiMildiou(this.id_plot_selected_to_edit);
        const cas_type_returned_by_api = cas_type[0];

        // Recherche de la phase de plantation avec un nom spécifique
        const plantationPhase = cas_type_returned_by_api.phases.find(phase => phase.name === 'PLANTATION') || { date: '' };

        

        //Champs du formulaire et autres infos du cas type en cours de modification
        this.cas_type_to_create_or_update = {
          id: cas_type_returned_by_api.id,
          name: cas_type_returned_by_api.name,
          latitude: cas_type_returned_by_api.latitude,
          longitude: cas_type_returned_by_api.longitude,
          variety: cas_type_returned_by_api.variety,
          plantation: plantationPhase.date,
          area: cas_type_returned_by_api.area,
          fournisseurStation: null ,
          codeStationFournisseur: null ,
          station: null,
          organism: 'BSV'
        };

        //Deployer les champs si la station du cas type n'est pas issue de la carte MAP (actuellement METEO FRANCE)
        if( cas_type_returned_by_api.device_iot.constructor!='METEO_FRANCE' &&cas_type_returned_by_api.device_iot.device_code !=null){
          this.synchroniseStation = true ;

          //Remplir les 2 champs du formulaire selon les infos stations
          this.cas_type_to_create_or_update = {
            fournisseurStation: cas_type_returned_by_api.device_iot?.constructor ,
            codeStationFournisseur: cas_type_returned_by_api.device_iot?.device_code ,
          };
        }

        // Remplir le champ du nom du cas type :
        this.cas_type_to_create_or_update.name = cas_type_returned_by_api.name;

        //Normaliser et utiliser `device_code` unique pour trouver la station
        const expectedDeviceCode = cas_type_returned_by_api.device_iot?.device_code?.trim();

        //Si station METEO FRANCE - l'afficher sur la carte
        let selectedStation = null;
        if(cas_type_returned_by_api.device_iot.constructor == 'METEO_FRANCE'){
          selectedStation = this.stations_list.find(
          station => String(station.ind_station).trim() === expectedDeviceCode);
          // Assigner la station trouvée pour l'utiliser dans v-autocomplete
          this.cas_type_to_create_or_update.station = selectedStation;
          this.updateMapForStation(selectedStation);
        }else{
          // Rechercher la station par `device_code`
          selectedStation = this.stations_list.find(
          station => String(station.ind_station).trim() === expectedDeviceCode
          );
        }
      } catch (error) {
        console.error("Erreur lors du chargement des données du cas type:", error);
      }
    },

    showItem(id_parcelle, type = null) {
      // Stockage de la parcelle sélectionnée dans le store
      let route = this.$router
        .resolve({
          name: 'mildiou-detail-parcelle',
          params: {
            id: id_parcelle
          }
        });

      if (type == 'href') {
        return route.href;
      } else {
        window.open(route.href);
      }
    },
    goTo(nomDiv) {
      this.$vuetify.goTo('#' + nomDiv)
    },

    filterTableById(id) {
      // Mettre à jour la variable de recherche avec l'ID de l'élément pour filtrer le tableau
      this.searchWord = id.toString(); // Utiliser l'ID pour la recherche
    },

    /**
     * Mettre à jour les données après une action (ajout, modification, suppression)
     * @param {string} typeMessage Message de succès à afficher à l'utilisateur
     */
     async updateDataAfterAction(typeMessage) {
      // Définir les régions à récupérer en fonction de l'utilisateur
      let regions = this.isAdmin ? null : this.infos_conseiller[0].region_bsv_mildiou;

      try {
        // Récupération des données
        const data = await parcellesMildiouService.getCasTypeRegion(regions);
        
        // Mettre à jour le tableau de données
        this.cas_types_region = data;

        // Actions de mise à jour de l'interface utilisateur
        this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
        this.$store.dispatch('informations/setInfo', {
          message: {
            titre: "Action bien effectuée",
            description: `${typeMessage} cas type bien réalisée.`
          }
        });
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
        // Optionnel : informer l'utilisateur de l'erreur
        this.$store.dispatch('informations/setInfo', {
          message: {
            titre: "Erreur",
            description: "La récupération des données a échoué."
          }
        });
      }
    },


    getColorRisque(statut) {
      if (statut == 10) return 'green'
      else if (statut == 50) return 'red'
      else if (statut == 40) return 'orange'
      else return 'grey'
    },
    getIconRisque(statut) {
      if (statut == 10) return 'mdi-emoticon-happy-outline'
      else if (statut == 50) return 'mdi-emoticon-sad-outline'
      else if (statut == 40) return 'mdi-emoticon-neutral-outline'
      else return 'mdi-close-thick'
    },
    getInfoRisque(statut) {
      if (statut == 10) return "Le climat n'est pas favorable et/ou la quantité de spores dans l'environnement n'est pas suffisante. Il n'y a donc <b>pas de risque</b> de contamination sur la parcelle."
      else if (statut == 50) return "Le climat et la quantité de spores sont favorables, il y a donc la présence d'un <b>risque de contamination</b> sur la parcelle."
      else if (statut == 40) return "Le climat est presque favorable et la quantité de spores est suffisante. Il n'y a donc pour le moment pas de risque de contamination mildiou sur la parcelle, mais il est nécessaire de <b>rester vigilant</b> au cas où les conditions climatiques deviennent favorables. Un risque de contamination serait alors présent."
      else return ''
    },

    getColorEtatCalcul(statut) {
      if (statut == 'calculajour') return 'green'
      else return 'red'
    },
    getIconCalculs(statut) {
      if (statut == 'calculajour') return 'mdi-check-circle'
      else return 'mdi-close-circle'
    },
    getInfoEtatCalculs(statut) {
      if (statut == 'calculajour') return 'Calculs dans les dernières heures'
      else return 'Calculs datés'
    },

  formatDate(dateString) {
    if (!dateString || !dateString.includes('-')) return ''; 
    const [day, month, year] = dateString.split('-');
    if (!day || !month || !year) return '';
    return `${day}-${month}-${year}`;
  }
,

confirmDeleteCasType(item){
      this.select_castype_to_delete = item;
      this.$store.dispatch("choix/setChoix", {
        question: "Suppression de cas-type",
        detail: " Voulez-vous vraiment supprimer le cas-type : " + item.plot_name + "?",
        choix: {
          choix1: { text: "Oui", function: "submitFormDeleteCasType" },
          choix2: { text: "Annuler", function: "closeChoix" },
        },
      });
    },

    closeChoix() {
      this.$store.dispatch("choix/unsetChoix");
    },

    async deleteItem(){
      this.closeChoix()
      var isDeleted = null
      isDeleted = await consentementService.deleteLienConseiller(this.delete_id);
    
      if(isDeleted == true){
            this.getInfosConseillersAgri()
      } else {
          alert('Un problème est survenu.')
      }
      this.delete_id = null;
    },

    generateUniqueId() {
      return `item_${index}_${new Date().getTime()}`;  
    },

  prepareData() {
    this.formattedTraitements = data.map((item, index) => ({
        ...item,
        unique_id: this.generateUniqueId(index),
      }));
  },


resetPlotForm() {
 // Réinitialiser les champs du formulaire
 this.cas_type_to_create_or_update = {
            id: '',
            name: '',
            latitude: '',
            longitude: '',
            variety: null, 
            plantation: '',
            area: '',
            organism: 'BSV',
        };

        this.select_plot_to_edit.id = ''; 

        this.checkStatus = ''; 
        this.isButtonDisabled = false; 
        this.isEditMode = false; 

        this.searchWord ='';
        this.ouvrir_ajout_cas_type = false;
        this.loadStations();
      },

  getNomFichier(){
      let now = new Date()
      now = getDate(now,'yyyymmdd')
      return now+'_Export_BSV_Mildiou_cas_type'
    },

    resetFormCasType() {
      // Réinitialiser l'objet cas_type_to_create_or_update
      this.cas_type_to_create_or_update = {
        name: '',
        station: '',
        fournisseurStation: '',
        codeStationFournisseur: '',
        organism:'BSV'
      };

      // Réinitialiser les autres champs du formulaire
      this.synchroniseStation = false;
      this.checkStatus = '';
      this.isButtonDisabled = false;
    },


    // MAP 
    getPositionCarte() {
    var array = [];
    var sumLat = 0;
    var sumLong = 0;
    var nbStation = 0;

    this.stations_list.forEach(station => {
    // Vérification stricte de la latitude et longitude
    let latitude = parseFloat(station.latitude);
    let longitude = parseFloat(station.longitude);

    if (!isNaN(latitude) && !isNaN(longitude)) {
        array.push({
            id: station.ind_station, // Identifiant de la station
            coordinates: [latitude, longitude], // Coordonnées de la station
            imageUrl: require('@/assets/map_icon_blue3.png'), // Icône sur la carte
            infobulle: `
            <div>
              <p><strong>Nom station ARVALIS :</strong> ${station.nom_commune}</p>
              <p><strong>Propriétaire :</strong> ${station.propriete}</p>
            </div>
          `
        });

            // Calcul de la somme des latitudes et longitudes pour le centre de la carte
            sumLat += latitude;
            sumLong += longitude;
            nbStation++;
        }
    });

    // Vérifier si au moins une station valide est présente
    if (nbStation > 0) {
        var centerLat = sumLat / nbStation;
        var centerLong = sumLong / nbStation;

        this.carte.markers = array; // Ajout des marqueurs à la carte
        this.carte.zoom = 6; // Zoom initial
        this.carte.center = [centerLat, centerLong]; // Centre de la carte
    } else {
        console.error("Aucune station avec des coordonnées valides n'a été trouvée.");
    }
},



  customFilter(station, query) {
      const searchTerm = query.toLowerCase();
      return (
        station.nom_commune.toLowerCase().includes(searchTerm) || // Recherche dans le nom de la commune
        station.propriete.toLowerCase().includes(searchTerm)      // Recherche dans la propriété
      );
    },

    async loadStations() {
      let userDepartements = null
      if (!this.isAdmin) {
        let departementsString = this.infos_conseiller [0].departements;
        let cleanedDepartementsString = departementsString
          .replace(/'/g, '"') // Remplacer les apostrophes par des guillemets
          .replace(/(\{|\})/g, ''); // Enlever les accolades
        // Convertir en tableau et retirer les guillemets
        userDepartements = cleanedDepartementsString.split(',')
          .map(departement => departement.trim().replace(/"/g, ''));
      }

      // Récupérer toutes les stations selon l'user 
      let allStations = await parcellesMildiouService.getStationMFcsv(userDepartements);

      // Mettre à jour la liste des stations
      this.stations_list = allStations;
      this.loadingStations = true;

      // Appel de la fonction pour mettre à jour la carte
      this.getPositionCarte();
    },


   updateMapForStation(selectedStation) {
    if (!selectedStation) {
        // Filtrer et afficher les stations valides (toutes les stations avec des coordonnées valides)
        this.carte.markers = this.stations_list
            .filter(station => station.latitude && station.longitude && !isNaN(station.latitude) && !isNaN(station.longitude))
            .map(station => ({
                id: station.ind_station,
                coordinates: [parseFloat(station.latitude), parseFloat(station.longitude)],
                imageUrl: require('@/assets/map_icon_blue3.png'),
                infobulle: `
                    <div>
                        <p><strong>Nom station ARVALIS :</strong> ${station.nom_commune}</p>
                        <p><strong>Propriétaire :</strong> ${station.propriete}</p>
                    </div>
                `
            }));

        this.carte.center = [48.8566, 2.3522]; // Centre par défaut
        this.carte.zoom = 5; // Zoom par défaut

        // Réinitialisation du nom de la station dans le cas type
        this.cas_type_to_create_or_update.nomStation = '';
        return;
    }

    // Si une station spécifique est sélectionnée, afficher uniquement cette station sur la carte
    this.carte.markers = [{
        id: selectedStation.ind_station,
        coordinates: [parseFloat(selectedStation.latitude), parseFloat(selectedStation.longitude)],
        imageUrl: require('@/assets/map_icon_blue3.png'), // Icône spéciale pour station sélectionnée
        infobulle: `
            <div>
                <p><strong>Nom station ARVALIS :</strong> ${selectedStation.nom_commune}</p>
                <p><strong>Propriétaire :</strong> ${selectedStation.propriete}</p>
            </div>
        `
    }];

    // Centrer et zoomer sur la station sélectionnée
    this.carte.center = [parseFloat(selectedStation.latitude), parseFloat(selectedStation.longitude)];
    this.carte.zoom = 6;
    this.chooseStation(selectedStation);

    // Mettre à jour le nom de la station dans `cas_type_to_create_or_update`
    this.cas_type_to_create_or_update.nomStation = selectedStation.nom_commune;
},

    zoomUpdated(zoom) {
      this.zoom = zoom;
    },

    centerUpdated(center) {
      this.center = center;
    },

    onStationSelectedOrChanged(station_selected) {
      // Mettre à jour la carte selon la valeur sélectionnée
      this.updateMapForStation(station_selected || null);

      // Réinitialiser les autres champs
      this.cas_type_to_create_or_update.fournisseurStation = null;
      this.cas_type_to_create_or_update.codeStationFournisseur = '';
      this.synchroniseStation = false;
    },


    //EXPORT XLS BSV
    async exportExcel() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Export');

      // en-têtes 1/2
      worksheet.addRow([
        'Stations météo',
        'Parcelle',
        'last_data_meteo',
        'Jours où seuil de nuisibilité atteint', '', '', '', '', '', '',
        'Poids de contamination', '', '', '',
        'Reserve de spores', '', '', '',
        'Index Conta.', '', '', '',
        'Potentiel de sporulation', '', '', '',
        'RR (mm) J-7 à J-1'
      ]);
      // en-têtes 2/2
      worksheet.addRow([
        '', 
        '',
        '',
        'J-7', 'J-6', 'J-5', 'J-4', 'J-3', 'J-2', 'J-1',
        'J reel', 'J previ', 'J+1', 'J+2',
        'J reel', 'J previ', 'J+1', 'J+2',
        'J reel', 'J previ', 'J+1', 'J+2',
        'J reel', 'J previ', 'J+1', 'J+2',
        ''
      ]);

      // Ajout des données
      this.cas_types_region.forEach(data => {
        // Préparer les données avec gestion des null et undefined
          let formatValue = (value) => (value === null || value === undefined) ? "N/A" : value;

          // Gestion spécifique pour la colonne 'station'
          let deviceConstructor = formatValue(data.device_constructor) === "N/A" ? "" : data.device_constructor;
          let deviceCode = formatValue(data.device_code) === "N/A" ? "" : data.device_code;
          let station = (deviceConstructor && deviceCode) 
              ? `${deviceConstructor} - ${deviceCode}` 
              : deviceConstructor || deviceCode || "N/A";

          const rowData = [
            station,                              // Colonne A = 'constructor + device code'
            formatValue(data.plot_name),          // Colonne B = 'Parcelle météo'
            formatValue(data.last_meteo_data),    // Colonne C = 'Dernière donnée météo'

            //Jours ou seuil de nuisibilité atteint
            formatValue(data.l10poids_jm7_reel),  // Colonne D = 'J-7'
            formatValue(data.l10poids_jm6_reel),  // Colonne E = 'J-6'
            formatValue(data.l10poids_jm5_reel),  // Colonne F = 'J-5'
            formatValue(data.l10poids_jm4_reel),  // Colonne G = 'J-4'
            formatValue(data.l10poids_jm3_reel),  // Colonne H = 'J-3'
            formatValue(data.l10poids_jm2_reel),  // Colonne I = 'J-2'
            formatValue(data.l10poids_jm1_reel),  // Colonne J = 'J-1'

            //Poids de contamination
            formatValue(data.l10poids_j_reel),    // Colonne K = 'J réel'
            formatValue(data.l10poids_j_previ),   // Colonne L = 'J prévi'
            formatValue(data.l10poids_jp1_previ), // Colonne M = 'J+1'
            formatValue(data.l10poids_jp2_previ), // Colonne N = 'J+2'

            //Réserve de spores
            formatValue(data.l10spores_j_reel),   // Colonne O = 'J réel'
            formatValue(data.l10spores_j_previ),  // Colonne P = 'J prévi'
            formatValue(data.l10spores_jp1_previ),// Colonne Q = 'J+1'
            formatValue(data.l10spores_jp2_previ),// Colonne R = 'J+2'

            //Index de Conta
            formatValue(data.indexconta_j_reel),  // Colonne S = 'J réel'
            formatValue(data.indexconta_j_previ), // Colonne T = J prévi'
            formatValue(data.indexconta_jp1_previ),// Colonne U = 'J+1'
            formatValue(data.indexconta_jp2_previ),// Colonne V = 'J+2'

            //Potentiel de sporulation
            formatValue(data.l10spospo_j_reel),   // Colonne W = 'J réel'
            formatValue(data.l10spospo_j_previ),  // Colonne X = 'J prévi'
            formatValue(data.l10spospo_jp1_previ),// Colonne Y = 'J+1'
            formatValue(data.l10spospo_jp2_previ),// Colonne Z = 'J+2'
            
            //PLUIE 7 J
            formatValue(data.total_pluie_7j)      // Colonne AA = 'RR'
        ];

       // Ajouter la ligne de données
        const row = worksheet.addRow(rowData);

        // Appliquer des bordures et un style conditionnel
        row.eachCell((cell, colNumber) => {
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };

            // Appliquer un style : 
            //remplir en rouge si valeur > 2 POUR les colonnes 'jours où seuil de nuisibilité atteint' -> 'J PREVI de poids de conta' soit jusqu'à la colonne <= 12 (colonne "L")
            if (colNumber <= 12 && typeof cell.value === 'number' && cell.value > 2) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFF0000' } // Rouge
                };
            }
             // Remplir en vert si valeur XXXXX à 10  pour les colonnes XXXX     fgColor: { argb: 'FF00FF00' } // Vert

            // Remplir en jaune si valeur XXXXX à 40  pour les colonnes XXXX    fgColor: { argb: 'FFFFA500' } // Jaune orangé (teinte d'orange clair)

            // Remplir en rouge si valeur XXXXX à 50  pour les colonnes XXXX    fgColor: { argb: 'FFFF0000' } // Rouge

        });

      });

      // Fusionner les cellules (selon structure fournie)
     // worksheet.mergeCells('A1:C2');     // Fusion des cellules pour inclure "Stations météo" et "Nom de la station"
      worksheet.mergeCells('D1:J1');     // Fusion pour "Jours où seuil de nuisibilité atteint"
      worksheet.mergeCells('K1:N1');     // Fusion pour "Poids de contamination"
      worksheet.mergeCells('O1:R1');     // Fusion pour "Reserve de spores"
      worksheet.mergeCells('S1:V1');     // Fusion pour "Index Conta."
      worksheet.mergeCells('W1:Z1');     // Fusion pour "Potentiel de sporulation"
      worksheet.mergeCells('AA1:AA2');   // Fusion pour "RR (mm) J-7 à J-1"

      // Appliquer le style aux en-têtes fusionnées
      let headerRow1 = worksheet.getRow(1);
      let headerRow2 = worksheet.getRow(2);

      [headerRow1, headerRow2].forEach(row => {
        row.eachCell(cell => {
          // Style de la police et du fond
          cell.font = {
            bold: true,
            color: { argb: 'FF000000' } // Texte en noir
          };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFD3D3D3' } // Fond gris clair
          };
          cell.alignment = { vertical: 'middle', horizontal: 'center' };

          // Bordures autour de chaque cellule de l'en-tête
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        });
      });


      // Générer et télécharger le fichier Excel
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: 'application/octet-stream'
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'export_simple.xlsx';
      a.click();
      window.URL.revokeObjectURL(url);
    }





    ////////////////////////

  } // FIN de la PARTIE des METHODES

};
  

</script>

<style scoped>

@media (max-width: 800px) {
  #name {
    width: 100% !important;
  }
}


/* Désactiver les spinners pour Chrome, Safari, Edge et Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Désactiver les spinners pour Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.boutons_actions {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	align-items: center;
	align-content: stretch;
}

#form_cas_type label{
  font-size:1rem !important;
}
input{
  background-color: #fff;
  padding:0.12rem;
  font-size:0.8rem !important;
}

#form_cas_type label,
#form_cas_type p {
  font-size: 1rem !important;
}

#edit_plot_form{
    display: flex; 
    flex-direction: column; 
  }

  #edit_plot_form fieldset{
    margin:0.4rem;
    padding:0.4rem;
    border:1px solid #82ac00;
    border-radius:5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
  }

  #edit_plot_form .field{
    margin:0.4rem;
    background-color: #fff !important;
  }

.input_field {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid grey;
  }

  .submit-button {
    width: 50%;
    padding: 15px;
    border-radius: 5px;
    background-color: #82ac00;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .add_button{
    background-color:#82ac00 !important;
    font-size: 1.25rem;
  }

  legend{
    font-size:1.2em;
    font-weight:bold;
    color:#82ac00;
  }

  i.icon_h3{
    font-size:1.15em !important;
  }

.form-row {
  display: flex; /* Utilise Flexbox pour l'agencement */
  flex-wrap: wrap; /* Permet aux éléments de se déplacer à la ligne suivante si nécessaire */
  gap: 16px; /* Ajoute un espacement entre les éléments */
}

.form-row > div {
  flex: 1; /* Permet aux éléments de prendre un espace égal */
  min-width: 200px; /* Assure une largeur minimale pour les champs */
}


.input_field {
  width: 100%;
}

.submit-button {
  align-self: center;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.status-icon {
  font-size: 32px;
  margin-left: 16px;
}

form div.col-md-8{
  padding:1.2rem !important;
}



</style>