<template>
  <div class="about">
    <h1 class='text-center' style="margin-bottom:40px;">Bienvenue sur VISIOFARM</h1>
    <div>
      Cette plateforme a pour but d'exposer les données issus des modèles ARVALIS.
      <br>Si vous rencontrez un problème lors de l'utilisation de cette plateforme, veuillez envoyer un mail à <a href="mailto:support.api@arvalis.fr">support.api@arvalis.fr</a>
    </div>
    <div style="text-align:center;width:900px;position:fixed;bottom: 70px;">
      <img src="@/assets/logo-arvalis-color.png" style="width: 250px;" />
    </div>
  </div>
</template>

<script>


  export default {
    name: 'Home',

  }
</script>

<style>

@media only screen and (max-width: 960px) {
  .about{
    width:80% !important;
  }
}

</style>

