<template>
  <div>
    <v-container style="margin-top: 10px">
      <h3 v-on:click="ouvrir_ajout_parcelle = !ouvrir_ajout_parcelle">
        {{ !isEditMode ? 'Création ' : ' Modifier' }} parcelle
        <v-icon small class="mr-2" v-if="!ouvrir_ajout_parcelle">
          mdi-chevron-down
        </v-icon>
        <v-icon small class="mr-2" v-else>
          mdi-chevron-up
        </v-icon>
      </h3>

      <!-- Création parcelle -->
      <div>
        <h4 v-on:click="ouvrir_caracteristique_parcelle = !ouvrir_caracteristique_parcelle" v-if="ouvrir_ajout_parcelle">Caractéristiques Parcelle
          <v-icon small class="mr-2" v-if="!ouvrir_caracteristique_parcelle">
          mdi-chevron-down
        </v-icon>
        <v-icon small class="mr-2" v-else>
          mdi-chevron-up
        </v-icon>
        </h4>
        <v-card v-if="ouvrir_caracteristique_parcelle" style="margin:0.4rem; background-color: rgb(250, 250, 250)">
          <v-card-text style="height: auto; margin: 0 auto;">
            <validation-observer ref="observer">
              <form @submit.prevent="submitFormAddOrPutPlot" id="edit_plot_form" class="row"
                style="display: flex; flex-direction: column; background-color:rgb(240, 240, 240)">
                <input type="hidden" v-model="select_plot_to_edit.id">
                  <!--INFOS générales de la parcelle-->
                  <div class="form-row">
                    <div style="margin-bottom: 15px; width:125px; margin:0.4rem">
                      <label for="name">Nom</label>
                      <input class="input_field" type="text" id="name" v-model="plot_to_put_or_create.name" required />
                    </div>
                    <div style="margin-bottom: 15px; width:125px; margin:0.4rem">
                      <label for="latitude">Latitude (ex: 48.329)</label>
                      <input class="input_field" type="float" id="latitude" v-model="plot_to_put_or_create.latitude" required   @wheel.prevent   @keydown="disableArrowKeys($event)" />
                    </div>
                    <div style="margin-bottom: 15px; width:125px; margin:0.4rem">
                      <label for="longitude">Longitude (ex: 2.382)</label>
                      <input class="input_field" type="float" id="longitude" v-model="plot_to_put_or_create.longitude" required   @wheel.prevent  @keydown="disableArrowKeys($event)" />
                    </div>
                  </div>

                  <div class="form-row">
                    <div style=" width:40% !important; margin:0.4rem">
                      <label for="variety">Varieté</label>
                      <v-autocomplete
                        v-model="plot_to_put_or_create.variety"
                        :items="varieties_list"
                        item-text="name"
                        item-value="name" 
                        class="input_field"
                        hide-details
                        style="background-color : white"
                        ></v-autocomplete>
                    </div>
                    <div style="margin-bottom: 15px; width:125px; margin:0.4rem">
                      <label for="plantation">Date de plantation</label>
                      <input class="input_field" type="date" id="plantation" v-model="plot_to_put_or_create.plantation" required />
                    </div>
                    <div style="margin-bottom: 15px; width:125px; margin:0.4rem">
                      <label for="area">Surface</label>
                      <input class="input_field" type="number" id="area" v-model="plot_to_put_or_create.area" required   @wheel.prevent          @keydown="disableArrowKeys($event)"  />
                    </div>
                  </div>

                  <!-- Opérateur Météo et Code station fournisseur -->
                  <div class="form-row">
                    <div style="width:200px !important; margin:0.4rem">
                      <label for="plot_weather_operator">Opérateur Météo</label>
                      <v-autocomplete id="plot_weather_operator" style="background-color : white"
                        v-model="plot_to_put_or_create.fournisseurStation" :items="weatherOperators" hide-details
                        item-text="name" item-value="name" class="input_field" required>
                      </v-autocomplete>
                    </div>
                    <div style="width:200px; margin:0.4rem">
                      <label for="plot_station_code">Code station</label>
                      <input type="text" id="plot_station_code" v-model="plot_to_put_or_create.codeStationFournisseur"
                        class="input_field" required />
                    </div>
                    <div style="display: flex; align-items: center; gap: 0.5rem; margin: 0.4rem;">
                      <button type="button"
                        style="width:120px; font-size:1rem; color:white; text-align:center; height:3rem;"
                        @click="checkInfosExistIOT" class="add_button" :disabled="isButtonDisabled">
                        Vérifier
                      </button>
                      <v-icon v-if="checkStatus === 'success'" color="green" class="status-icon">
                        mdi-check-circle-outline
                      </v-icon>
                      <v-icon v-else-if="checkStatus === 'error'" color="red" class="status-icon">
                        mdi-close-circle-outline
                      </v-icon>
                    </div>
                  </div>


                <!-- Bouton de soumission -->
                <div class="boutons_actions">                  <button type="submit" :disabled="isFormAddPlotInvalid" class="submit-button">
                    <span>{{ isEditMode ? 'Modifier' : 'Enregistrer' }}</span>
                  </button>
                  <button type="button" @click="togglePlotMode" class="reset-button">
                    <v-icon left>mdi-refresh</v-icon> Reset
                </button>
                </div>
              </form>

            </validation-observer>
          </v-card-text>
        </v-card>
      </div>
            <!-- Ajout de stades -->
        <h4 v-if="ouvrir_ajout_parcelle && isEditMode" v-on:click="ouvrir_ajout_stade = !ouvrir_ajout_stade">
        {{ stadeSelectedToEdit ? 'Modifier stades' : 'Stades' }} 
        <v-icon small class="mr-2" v-if="!ouvrir_ajout_stade">
          mdi-chevron-down
        </v-icon>
        <v-icon small class="mr-2" v-else>
          mdi-chevron-up
        </v-icon>
      </h4>

      <section v-if='ouvrir_ajout_stade'>
        <v-card v-if='afficherFormulaireStade()' style="margin:0.4rem; background-color: rgb(250, 250, 250)">
          <v-card-text style="height: auto; margin: 0 auto;">
            <validation-observer ref="observer">
              <v-form @submit.prevent="submitFormAddOrPutPhases" class="form-container">
                <v-container>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field v-model="form_stades.plantation" :label="$tc('mildiou.stades.plantation')" type="date" required />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field v-model="form_stades.levee30" :label="$tc('mildiou.stades.levee30')" type="date" required />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field v-model="form_stades.croissanceactive" :label="$tc('mildiou.stades.croissanceactive')" type="date" required />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field v-model="form_stades.croissancestabilisee" :label="$tc('mildiou.stades.croissancestabilisee')" type="date" required />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field v-model="form_stades.senescence" :label="$tc('mildiou.stades.debutsenescence')" type="date" />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field v-model="form_stades.defanage" :label="$tc('mildiou.stades.defanage')" type="date" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-alert v-if="errorMessageStade.length" type="error">
                        <ul>
                          <li v-for="(error, index) in errorMessageStade" :key="index">{{ error }}</li>
                        </ul>
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-container>
                <v-row>
                  <v-col cols="4">
                    <label>Date de récolte:</label>
                    <input class="input_field" type="date" v-model="form_harvest.datetime">
                  </v-col>
                  <v-col cols="4">
                    <label>Rendement de récolte:</label>
                    <input class="input_field" type="number" v-model="form_harvest.yield"   @wheel.prevent 
                    @keydown="disableArrowKeys($event)" >
                  </v-col>
                </v-row>
                <br>
    
                <div class="boutons_actions">
                <!-- Bouton de soumission -->
                <button type="submit" :disabled="isFormStadeInvalid" class="submit-button">
                  <span>{{ isEditModeStade && presence_stade ? 'Modifier' : 'Enregistrer' }}</span>
                </button>
                <button type="button" @click="toggleStadesMode" class="reset-button">
                    <v-icon left>mdi-refresh</v-icon> Reset
                </button>
              </div>
              </v-form>
            </validation-observer>
          </v-card-text>
        </v-card>
        <!--Tableau des Stades -->
        <v-card style="margin:0.4rem; background-color: rgb(250, 250, 250)">
          <v-card-text v-if="isEditMode">
            <div v-if="isEditMode && !presence_stade">
              <p>
                Cette parcelle ne possède pas de stades.
              </p>
            </div>
            <div v-if="shouldDisplayTableStade()" class="parcelle-card">
              <h4>Stades :</h4>
              <div>
              <span class="parcelle-card-title">{{ $tc("mildiou.stades.plantation") }} :</span>
              <span class="parcelle-card-val">{{ formatDate(stades_list.plantation) }}</span>
            </div>
            <div>
              <span class="parcelle-card-title">{{ $tc("mildiou.stades.levee30") }} :</span>
              <span class="parcelle-card-val">{{ formatDate(stades_list.levee30) }}</span>
            </div>
            <div>
              <span class="parcelle-card-title">{{ $tc("mildiou.stades.croissanceactive") }} :</span>
              <span class="parcelle-card-val">{{ formatDate(stades_list.croissanceactive) }}</span>
            </div>
            <div >
              <span class="parcelle-card-title">{{ $tc("mildiou.stades.croissancestabilisee") }} :</span>
              <span class="parcelle-card-val">{{ formatDate(stades_list.croissancestabilisee) }}</span>
            </div>
            <div >
              <span class="parcelle-card-title">{{ $tc("mildiou.stades.debutsenescence") }} :</span>
              <span class="parcelle-card-val">{{ formatDate(stades_list.senescence) }}</span>
            </div>
            <div >
              <span class="parcelle-card-title">{{ $tc("mildiou.stades.defanage") }} :</span>
              <span class="parcelle-card-val">{{ formatDate(stades_list.defanage) }}</span>
            </div>


            <div v-if="plot_to_put_or_create.harvest && plot_to_put_or_create.harvest.datetime">
              <span class="parcelle-card-title">Récolte :</span>
              <span class="parcelle-card-val">{{ formatDate(plot_to_put_or_create.harvest.datetime) }}</span>
            </div>

            <v-btn @click="editStades()" style="width:220px; background-color:#82ac00; color:white; margin: 0.4rem">
              <v-icon>mdi-pencil</v-icon> Modifier les stades
            </v-btn>
            <v-btn @click="confirmDeleteStade()" style="width:220px; background-color:#82ac00; color:white; margin: 0.4rem">
              <v-icon>mdi-delete</v-icon> Supprimer les stades
            </v-btn>
          </div>

          </v-card-text>
        </v-card>
      </section>
      <br>


      <!-- AJOUT TRAITEMENT -->
      <h3 v-on:click="ouvrir_ajout_traitement = !ouvrir_ajout_traitement">
        {{ oneTraitementSelectedToEdit ? 'Modifier traitement' : 'Ajout traitement' }} 
        <v-icon small class="mr-2" v-if="!ouvrir_ajout_traitement">
          mdi-chevron-down
        </v-icon>
        <v-icon small class="mr-2" v-else>
          mdi-chevron-up
        </v-icon>
      </h3>

      <section v-if="ouvrir_ajout_traitement">

        <v-card v-if='afficherFormulaireTraitement()' style="margin:0.4rem; background-color: rgb(250, 250, 250)">
          <v-card-text style="height: auto; margin: 0 auto;">
            <validation-observer ref="observer">
              <form @submit.prevent="submitFormAddOrPutTraitement" style="display: flex; flex-direction: column;">
                <!-- Date du Traitement -->
                <v-row class="mb-4">
                  <v-col cols="12" md="4">
                    <v-text-field v-model="form_date_traitement" label="Date du Traitement" type="datetime-local"
                      required></v-text-field>
                  </v-col>
                </v-row>

                <!-- Sections pour chaque traitement -->
                <section v-for="(treatment, index) in treatments" :key="index">
                  <v-row>
                    <v-col cols="12">
                      <v-row>
                        <!-- Produit (autocomplete) -->
                        <v-col cols="12" md="4">
                          <v-autocomplete v-model="treatment.selectedProduct" :items="all_products" item-text="name"
                            item-value="name" label="Produit" @change="updateProductDetailsForTreatment(index)" required
                            return-object></v-autocomplete>
                        </v-col>

                        <!-- Lessivage -->
                        <v-col cols="12" md="3">
                          <v-text-field v-model="treatment.form_washout_resistance_traitement" label="Lessivage"
                            readonly required></v-text-field>
                        </v-col>

                        <!-- rémanence de base -->
                        <v-col cols="12" md="3">
                          <p class="mt-4">
                            <v-icon>mdi-information-box-outline</v-icon> Rémanence théorique 
                            <span
                              style="font-weight: bold; color: rgb(126, 199, 0)">{{ treatment.form_persistence_de_base }}</span>
                          </p>
                          <v-checkbox v-model="treatment.forcePersistence" label="Forcer la rémanence"></v-checkbox>
                        </v-col>

                        <!-- Bouton de suppression -->
                        <v-col cols="12" md="2" class="d-flex justify-start align-center" style="margin-bottom: -4rem">
                          <v-btn icon @click="removeTheProductOfTheTreatment(index)" color="red">
                            <v-icon>mdi-delete</v-icon> Produit
                          </v-btn>
                        </v-col>
                      </v-row>

                      <!-- Rémanence modifiable (si la case est cochée) -->
                      <v-row v-if="treatment.forcePersistence">
                        <v-col cols="12" md="4">
                          <v-text-field v-model="treatment.form_persistence_traitement" label="Rémanence (j)"
                            type="number" :min="4" :max="10" @wheel.prevent @keydown="disableArrowKeys($event)"
                            required></v-text-field>
                        </v-col>
                      </v-row>

                      <!-- Détails supplémentaires du produit -->
                      <v-row>
                        <!-- Type de produit -->
                        <v-col cols="12" md="4">
                          <v-text-field v-model="treatment.form_product_type_traitement" label="Type de produit"
                            readonly required></v-text-field>
                        </v-col>

                        <!-- Dose de produit -->
                        <v-col cols="12" md="3">
                          <v-text-field v-model="treatment.form_product_dose_traitement" label="Dose de produit" type="number" style="background-color: transparent;border:transparent !important" @wheel.prevent @keydown="disableArrowKeys($event)" required></v-text-field>
                        </v-col>

                        <!-- Unité de dose -->
                        <v-col cols="12" md="2">
                          <v-select v-model="treatment.form_dose_unit_traitement" :items="['kg/ha', 'L/ha']"
                            label="Unité de dose" required></v-select>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-divider class="my-4"></v-divider>
                </section>

                <button type="button" style="width:150px !important; color:white !important; text-align:center;"
                  @click="addTreatment" class="add_button pl-5">+ Produit</button>

                <p v-if="!isEditModeTraitement" style="font-style: italic; color: grey;">
                  <v-icon color="warning">mdi-alert</v-icon> - Sélectionner la/les parcelles avant de cliquer sur
                  <span style="font-weight: bold; color: rgb(126, 199, 0);">Enregistrer</span>.
                </p>

                <div class="boutons_actions">
                  <button type="submit" :disabled="isFormTraitInvalid" class="submit-button">
                    <span>{{ oneTraitementSelectedToEdit && presence_traitement ? 'Modifier' : 'Enregistrer' }}</span>
                  </button>
                  <button type="button" @click="toggleTreatmentMode" class="toggle-button" style="margin: 0 auto;">
                    <v-icon left>mdi-refresh</v-icon> Reset
                  </button>
                </div>
              </form>

            </validation-observer>
          </v-card-text>
        </v-card>

        <!--Tableau des Traitements -->
        <v-card style="margin:0.4rem; background-color: rgb(250, 250, 250)">
            <div >
              <p v-if="isEditMode && !presence_traitement">
                Cette parcelle ne possède pas de traitements.
              </p>
            </div>
            <v-data-table v-if="shouldDisplayTableTraitement()" :headers="headers_traitements" :items="formattedTraitements" item-key="unique_id"
              :group-by="['treated_at']" class="my-awesome-table" dense>
              
              <template v-slot:[`item.treated_at`]="{ item }">
                {{ new Date(item.treated_at).toLocaleString() }}
              </template>
              <template v-slot:[`item.product_info`]="{ item }">
                <div>{{ item.product_name }}</div>
                <div>{{ item.product_type }}</div>
                <div>{{ item.product_amm }}</div>
              </template>
              <template v-slot:[`item.product_dose`]="{ item }">
                {{ item.product_dose }} {{ item.dose_unit }}
              </template>
              <template v-slot:[`item.persistence`]="{ item }">
                  {{ item.standard_persistence }}
                </template>
                <template v-slot:[`item.persistence_modif`]="{ item }">
                  {{ item.modified_persistence }}
                </template>
              <template v-slot:[`item.washout_resistance`]="{ item }">
                {{ item.washout_resistance }} jours
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn icon @click="editTraitement(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon @click="confirmDeleteTraitement(item)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>

        </v-card>
      </section>

    
      <h3 v-on:click="ouvrir_ajout_irrigation = !ouvrir_ajout_irrigation">
        {{ oneIrrigationSelectedToEdit ? 'Modifier irrigation' : 'Ajout irrigation' }} 
        <v-icon small class="mr-2" v-if="!ouvrir_ajout_irrigation">
          mdi-chevron-down
        </v-icon>
        <v-icon small class="mr-2" v-else>
          mdi-chevron-up
        </v-icon>
      </h3>

      <section v-if='ouvrir_ajout_irrigation'>

        <v-card v-if='afficherFormulaireIrrigation()'
          style="margin:0.4rem; background-color: rgb(250, 250, 250)">
          <v-card-text style="height: auto; margin: 0 auto;">
            <validation-observer ref="observer">
              <form @submit.prevent="submitFormAddOrPutIrrigation"
                style="display: flex; flex-direction: column; flex-wrap: nowrap; justify-content: space-between;">

                <div class="form-row">
                  <div style="margin-bottom: 15px;">
                    <label for="form_date_debut_irrig">Date de début d'irrigation</label><br>
                    <input type="datetime-local" id="form_date_debut_irrig" v-model="form_date_debut_irrig" required
                      class="input_field" @change="validateDates">
                  </div>

                  <div style="margin-bottom: 15px;">
                    <label for="form_date_fin_irrig">Date de fin d'irrigation</label><br>
                    <input type="datetime-local" id="form_date_fin_irrig" v-model="form_date_fin_irrig" required
                      class="input_field" @change="validateDates">
                    <!-- Message d'erreur -->
                    <div v-if="errorMessage" style="color: red;">
                      {{ errorMessage }}
                    </div>
                  </div>

                  <div style="margin-bottom: 15px;">
                    <label for="form_qte_mm">Quantité (mm/ha)</label><br>
                    <input type="number" id="form_qte_mm" v-model="form_qte_mm" required  class="input_field"   @wheel.prevent 
         @keydown="disableArrowKeys($event)" >
                  </div>
                </div>

                <p v-if="!isEditModeIrrigation" style="font-style:italic;color:grey;">
                  <v-icon color="warning"> mdi-alert </v-icon>
                  - Sélectionner la/les parcelles avant de cliquer sur
                  <span style="font-weight: bold; color: rgb(126, 199, 0);">Enregistrer</span>.
                </p>
                <div class="boutons_actions">
                  <button type="submit" :disabled="isFormIrrigInvalid" class="submit-button">
                    <span>{{ oneIrrigationSelectedToEdit && presence_irrigation ? 'Modifier' : 'Enregistrer' }}</span>
                  </button>
                  <button type="button" @click="toggleIrrigationMode" class="toggle-button" style="margin: 0 auto;">
                    <v-icon left>mdi-refresh</v-icon> Reset
                  </button>
                </div>
              </form>

            </validation-observer>
          </v-card-text>
        </v-card>
        <!--Tableau des Irrigations -->
        <v-card style="margin:0.4rem; background-color: rgb(250, 250, 250)">
          <div v-if="isEditMode && !presence_irrigation">
            <p >
                Cette parcelle ne possède pas d'irrigations.
              </p>  
          </div>
            <v-data-table v-if="shouldDisplayTableIrrigation()" :headers="[
                  { text: 'Date de debut', value: 'begin_at' },
                  { text: 'Date de fin', value: 'end_at' },
                  { text: 'Dose', value: 'water_dose' },
                  { text: 'Actions', value: 'actions' }
                ]" :items="formattedIrrigations" item-key="begin_at">
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>{{ new Date(item.begin_at).toLocaleString() }}</td>
                    <td>{{ new Date(item.end_at).toLocaleString() }}</td>
                    <td>{{ item.water_dose }} {{ item.dose_unit }}</td>
                    <td>
                      <v-btn icon @click="editIrrigation(item)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn icon @click="confirmDeleteIrrigation(item)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
        </v-card>
      </section>

      
      <h3 @click="ouvrir_ajout_observation = !ouvrir_ajout_observation">
        {{ oneObservationSelectedToEdit ? 'Modifier observation' : 'Ajout observation' }}
        <v-icon small class="mr-2" v-if="!ouvrir_ajout_observation">
          mdi-chevron-down
        </v-icon>
        <v-icon small class="mr-2" v-else>
          mdi-chevron-up
        </v-icon>
      </h3>

      <section v-if='ouvrir_ajout_observation'>

        <v-card v-if='afficherFormulaireObs()'
          style="margin:0.4rem; background-color: rgb(250, 250, 250)">
          <v-card-text style="height: auto; margin: 0 auto;">
            <validation-observer ref="observer">
              <form @submit.prevent="submitFormAddOrPutObs">
                <v-row>
                  <v-col cols="12" sm="6">
                    <div class="mb-3">
                      <label for="form_date_obs">Date d'observation du mildiou</label>
                      <input type="date" id="form_date_obs" v-model="form_date_obs" required class="input_field">
                    </div>
                  </v-col>
                  <v-col>
                    <p v-if="!isEditModeObservation" style="font-style:italic;color:grey;">
                      <v-icon color="warning">mdi-alert </v-icon>- Sélectionner la/les parcelles avant de cliquer sur
                      <span style="font-weight: bold; color: rgb(126, 199, 0);">Enregistrer</span>.
                    </p>
                    <div class="boutons_actions">
                    <button type="submit" :disabled="isFormObsInvalid" class="submit-button">
                      <span>{{ oneObservationSelectedToEdit && presence_obs ? 'Modifier' : 'Enregistrer' }}</span>
                    </button>
                    <button type="button" @click="toggleObservationMode" class="reset-button">
                        <v-icon left>mdi-refresh</v-icon> Reset
                    </button>
                  </div>
                  </v-col>
                </v-row>
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>
        <!-- Tableau des observations -->
        <v-card style="margin:0.4rem; background-color: rgb(250, 250, 250)">
            <div v-if="isEditMode && !presence_obs">
              <p>
                Cette parcelle ne possède pas d'observations.
              </p>
            </div>
            <v-data-table v-if="shouldDisplayTableObs()" :headers="[
                  { text: 'Date d\'Observation', value: 'date' },
                  { text: 'Actions', value: 'actions' }
                ]" :items="formattedObservations" item-key="date">
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>{{ item }}</td>
                    <td>
                      <v-btn icon @click="editObservation(item)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn icon @click="confirmDeleteObs(item)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
        </v-card>
      </section>

      <div style="margin-top: 20px">
      <h3 v-on:click="showParcelles = !showParcelles">
        Mes parcelles
        <v-icon small class="mr-2" v-if="!showParcelles">
          mdi-chevron-down
        </v-icon>
        <v-icon small class="mr-2" v-else>
          mdi-chevron-up
        </v-icon>
      </h3>
      <div v-if="showParcelles" style="margin-bottom:20px;">
        <template>
  <v-card>
    <v-card-title>
      <!-- Vous pouvez ajouter ici du texte ou d'autres composants si nécessaire -->
      <v-spacer></v-spacer>
    </v-card-title>

    <!-- Champ de recherche invisible -->
    <v-text-field
      v-model="searchWord"
      style="display: none;"
    ></v-text-field>

    <!-- Tableau de données -->
    <v-data-table
      :search="searchWord"
      :loading="!dataloaded"
      loading-text="Chargement des données...."
      :headers="headers_parcelle"
      :items="parcelles_mildiou"
      :footer-props="{'items-per-page-options': [15, 25, 50, 75, 100]}"
      :items-per-page="15"
      v-model="arr_select_plot_table"
      item-key="id"
      class="my-awesome-table elevation-1"
      dense
      fixed-header
      mobile-breakpoint="0"
      show-select
    >
      <!-- Slots pour les actions de visualisation et les détails des parcelles -->
      <template v-slot:[`item.actions_see_plot`]="{ item }">
        <v-icon small class="mr-2" @click="showItem(item.id)">
          mdi-eye
        </v-icon>
      </template>

      <template v-slot:[`item.parcelle`]="{ item }">
        {{ item.plot_name }} [{{ item.id }}]
      </template>

      <template v-slot:[`item.risque`]="{ item }">
        <v-tooltip bottom max-width="350">
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2" :color="getColorRisque(item.risque)" v-bind="attrs" v-on="on">
              {{getIconRisque(item.risque)}}
            </v-icon>
          </template>
          <span v-html="getInfoRisque(item.risque)"></span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.parcelleprotegee`]="{ item }">
        <v-tooltip bottom max-width="350">
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2" :color="getColorProtection(item.parcelleprotegee)" v-bind="attrs" v-on="on">
              {{getIconProtection(item.parcelleprotegee)}}
            </v-icon>
          </template>
          <span v-html="getInfoProtection(item.parcelleprotegee)"></span>
        </v-tooltip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon style="font-size: 18px; margin-right: .8rem;" v-if="arr_select_plot_table.length < 2" small
          @click="activeEditPlotMode(item); filterTableById(item.id)">
          mdi-pencil
        </v-icon>
        <v-icon style="font-size: 18px; margin-left: .8rem;" v-if="arr_select_plot_table.length < 2" small
          @click="confirmDeletePlot(item); filterTableById(item.id)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>
<!-- <CommentaireComponent :dialog_init="dialog" :id_plot="selected_plot" :oad="oad" v-on:closeCommentaire="closeCommentaire"/>
-->
      </div>
    </div>

    </v-container>
  </div>
</template>

<script>
import downloadExcel from "vue-json-excel";
import parcellesMildiouService from "@/service/parcellesMildiou";
import { LMap, LTileLayer, LMarker, LIcon, LPopup } from 'vue2-leaflet';
import * as echarts from 'echarts';
import VChart from 'vue-echarts';
import { getDate, checkIsMobile } from "@/helpers/functions";
import { ValidationObserver } from 'vee-validate';
import ChoixModal from "@/components/modals/ChoixModal.vue";
//import CommentaireComponent from "@/components/global/CommentaireSimple.vue";




export default {
  name: "DetailParcelle_Mildiou",
  props: ['parcelleId'],
  components: {
      downloadExcel,
    parcellesMildiouService,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
    VChart,
    getDate,
    checkIsMobile,
    ValidationObserver,
    ChoixModal
   // CommentaireComponent


  },
  data() {
    return {
      oad: 'MILDIOU',
      selected_plot: null,

      isEditMode:false,
      isMobile: false,

      // Pour les suppressions
      object_to_delete : null,
      object_deleted:false,
  
      searchWord: "",
      parcelles_mildiou: [],
      arr_select_plot_table:[],
      varieties_list:[],
      
      groupBy: [
          {
            key: 'treated_at',
            order: 'asc',
          },
        ],

        headers_traitements: [
          { text: 'Date de traitement', value: 'treated_at' },
          { text: 'Produit', value: 'product_info' },
          { text: 'Dose', value: 'product_dose' },
          { text: 'Rémanence (j)', value: 'persistence' },
          { text: 'Rémanence modifiée', value: 'persistence_modif' },
          { text: 'Résistance au lessivage', value: 'washout_resistance' },
          { text: 'Actions', value: 'actions' }
      ],

      minDate: '',
      maxDate: '',

      id_plot_selected_to_edit :null,

      showParcelles:true,
      ajout_traitement:false,
      ajout_irrigation:false,

      // Gestion des volets d'interventions
      ouvrir_caracteristique_parcelle:false,
      ouvrir_ajout_parcelle:false,
      ouvrir_ajout_observation:false,
      presence_obs:false,
      ouvrir_ajout_irrigation:false,
      presence_irrigation:false,
      ouvrir_ajout_traitement:false,
      presence_traitement:false,
      ouvrir_ajout_stade:false,
      presence_stade:false,
      isEditModeObservation: false,
      isEditModeIrrigation:false,
      isEditModeTraitement:false,
      isEditModeStade:false,

      //Volet stade
      stadeSelectedToEdit:false,
      form_stades: {
        plantation: '',
        levee30: '',
        croissanceactive: '',
        croissancestabilisee: '',
        senescence: '',
        defanage: '',
      },   
      form_harvest:{
          datetime:'',
          yield:''
        }  , 
        errorMessageStade:[],

      // Volet traitement
      initial_date_treated_at:'',
      all_products:[],
      selectedProduct:null,
      form_date_traitement: '',
      form_product_type_traitement: '',
      form_persistence_traitement: '',
      form_washout_resistance_traitement: '',
      form_product_dose_traitement: '',
      form_dose_unit_traitement:'L/ha',
      formattedTraitements:[],
      oneTraitementSelectedToEdit:false,

      // Volet Irrigation
      initial_date_debut_irrig:'',
      form_date_debut_irrig: '',
      form_date_fin_irrig: '',
      form_qte_mm: '',
      formattedIrrigations:[],
      errorMessage:'',
      oneIrrigationSelectedToEdit:false,

      // Volet observations
      form_date_obs: '',
      formattedObservations: [],
      oneObservationSelectedToEdit:false,

      // Les listes d'interventions diverses
      stades_list:{},

      select_plot_to_delete:[],

      select_plot_to_edit:[],

      plot_to_put_or_create: {
        name: '',
        city: '',
        zipcode: '',
        variety: '',
        plantation:'',
        organism:'ARVALIS',
        area: null,
        fournisseurStation: '',
        codeStationFournisseur: '',
        nomStation: '',
        latitude: 0,
        longitude: 0,
        farmer: this.userId
      },
      weatherOperators:[],
      checkStatus: '', 
      isButtonDisabled: true,
      isVerified:false,

      treatments: [
        {
          selectedProduct: null,
          form_washout_resistance_traitement: '',
          form_product_type_traitement: '',
          form_persistence_traitement: '', 
          form_product_dose_traitement: '',
          form_dose_unit_traitement: 'kg/ha' ,
          forcePersistence: false
        }
      ],
    
      droits: this.$store.state.user.user.droitsTPC,
      userId: this.$store.state.user.user.id,
      dataloaded: false,
      filter: null,
      sortDescInd: true ,
      headers_parcelle: [
        //colonnes non visibles mais permettant de faire une recherche sur le contenu
        { text: 'Nom parcelle', value: 'plot_name', align: ' d-none' },
        { text: 'Id parcelle', value: 'id', align: ' d-none' },
        { text: 'Nom agri', value: 'nom_agri', align: ' d-none' },
        { text: 'Prénom agri', value: 'prenom_agri', align: ' d-none' },
        //colonnes visibles
        { text: "", value: "actions_see_plot", sortable: false},
        { text: 'Agriculteur', value: 'farmer',  align: ' d-none'},
       // { text: "Variété", value: "variete"},
        { text: "Parcelle", value: "parcelle"},
        { text: 'Protection', value: 'parcelleprotegee'},
        { text: 'Risque Mildiou', value: 'risque'},
        { text: "", value: "actions", sortable: false},
      ],
    };
  },

  created(){

      // Récupérer le paramètre query `id`
    if (this.fromDetailParcelle) {
    this.arr_select_plot_table.push(this.fromDetailParcelle);
    
    this.ajout_irrigation = false;
    this.ajout_traitement = false;
    this.ouvrir_ajout_observation = false;
    this.ouvrir_ajout_stade = false;


    // Ouvrir l'onglet en fonction de l'intervention
    switch (this.fromDetailParcelle.intervention) {
        case 'irrigation':
            this.ajout_irrigation = true;
            break;
        case 'traitement':
            this.ajout_traitement = true;
            break;
        case 'observation':
            this.ouvrir_ajout_observation = true;
            break;
        case 'stade':
            this.ouvrir_ajout_stade = true;
            break;
    }
}

    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');

        // Date du jour
    this.maxDate = `${year}-${month}-${day}`;
    
    // 1er mars de l'année en cours
    this.minDate = `${year}-03-01`;

  },

  computed: {

    /**
     *  * @returns {boolean} true si un des champs est manquant ou non défini, sinon false
     */
    isFieldsIOTValid() {
        // Retourne true si les champs sont valides, sinon false
        return this.plot_to_put_or_create.fournisseurStation && this.plot_to_put_or_create.codeStationFournisseur;
      },

    currentRouteName() {
      return this.$route.name;
    },
    checkDroitLectureExterne() {
      return this.$store.getters['user/checkDroit']('EXTERNE','lecture')
    },
    isFormStadeInvalid() {
      
    // Vérifier que AU MOINS 1 champ de date soit saisie
    let JustOneDateFilled = 
      this.form_stades.plantation || 
      this.form_stades.levee30 || 
      this.form_stades.croissanceactive || 
      this.form_stades.croissancestabilisee || 
      this.form_stades.senescence || 
      this.form_stades.defanage || 
      this.form_harvest.datetime;
    // Vérifier que arr_select_plot_table a au moins un élément
    let plotTableIsValid = this.arr_select_plot_table.length > 0;
    // Le formulaire est invalide si un champ n'est pas rempli ou si arr_select_plot_table est vide
    return !JustOneDateFilled || !plotTableIsValid || this.errorMessageStade.length > 0;
    },

    isFormObsInvalid() {
      return !this.form_date_obs || !this.arr_select_plot_table.length >= 1;
    },
    isFormIrrigInvalid() {
      return !!this.errorMessage || !this.form_date_debut_irrig || !this.form_date_fin_irrig || !this.form_qte_mm ||  !this.arr_select_plot_table.length >= 1;
    },
    isFormTraitInvalid() {
      return this.treatments.some(
        treatment =>
        !treatment.selectedProduct ||
        !treatment.form_product_dose_traitement
      ) 
        || !this.form_date_traitement 
        || !this.arr_select_plot_table.length >= 1
    },

    isFormAddPlotInvalid() {
      let plot = this.plot_to_put_or_create;
      return !plot.name || 
          !plot.latitude ||  
          !plot.longitude || 
          !plot.variety || 
          !plot.area || 
          !plot.plantation || 
          !plot.codeStationFournisseur || 
          !plot.fournisseurStation ||
          !this.isFieldsIOTValid ||
          !this.isVerified;
    },


    fromDetailParcelle() {
      return this.$store.getters['mildiou/fromDetailParcelle'];
    },

    choixSelected() {
      return this.$store.state.choix.choixSelected;
    }


  },

  watch: {

    plot_to_put_or_create: {
      handler() {
        this.isButtonDisabled = !this.isFieldsIOTValid;
      },
      deep: true 
    },
    choixSelected: function () {
      //appelle la function dont le nom est stocké dans le store
      if (this.choixSelected != "") {
        this[this.choixSelected]();
      }
    },

    fromDetailParcelle: {
      handler(newVal) {
        if (newVal && newVal.intervention) {
          this.ouvrirOngletCorrespondant();
        }
      },
      immediate: true
    },

  'form_stades.plantation': 'validateStadeDates',
  'form_stades.levee30': 'validateStadeDates',
  'form_stades.croissanceactive': 'validateStadeDates',
  'form_stades.croissancestabilisee': 'validateStadeDates',
  'form_stades.senescence': 'validateStadeDates',
  'form_stades.defanage': 'validateStadeDates',

  },

  async mounted() {   
    // Vérifier la taille de l'écran une fois la page chargée
    this.isMobile = checkIsMobile();

    this.weatherOperators = await parcellesMildiouService.getListesConstructorIOT();
     // Ajouter l'élément "ARVALIS"
    this.weatherOperators.push({name: 'ARVALIS'});

    this.varieties_list = await parcellesMildiouService.getListeVariety();
    // Si ns arrivons sur cette page grace au DETAIL PARCELLE
    if (this.fromDetailParcelle) {this.ouvrirOngletCorrespondant();}

    /////////////////////////////////////////////////////////////////
    // Récupérer les informations des parcelles
    this.parcelles_mildiou = await parcellesMildiouService.getInfosParcelles('arvalis');
    this.dataloaded = true;

    this.all_products = await parcellesMildiouService.getInfosAllProducts(this.$route.params.id);
  
  },

  
  methods: {

    checkDroits(droit, type) {
      var index = this.droits.findIndex(item => item.code === droit)
      if (index >= 0 && this.droits[index][type] == true) {
        return true
      } else {
        return false
      }
    },

    ouvrirOngletCorrespondant() {
      if (this.fromDetailParcelle && this.fromDetailParcelle.intervention) {
        // Ouvre l'onglet correspondant si un type d'intervention est défini
        switch (this.fromDetailParcelle.intervention) {
          case 'irrigation':
            this.ouvrir_ajout_irrigation = true;
            this.loadIrrigationsData();

            break;
          case 'traitement':
            this.ajout_traitement = true;
            this.loadTraitementsData();

            break;
          case 'observation':
            this.ouvrir_ajout_observation = true;
            this.loadObservationsData();

            break;
          case 'stade':
            this.ouvrir_ajout_stade = true;
            this.loadStadesData();

            break;
        }
      }
    },

    afficherFormulaireStade () {
      return this.ouvrir_ajout_stade || this.isEditMode ;
    },
    afficherFormulaireObs() {
      
      return this.ouvrir_ajout_observation || this.isEditMode ;
    },
    afficherFormulaireIrrigation() {
     
      return this.ouvrir_ajout_irrigation || this.isEditMode;
    },
    afficherFormulaireTraitement() {
      return this.ouvrir_ajout_traitement || this.isEditMode;
    },

    async checkInfosExistIOT() {
      try {
        if (!this.isFieldsIOTValid) {
          this.checkStatus = 'error'; // Affiche une erreur si les champs ne sont pas remplis
          this.isButtonDisabled = true; // Désactiver le bouton de vérification si les champs sont invalides
          this.isVerified = false; // Indiquer que la vérification n'a pas été effectuée
          return;
        }

        let iot_exists = await parcellesMildiouService.getInfosExistIOT(
          this.plot_to_put_or_create.fournisseurStation,
          this.plot_to_put_or_create.codeStationFournisseur
        );

        if (iot_exists) {
          this.checkStatus = 'success';
          this.isButtonDisabled = false; // Activer le bouton si les informations sont valides
          this.isVerified = true; // Indiquer que la vérification a réussi
        } else {
          this.checkStatus = 'error';
          this.isButtonDisabled = true; // Désactiver le bouton si les informations ne sont pas valides
          this.isVerified = false; // Indiquer que la vérification a échoué
        }

      } catch (error) {
        console.error('Erreur lors de la vérification:', error);
        this.checkStatus = 'error';
        this.isButtonDisabled = true; // Désactiver le bouton en cas d'erreur
        this.isVerified = false; // Indiquer que la vérification a échoué
      }
    },

    async submitFormAddOrPutPlot() {

      this.$store.dispatch('general/setWaitingOverlay', {
        loading: true,
        fullpage: true
      });

      if (this.isEditMode) {
        let id_plot = this.arr_select_plot_table[0].id;
        await parcellesMildiouService.editOnePlotToApiMildiou(id_plot, this.plot_to_put_or_create)
      } else {
        await parcellesMildiouService.addOnePlotToApiMildiou(this.plot_to_put_or_create)
      }
      this.parcelles_mildiou = await parcellesMildiouService.getInfosParcelles('arvalis');
      this.resetPlotForm()

      this.$store.dispatch('general/setWaitingOverlay', {
        loading: false,
        fullpage: true
      });
    },

    async submitFormDeletePlot() {
      try {
        this.closeChoix();

        let id_plot = this.select_plot_to_delete.id;

        // Suppression de la parcelle via l'API
        await parcellesMildiouService.deleteOnePlotApiMildiou(id_plot);

        // Recharger les parcelles
        this.parcelles_mildiou = await parcellesMildiouService.getInfosParcelles('arvalis');
        this.searchWord = null;
      } catch (error) {
        this.$store.dispatch('errors/setError', { message: {description:"Erreur lors de la suppression de la parcelle."} });
      }
    },


    /**
     * Passer en mode EDITION/MODIFICATION sur la parcelle cliquée
     */
    async activeEditPlotMode(item) {
      try {
        this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: false });
        this.isEditMode = true; // Activer le mode MODIFICATION
        this.id_plot_selected_to_edit = item.id;
        this.select_plot_to_edit = item;
        this.arr_select_plot_table = [item];
        this.ouvrir_ajout_parcelle = true;

        // Récupérer toutes les données nécessaires d'une parcelle pour alimenter les différents volets d'interventions
        await Promise.all([
          this.loadObservationsData(),
          this.loadStadesData(),
          this.loadIrrigationsData(),
          this.loadTraitementsData(),
          this.loadPlotData()
        ]);

        // Désactiver le mode overlay après le chargement complet
        this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
      } catch (error) {
        console.error("Erreur:", error);
        this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
      }
    },

    /**
     * Obtenir les données OBSERVATIONS de la parcelle
     */
    async loadObservationsData() {
      try {
        const data = await parcellesMildiouService.getObservationsOfThePlot(this.arr_select_plot_table[0].id);
        this.formattedObservations = data;
        this.presence_obs = Array.isArray(this.formattedObservations) && this.formattedObservations.length > 0;
      } catch (error) {
        console.error("Erreur lors du chargement des observations:", error);
        this.presence_obs = false;
      }
    },

    /**
     * Obtenir les données de STADES / PHASES de la parcelle
     */
     async loadStadesData() {
    try {
        const data = await parcellesMildiouService.getStadesOfThePlot(this.arr_select_plot_table[0].id);

        if (data) {
            this.stades_list = this.transformStadesData(data);
            this.presence_stade = true ;

              // Affecter les données récupérées aux champs du formulaire
              this.form_stades.plantation = this.stades_list.plantation || '';
            this.form_stades.levee30 = this.stades_list.levee30 || '';
            this.form_stades.croissanceactive = this.stades_list.croissanceactive || '';
            this.form_stades.croissancestabilisee = this.stades_list.croissancestabilisee || '';
            this.form_stades.senescence = this.stades_list.senescence || '';
            this.form_stades.defanage = this.stades_list.defanage || '';
        } else {
            console.warn("Aucune donnée de stades reçue, 'data' est undefined ou null.");
            this.presence_stade = false;
        }
    } catch (error) {
        console.error("Erreur lors du chargement des stades:", error);
        this.presence_stade = false;
    }

    try {
        const harvest = await parcellesMildiouService.get_harvest_from_api_potato_late_blight(this.arr_select_plot_table[0].id);
    } catch (error) {
        console.error("Erreur lors du chargement des données de récolte:", error);
        this.presence_stade = false;
    }
},


    /**
     * Obtenir les données d'irrigations de la parcelle
     */
    async loadIrrigationsData() {
      try {
        const data = await parcellesMildiouService.getIrrigationsOfThePlot(this.arr_select_plot_table[0].id);
        // Si 'data' est un objet, le convertir en tableau
        if (!Array.isArray(data)) {
          this.formattedIrrigations = [data];
        } else {
          this.formattedIrrigations = data;
        }
        this.presence_irrigation = this.formattedIrrigations.length > 0;
      } catch (error) {
        console.error("Erreur lors du chargement des irrigations:", error);
        this.presence_irrigation = false;
      }
    },

    /**
     * Obtenir des ldonnées de traitements de la aprcelle
     */
    async loadTraitementsData() {
      try {
        const data = await parcellesMildiouService.getTraitementsOfThePlot(this.arr_select_plot_table[0].id);
        this.formattedTraitements = data;
        this.presence_traitement = Array.isArray(this.formattedTraitements) && this.formattedTraitements.length > 0;
        this.affichagesRemanenceTraitements();

      } catch (error) {
        console.error("Erreur lors du chargement des traitements:", error);
        this.presence_traitement = false;
      }
    },

     /**
     * Obtenir des ldonnées de la aprcelle
     */
    async loadPlotData() {
      try {
        const one_plot = await parcellesMildiouService.getOnePlotApiMildiou(this.id_plot_selected_to_edit);
        const plot_returned_by_get_api = one_plot[0];
      // Recherche de la phase de plantation avec un nom spécifique
      const plantationPhase = plot_returned_by_get_api.phases.find(phase => phase.name === 'PLANTATION') || { date: '' };

        this.plot_to_put_or_create = {
            id: plot_returned_by_get_api.id,
            name: plot_returned_by_get_api.name,
            city: plot_returned_by_get_api.city || '',
            zipcode: plot_returned_by_get_api.zipcode || '',
            latitude: plot_returned_by_get_api.latitude,
            longitude: plot_returned_by_get_api.longitude,
            variety: plot_returned_by_get_api.variety,
            variety_code: plot_returned_by_get_api.variety_code,
            note_precocity_al: plot_returned_by_get_api.note_precocity_al,
            phases: plot_returned_by_get_api.phases,
            harvest: plot_returned_by_get_api.harvest || {
                datetime: null,
                yield: null
            },
            farmer: plot_returned_by_get_api.farmer,
            organism: plot_returned_by_get_api.organism,
            plantation: plantationPhase.date, // Utiliser la date de plantation trouvée ou une valeur par défaut
            area: plot_returned_by_get_api.area,
            treatments: plot_returned_by_get_api.treatments,
            irrigations: plot_returned_by_get_api.irrigations,
            observations: plot_returned_by_get_api.observations,
            device_iot: plot_returned_by_get_api.device_iot || {
                constructor: '',
                device_code: ''
            },
            fournisseurStation: plot_returned_by_get_api.device_iot?.constructor || '',
            codeStationFournisseur: plot_returned_by_get_api.device_iot?.device_code || ''
        };
      } catch (error) {
        console.error("Erreur lors du chargement des données de la parcelle:", error);
      }
    },

    showItem(id_parcelle, type = null) {
      // Stockage de la parcelle sélectionnée dans le store
      let route = this.$router
        .resolve({
          name: 'mildiou-detail-parcelle',
          params: {
            id: id_parcelle
          }
        });

      if (type == 'href') {
        return route.href;
      } else {
        window.open(route.href);
      }
    },
    goTo(nomDiv) {
      this.$vuetify.goTo('#' + nomDiv)
    },

    filterTableById(id) {
      // Mettre à jour la variable de recherche avec l'ID de l'élément pour filtrer le tableau
      this.searchWord = id.toString(); // Utiliser l'ID pour la recherche
    },

    /////////////////////////
    // STADES 

    editStades() {
      this.isEditModeStade = true ;
      this.stadeSelectedToEdit = true ;

      if (this.stades_list.plantation) {
        this.form_stades.plantation = this.formatDate(this.stades_list.plantation);
        }

        if (this.stades_list.levee30) {
          this.form_stades.levee30 = this.formatDate(this.stades_list.levee30);
        }

        if (this.stades_list.croissanceactive) {
          this.form_stades.croissanceactive = this.formatDate(this.stades_list.croissanceactive);
        }

        if (this.stades_list.croissancestabilisee) {
          this.form_stades.croissancestabilisee = this.formatDate(this.stades_list.croissancestabilisee);
        }

        if (this.stades_list.senescence) {
          this.form_stades.senescence = this.formatDate(this.stades_list.senescence);
        }

        if (this.stades_list.defanage) {
          this.form_stades.defanage = this.formatDate(this.stades_list.defanage);
        }

  },

  deleteStades() {
    this.closeChoix()

      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      
      
      this.message_retour = parcellesMildiouService.deleteStadesOfThePlot(this.arr_select_plot_table[0].id
      ).then(() => {
        this.object_deleted = true ;
          this.updateDataAfterActionIntervention(this.arr_select_plot_table[0].id, 'Stade');
          this.resetStadesForm();
        })
        .catch(error => {
          console.error("Erreur lors de la maj de Stade", error);
        });
    },

  submitFormAddOrPutPhases() {

        // Préparer les données de traitement
        let phases_in_the_form = this.form_stades;
        let recolte = this.form_harvest;
        this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });

        // Envoyer les données pour chaque parcelle sélectionnée
        if (Array.isArray(this.arr_select_plot_table)) {
          this.arr_select_plot_table.forEach(parcelle => {
            if (this.isEditMode) {
              this.launchPutStadePlot(this.id_plot_selected_to_edit, phases_in_the_form);
            } else {
              this.launchAddStadePlot(parcelle.id, phases_in_the_form);
            }
            if (recolte) {
              this.launchAddHarvestPlot(parcelle.id, recolte);
            }
          });
        } else {
          console.error('Erreur dans le bouclage des parcelles');
        }

        // Réinitialiser les champs et la sélection de parcelle après la soumission
        this.form_date_traitement = '';
        this.treatments = []; // Réinitialiser les traitements
},

    launchPutStadePlot(id_plot, form_data) {
      this.message_retour = parcellesMildiouService.putStadeToPlotInApi(id_plot, form_data)
        // Une fois que l'ajout d'Stade bien réalisé, recuperation des données mises à jour
        .then(() => {
          this.updateDataAfterActionIntervention(id_plot, 'Stade');
        })
        .catch(error => {
          console.error("Erreur lors de la maj de l 'Stade", error);
        });
    },

    launchAddStadePlot(id_plot, stade_form_data) {
      // Construire un tableau d'objets pour les stades remplis
      const stades = [];

      // Parcourir les données des stades dans le formulaire
      for (const [key, value] of Object.entries(stade_form_data)) {
        if (value) { // Si une date est renseignée
          // Inverser le mapping pour utiliser les noms API
          const apiName = this.inverseMapStadeName(key);
          stades.push({ name: apiName, date: value });
        }
      }

      if (stades.length === 0) {
        console.warn('Aucun stade rempli. Aucune action n\'a été effectuée.');
        return; // Si aucun stade n'est rempli, on ne fait rien.
      }

      // Appel API avec les stades construits
      this.message_retour = parcellesMildiouService.addStadesToPlotInApi(id_plot, stades)
        .then(() => {
          this.updateDataAfterActionIntervention(id_plot, 'Stade');
        })
        .catch(error => {
          console.error("Erreur lors de l'ajout des stades", error);
        });
    },


    launchAddHarvestPlot(id_plot, form_data) {
      this.message_retour = parcellesMildiouService.putHarvestToPlotInApi(id_plot, form_data)
        .then(() => {
          this.updateDataAfterActionIntervention(id_plot, 'Stade');
        })
        .catch(error => {
          console.error("Erreur lors de l'ajout de l 'Harvest", error);
        });
    },

    ///////////////////////////
    // TRAITEMENT

    async submitFormAddOrPutTraitement() {

      // Préparer les données de traitement
      let traitements_data = this.treatments.map(treatment => ({
        date: this.form_date_traitement, 
        remanence: treatment.forcePersistence ? treatment.form_persistence_traitement : '', 
        id_produit: treatment.selectedProduct.id, 
        typeproduit: treatment.form_product_type_traitement,
        dose: treatment.form_product_dose_traitement,
        unite:treatment.form_dose_unit_traitement,
        resistancelessivage: treatment.form_washout_resistance_traitement, 
        remanence_alt: 0, 
        resistancelessivage_alt: 0 
      }));

      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });

      // Envoyer les données pour chaque parcelle sélectionnée
      if (Array.isArray(this.arr_select_plot_table)) {
        this.arr_select_plot_table.forEach(parcelle => {
          if (this.oneTraitementSelectedToEdit) {
              this.launchPutTraitementPlot(parcelle.id, traitements_data);
          } else {
              this.launchAddTraitementPlot(parcelle.id, traitements_data);
          }
        });
      } else {
        console.error('Erreur dans le bouclage des parcelles');
      }

      // Réinitialiser les champs et la sélection de parcelle après la soumission
      this.form_date_traitement = '';
      this.treatments = []; // Réinitialiser les traitements
},

    async launchAddTraitementPlot(id_plot, traitementFormData) {
      this.message_retour = parcellesMildiouService.addOneTreatmentToPlotInApi(id_plot, traitementFormData)
      .then(() => {
          this.updateDataAfterActionIntervention(id_plot, 'Traitement');
        })
        .catch(error => {
          console.error("Erreur lors de l'ajout de l 'Traitement", error);
        });
    },

    async launchPutTraitementPlot(id_plot, traitementFormData) {
      let treated_at = this.initial_date_treated_at;
      this.message_retour = parcellesMildiouService.putOneTraitementToPlotInApi(id_plot, traitementFormData,treated_at)
      .then(() => {
          this.updateDataAfterActionIntervention(id_plot, 'Traitement');
        })
        .catch(error => {
          console.error("Erreur lors de la maj de l 'Traitement", error);
        });
    },


    editTraitement(item) {
      this.oneTraitementSelectedToEdit = true;
      
      // Stocker la date du traitement
      this.initial_date_treated_at = this.formatDateForDatetimeLocal(item.treated_at);

      // Réinitialiser les traitementss
      this.treatments = [];

      // Filtrer tous les traitements ayant la même date (traitement groupé par date)
      const traitementsGroupe = this.formattedTraitements.filter(t => t.treated_at === item.treated_at);

      this.form_date_traitement = this.formatDateForDatetimeLocal(item.treated_at);

    // Ajouter chaque produit du traitement au tableau treatments
    traitementsGroupe.forEach(treatmentItem => {
        const isPersistenceModified = treatmentItem.modified_persistence && treatmentItem.modified_persistence !== treatmentItem.standard_persistence;

        this.treatments.push({
          selectedProduct: this.all_products.find(product => product.id === treatmentItem.product_id),
          form_product_type_traitement: treatmentItem.product_type,
          form_persistence_traitement: treatmentItem.modified_persistence || treatmentItem.standard_persistence, // Utilise la rémanence modifiée si présente
          form_product_dose_traitement: treatmentItem.product_dose,
          form_dose_unit_traitement: treatmentItem.dose_unit,
          form_washout_resistance_traitement: treatmentItem.washout_resistance,
          form_persistence_de_base: treatmentItem.standard_persistence,
          forcePersistence: isPersistenceModified // Afficher le champ si la rémanence a été modifiée
        });
      });
    },


    deleteTraitement() {
      this.closeChoix();
      
      if (!this.object_to_delete) {
      console.error('Aucun élément sélectionné pour suppression.');
      return;
      }
    
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      
      let treated_at = this.object_to_delete.treated_at;

      this.message_retour = parcellesMildiouService.deleteOneTraitementOfThePlot(this.id_plot_selected_to_edit,treated_at
      ).then(() => {
        this.object_deleted = true ;

          this.updateDataAfterActionIntervention(this.id_plot_selected_to_edit, 'Traitement');
        })
        .catch(error => {
          console.error("Erreur lors de la maj de traitement", error);
        })
        .finally(() => {
        this.object_to_delete = null; 
        this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
      });
    },


    //////////////////////
    // OBSERVATIONS 
    submitFormAddOrPutObs() {

      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });

      if (Array.isArray(this.arr_select_plot_table)) {
        this.arr_select_plot_table.forEach(parcelle => {
          if (this.oneObservationSelectedToEdit) {
            this.launchPutObservationPlot(parcelle.id, this.form_date_obs);
          } else {
            this.launchAddObservationPlot(parcelle.id, this.form_date_obs);
          }
        });
      } else {
        console.error('Erreur dans le bouclage des parcelles');
      }

      // Réinitialiser les champs et la selection de parcelle après la soumission du formulaire
      this.form_date_obs = '';
    },

    launchAddObservationPlot(id_plot, form_date_obs) {

      this.message_retour = parcellesMildiouService.addOneObservationToPlotInApi(id_plot, form_date_obs)
        // Une fois que l'ajout d'observation bien réalisé, recuperation des données mises à jour
        .then(() => {
          this.updateDataAfterActionIntervention(id_plot, 'Observation');
        })
        .catch(error => {
          console.error("Erreur lors de l'ajout de l 'observation", error);
        });
    },

    launchPutObservationPlot(id_plot, form_date_obs) {
      this.message_retour = parcellesMildiouService.putOneObservationToPlotInApi(id_plot, form_date_obs)
        // Une fois que l'ajout d'observation bien réalisé, recuperation des données mises à jour
        .then(() => {
          this.updateDataAfterActionIntervention(id_plot, 'Observation');
        })
        .catch(error => {
          console.error("Erreur lors de la maj de l 'observation", error);
        });
    },


    editObservation(item) {
      this.oneObservationSelectedToEdit = true; // Activer le mode édition
      this.form_date_obs = item; // Pré-remplir le formulaire avec la date d'observation actuelle en BDD
     // this.arr_select_plot_table.push(this.id_plot_selected_to_edit);
    },

    deleteObservation() {
      this.closeChoix()

if (!this.object_to_delete) {
console.error('Aucun élément sélectionné pour suppression.');
return;
}

      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      this.message_retour = parcellesMildiouService.deleteOneObservationOfThePlot(this.arr_select_plot_table[0].id,this.object_to_delete)
        // Une fois suppression d'observation bien réalisé, recuperation des données mises à jour
        .then(() => {
          this.object_deleted = true ;
          this.updateDataAfterActionIntervention(this.arr_select_plot_table[0].id, 'Observation');
        })
        .catch(error => {
          console.error("Erreur lors de la maj de l 'observation", error);
        })
        .finally(() => {
        this.object_to_delete = null; 
        this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
      });
    },


    ///////////////////////////
    // IRRIGATION

    submitFormAddOrPutIrrigation() {


      let irrigationFormData = {
        begin_at: this.form_date_debut_irrig,
        end_at: this.form_date_fin_irrig,
        water_dose: this.form_qte_mm,
        dose_unit: 'MM'
      };

      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      if (Array.isArray(this.arr_select_plot_table)) {
        this.arr_select_plot_table.forEach(parcelle => {
          if (this.oneIrrigationSelectedToEdit) {
            this.launchPutIrrigationPlot(parcelle.id, irrigationFormData);
          } else {
            this.launchAddIrrigationPlot(parcelle.id, irrigationFormData);
          }
        });
      } else {
        console.error('Erreur dans le bouclage des parcelles');
      }

      // Réinitialiser les champs et la selection de parcelle après la soumission du formulaire
      this.form_date_debut_irrig= '';
      this.form_date_fin_irrig= '';
      this.form_qte_mm= '';
    },

    launchAddIrrigationPlot(id_plot, irrigationFormData) {

      this.message_retour = parcellesMildiouService.addOneIrrigationToPlotInApi(id_plot, irrigationFormData)
        // recuperation des données mises à jour
        .then(() => {
          this.updateDataAfterActionIntervention(id_plot, 'Irrigation');
        })
        .catch(error => {
          console.error("Erreur lors de l'ajout de l 'Irrigation", error);
        });
    },

    launchPutIrrigationPlot(id_plot, irrigationFormData) {

      let begin_at = this.initial_date_debut_irrig;
      this.message_retour = parcellesMildiouService.putOneIrrigationToPlotInApi(id_plot, irrigationFormData,begin_at)
        // recuperation des données mises à jour
        .then(() => {
          this.updateDataAfterActionIntervention(id_plot, 'Irrigation');
        })
        .catch(error => {
          console.error("Erreur lors de la maj de l 'Irrigation", error);
        });
    },


    editIrrigation(item) {
      this.oneIrrigationSelectedToEdit = true; // Activer le mode édition

      // Convertir les dates au format YYYY-MM-DDTHH:MM pour les champs datetime-local
      
      this.initial_date_debut_irrig = this.formatDateForDatetimeLocal(item.begin_at);
      this.form_date_debut_irrig = this.formatDateForDatetimeLocal(item.begin_at);
      this.form_date_fin_irrig = this.formatDateForDatetimeLocal(item.end_at);

      this.form_qte_mm =  item.water_dose;
    },

    deleteIrrigation() {
      this.closeChoix()

      if (!this.object_to_delete) {
      console.error('Aucun élément sélectionné pour suppression.');
      return;
    }
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });

      this.message_retour = parcellesMildiouService.deleteOneIrrigationOfThePlot(this.arr_select_plot_table[0].id,this.object_to_delete.begin_at)
        // recuperation des données mises à jour
        .then(() => {
          this.object_deleted = true ;
          this.updateDataAfterActionIntervention(this.arr_select_plot_table[0].id, 'Irrigation');
        })
        .catch(error => {
          console.error("Erreur lors de la maj de l 'Irrigation", error);
        })
        .finally(() => {
        this.object_to_delete = null; 
        this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
      });
    },

    // Méthode pour formater les dates au format YYYY-MM-DDTHH:MM
    formatDateForDatetimeLocal(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    },

    getColorRisque(statut) {
      if (statut == 10) return 'green'
      else if (statut == 50) return 'red'
      else if (statut == 40) return 'orange'
      else return 'grey'
    },
    getIconRisque(statut) {
      if (statut == 10) return 'mdi-emoticon-happy-outline'
      else if (statut == 50) return 'mdi-emoticon-sad-outline'
      else if (statut == 40) return 'mdi-emoticon-neutral-outline'
      else return 'mdi-close-thick'
    },
    getInfoRisque(statut) {
      if (statut == 10) return "Le climat n'est pas favorable et/ou la quantité de spores dans l'environnement n'est pas suffisante. Il n'y a donc <b>pas de risque</b> de contamination sur la parcelle."
      else if (statut == 50) return "Le climat et la quantité de spores sont favorables, il y a donc la présence d'un <b>risque de contamination</b> sur la parcelle."
      else if (statut == 40) return "Le climat est presque favorable et la quantité de spores est suffisante. Il n'y a donc pour le moment pas de risque de contamination mildiou sur la parcelle, mais il est nécessaire de <b>rester vigilant</b> au cas où les conditions climatiques deviennent favorables. Un risque de contamination serait alors présent."
      else return ''
    },

    getColorProtection(statut) {
      if (statut == 1) return 'green'
      else return 'red'
    },
    getIconProtection(statut) {
      if (statut == 1) return 'mdi-shield'
      else return 'mdi-shield-off'
    },
    getInfoProtection(statut) {
      if (statut == 1) return 'Parcelle protégée'
      else return 'Parcelle non protégée'
    },

  formatDate(dateString) {
    if (!dateString || !dateString.includes('-')) return ''; 
    const [day, month, year] = dateString.split('-');
    if (!day || !month || !year) return '';
    return `${day}-${month}-${year}`;
  }
,


    /**
     * S'assurer qu'il y a 1 h de decallage entre les 2 champs au minimum
     */
    validateDates() {
      if (this.form_date_debut_irrig && this.form_date_fin_irrig) {
        let startDate = new Date(this.form_date_debut_irrig);
        let endDate = new Date(this.form_date_fin_irrig);
        let oneHour = 60 * 60 * 1000;

        if (endDate <= startDate || (endDate - startDate) < oneHour) {
          this.errorMessage = 'La date de fin doit être au moins 1 heure après la date de début.';
        } else {
          this.errorMessage = '';
        }
      }
    },
    validateStadeDates() {
    let errors = [];

    const { plantation, levee30, croissanceactive, croissancestabilisee, senescence, defanage } = this.form_stades;

    if (plantation && levee30) {
      if (new Date(levee30) <= new Date(plantation)) {
        errors.push('La date de la levée 30 doit être postérieure à la date de plantation.');
      }
    }

    if (levee30 && croissanceactive) {
      if (new Date(croissanceactive) <= new Date(levee30)) {
        errors.push('La date de croissance active doit être postérieure à la date de levée 30.');
      }
    }

    if (croissanceactive && croissancestabilisee) {
      if (new Date(croissancestabilisee) <= new Date(croissanceactive)) {
        errors.push('La date de croissance stabilisée doit être postérieure à la date de croissance active.');
      }
    }

    if (croissancestabilisee && senescence) {
      if (new Date(senescence) <= new Date(croissancestabilisee)) {
        errors.push('La date de début de sénescence doit être postérieure à la date de croissance stabilisée.');
      }
    }

    if (senescence && defanage) {
      if (new Date(defanage) <= new Date(senescence)) {
        errors.push('La date de défanage doit être postérieure à la date de sénescence.');
      }
    }

    this.errorMessageStade = errors;
  },


    /**
     * Formater les labels des champs pour les afficher dans le formulaire de modification
     * @param {*} key 
     */
    formatLabel(key) {
      // Remplace les '_' par des espaces et Met en majuscule la première lettre de chaque mot de label
      return key.replace(/_/g, ' ').replace(/\b\w/g, (firstLetter) => firstLetter.toUpperCase());
    },

    addTreatment() {
      this.treatments.push({
        selectedProduct: null,
        form_product_type_traitement: '',
        form_persistence_traitement: '',
        form_product_dose_traitement: '',
        form_dose_unit_traitement: 'kg/ha'
      });
    },

    removeTheProductOfTheTreatment(index) {
      this.treatments.splice(index, 1);
    },

    updateProductDetailsForTreatment(index) {
        const product = this.treatments[index].selectedProduct;
        if (product) {
          this.$set(this.treatments, index, {
            ...this.treatments[index],
            form_product_type_traitement: product.type,
            form_persistence_traitement: product.remanence,
            form_persistence_de_base: product.remanence, 
            form_washout_resistance_traitement: product.resistancelessivage
          });
        }
      },

    async launchAddTreatmentPlot(id_plot, traitements) {
      try {
        // Itérer sur chaque traitement et faire l'appel API pour chacun
        for (let traitement of traitements) {
          await parcellesMildiouService.addOneTreatmentToPlotInApi(id_plot, traitement);
          // Mise à jour des données après chaque ajout de traitement
          this.updateDataAfterActionIntervention(id_plot, 'Traitement');
        }
      } catch (error) {
        console.error("Erreur lors de l'ajout de traitement", error);
      }
    },


    //////////////////////////////////////////////
    // * INTERVENTION SUR LA PARCELLE

    /***
     * Fonction pour mettre à jour les données après action CRUD sur les interventions d'une parcelle et retourner à l'user une info
     */
    async updateDataAfterActionIntervention(id_plot, thematique_concernee) {

    // Vérification si MULTI PARCELLE  
    if (this.arr_select_plot_table.length > 1) {
      this.$store.dispatch('general/setWaitingOverlay', {
        loading: false,
        fullpage: true
      });
      this.$store.dispatch('informations/setInfo', {
        message: {
          titre: "Action bien effectuée sur votre selection de parcelle",
          description: `Ajout de ${thematique_concernee} réussie.`
        }
      });
      return; // Sortir de la fonction directement car multi parcelle incompatible avec ce fonctionnement de recuperation des données d'une parcelle par intervention
    }

    let verbe_action = this.object_deleted
    ? 'Suppression de '
    : (this.oneIrrigationSelectedToEdit || this.oneTraitementSelectedToEdit || this.oneObservationSelectedToEdit || this.stadeSelectedToEdit)
        ? 'Modification de '
        : 'Ajout de ';

    if (this.object_deleted) {
    this.object_deleted = false;
    }

    let actionPromise;
    switch (thematique_concernee) {
        case 'Traitement':
            actionPromise = this.loadTraitementsData()
                .then(() => {
                    this.isEditMode = false;
                    this.oneTraitementSelectedToEdit = false;
                    this.initial_date_treated_at = null;
                    // Enrichir les traitements avec les remanences modifiées ou non
                });
            break;
        case 'Observation':
            actionPromise = this.loadObservationsData()
                .then(() => {
                    this.isEditMode = false;
                    this.oneObservationSelectedToEdit = false;
                });
            break;
        case 'Irrigation':
            actionPromise = this.loadIrrigationsData()
                .then(() => {
                    this.isEditMode = false;
                    this.initial_date_debut_irrig = null;
                    this.oneIrrigationSelectedToEdit = false;
                });
            break;
        case 'Stade':
            actionPromise = this.loadStadesData()
                .then(() => {
                    this.isEditMode = false;
                });
            break;
        default:
            console.error('Thématique inconnue:', thematique_concernee);
            this.isEditMode = false;
            this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
            return;
    }

    // Gestion commune de l'affichage du chargement et des notifications
    if (actionPromise) {
        actionPromise
            .catch(error => {
                console.error('Erreur lors de la mise à jour des données:', error);
                this.$store.dispatch('informations/setInfo', {
                    message: {
                        titre: "Erreur",
                        description: `Erreur lors de ${verbe_action} ${thematique_concernee}.`
                    }
                });
            })
            .finally(() => {
                this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
                this.$store.dispatch('informations/setInfo', {
                    message: {
                        titre: "Action bien effectuée",
                        description: `${verbe_action}${thematique_concernee} réussie.`
                    }
                });
            });
    }
},

    transformStadesData(data) {
        let transformed_data = {};
        data.forEach(stade => {
          transformed_data[this.mapStadeName(stade.name)] = this.formatDate(stade.date) || null;
        });
        return transformed_data;
      },

  mapStadeName(name) {
    // Mapper les noms des stades aux clés souhaitées
    const phaseMapping = {
      'PLANTATION': 'plantation',
      '30PERCENT-EMERGENCE': 'levee30',
      'RAPID-HAULM-GROWTH': 'croissanceactive',
      'STABLE-CANOPY': 'croissancestabilisee',
      'HAULM-DESSICATION': 'defanage',
      'HAULM-SENESCENCE': 'senescence',
      'HARVEST': 'recolte'
    };

    return phaseMapping[name.toUpperCase()] || name;
    },

    inverseMapStadeName(stade_vue_utilisateur) {
      const phaseMapping = {
        'plantation': 'PLANTATION',
        'levee30': '30PERCENT-EMERGENCE',
        'croissanceactive': 'RAPID-HAULM-GROWTH',
        'croissancestabilisee': 'STABLE-CANOPY',
        'defanage': 'HAULM-DESSICATION',
        'senescence': 'HAULM-SENESCENCE',
        'recolte': 'HARVEST'
      };

      return phaseMapping[stade_vue_utilisateur] || stade_vue_utilisateur;
    },


    confirmDeleteStade(){
      this.$store.dispatch("choix/setChoix", {
        question: "Suppression de stade",
        detail: "Etes-vous sûr de vouloir supprimer les stades ?",
        choix: {
          choix1: { text: "Oui", function: "deleteStades" },
          choix2: { text: "Annuler", function: "closeChoix" },
        },
      });
    },

    confirmDeletePlot(item){
      this.select_plot_to_delete = item
      this.$store.dispatch("choix/setChoix", {
        question: "Suppression de parcelle",
        detail: "Etes-vous sûr de vouloir supprimer la parcelle ?",
        choix: {
          choix1: { text: "Oui", function: "submitFormDeletePlot" },
          choix2: { text: "Annuler", function: "closeChoix" },
        },
      });
    },

    confirmDeleteObs(item){
      this.object_to_delete = item
      this.$store.dispatch("choix/setChoix", {
        question: "Suppression d'observation",
        detail: "Etes-vous sûr de vouloir supprimer l'observation ?",
        choix: {
          choix1: { text: "Oui", function: "deleteObservation" },
          choix2: { text: "Annuler", function: "closeChoix" },
        },
      });
    },

    confirmDeleteTraitement(item){
      this.object_to_delete = item
      this.$store.dispatch("choix/setChoix", {
        question: "Suppression de traitement",
        detail: "Etes-vous sûr de vouloir supprimer ce traitement ?",
        choix: {
          choix1: { text: "Oui", function: "deleteTraitement" },
          choix2: { text: "Annuler", function: "closeChoix" },
        },
      });
    },

    confirmDeleteIrrigation(item){
      this.object_to_delete = item
      this.$store.dispatch("choix/setChoix", {
        question: "Suppression d'irrigation",
        detail: "Etes-vous sûr de vouloir supprimer cette irrigation ?",
        choix: {
          choix1: { text: "Oui", function: "deleteIrrigation" },
          choix2: { text: "Annuler", function: "closeChoix" },
        },
      });
    },

    closeChoix() {
      this.$store.dispatch("choix/unsetChoix");
        this.searchWord = null;
    },

    async deleteItem(){
      this.closeChoix()
      var isDeleted = null
      isDeleted = await consentementService.deleteLienConseiller(this.delete_id);
    
      if(isDeleted == true){
            this.getInfosConseillersAgri()
      } else {
          alert('Un problème est survenu.')
      }
      this.delete_id = null;
    },

    // Formatez la date pour l'affichage du groupe dans le tableau
    formatGroupDate(dateString) {

    try {
        // Convertir la chaîne en objet Date (automatiquement en UTC)
        const date = new Date(dateString);

        // Vérifiez si la date est valide
        if (isNaN(date.getTime())) {
            console.error('Invalid date:', dateString);
            return dateString; // Retourne la chaîne d'origine si la date est invalide
        }

        // Spécifiez les options pour le formatage de la date et de l'heure locales
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false, // Utilisez le format 24 heures
            timeZone: 'Europe/Paris' // Fuseau horaire spécifique pour Paris
        };

        const formattedDate = new Intl.DateTimeFormat('fr-FR', options).format(date);
        return formattedDate;

    } catch (error) {
        console.error('Error in formatGroupDate:', error);
        return dateString; // Retourne la chaîne d'origine en cas d'erreur
    }
},

    toggleMode() {
      this.isEditMode = !this.isEditMode;
      this.arr_select_plot_table = [];
      this.oneTraitementSelectedToEdit = false;
      this.oneIrrigationSelectedToEdit = false;
      this.oneObservationSelectedToEdit = false;
    },

    generateUniqueId() {
      return `item_${index}_${new Date().getTime()}`;  
    },
  prepareData() {
    this.formattedTraitements = data.map((item, index) => ({
        ...item,
        unique_id: this.generateUniqueId(index),
      }));
  },

  async affichagesRemanenceTraitements() {
    try {
        let traitementsArray = this.formattedTraitements;

        // Enrichissement des traitements avec les informations de rémanence
        traitementsArray.forEach(trait => {
            let product_found = this.all_products.find(p => p.name === trait.product_name);
            
            // Si le produit est trouvé
            if (product_found) {
                // Convertir les valeurs de rémanence en nombres
                let standardPersistence = Number(product_found.remanence); // REMANENCE DE BASE
                let userPersistence = Number(trait.persistence);

                // La rémanence standard
                trait.standard_persistence = !isNaN(standardPersistence) ? standardPersistence : '-';

                // Déterminer la rémanence modifiée
                trait.modified_persistence = (trait.persistence === '' || isNaN(userPersistence))
                    ? '-'  // Si persistence est vide ou non numérique
                    : userPersistence;
            } else {
                // Si le produit n'est pas trouvé
                trait.standard_persistence = '-';
                trait.modified_persistence = (trait.persistence === '' || isNaN(Number(trait.persistence)))
                    ? '-'  // Si persistence est vide ou non numérique
                    : trait.persistence;
            }
        });

        // Met à jour this.formattedTraitements avec le tableau enrichi des données de remanences
        this.formattedTraitements = traitementsArray;
    } catch (error) {
        console.error('Erreur lors de l\'enrichissement des traitements avec les informations de rémanence :', error);
    }
},


resetIrrigationForm() {
  this.form_date_debut_irrig = '';
  this.form_date_fin_irrig = '';
  this.form_qte_mm = '';
  this.errorMessage = '';
  this.searchWord ='';
},

toggleIrrigationMode() {
  this.isEditModeIrrigation = !this.isEditModeIrrigation;
  this.oneIrrigationSelectedToEdit = false;
  this.arr_select_plot_table = [];
  this.resetIrrigationForm();
},

resetTreatmentForm() {
        // Réinitialiser la date du traitement
        this.form_date_traitement = null;
        // Réinitialiser la liste des traitements
        this.treatments = this.treatments.map(() => ({
            selectedProduct: null,
            form_washout_resistance_traitement: '',
            form_persistence_traitement: '',
            forcePersistence: false,
            form_product_type_traitement: '',
            form_product_dose_traitement: '',
            form_dose_unit_traitement: 'kg/ha' // ou la valeur par défaut que vous souhaitez
        }));
        // Reset des erreurs ou autres états liés au formulaire
        this.errorMessage = '';
        this.searchWord ='';
    },

toggleTreatmentMode() {
  this.isEditModeTreatment = !this.isEditModeTreatment;
  this.oneTraitementSelectedToEdit = false;
  this.arr_select_plot_table = [];
  this.resetTreatmentForm();
},

resetObservationForm() {
         // Réinitialiser les champs des stades
         this.form_stades = {
            plantation: '',
            levee30: '',
            croissanceactive: '',
            croissancestabilisee: '',
            senescence: '',
            defanage: ''
        };

        // Réinitialiser les champs de récolte
        this.form_harvest = {
            datetime: '',
            yield: ''
        };

        this.searchWord ='';

    },

toggleObservationMode() {
  this.isEditModeObservation = !this.isEditModeObservation;
  this.oneObservationSelectedToEdit = false;
  this.arr_select_plot_table = [];
  this.resetObservationForm();
},

resetStadesForm() {
        // Réinitialiser les champs des stades
        this.form_stades = {
            plantation: '',
            levee30: '',
            croissanceactive: '',
            croissancestabilisee: '',
            senescence: '',
            defanage: ''
        };

        // Réinitialiser les champs de récolte
        this.form_harvest = {
            datetime: '',
            yield: ''
        };

        // Réinitialiser les messages d'erreur
        this.errorMessageStade = [];
        this.searchWord ='';

    },

toggleStadesMode() {
  this.isEditModeStade = !this.isEditModeStade;
  this.arr_select_plot_table = [];
  this.resetStadesForm();
},

resetPlotForm() {
 // Réinitialiser les champs du formulaire
 this.plot_to_put_or_create = {
            id: '',
            name: '',
            latitude: '',
            longitude: '',
            variety: null, 
            plantation: '',
            area: ''
        };

        this.select_plot_to_edit.id = ''; // Réinitialiser l'ID de la parcelle sélectionnée


        this.checkStatus = ''; 
        this.isButtonDisabled = false; 
        this.isEditMode = false; 

        this.errorMessage = ''; // Réinitialiser les messages d'erreur spécifiques
        this.searchWord ='';
        this.ouvrir_ajout_parcelle = false;

},

togglePlotMode(){
  this.arr_select_plot_table = [];
  this.resetPlotForm();
},
shouldDisplayTableStade() {
        return this.arr_select_plot_table.length===1 && this.presence_stade;
    },
    shouldDisplayTableTraitement() {
        return this.arr_select_plot_table.length===1 && this.presence_traitement;
    },
    shouldDisplayTableIrrigation() {
        return this.arr_select_plot_table.length===1 &&  this.presence_irrigation;
    },
    shouldDisplayTableObs() {
        return this.arr_select_plot_table.length===1 && this.presence_obs;
    },
    disableArrowKeys(event) {
    // Empêcher uniquement les flèches haut et bas
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
    }
  }


  } // FIN de la PARTIE des METHODES

};
  

</script>

<style scoped>

h4{
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
  margin-left: 20px;
  margin:0.5rem;
}


/* Désactiver les spinners pour Chrome, Safari, Edge et Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Désactiver les spinners pour Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.boutons_actions {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	align-items: center;
	align-content: stretch;
}


input{
  background-color: #fff;
  padding:0.12rem;
}

/* EN MOBILE  */
@media only screen and (max-width: 960px) {

  .v-dialog{
    height: 90vh !important;
    background-color: whitesmoke !important;
    padding:0.4rem;
  }

}

#edit_plot_form{
    display: flex; 
    flex-direction: column; 
    background-color:rgb(240, 240, 240);
  }

  #edit_plot_form fieldset{
    margin:0.4rem;
    padding:0.4rem;
    border:1px solid #82ac00;
    border-radius:5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
  }

  #edit_plot_form .field{
    margin:0.4rem;
    background-color: #fff !important;
  }

.input_field {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid grey;
  }

  .submit-button {
    width: 50%;
    padding: 15px;
    border-radius: 5px;
    background-color: #82ac00;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .add_button{
    
    height:2.5rem !important;
    font-size:1.5rem !important;
    background-color:#82ac00 !important;
    margin:0.4rem !important;
  }

  legend{
    font-size:1.2em;
    font-weight:bold;
    color:#82ac00;
  }

  i.icon_h3{
    font-size:1.15em !important;
  }

.form-row {
  display: flex; /* Utilise Flexbox pour l'agencement */
  flex-wrap: wrap; /* Permet aux éléments de se déplacer à la ligne suivante si nécessaire */
  gap: 16px; /* Ajoute un espacement entre les éléments */
}

.form-row > div {
  flex: 1; /* Permet aux éléments de prendre un espace égal */
  min-width: 200px; /* Assure une largeur minimale pour les champs */
}


.input_field {
  width: 100%;
}

.submit-button {
  align-self: center;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.status-icon {
  font-size: 32px;
  margin-left: 16px;
}


</style>