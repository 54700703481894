<template>
  <div>
    <v-container style="margin-top: 10px">
      <!--Form-->
      <div id="form" style="text-align: center;">
        <v-row>
          <v-col cols="3" style="padding:0.5rem">
            <v-select style="padding:0.5rem" v-model="selectedEspece" :items="listeEspece" item-text="name"
              item-value="code" menu-props="auto" label="Espèce" hide-details prepend-icon="mdi-sprout" single-line
              chips multiple dense>
            </v-select>
          </v-col>
          <v-col cols="3" style="padding:0.5rem">
            <v-autocomplete style="padding:0.5rem" v-model="selectedVariete" :items="listeVariete" item-value="value"
              item-text="value" menu-props="auto" label="Variété" hide-details prepend-icon="mdi-sprout" single-line
              multiple chips dense>
            </v-autocomplete>
          </v-col>
          
          <v-col cols="3" style="padding:0.5rem">
            <v-autocomplete style="padding:0.5rem" v-model="selectedDepartement" item-value="value" item-text="value"
              :items="listeDepartement" menu-props="auto" label="Departement" hide-details
              prepend-icon="mdi-map-marker-outline" single-line chips multiple dense></v-autocomplete>
          </v-col>
          <v-col cols="3" style="padding:0.5rem">
            <v-autocomplete style="padding:0.5rem" v-model="selectedClient" item-value="value" item-text="value"
              :items="listeClient" menu-props="auto" label="Client" hide-details prepend-icon="mdi-account" single-line
              chips multiple dense></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col offset="1" cols="3">
            <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
              min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="date_debut" label="Date début des semis" prepend-icon="mdi-calendar" readonly
                  v-bind="attrs" v-on="on" class="float w-160"></v-text-field>
              </template>
              <v-date-picker v-model="date_debut"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col offset="1" cols="3">
            <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
              min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="date_fin" label="Date fin des semis" prepend-icon="mdi-calendar" readonly
                  v-bind="attrs" v-on="on" class="float w-160"></v-text-field>
              </template>
              <v-date-picker v-model="date_fin"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col offset="1" cols="1">
            <v-btn style="padding:0.5rem" @click="resetFiltres()" color="info" class="h-70">
              <v-tooltip bottom max-width="350">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-rotate-left
                  </v-icon>
                </template>
                <span>Effacer les filtres</span>
              </v-tooltip>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <!--Fin Form-->

      <!-- Tableur -->
      <div>
        <h3>
          Tableau des parcelles
        </h3>
        <div> 
          <v-card>
            <v-card-title>
              <v-spacer></v-spacer>
              <v-text-field v-model="searchWord_cap" append-icon="mdi-magnify" label="Recherche par mot clé" single-line
                hide-details></v-text-field>
            </v-card-title>

            <!--https://codepen.io/wolfpup/pen/gOadmPx-->
            <v-data-table v-model="selected_cap" :items="all_info_about_stades" item-key="id_plot" :headers="headers"
              :search="searchWord_cap" dense>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="showItem(item.id_plot)">
                  mdi-eye
                </v-icon>
              </template>
              <template v-slot:[`item.operateur`]="{ item }">{{ item.nom_operateur }} [{{ item.id_operateur }}]</template>
            </v-data-table>
          </v-card>
          <!--EXCEL-->
          <v-btn small color="info" class="mt-2 white--text">
            <download-excel :data="all_info_about_stades" :name="getNomFichier()" :fields="excelfields">
              Export Excel
              <v-icon small class="mr-2">
                mdi-download
              </v-icon>
            </download-excel>
          </v-btn>
          <div v-if="checkDroits('ADMIN','lecture') || this.$store.getters['user/login']=='hamerd'">
            <v-btn small color="info" class="mt-2 white--text">
              <download-excel :fetch="getInfosParcellesDetail" :name="getNomFichier()">
                Export Excel détaillé
                <v-icon small class="mr-2">
                  mdi-download
                </v-icon>
              </download-excel>
            </v-btn> (notes réelles appliquées sur les parcelles)
          </div>
          
        </div>
      </div>
      <!-- FIN  Tableur -->

      <!--  Graphique Stade -->
      <div>
        <h3>
          Stades à observer
        </h3>
        <div style="margin-bottom:20px;margin-top:20px;">
          <v-row>
            <v-col style="margin: 0 auto;" cols="3">
              <v-select
                v-model="selected_stade_graph"
                v-on:change="viewStadeGraph()"
                :items="stades_graphs"
                item-text="name"
                item-value="code"
                menu-props="auto"
                label="Stade"
                hide-details
                chips
                dense
              ></v-select>
            </v-col>
            <v-col style="margin: 0 auto;" cols="8">
              <v-chart class="chart" style="height:350px" :option="chartOptions" />
            </v-col>
          </v-row>
        </div>
      </div>
      <!-- FIN  Graph Stade -->

      <!--  Appels API de VIGICULTURE reservés aux ADMIN -->
      <div id="cmd_vigiculture" v-if="checkDroits('ADMIN','lecture')">
        <h3>
          Gestion Parcelles de Vigiculture (admin).
        </h3>
        <div>
          <!-- <v-btn  @click="launchAPIGetLogged()" color="info" class="h-70">
          <v-tooltip bottom max-width="350">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">
                LOGIN
              </v-icon>
            </template>
            <span>LOGIN TEST </span>
          </v-tooltip>
        </v-btn> -->
          <v-btn small color="info" class="mt-2 mb-50 white--text"  @click="launchAPIGetListPlotVigiculture()" >
            Obtenir les parcelles 
          </v-btn> 
          <!--Plus d'usage actuel-->
          <!-- <v-btn small color="info" class="mt-2 mb-50 white--text"  @click="launchAPIGetObsPlotVigiculture()" >
            Obtenir les observations
          </v-btn>  --> 
        
        </div>

      </div>
    </v-container>
  </div>
</template>

<script>
import CommentaireComponent from "@/components/global/CommentaireSimple.vue";
import downloadExcel from "vue-json-excel";
import parcellesStadeService from "@/service/parcellesStade";

import  VChart  from "vue-echarts";
import { getDate } from "@/helpers/functions";
import { LMap, LTileLayer, LMarker, LIcon, LPopup } from 'vue2-leaflet';

export default {
  name: "Suivi_Stade_Cap",
  components: {
    CommentaireComponent,
    downloadExcel,
    parcellesStadeService,
    VChart,
    getDate,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup
  },
  data() {
    return {
      login: this.$store.state.user.user.login.toLowerCase(),
      droits: this.$store.state.user.user.droitsTPC,
      dialog: false,
      dialog_api : false,
      csv: null,
      dataloaded: false,
      calendar : {},
      selected_cap:[],
      searchWord_cap: '',
      all_info_about_stades:[],
      date_debut:'',
      date_fin:'',
      liste_formulaire: null,
      selectedVariete: [],
      selectedEspece: [],
      selectedDepartement: [],
      selectedClient: [],
      listeClient: [],
      listeDepartement: [],
      listeVariete: [],
      listeEspece: [{code:17, name:'Blé tendre'},{code:40, name:'Blé dur'},{code:27, name:'Orge d\'hiver'},{code:19, name:'Triticale'}],
      headers: [
        //colonnes non visibles mais permettant de faire une recherche sur le contenu
        { text: 'Nom opérateur', value: 'nom_operateur', align: ' d-none' },
        { text: 'Id opérateur', value: 'id_operateur', align: ' d-none' },
        //colonnes visibles
        { text: "", value: "actions", sortable: false, align:"center",width: '36px'},
        { text: "Code client", value: "nom_operateur"},
        { text: "Opérateur", value: "operateur"},
        { text: "Id user", value: "id_user"},
        { text: "Nom user", value: "nom_user"},
        { text: "Code parcelle", value: "code_parcelle_os",width: '140px'},
        { text: "Id parcelle", value: "id_plot"},
        { text: "Code espece", value: "codeespece"},
        { text: "Date semis", value: "date_semis"},
        { text: "Variete", value: "variete"},
        { text: "Code sol", value: "codesol"},
        { text: "Labour", value: "labour"},
        { text: "Précédent", value: "code_precedent"},
        { text: "A calculer", value: "a_calculer"},
        { text: "Date calcul", value: "date_calcul"},
        { text: "Date fin prévisions", value: "date_fin_previsions"},
        { text: "Code INSEE", value: "code_insee"},
        { text: "x", value: "x"},
        { text: "y", value: "y"},
        { text: "E1C observé", value: "e1cm_observe"},
        { text: "Hauteur moyenne", value: "hauteur_moyenne"},
        { text: "Levée", value: "stade_levee"},
        { text: "Début tallage", value: "stade_debuttallage"},
        { text: "E1C", value: "stade_epi1cm"},
        { text: "E1C prévu", value: "stade_epi1cm_prevu"},
        { text: "1N", value: "stade_1n"},
        { text: "2N", value: "stade_2n"},
        { text: "DFP", value: "stade_dfp"},
        { text: "Méïose", value: "stade_meiose"},
        { text: "Epiaison", value: "stade_epiaison"},
        { text: "Floraison", value: "stade_floraison"},
        { text: "Maturité", value: "stade_maturite"},
        { text: "fusa_datealerte", value: "fusa_datealerte"},
        { text: "fusa_datet1", value: "fusa_datet1"},
        { text: "risque_fusa", value: "risque_fusa"},
        { text: "don_classerisque", value: "don_classerisque"},
        { text: "risque_agronomique", value: "risque_agronomique"},
        { text: "score_don_moyenne", value: "score_don_moyenne"},
        { text: "septo_datealerte", value: "septo_datealerte"},
        { text: "septo_datet1", value: "septo_datet1"},
        { text: "septo_datealerte2", value: "septo_datealerte2"},
        { text: "septo_datet2", value: "septo_datet2"},
        { text: "septo_datet1_observe", value: "septo_datet1_observe"},
        { text: "septo_datet1_bloque", value: "septo_datet1_bloque"},
        { text: "rouillebrune_datealerte", value: "rouillebrune_datealerte"},
        { text: "rouillebrune_datet1", value: "rouillebrune_datet1"},
        { text: "rouillejaune_datealerte", value: "rouillejaune_datealerte"},
        { text: "rouillejaune_datet1", value: "rouillejaune_datet1"},
        { text: "pv_datealerte", value: "pv_datealerte"},
        { text: "pv_datet1", value: "pv_datet1"},
        { text: "pv_risque_actualise", value: "pv_risque_actualise"},
        { text: "rouillejaune_ydebride_datealerte", value: "rouillejaune_ydebride_datealerte"},
        { text: "rouillejaune_ydebride_datet1", value: "rouillejaune_ydebride_datet1"},
        { text: "rouillejaune_crusty_date1", value: "rouillejaune_crusty_date1"},
        { text: "rouillejaune_crusty_date2", value: "rouillejaune_crusty_date2"},
        { text: "rouillejaune_crusty_date3", value: "rouillejaune_crusty_date3"},
        { text: "pv_cumul_top", value: "pv_cumul_top"},
        { text: "helmintho_date1", value: "helmintho_date1"},
        { text: "helmintho_date2", value: "helmintho_date2"},
        { text: "rhyncho_date1", value: "rhyncho_date1"},
        { text: "rhyncho_date2", value: "rhyncho_date2"},
        { text: "pv_score_actualise", value: "pv_score_actualise"},
        { text: "epi1cm_decalageoadjours", value: "epi1cm_decalageoadjours"},
        { text: "epiaison_decalageoadjours", value: "epiaison_decalageoadjours"},
        { text: "date_fix_t1", value: "date_fix_t1"},
        { text: "date_fix_t2", value: "date_fix_t2"},
        { text: "septo_datealerte3", value: "septo_datealerte3"},
        { text: "septo_datet3", value: "septo_datet3"},
        { text: "code_station", value: "code_station"},
        { text: "Stades observés", value: "stades"},
        { text: "Type d'observateur", value: "type_observateur"},
        { text: "Precocite épiaison", value: "precocite_epiaison"},
        { text: "Précocité montaison", value: "precocite_montaison"}
      ],
      apercu:[],
      parse_header: [],
      parse_csv: [],
      sortOrders:{},
      sortKey: '',
      nbr_plot_csv:0,
      message_api:'',
      array_message_erreur_api:[],

      excelfields: {
        'etat_calcul':'etat_calcul',
        'code_dept':'code_dept',
        'nom_operateur':'nom_operateur',
        'id_operateur':'id_operateur',
        'id_user':'id_user',
        'nom_user':'nom_user',
        'code_parcelle_os':'code_parcelle_os',
        'id_plot':'id_plot',
        'codeespece':'codeespece',
        'code sol':'codesol',
        'date_semis':'date_semis',
        'variete':'variete',
        'labour':'labour',
        'code_precedent':'code_precedent',
        'a_calculer':'a_calculer',
        'p_dt_lastechec':'p_dt_lastechec',
        'date_calcul':'date_calcul',
        'date_fin_previsions':'date_fin_previsions',
        'stade_levee':'stade_levee',
        'e1cm_observe':'e1cm_observe',
        'hauteur_moyenne':'hauteur_moyenne',
        'code_insee':'code_insee',
        'x':'x',
        'y':'y',
        'stade_debuttallage':'stade_debuttallage',
        'stade_epi1cm':'stade_epi1cm',
        'stade_epi1cm_prevu':'stade_epi1cm_prevu',
        'stade_1n':'stade_1n',
        'stade_2n':'stade_2n',
        'stade_f5':'stade_f5',
        'stade_f4':'stade_f4',
        'stade_f3':'stade_f3',
        'stade_f2':'stade_f2',
        'stade_dfp':'stade_dfp',
        'stade_meiose':'stade_meiose',
        'stade_epiaison':'stade_epiaison',
        'stade_floraison':'stade_floraison',
        'stade_maturite':'stade_maturite',
        'stade_recolte':'stade_recolte',
        'fusa_datealerte':'fusa_datealerte',
        'fusa_datet1':'fusa_datet1',
        'risque_fusa':'risque_fusa',
        'don_classerisque':'don_classerisque',
        'risque_agronomique':'risque_agronomique',
        'score_don_moyenne':'score_don_moyenne',
        'septo_datealerte':'septo_datealerte',
        'septo_datet1':'septo_datet1',
        'septo_datealerte2':'septo_datealerte2',
        'septo_datet2':'septo_datet2',
        'septo_datet1_observe':'septo_datet1_observe',
        'septo_datet1_bloque':'septo_datet1_bloque',
        'rouillebrune_datealerte':'rouillebrune_datealerte',
        'rouillebrune_datet1':'rouillebrune_datet1',
        'pv_datealerte':'pv_datealerte',
        'pv_datet1':'pv_datet1',
        'pv_risque_actualise':'pv_risque_actualise',
        'rouillejaune_ydebride_datealerte':'rouillejaune_ydebride_datealerte',
        'rouillejaune_ydebride_datet1':'rouillejaune_ydebride_datet1',
        'rouillejaune_crusty_date1':'rouillejaune_crusty_date1',
        'rouillejaune_crusty_date2':'rouillejaune_crusty_date2',
        'rouillejaune_crusty_date3':'rouillejaune_crusty_date3',
        'pv_cumul_top':'pv_cumul_top',
        'helmintho_date1':'helmintho_date1',
        'helmintho_date2':'helmintho_date2',
        'rhyncho_date1':'rhyncho_date1',
        'rhyncho_date2':'rhyncho_date2',
        'pv_score_actualise':'pv_score_actualise',
        'epi1cm_decalageoadjours':'epi1cm_decalageoadjours',
        'epiaison_decalageoadjours':'epiaison_decalageoadjours',
        'date_fix_t1':'date_fix_t1',
        'date_fix_t2':'date_fix_t2',
        'septo_datealerte3':'septo_datealerte3',
        'septo_datet3':'septo_datet3',
        'code_station' : 'code_station',
        'Stades observés' : 'stades',
        'Type d\'observateur' : 'type_observateur',
        "precocite_epiaison": "precocite_epiaison",
        "precocite_montaison": "precocite_montaison"
      },

      stades_graphs:[
      {code:'stade_epi1cm', name:'Stade epi 1cm'},
      {code:'stade_1n', name:'Stade 1N'},
      {code:'stade_2n', name:'Stade 2N'},
      {code:'stade_dfp', name:'Stade DFP'},
      {code:'stade_epiaison', name:'Stade épiaison'},
      {code:'stade_floraison', name:'Stade floraison'},
      {code:'stade_maturite', name:'Stade maturité'}
      ],
      selected_stade_graph:null,
      chartOptions: {
        title: {
          text: 'Répartition des parcelles selon leurs stades (%)'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['Stade epi 1cm','Stade 1N', 'Stade 2N','Stade DFP','Stade épiaison','Stade floraison','Stade maturité'],
          y: 'bottom',
          selected: {
            'Stade 1N': true,
            'Stade 2N': true,
            'Stade DFP': true,
            'Stade épiaison': true,
            'Stade floraison': true,
            'Stade maturité': true,
            'Stade epi 1cm': true
          }
        },
        grid: {
          left: '8%',
          right: '10%',
          bottom: '30%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {title:"Télécharger l'image"}
          }
        },
        xAxis:  {
            type: 'category',
            boundaryGap:false,
            data: []
        },
        yAxis: {
          type: 'value',
          nameRotate: 90,
          nameLocation: 'center',
          nameGap: 30,
          inverse:false,
          axisLine : {
            lineStyle : {color: '#000'}
          },
          splitLine: {
              lineStyle: {
                  color: '#000'
              }
          }
        },
        series: [
           {
            name: 'Stade epi 1cm',
            type: 'bar',
            data: [],
            lineStyle: {color: 'rgb(0,10,0)'},
            showSymbol:false,
            itemStyle: {color: 'rgb(0,10,0)'},
          },
          {
            name: 'Stade 1N',
            type: 'bar',
            data: [],
            lineStyle: {color: 'rgb(0,35,0)'},
            showSymbol:false,
            itemStyle: {color: 'rgb(0,35,0)'},
          },
          {
            name: 'Stade 2N',
            type: 'bar',
            data: [],
            lineStyle: {color: 'rgb(0,90,0)'},
            showSymbol:false,
            itemStyle: {color: 'rgb(0,90,0)'},
          },
          {
            name: 'Stade DFP',
            type: 'bar',
            data: [],
            lineStyle: {color: 'rgb(0,145,0)'},
            showSymbol:false,
            itemStyle: {color: 'rgb(0,145,0)'},
          },
          {
            name: 'Stade épiaison',
            type: 'bar',
            data: [],
            lineStyle: {color: 'rgb(0,180,0)'},
            showSymbol:false,
            itemStyle: {color: 'rgb(0,180,0)'},
          },
          {
            name: 'Stade floraison',
            type: 'bar',
            data: [],
            lineStyle: {color: 'rgb(0,210,0)'},
            showSymbol:false,
            itemStyle: {color: 'rgb(0,210,0)'},
          },
          {
            name: 'Stade maturité',
            type: 'bar',
            data: [],
            lineStyle: {color: 'rgb(0,225,0)'},
            showSymbol:false,
            itemStyle: {color: 'rgb(0,225,0)'},
          },
          
        ],
        dataZoom: [
          {
            show: true,
            xAxisIndex: [0, 1],
            type: 'slider',
            top: '75%',
            start: 0,
            end: 100
          }
        ],
        graphic: [{
          type: 'image', // Graphic element type
          id: 'logo', // Specify which graphic element of the update when updating or deleting graphic elements, if it is not required to be ignored.
          right: 'center', // Position according to the parent element (in the middle)
          bottom: '60%', // According to the parent element (0%), if the value of the bottom is 0, the BOTTOM property value can also be deleted.
          z: 0,  // laminated
          bounding: 'all', // Decide how this graphic element is positioned, the method of calculating its own enclosure
          style: {
              image: require('@/assets/logo-arvalis-color.png'), // Here, it must be noted that attention must be a picture path address at the beginning of HTTPS.
              width: 165,
              height: 32
          }
        }]
      },

    };
  },

  watch: {
    selectedEspece: function () {
        this.FilterParcelles()
        this.actualiseGraph()
    },
    selectedVariete: function () {
        this.FilterParcelles()
        this.actualiseGraph()
    },
    selectedDepartement: function () {
        this.FilterParcelles()
        this.actualiseGraph()
    },
    selectedClient: function () {
        this.FilterParcelles()
        this.actualiseGraph()
    },
    date_debut: function () {
      this.FilterParcelles()
      this.actualiseGraph()
    },
    date_fin: function () {
      this.FilterParcelles()
      this.actualiseGraph()
    },
    message_api: function(){
      this.resultat_message(this.message_api)
    },

  },

  computed: {},

  async mounted() {
    if ((this.$store.getters['cap/ParcellesList']()).length == 0) {
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      this.all_info_about_stades = await parcellesStadeService.getInfos('clients');
      this.$store.commit('cap/defineParcellesList', {
        stades: this.all_info_about_stades
      });
      this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
    } else {
      this.all_info_about_stades = this.$store.getters['cap/ParcellesList']()
    }

    //STORE
    this.listeVariete = this.$store.getters['cap/DefineListSelect']('variete','clients');
    this.listeDepartement = this.$store.getters['cap/DefineListSelect']('code_dept','clients');
    this.listeClient = this.$store.getters['cap/DefineListSelect']('nom_user','clients');

    //GRAPH
    this.actualiseGraph()
    
    this.dataloaded = true;

  },

   filters: {
    capitalize: function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  },

  methods: {
    showItem(id_plot, type = null) {
        let route = this.$router
          .resolve({
            name: 'detail-plot',
            params: {
              id: id_plot
            }
          });
        if (type == 'href') {
          return route.href;
        } else {
          window.open(route.href);
        }
      },
  
      resultat_message(message_api){
        var message_api_erreur =  message_api.erreur;
        const myJSON = JSON.stringify(message_api_erreur);
  
        //Traitement et mise en forme du message d'erreur de l'api
        let res = myJSON.replace(/","/g, "<br>");
        let res1 = res.replace('{','');
        let res2 = res1.replace('}','');
        let res3 = res2.replace(/":"/g,' → ');
        let message_erreur_api = res3.replace(/"/g,'');
       
        let array_message_erreur_api = message_erreur_api.split("<br>");
      
        let nbr_erreur_api= 0
        if(array_message_erreur_api[0]==="[]"){
          nbr_erreur_api = 0
          message_erreur_api = ''
        }else{
          nbr_erreur_api = array_message_erreur_api.length;
        }
      

        //Affichage du message de confirmation d'import de parcelle
        this.$store.dispatch('informations/setInfo', { message: {titre:"Import réalisé avec succés",description:
        `<div style="width:100%;margin-left:40%">
          <h4>Votre import à réalisé :</h4><br>
        
          <h5>Parcelles : </h5>
            <p><span style="color:darkgreen">${message_api.ajout}</span> création(s) de nouvelle(s) parcelle(s)<br>
            <span style="color:darkgreen">${message_api.modif}</span> modification(s) de parcelle(s).</p><br>
          <h5>Mesures et observations </h5>
            <p><span style="color:darkgreen">${message_api.mesure}</span> ajout(s) de mesure(s)<br>
            <span style="color:darkgreen">${message_api.maj_mesure}</span> modification(s) de mesure(s)</p>
          <h5>Erreurs : </h5>
            <p><span style="color:darkred">${nbr_erreur_api}</span> erreur(s) :</p>
            <p>${message_erreur_api}</p>
        </div>
        
      `} });

        return array_message_erreur_api;
      },

      checkDroits(droit, type) {
      var index = this.droits.findIndex(item => item.code === droit)
      if (index >= 0 && this.droits[index][type] == true) {
        return true
      } else {
        return false
      }
    },

    sortData (tab,propriete) {
      var result = []
        result = tab.slice().sort((a, b) => {
          var fa = a[propriete]!=null ? a[propriete].toLowerCase() : a[propriete]
          var fb = b[propriete]!=null ? b[propriete].toLowerCase() : b[propriete]
          if (fa < fb) {
              return -1;
          }
          if (fa > fb) {
              return 1;
          }
          return 0;
        });
      return result
    },
    
   
    async FilterParcelles() {
      this.all_info_about_stades = this.$store.getters['cap/FilterParcelles']('clients', this.selectedEspece,this.selectedVariete, this.selectedDepartement, this.selectedClient, this.date_debut, this.date_fin, null, null, null, null, null)
    },

    async actualiseGraph() {
      this.chartOptions.xAxis.data = this.getTabChartDate();
      this.chartOptions.series[0].data = this.getTabChartCountOf('stade_epi1cm');
      this.chartOptions.series[1].data = this.getTabChartCountOf('stade_1n');
      this.chartOptions.series[2].data = this.getTabChartCountOf('stade_2n');
      this.chartOptions.series[3].data = this.getTabChartCountOf('stade_dfp');
      this.chartOptions.series[4].data = this.getTabChartCountOf('stade_epiaison');
      this.chartOptions.series[5].data = this.getTabChartCountOf('stade_floraison');
      this.chartOptions.series[6].data = this.getTabChartCountOf('stade_maturite');
      
    },

    resetFiltres() {
      this.selectedDepartement = []
      this.selectedEspece = []
      this.selectedVariete = []
      this.selectedClient = []
      this.date_debut=''
      this.date_fin=''
    },

    getNomFichier(){
      var now = new Date()
      now = getDate(now,'yyyymmdd')
      return now+'_previlis.xls'
    },

    getMin(arrayOfObject,key){
      var liste = arrayOfObject.filter(parcelle => parcelle[key] != null)
      if(liste.length>0){
        var objectMin = liste.reduce(function(prev, curr) {
            return prev[key] < curr[key] ? prev : curr;
        });
        return objectMin[key]
      } else {
        return null;
      }
      
    },

    getMax(arrayOfObject,key){
      var liste = arrayOfObject.filter(parcelle => parcelle[key] != null)
      if(liste.length>0){
        var objectMax = liste.reduce(function(prev, curr) {
            return prev[key] > curr[key] ? prev : curr;
        });
        return objectMax[key]
      } else {
        return null;
      }

    },

    getTabChartDate() {
         //TT la base de donnée
         var all_data = this.all_info_about_stades;

        //récupération des dates minimum et maximum possible pour les stades
        var minEachStades = []
        minEachStades.push(new Date(this.getMin(all_data,'stade_epi1cm')))
        minEachStades.push(new Date(this.getMin(all_data,'stade_1n')))
        minEachStades.push(new Date(this.getMin(all_data,'stade_2n')))

        var maxLastStades = [];
        maxLastStades.push(new Date(this.getMax(all_data,'stade_epiaison')))
        maxLastStades.push(new Date(this.getMax(all_data,'stade_floraison')))
        maxLastStades.push(new Date(this.getMax(all_data,'stade_maturite')))

        
        //Definitions des dates MIN et MAX pour futur appel fonction
        let date_min = new Date(Math.min(...minEachStades))
        let date_max = new Date(Math.max(...maxLastStades))


        var calendar = new Array();
        var currentDate = date_min;
        //Réalisation d'un tableau de la date min à la date max
        while (currentDate <= date_max) {

            calendar.push(new Date (currentDate).toDateString('YYYY-MM-dd'));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return calendar;
    },

    getTabChartCountOf(nom_var) {
      //Récuperer les données 
      var array_of_date_from_one_stade = [];
      //TT la base de donnée
      var all_data = this.all_info_about_stades;
    
      //Recuperer uniquement les infos d'une colonne de la base de donnée
      all_data.forEach(element => {
        if (element[nom_var]!=null){
          var dt = new Date (element[nom_var]).toDateString('YYYY-MM-dd')
          if(!(dt in array_of_date_from_one_stade)){
            array_of_date_from_one_stade[dt] = 1
          } else {
            array_of_date_from_one_stade[dt] += 1
          }
        }
      });

      var calendar = this.chartOptions.xAxis.data; //pour éviter de rappeler getTabChart
      var final_data = []
      calendar.forEach(element => {     
        if(!(element in array_of_date_from_one_stade)){
          final_data.push(0);
        } else {
          final_data.push(array_of_date_from_one_stade[element]/all_data.length*100);
        }
      });

      return final_data
    },

    viewStadeGraph() {

      var show_selected = {}
      this.stades_graphs.forEach(element => {
        if(this.selected_stade_graph==element.code){
          show_selected[element.name] = true;
        } else {
          show_selected[element.name] = false;
        }
      });
      this.chartOptions.legend.selected = show_selected;

    },

    launchAPIGetLogged() {

    // Obtention du token⁡⁡
    var data_reponse = parcellesStadeService.getLogged();

    // ⁡⁢⁢⁢Transformer les données dans un format utilisable par la suite⁡
    Promise.resolve(data_reponse).then(value => {
      let retour_data = value;

      console.log(retour_data);

    })
    },

  launchAPIGetListPlotVigiculture() {

    // Obtention des parcelles⁡⁡
    var data_reponse = parcellesStadeService.getListPlotVigiculture();
    //console.log(JSON.stringify(data_reponse));

    // ⁡⁢⁢⁢Transformer les données dans un format utilisable par la suite⁡
    Promise.resolve(data_reponse).then(value => {
      console.log(JSON.stringify(data_reponse));
      let retour_data = value;

    })

  },
  launchAPIGetObsPlotVigiculture() {

    // Obtention des parcelles⁡⁡
    var data_reponse = parcellesStadeService.getObsPlotVigiculture();
    console.log(JSON.stringify(data_reponse));

    // ⁡⁢⁢⁢Transformer les données dans un format utilisable par la suite⁡
    Promise.resolve(data_reponse).then(value => {
      console.log(JSON.stringify(data_reponse));
      let retour_data = value;


    })

  },

  async getInfosParcellesDetail() {

    this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
    var plots = await parcellesStadeService.getInfos('clients', true);
    this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
    return plots

  }



  }
};

</script>

<style>
h3{
  margin:18px 0px 12px 0px;
  padding:0.5rem;
}

#form col{
  padding:1rem;
}

</style>
