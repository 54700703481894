import Equipment from '@/models/equipment/equipment';
import Technical_Features from '@/models/equipment/technical_features';
import Measured_Features from '@/models/equipment/measured_features';
import Maintenance from '@/models/maintenance/maintenance';
import Document from '@/models/equipment/document';

import store from '@/store/index';
import { handleServiceParams, errorDescription } from "@/helpers/serviceParamsHandler";

export default {
  // we load data from an API
  async getAll() {
    return await this.find({});
  },
  async find(params) {
    const url = new URL(`${process.env.VUE_APP_BASE_API}equipments`);
    url.search = handleServiceParams(params);
    return await fetch(url, {
      method: 'GET',
      headers: {
        //'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        //Accept: 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then(json =>
      // we map every json object to the JS object
      json.data.map((item) => {
        const a = new Equipment(item);
        return a;
      })
    )
    .catch((error) => {
      store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
    });
  },

  async getInfosParcelles(type=null,campagne=null) {
    // get a fully desribed parcelle from API
    // URL should be a composed string from Env constant and ressource name
    let query = '';
    if (campagne != null) {
        query += `?campagne=${campagne}`;
    }
    if (type != null) {
        // Ajouter un & si type 
        query += (query ? '&' : '?') + `type=${type}`;
    }
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/situationMildiou`+query, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        // we map json object to the JS object
        /*if (json.data.length>0)
        {
          const a = new Equipment(json.data[0]);
          return a
        }
        else
        {
          return null;
        }*/
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getFewInfosParcelles(type) {
    // get a fully desribed parcelle from API
    // URL should be a composed string from Env constant and ressource name
   
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/few_datas?type=${type}`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        // we map json object to the JS object
        /*if (json.data.length>0)
        {
          const a = new Equipment(json.data[0]);
          return a
        }
        else
        {
          return null;
        }*/
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },



  async getListeCampagne() {
    // get a fully desribed parcelle from API
    // URL should be a composed string from Env constant and ressource name

    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/campagnes`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        // we map json object to the JS object
        /*if (json.data.length>0)
        {
          const a = new Equipment(json.data[0]);
          return a
        }
        else
        {
          return null;
        }*/
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },


  async getListes() {
    // get a fully desribed parcelle from API
    // URL should be a composed string from Env constant and ressource name
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/listes`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getIndicesParcelle(id) {
    // get a fully desribed parcelle from API
    // URL should be a composed string from Env constant and ressource name
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id}/indices`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        // we map json object to the JS object
        /*if (json.data.length>0)
        {
          const a = new Equipment(json.data[0]);
          return a
        }
        else
        {
          return null;
        }*/
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },


  async getIndicesParcelleAlternaria(id) {
    // get a fully desribed parcelle from API
    // URL should be a composed string from Env constant and ressource name
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id}/indicesAlternaria`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        // we map json object to the JS object
        /*if (json.data.length>0)
        {
          const a = new Equipment(json.data[0]);
          return a
        }
        else
        {
          return null;
        }*/
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },


  async getIndicesHorairesParcelle(id) {
    // get a fully desribed parcelle from API
    // URL should be a composed string from Env constant and ressource name
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id}/indices/horaires`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        // we map json object to the JS object
        /*if (json.data.length>0)
        {
          const a = new Equipment(json.data[0]);
          return a
        }
        else
        {
          return null;
        }*/
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getInfosParcelle(id) {
    // get a fully desribed parcelle from API
    // URL should be a composed string from Env constant and ressource name
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id}`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        // we map json object to the JS object
        /*if (json.data.length>0)
        {
          const a = new Equipment(json.data[0]);
          return a
        }
        else
        {
          return null;
        }*/
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getIndicesAlternariaHorairesParcelle(id) {
    // get a fully desribed parcelle from API
    // URL should be a composed string from Env constant and ressource name
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id}/indicesAlternaria/horaires`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        // we map json object to the JS object
        /*if (json.data.length>0)
        {
          const a = new Equipment(json.data[0]);
          return a
        }
        else
        {
          return null;
        }*/
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getInfosDMParcelle(id) {
    // get a fully desribed parcelle from API
    // URL should be a composed string from Env constant and ressource name
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id}/dm/quotidiennes/nbValues`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        // we map json object to the JS object
        /*if (json.data.length>0)
        {
          const a = new Equipment(json.data[0]);
          return a
        }
        else
        {
          return null;
        }*/
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },


  async getInfosDMStation(id) {
    // get a fully desribed parcelle from API
    // URL should be a composed string from Env constant and ressource name
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}iot/station/${id}/dm/quotidiennes/nbValues`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        // we map json object to the JS object
        /*if (json.data.length>0)
        {
          const a = new Equipment(json.data[0]);
          return a
        }
        else
        {
          return null;
        }*/
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },
  
  // async get_info_parcelle_hour(id) {
  //   // get informations each hour for each parcelle from API
  //   // URL should be a composed string from Env constant and ressource name

  //   const local_dossier = await
  //     fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id}/meteo/horaires`, {
  //       method: 'GET',
  //       headers: {
  //         //'Content-Type': 'application/json',
  //         Authorization: `Bearer ${store.getters.tokenValue}`,
  //         //Accept: 'application/json',
  //       },
  //     }).then((response) => {
  //       if (response.ok) {
  //         return response.json();
  //       }
  //     })
  //     .then((json) => {
  //       // we map json object to the JS object
  //       /*if (json.data.length>0)
  //       {
  //         const a = new Equipment(json.data[0]);
  //         return a
  //       }
  //       else
  //       {
  //         return null;
  //       }*/
  //       return json.data;
  //     })
  //       .catch((error) => {
  //         store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
  //       },
  //       );
  //   return local_dossier;
  // },

  
  // async get_info_parcelle_day(id) {
  //   // get informations each hour for each parcelle from API
  //   // URL should be a composed string from Env constant and ressource name

  //   const local_dossier = await
  //     fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id}/meteo/jour`, {
  //       method: 'GET',
  //       headers: {
  //         //'Content-Type': 'application/json',
  //         Authorization: `Bearer ${store.getters.tokenValue}`,
  //         //Accept: 'application/json',
  //       },
  //     }).then((response) => {
  //       if (response.ok) {
  //         return response.json();
  //       }
  //     })
  //     .then((json) => {
  //       // we map json object to the JS object
  //       /*if (json.data.length>0)
  //       {
  //         const a = new Equipment(json.data[0]);
  //         return a
  //       }
  //       else
  //       {
  //         return null;
  //       }*/
  //       return json.data;
  //     })
  //       .catch((error) => {
  //         store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
  //       },
  //       );
  //   return local_dossier;
  // },

  async getListesAllStations() {
    // get a list of all stations from API
    // URL should be a composed string from Env constant and ressource name
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}iot/stations`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getActualHourlyDataStation(id) {
    // get the real data buy hour of the station from API
    // URL should be a composed string from Env constant and ressource name
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}iot/station/${id}/data/actual/hour`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getActualDailyDataStation(id) {
    // get the real data by day of the station from API
    // URL should be a composed string from Env constant and ressource name
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}iot/station/${id}/data/actual/day`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getForecastHourlyDataStation(id) {
    // get the forecast data by hour of the station from API
    // URL should be a composed string from Env constant and ressource name
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}iot/station/${id}/data/forecast/hour`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getForecastDailyDataStation(id) {
    // get the forecast data by day of the station from API
    // URL should be a composed string from Env constant and ressource name
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}iot/station/${id}/data/forecast/day`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getDailyDataStation(id) {
    // get the forecast data by day of the station from API
    // URL should be a composed string from Env constant and ressource name
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}iot/station/${id}/data/day`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getHourlyDataStation(id) {
    // get the forecast data by day of the station from API
    // URL should be a composed string from Env constant and ressource name
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}iot/station/${id}/data/hour`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getStation(id) {
    // get information of one station from API with the ID STATION provided
    // URL should be a composed string from Env constant and ressource name
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}iot/station/${id}`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getInfosParcelleStation(pStationId) {
    // get a information linked between 'parcelle' and its 'station' from API
    // URL should be a composed string from Env constant and ressource name
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}iot/station/${pStationId}/plot/mildiou`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {

        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getInfosUseStation(pStationId) {
    // get a information linked between 'parcelle' and its 'station' from API
    // URL should be a composed string from Env constant and ressource name
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}iot/station/${pStationId}/stat`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {

        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getInfosAuthorisationsStation(pStationId) {
    // get a information linked between 'parcelle' and its 'station' from API
    // URL should be a composed string from Env constant and ressource name
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}iot/station/${pStationId}/authorisation`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {

        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getInfosAuthorisationsAllStations() {
    // get a information linked between 'parcelle' and its 'station' from API
    // URL should be a composed string from Env constant and ressource name
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}iot/station/authorisations`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {

        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },


  async getCodeScenario(parcelle) {
    // get a information linked between 'parcelle' and its 'station' from API
    // URL should be a composed string from Env constant and ressource name
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/scenarioscode/${parcelle}`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async setCodeScenario(code_scenario , parcelle) {

    let data = {data_to_send: {
      code_scenario :code_scenario,
      id_plot : parcelle
    }}
  
    const result = await
    fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/scenarioscode/plot/${parcelle}`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then(response => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    }).then(json => {
      return json.data;
    }).catch((error) => {
      store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
    });
    return result;
  },
  
  async getCalculsMildiouPlot(id_plot) {
    // get a information linked between 'parcelle' and its 'station' from API
    // URL should be a composed string from Env constant and ressource name
    const local_dossier = await
    fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/calcul/verif/plot/${id_plot}`, {
      method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },


  async getIndicesHumiditeTempPlot(pMaladiesId) {
    const local_dossier = await
    fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${pMaladiesId}/meteoData/indices`, {
      method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },


  async addOneObservationToPlotInApi(id_plot,form_date_obs) {

    let data = {
        date_obs: form_date_obs,
    }

    const local_dossier = await
    fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/observation`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((json) => {
      return json.data;
    })
    .catch((error) => {
      store.dispatch('errors/setError', {
        message: {
          code: error.status,
          description: errorDescription(error.status)
        }
      });
    }, );
    return local_dossier;
  },

  async putOneObservationToPlotInApi(id_plot,form_date_obs) {

    let data = {
        date_obs: form_date_obs,
    }

    const local_dossier = await
    fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/observation`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((json) => {
      return json.data;
    })
    .catch((error) => {
      store.dispatch('errors/setError', {
        message: {
          code: error.status,
          description: errorDescription(error.status)
        }
      });
    }, );
    return local_dossier;
  },

  async deleteOneObservationOfThePlot(id_plot,date_obs) {
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/observation/${date_obs}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${store.getters.tokenValue}`,
        },
      });
  
      if (response.ok) {
        //console.log('Suppression réussie');
      } else {
        throw new Error(`Erreur ${response.status}: ${errorDescription(response.status)}`);
      }
    } catch (error) {
      // Gérer les erreurs et déclencher un dispatch pour l'enregistrement de l'erreur
      store.dispatch('errors/setError', { message: { code: error.status, description: error.message } });
      // Retourner une promesse rejetée pour indiquer une erreur
      return Promise.reject(error);
    }
  },


  async getObservationsOfThePlot(id_plot) {
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/observations`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${store.getters.tokenValue}`,
        },
      });
  
      if (response.ok) {
        const json = await response.json();

        // renvoyer les données
        return json.data;
      } else {
        throw new Error(`Erreur ${response.status}: ${errorDescription(response.status)}`);
      }
    } catch (error) {
      // Gérer les erreurs et déclencher un dispatch pour l'enregistrement de l'erreur
      store.dispatch('errors/setError', { message: { code: error.status, description: error.message } });
      // Retourner une promesse rejetée pour indiquer une erreur
      return Promise.reject(error);
    }
  },


  async addOneIrrigationToPlotInApi(id_plot,irrigation_data) {

    let data = {
        irrigation_data:irrigation_data
    }

    const local_dossier = await
    fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/irrigation`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((json) => {
      return json.data;
    })
    .catch((error) => {
      store.dispatch('errors/setError', {
        message: {
          code: error.status,
          description: errorDescription(error.status)
        }
      });
    }, );
    return local_dossier;
  },

  async putOneIrrigationToPlotInApi(id_plot,irrigation_data,begin_at) {

    let data = {
      irrigation_data: irrigation_data,
    }

    const local_dossier = await
    fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/irrigation/${begin_at}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((json) => {
      return json.data;
    })
    .catch((error) => {
      store.dispatch('errors/setError', {
        message: {
          code: error.status,
          description: errorDescription(error.status)
        }
      });
    }, );
    return local_dossier;
  },

  async deleteOneIrrigationOfThePlot(id_plot,begin_at) {
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/irrigation/${begin_at}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${store.getters.tokenValue}`,
        },
      });
  
      if (response.ok) {
        //console.log('Suppression réussie');
      } else {
        throw new Error(`Erreur ${response.status}: ${errorDescription(response.status)}`);
      }
    } catch (error) {
      // Gérer les erreurs et déclencher un dispatch pour l'enregistrement de l'erreur
      store.dispatch('errors/setError', { message: { code: error.status, description: error.message } });
      // Retourner une promesse rejetée pour indiquer une erreur
      return Promise.reject(error);
    }
  },

  async getIrrigationsOfThePlot(id_plot) {
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/irrigations`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${store.getters.tokenValue}`,
        },
      });
  
      if (response.ok) {
        const json = await response.json();

        // renvoyer les données
        return json.data;
      } else {
        throw new Error(`Erreur ${response.status}: ${errorDescription(response.status)}`);
      }
    } catch (error) {
      // Gérer les erreurs et déclencher un dispatch pour l'enregistrement de l'erreur
      store.dispatch('errors/setError', { message: { code: error.status, description: error.message } });
      // Retourner une promesse rejetée pour indiquer une erreur
      return Promise.reject(error);
    }
  },

  async addOneTreatmentToPlotInApi(id_plot,traitement_data) {

    let data = {
        traitement_data: traitement_data
    }

    const local_dossier = await
    fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/treatment`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
      }
    })
    .catch((error) => {
      store.dispatch('errors/setError', {
        message: {
          code: error.status,
          description: errorDescription(error.status)
        }
      });
    }, );
    return local_dossier;
  },

  async putOneTraitementToPlotInApi(id_plot,traitement_data,treated_at) {

    let data = {
        traitement_data: traitement_data
    }

    const local_dossier = await
    fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/treatment/${treated_at}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
       // console.log('Traitement ajouté');
      }
    })
    .catch((error) => {
      store.dispatch('errors/setError', {
        message: {
          code: error.status,
          description: errorDescription(error.status)
        }
      });
    }, );
    return local_dossier;
  },

  async deleteOneTraitementOfThePlot(id_plot,treated_at) {

    try{
    const response = await fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/treatment/${treated_at}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${store.getters.tokenValue}`,
      },
    });

    if (response.ok) {
      //console.log('Suppression réussie');
    } else {
      throw new Error(`Erreur ${response.status}: ${errorDescription(response.status)}`);
    }
  } catch (error) {
    // Gérer les erreurs et déclencher un dispatch pour l'enregistrement de l'erreur
    store.dispatch('errors/setError', { message: { code: error.status, description: error.message } });
    // Retourner une promesse rejetée pour indiquer une erreur
    return Promise.reject(error);
  }
},

  async getTraitementsOfThePlot(id_plot) {
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/treatments`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${store.getters.tokenValue}`,
        },
      });
  
      if (response.ok) {
        const json = await response.json();

        // renvoyer les données
        return json.data;
      } else {
        throw new Error(`Erreur ${response.status}: ${errorDescription(response.status)}`);
      }
    } catch (error) {
      // Gérer les erreurs et déclencher un dispatch pour l'enregistrement de l'erreur
      store.dispatch('errors/setError', { message: { code: error.status, description: error.message } });
      // Retourner une promesse rejetée pour indiquer une erreur
      return Promise.reject(error);
    }
  },

  async getCorrectionsMeteo() {

    const local_dossier = await
    fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/getAllReplacement`, {
      method: 'GET',
      //body: JSON.stringify(data),
      headers: {
       // 'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
       // Accept: 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((json) => {
      return json.data;
    })
    .catch((error) => {
      store.dispatch('errors/setError', {
        message: {
          code: error.status,
          description: errorDescription(error.status)
        }
      });
    }, );
    return local_dossier;
  },



  async comblerGapMeteo(id_plot,id_item_gap_meteo) {
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/gapMeteos/${id_item_gap_meteo}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${store.getters.tokenValue}`,
        },
      });
  
      if (!response.ok) {
        const errorMessage = await response.json();
        throw new Error(errorMessage.message);
      }
      
      return true; // Indiquer que la suppression a réussi
    } catch (error) {
      store.dispatch('errors/setError', { message: { code: error.status, description: errorDescription(error.status) } });
      throw error; // Renvoyer l'erreur pour que l'appelant puisse la gérer
    }
  },

  async getAllReplacementsMeteoForThePlot(id_plot) {
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/replacements`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${store.getters.tokenValue}`,
        },
      });
  
      if (response.ok) {
        const json = await response.json();

        // renvoyer les données
        return json.data;
      } else {
        throw new Error(`Erreur ${response.status}: ${errorDescription(response.status)}`);
      }
    } catch (error) {
      // Gérer les erreurs et déclencher un dispatch pour l'enregistrement de l'erreur
      store.dispatch('errors/setError', { message: { code: error.status, description: error.message } });
      // Retourner une promesse rejetée pour indiquer une erreur
      return Promise.reject(error);
    }
  },

  async deleteOneReplacementsMeteoForThePlot(id_plot,date_debut_periode) {
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/replacements/${date_debut_periode}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${store.getters.tokenValue}`,
        },
      });
  
      if (response.ok) {
        //console.log('Suppression réussie');

      } else {
        throw new Error(`Erreur ${response.status}: ${errorDescription(response.status)}`);
      }
    } catch (error) {
      // Gérer les erreurs et déclencher un dispatch pour l'enregistrement de l'erreur
      store.dispatch('errors/setError', { message: { code: error.status, description: error.message } });
      // Retourner une promesse rejetée pour indiquer une erreur
      return Promise.reject(error);
    }
  },

  async getStadePlotInApi(id_plot) {

    const local_dossier = await fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/phases`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((json) => {
      return json.data;
    }).catch((error) => {
      store.dispatch('errors/setError', {
        message: {
          code: error.status,
          description: errorDescription(error.status)
        }
      });
    });
  
    return local_dossier;
  },

  async deleteStadesOfThePlot(id_plot) {
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/phases`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${store.getters.tokenValue}`,
        },
      });
  
      if (response.ok) {
        //console.log('Suppression réussie');

      } else {
        throw new Error(`Erreur ${response.status}: ${errorDescription(response.status)}`);
      }
    } catch (error) {
      // Gérer les erreurs et déclencher un dispatch pour l'enregistrement de l'erreur
      store.dispatch('errors/setError', { message: { code: error.status, description: error.message } });
      // Retourner une promesse rejetée pour indiquer une erreur
      return Promise.reject(error);
    }
  },
  

  async addStadesToPlotInApi(id_plot, phases) {
    let data = {
        phases: phases
    };

    const local_dossier = await fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/phases`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((json) => {
      return json.data;
    }).catch((error) => {
      store.dispatch('errors/setError', {
        message: {
          code: error.status,
          description: errorDescription(error.status)
        }
      });
    });
  
    return local_dossier;
  },


  async putStadeToPlotInApi(id_plot, phases) {
    let data = {
        phases: phases
    };
    
    const local_dossier = await fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/phases`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((json) => {
      return json.data;
    }).catch((error) => {
      store.dispatch('errors/setError', {
        message: {
          code: error.status,
          description: errorDescription(error.status)
        }
      });
    });
  
    return local_dossier;
  },

  async putHarvestToPlotInApi(id_plot, harvest) {
    let data = {
        harvest: harvest
    };
    
    const local_dossier = await fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/harvest`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((json) => {
      return json.data;
    }).catch((error) => {
      store.dispatch('errors/setError', {
        message: {
          code: error.status,
          description: errorDescription(error.status)
        }
      });
    });
  
    return local_dossier;
  },

  async get_harvest_from_api_potato_late_blight(id_plot) {

    const local_dossier = await fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/harvest`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${store.getters.tokenValue}`,
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((json) => {
      return json.data;
    }).catch((error) => {
      store.dispatch('errors/setError', {
        message: {
          code: error.status,
          description: errorDescription(error.status)
        }
      });
    });
    return local_dossier;
  },


  async deleteHarvestOfThePlot(id_plot) {
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/harvest`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${store.getters.tokenValue}`,
        },
      });
  
      if (response.ok) {
        //console.log('Suppression réussie');

      } else {
        throw new Error(`Erreur ${response.status}: ${errorDescription(response.status)}`);
      }
    } catch (error) {
      // Gérer les erreurs et déclencher un dispatch pour l'enregistrement de l'erreur
      store.dispatch('errors/setError', { message: { code: error.status, description: error.message } });
      // Retourner une promesse rejetée pour indiquer une erreur
      return Promise.reject(error);
    }
  },


  async getStadesOfThePlot(id_plot) {
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/phases`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${store.getters.tokenValue}`,
        },
      });
  
      if (response.ok) {
        const json = await response.json();

        // renvoyer les données
        return json.data;
      } else {
        throw new Error(`Erreur ${response.status}: ${errorDescription(response.status)}`);
      }
    } catch (error) {
      // Gérer les erreurs et déclencher un dispatch pour l'enregistrement de l'erreur
      store.dispatch('errors/setError', { message: { code: error.status, description: error.message } });
      // Retourner une promesse rejetée pour indiquer une erreur
      return Promise.reject(error);
    }
  },


  async addReplacementPlot(id_plot, replacement_data) {
    let data = {
      replacement_data: replacement_data
    };

    const local_dossier = await fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/replacement`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((json) => {
      return json.data;
    }).catch((error) => {
      store.dispatch('errors/setError', {
        message: {
          code: error.status,
          description: errorDescription(error.status)
        }
      });
    });
    return local_dossier;
  },


  async editOneReplacementsMeteoForThePlot(id_plot, replacement_data) {
    let data = {
      replacement_data: replacement_data
    };

    const local_dossier = await fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/replacement`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    }).then((json) => {
      return json.data;
    }).catch((error) => {
      store.dispatch('errors/setError', {
        message: {
          code: error.status,
          description: errorDescription(error.status)
        }
      });
    });
    return local_dossier;
  },

  async searchFoundMissingReplacementMeteoForPlot(id_plot) {
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/replacementMissing`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${store.getters.tokenValue}`,
        },
      });
  
      if (response.ok) {
        const json = await response.json();

        // renvoyer les données
        return json.data;
      } else {
        throw new Error(`Erreur ${response.status}: ${errorDescription(response.status)}`);
      }
    } catch (error) {
      // Gérer les erreurs et déclencher un dispatch pour l'enregistrement de l'erreur
      store.dispatch('errors/setError', { message: { code: error.status, description: error.message } });
      // Retourner une promesse rejetée pour indiquer une erreur
      return Promise.reject(error);
    }
  },

  async addOnePlotToApiMildiou(data_plot_to_create,bsv=null) {
    let query = '';

    let data = {
        data_plot_to_create:data_plot_to_create
    }

    if (bsv != null) {
      // Ajouter un & si bsv / cas type
      query += (query ? '&' : '?') + `bsv=${bsv}`;
  }

    const local_dossier = await
    fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/creation`+query, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((json) => {
      return json.data;
    })
    .catch((error) => {
      store.dispatch('errors/setError', {
        message: {
          code: error.status,
          description: errorDescription(error.status)
        }
      });
    }, );
    return local_dossier;
  },


  async editOnePlotToApiMildiou(id_plot,data_plot_to_edit,bsv=null) {
    let query = '';
    let data = {
        data_plot_to_edit:data_plot_to_edit
    }

    if (bsv != null) {
      // Ajouter un & si bsv / cas type
      query += (query ? '&' : '?') + `bsv=${bsv}`;
  }

    const local_dossier = await
    fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/edit`+query, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((json) => {
      return json.data;
    })
    .catch((error) => {
      store.dispatch('errors/setError', {
        message: {
          code: error.status,
          description: errorDescription(error.status)
        }
      });
    }, );
    return local_dossier;
  },

  async deleteOnePlotApiMildiou(id_plot) {
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/delete`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${store.getters.tokenValue}`,
        },
      });
  
      if (response.ok) {
        //console.log('Suppression réussie');
      } else {
        throw new Error(`Erreur ${response.status}: ${errorDescription(response.status)}`);
      }
    } catch (error) {
      // Gérer les erreurs et déclencher un dispatch pour l'enregistrement de l'erreur
      store.dispatch('errors/setError', { message: { code: error.status, description: error.message } });
      // Retourner une promesse rejetée pour indiquer une erreur
      return Promise.reject(error);
    }
  },

  async getOnePlotApiMildiou(id_plot) {
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/getInfos`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${store.getters.tokenValue}`,
        },
      });
  
      if (response.ok) {
        const json = await response.json();

        // renvoyer les données
        return json.data;
      } else {
        throw new Error(`Erreur ${response.status}: ${errorDescription(response.status)}`);
      }
    } catch (error) {
      // Gérer les erreurs et déclencher un dispatch pour l'enregistrement de l'erreur
      store.dispatch('errors/setError', { message: { code: error.status, description: error.message } });
      // Retourner une promesse rejetée pour indiquer une erreur
      return Promise.reject(error);
    }
  },


  async getAllTreatmentSkipped(id_plot) {
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/treatmentskipped`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${store.getters.tokenValue}`,
        },
      });
  
      if (response.ok) {
        const json = await response.json();

        // renvoyer les données
        return json.data;
      } else {
        throw new Error(`Erreur ${response.status}: ${errorDescription(response.status)}`);
      }
    } catch (error) {
      // Gérer les erreurs et déclencher un dispatch pour l'enregistrement de l'erreur
      store.dispatch('errors/setError', { message: { code: error.status, description: error.message } });
      // Retourner une promesse rejetée pour indiquer une erreur
      return Promise.reject(error);
    }
  },


  async getTreatmentsOfThePlot(id_plot) {
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/treatments`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${store.getters.tokenValue}`,
        },
      });
  
      if (response.ok) {
        const json = await response.json();

        // renvoyer les données
        return json.data;
      } else {
        throw new Error(`Erreur ${response.status}: ${errorDescription(response.status)}`);
      }
    } catch (error) {
      // Gérer les erreurs et déclencher un dispatch pour l'enregistrement de l'erreur
      store.dispatch('errors/setError', { message: { code: error.status, description: error.message } });
      // Retourner une promesse rejetée pour indiquer une erreur
      return Promise.reject(error);
    }
  },

  async getRemanenceOfProduct(id_plot,product_id) {
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/product/${product_id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${store.getters.tokenValue}`,
        },
      });
  
      if (response.ok) {
        const json = await response.json();

        // renvoyer les données
        return json;
      } else {
        throw new Error(`Erreur ${response.status}: ${errorDescription(response.status)}`);
      }
    } catch (error) {
      store.dispatch('errors/setError', { message: { code: error.status, description: error.message } });
      return Promise.reject(error);
    }
  },

  // async getInfosAllProducts(id_plot) {
  //   try {
  //     const response = await fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/${id_plot}/allProduct`, {
  //       method: 'GET',
  //       headers: {
  //         Authorization: `Bearer ${store.getters.tokenValue}`,
  //       },
  //     });
  
  //     if (response.ok) {
  //       const json = await response.json();

  //       // renvoyer les données
  //       return json.data;
  //     } else {
  //       throw new Error(`Erreur ${response.status}: ${errorDescription(response.status)}`);
  //     }
  //   } catch (error) {
  //     // Gérer les erreurs et déclencher un dispatch pour l'enregistrement de l'erreur
  //     store.dispatch('errors/setError', { message: { code: error.status, description: error.message } });
  //     // Retourner une promesse rejetée pour indiquer une erreur
  //     return Promise.reject(error);
  //   }
  // },

  async getInfosAllProducts() {
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/treatments/allProducts`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${store.getters.tokenValue}`,
        },
      });
  
      if (response.ok) {
        const json = await response.json();

        // renvoyer les données
        return json.data;
      } else {
        throw new Error(`Erreur ${response.status}: ${errorDescription(response.status)}`);
      }
    } catch (error) {
      // Gérer les erreurs et déclencher un dispatch pour l'enregistrement de l'erreur
      store.dispatch('errors/setError', { message: { code: error.status, description: error.message } });
      // Retourner une promesse rejetée pour indiquer une erreur
      return Promise.reject(error);
    }
  },


  async getInfosExistIOT(constructor, device_code, bsv = null) {
    try {
        let query = '';
        // Ajouter la variable `bsv` au querystring si elle est définie
        if (bsv !== null) {
            query += `?bsv=${encodeURIComponent(bsv)}`;
        }

        const url = `${process.env.VUE_APP_BASE_API}maladiesPdt/plots/iot/${encodeURIComponent(constructor)}/device/${encodeURIComponent(device_code)}/exist${query}`;

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${store.getters.tokenValue}`,
            },
        });

        if (response.ok) {
            const json = await response.json();

            // Vérifier si la réponse contient les champs attendus
            if (typeof json.exists === 'boolean' && json.data_geographiques !== undefined) {
                return {
                    exists: json.exists,
                    data_geographiques: json.data_geographiques,
                };
            } else {
                // Log ou traitement supplémentaire en cas de réponse inattendue
                console.error('Réponse inattendue du serveur', json);
                throw new Error('Réponse inattendue du serveur');
            }
        } else {
            // Gérer les erreurs HTTP explicites
            const errorMessage = `Erreur ${response.status}: ${errorDescription(response.status)}`;
            throw new Error(errorMessage);
        }
    } catch (error) {
        // Gérer les erreurs réseau ou autres exceptions
        console.error('Erreur lors de la récupération des informations IOT :', error);

        store.dispatch('errors/setError', {
            message: { code: error.status || 'NETWORK_ERROR', description: error.message },
        });

        // Retourner une promesse rejetée pour signaler une erreur
        return Promise.reject(error);
    }
},



  async getListesConstructorIOT() {
    // URL should be a composed string from Env constant and ressource name
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/iot/constructor/list`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getListeVariety() {
    // get a fully desribed parcelle from API
    // URL should be a composed string from Env constant and ressource name
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}maladiesPdt/plots/liste/variete`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },


  async getCasTypeRegion(id_regions = null) {
    const local_dossier = await fetch(`${process.env.VUE_APP_BASE_API}bsv/castype`, {
      method: 'POST',  
      headers: {
        'Content-Type': 'application/json',  // Indiquer que le corps de la requête est en JSON
        Authorization: `Bearer ${store.getters.tokenValue}`,
      },
      body: JSON.stringify({ id_regions }),  // Envoyer le tableau d'ID de région dans le corps
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
      .catch((error) => {
        store.dispatch('errors/setError', { message: { code: error.status, description: errorDescription(error.status) } });
      });
  
    return local_dossier;
  },

  async getStationMFcsv(departements) {

    const url = `${process.env.VUE_APP_BASE_API}bsv/stations`;

    const local_dossier = await fetch(url, {
        method: 'POST', 
        headers: {
            'Content-Type': 'application/json', // Spécifier le type de contenu
            Authorization: `Bearer ${store.getters.tokenValue}`,
        },
        body: JSON.stringify({ departements }) // Passer les départements dans le corps de la requête
    })
    .then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error('Erreur lors de la récupération des stations');
        }
    })
    .then((json) => {
        return json.data; // Renvoie les données des stations
    })
    .catch((error) => {
        store.dispatch('errors/setError', { message: { code: error.status, description: error.message } });
        return []; // Renvoie un tableau vide en cas d'erreur
    });

    return local_dossier; // Renvoie les stations
},

  // async getStationMF(departements) {
  //   const url = `${process.env.VUE_APP_BASE_API}bsv/stationsMF`;

  //   const local_dossier = await fetch(url, {
  //       method: 'POST', 
  //       headers: {
  //           'Content-Type': 'application/json', // Spécifier le type de contenu
  //           Authorization: `Bearer ${store.getters.tokenValue}`,
  //       },
  //       body: JSON.stringify({ departements }) // Passer les départements dans le corps de la requête
  //   })
  //   .then((response) => {
  //       if (response.ok) {
  //           return response.json();
  //       } else {
  //           throw new Error('Erreur lors de la récupération des stations');
  //       }
  //   })
  //   .then((json) => {
  //       return json.data; // Renvoie les données des stations
  //   })
  //   .catch((error) => {
  //       store.dispatch('errors/setError', { message: { code: error.status, description: error.message } });
  //       return []; // Renvoie un tableau vide en cas d'erreur
  //   });

  //   return local_dossier; // Renvoie les stations
  // },

  async postStationMFinIOT(station_mf_to_iot) {
    //console.log(JSON.stringify(station_mf_to_iot));
    const url = `${process.env.VUE_APP_BASE_API}bsv/stationsMF/postInIOT`;

    const result = await fetch(url, {
        method: 'POST', 
        headers: {
            'Content-Type': 'application/json', // Spécifier le type de contenu
            Authorization: `Bearer ${store.getters.tokenValue}`,
        },
        body: JSON.stringify({ station_mf_to_iot })
    })
    .then((response) => {
        if (response.ok) {
            return true;  // Renvoie true si la réponse est OK
        } else {
            throw new Error('Erreur lors de la récupération des stations');
        }
    })
    .catch((error) => {
        // Gestion de l'erreur et affichage d'un message
        store.dispatch('errors/setError', { message: { code: error.status || 500, description: error.message } });
        return false; // Renvoie false en cas d'erreur
    });

    return result; // La fonction retourne true ou false selon le cas
  },


// TODO - Suite endpoint EMMANUEL
async getCheckStationMF() {
  const url = `${process.env.VUE_APP_BASE_API}checkStationsMF`; // Utilisation du nouvel endpoint
  
  const stationData = {
      name: "test",
      identification: "testmeteofrance",
      latitude: 0.5,
      longitude: 0.5,
      altitude: 0,
      lastmovedate: "2001-01-01"
  };

  try {
      const response = await fetch(url, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${store.getters.tokenValue}`,
          },
          body: JSON.stringify(stationData)
      });

      if (!response.ok) {
          throw new Error('Erreur lors de la vérification de la station');
      }

      const json = await response.json();
      return json.data;

  } catch (error) {
      store.dispatch('errors/setError', { message: { code: error.status || 500, description: error.message } });
      return null;
  }
}





  
};
