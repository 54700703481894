<template>
  <div>
    <v-container style="margin-top: 10px">
      <!--Form-->
      <div id="form" style="text-align: center;">
        <v-row>
          <v-col cols="3" style="padding:0.5rem">
            <v-select style="padding:0.5rem" v-model="selectedZAM" :items="listeZAM" item-text="value"
              item-value="value" menu-props="auto" label="ZAM" hide-details prepend-icon="mdi-sprout" single-line
              chips multiple dense>
            </v-select>
          </v-col>
          
          <v-col cols="3" style="padding:0.5rem">
            <v-autocomplete style="padding:0.5rem" v-model="selectedClient" item-value="value" item-text="value"
              :items="listeClient" menu-props="auto" label="Client" hide-details prepend-icon="mdi-account" single-line
              chips multiple dense></v-autocomplete>
          </v-col>

          <v-col cols="3" style="padding:0.5rem">
            <v-select style="padding:0.5rem" v-model="selectedEspece" :items="listeEspece" item-text="name"
              item-value="code" menu-props="auto" label="Espèce" hide-details prepend-icon="mdi-sprout" single-line
              chips multiple dense>
            </v-select>
          </v-col>

          <v-col cols="3" style="padding:0.5rem">
            <v-select style="padding:0.5rem" v-model="selectedDepartement" :items="listeDepartement" item-text="value"
              item-value="value" menu-props="auto" label="Département" hide-details prepend-icon="mdi-sprout" single-line
              chips multiple dense>
            </v-select>
          </v-col>
        

        </v-row>
        <v-row>

          <v-col cols="3" style="padding:0.5rem">
            <v-select style="padding:0.5rem" v-model="selectedDebouche" :items="listeDebouche" item-text="value"
              item-value="value" menu-props="auto" label="Débouché" hide-details prepend-icon="mdi-sprout" single-line
              chips multiple dense>
            </v-select>
          </v-col>
        
          <v-col cols="3" style="padding:0.5rem">
            <v-select style="padding:0.5rem" v-model="selectedStatut" :items="listeStatut" item-text="value"
              item-value="value" menu-props="auto" label="Statut" hide-details prepend-icon="mdi-sprout" single-line
              chips multiple dense>
            </v-select>
          </v-col>

          <v-col offset="1" cols="1">
            <v-btn style="padding:0.5rem" @click="resetFiltres()" color="info" class="h-70">
              <v-tooltip bottom max-width="350">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-rotate-left
                  </v-icon>
                </template>
                <span>Effacer les filtres</span>
              </v-tooltip>
            </v-btn>
          </v-col>

        </v-row>
      </div>
      <!--Fin Form-->


      <!--Indicateurs-->
      <div>
      <v-row class="mt-3">
        <v-col cols="3" >
          <v-card class="info-card">Nb de parcelles : <span style="font-weight:bolder">{{parcelles_qn.length}}</span></v-card>
        </v-col>
        <v-col cols="3" >
          <v-card class="info-card">Préco livrée moyenne : <span style="font-weight:bolder">{{ meanPrecoLivree }}</span></v-card>
        </v-col>
      </v-row>
    </div>
    <!--Fin Indicateurs-->

    <!--Graphs-->
    <h3 v-on:click="showGraphiques = !showGraphiques">
      Graphiques
      <v-icon small class="mr-2" v-if="!showGraphiques">
        mdi-chevron-down
      </v-icon>
      <v-icon small class="mr-2" v-else>
        mdi-chevron-up
      </v-icon>
    </h3>
    <div v-if="showGraphiques" style="margin-bottom:20px;margin-top:20px;">
      <v-row>
        <v-col cols="5"><v-chart style="height:350px;" :option="chartPrecoLivree"/></v-col>
        <v-col cols="5"><v-chart style="height:350px;" :option="chartPrecoBrut"/></v-col>
      </v-row>
      <v-row>
        <v-col cols="5"><v-chart style="height:350px;" :option="chartDateImage"/></v-col>
        <v-col cols="5"><v-chart style="height:350px;" :option="chartStade"/></v-col>
      </v-row>
      <v-row>
        <v-col cols="5"><v-chart style="height:350px;" :option="chartClient"/></v-col>
        <v-col cols="5"><v-chart style="height:350px;" :option="chartPrecoClient"/></v-col>
      </v-row>
      
      <!--Carto-->
      <v-container style="margin-top:70px;"><!--MAP DESKTOP-->
        <!--
          https://www.nicolaskempf.fr/creez-vos-cartes-interactives-avec-vuejs-et-leaflet/
          https://vue2-leaflet.netlify.app/examples/custom-icons.html
          -->
        <l-map
          :center="carte.center"
          :zoom="carte.zoom"
          class="map"
          ref="map"
          @update:zoom="zoomUpdated"
          @update:center="centerUpdated"
          
        >
          <l-tile-layer
            :url="carte.url"
          >
          </l-tile-layer>
          <l-marker
            v-for="marker in carte.markers"
            :key="marker.id"
            :lat-lng="marker.coordinates"
          >
            <l-icon 
              ref="icon"
              :icon-url="marker.imageUrl"
            >
            </l-icon>
            <l-popup :content="marker.infobulle"/>
          </l-marker>
        </l-map>
      </v-container><!--FIN MAP DESKTOP-->
      <!--Fin Carto-->
    
    </div>
    <!--Fin Graphs-->



    
      <!-- Tableau -->
      <h3 v-on:click="showTableau = !showTableau">
        Tableau des parcelles
        <v-icon small class="mr-2" v-if="!showTableau">
          mdi-chevron-down
        </v-icon>
        <v-icon small class="mr-2" v-else>
          mdi-chevron-up
        </v-icon>
      </h3>
      <div  v-if="showTableau">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field v-model="searchWord" append-icon="mdi-magnify" label="Recherche par mot clé" single-line
              hide-details></v-text-field>
          </v-card-title>

          <!--https://codepen.io/wolfpup/pen/gOadmPx-->
          <v-data-table show-select v-model="selected_plotQN" :items="parcelles_qn" item-key="uuid_plot" :headers="headers"
            :search="searchWord" dense fixed-header height="450">
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="showItem(item)">
                mdi-eye
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
        <!--EXCEL-->
        <v-btn small color="info" class="mt-2 white--text">
          <download-excel :data="parcelles_qn" :name="getNomFichier()">
            Export Excel
            <v-icon small class="mr-2">
              mdi-download
            </v-icon>
          </download-excel>
        </v-btn>
      </div>

      <!-- FIN  Tableau -->

      <!--  Gestion corrections -->
      <h3 v-on:click="showCorriger = !showCorriger">
        Corriger des parcelles
        <v-icon small class="mr-2" v-if="!showCorriger">
          mdi-chevron-down
        </v-icon>
        <v-icon small class="mr-2" v-else>
          mdi-chevron-up
        </v-icon>
      </h3>

      <div v-if="showCorriger" style="margin-bottom:20px;margin-top:20px;">
        <v-row>
          <v-col>
            <v-icon>mdi-pencil</v-icon>
            Correction à appliquer :
            <v-radio-group v-model="type_correction">
              <div>
                <span style="display: inline; float: left">
                  <v-radio :label="nomCorrection('preco_finale')" value="preco_finale"></v-radio>
                </span>
                <span v-if="type_correction=='preco_finale'" style="display: inline; float: left; margin-left: 20px;" >
                  <v-text-field
                    v-model="cor_preco_finale"
                    dense
                    single-line
                    type="number"
                    suffix="kg/ha"
                  />
                </span>
              </div>
              <div>
                <span style="display: inline; float: left">
                  <v-radio :label="nomCorrection('QMS_floraison')" value="QMS_floraison"></v-radio>
                </span>
                <span v-if="type_correction=='QMS_floraison'" style="display: inline; float: left; margin-left: 20px;" >
                  <v-text-field
                    v-model="cor_QMS_floraison"
                    dense
                    single-line
                    type="number"
                    suffix="T/ha"
                  />
                </span>
              </div>
              <div>
                <span style="display: inline; float: left">
                  <v-radio :label="nomCorrection('QMS_acq_delta')" value="QMS_acq_delta"></v-radio>
                </span>
                <span v-if="type_correction=='QMS_acq_delta'" style="display: inline; float: left; margin-left: 20px;" >
                  <v-text-field
                    v-model="cor_QMS_acq_delta"
                    dense
                    single-line
                    type="number"
                    suffix="T/ha"
                  />
                </span>
              </div>
              <div>
                <span style="display: inline; float: left">
                  <v-radio :label="nomCorrection('QN_acq_facteur_delta')" value="QN_acq_facteur_delta"></v-radio>
                </span>
                <span v-if="type_correction=='QN_acq_facteur_delta'" style="display: inline; float: left; margin-left: 20px;" >
                  <v-text-field
                    v-model="cor_facteur_QN_acq_facteur_delta"
                    dense
                    single-line
                    type="number"
                    suffix="facteur"
                  />
                </span>
                <span v-if="type_correction=='QN_acq_facteur_delta'" style="display: inline; float: left; margin-left: 10px;" >
                  <v-text-field
                    v-model="cor_delta_QN_acq_facteur_delta"
                    dense
                    single-line
                    type="number"
                    suffix="delta"
                  />
                </span>
              </div>
              <div>
                <span style="display: inline; float: left">
                  <v-radio :label="nomCorrection('sol')" value="sol"></v-radio>
                </span>
                <span v-if="type_correction=='sol'" style="display: inline; float: left; margin-left: 20px;" >
                  <v-text-field
                    v-model="cor_sol"
                    dense
                    single-line
                    type="number"
                    suffix="kg/ha"
                  />
                </span>
              </div>
            </v-radio-group>
          </v-col>
          <v-col>
            <v-icon>mdi-note-text</v-icon>
            Commentaire :
            <v-textarea
              v-model="cor_commentaire"
              solo
              name="commentaireRecalage"
              label="Commentaire"
              rows="12"
              row-height="20"
              style="margin-top:10px;"
            ></v-textarea>
          </v-col>
        </v-row>
        

        <v-btn small color="info" class="mt-2 white--text" type="submit" @click="verifSaveCorrections()">
          Enregistrer
        </v-btn>

        <div v-if="checkDroitAdmin" class="mt-10">
          <v-data-table
            :items="demandesCorrections"
            item-key="id" 
            :headers="headers_corrections"
            sort-by="date_demande"
            :sort-desc="true"
            dense>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small v-if="item.validation==true" color="green" class="mr-2">
                mdi-check
              </v-icon>
              <v-icon small v-else color="red" class="mr-2" @click="validateCorrection(item)">
                mdi-clock-alert-outline
              </v-icon>
            </template>
          </v-data-table>
        </div>
      </div>
      <!--  Fin gestion corrections -->

    </v-container>

  </div>

  
</template>

<script>
import downloadExcel from "vue-json-excel";
import parcellesQnService from "@/service/parcellesQn";
import VChart from "vue-echarts";
import { getDate } from "@/helpers/functions";
import { LMap, LTileLayer, LMarker, LIcon, LPopup } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

export default {
  name: "Suivi_QN",
  components: {
    downloadExcel,
    parcellesQnService,
    VChart,
    getDate,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup
  },
  data() {
    return {
      droits: this.$store.state.user.user.droitsTPC, //this.$store.dispatch("user/fetchUser"), //this.$store.state.user.droitsTPC, //this.$store.state.auth.user.droits,
      parcelles_qn: [],
      listeZAM: [],
      listeEspece: this.$store.getters['qn/ListeEspeces'](),
      listeDebouche: [],
      listeClient: [],
      listeStatut: [],
      listeDepartement: [],
      selectedZAM: [],
      selectedEspece: [],
      selectedDebouche: [],
      selectedClient: [],
      selectedStatut: [],
      selectedDepartement: [],
      nbParcelles: 0,
      searchWord: null,
      selected_plotQN: [],
      showCorriger: false,
      showGraphiques: true,
      showTableau: true,
      headers: [
        //colonnes non visibles mais permettant de faire une recherche sur le contenu
        { text: 'Nom opérateur', value: 'nom_operateur', align: ' d-none' },
        { text: 'Id opérateur', value: 'id_operateur', align: ' d-none' },
        //colonnes visibles
        { text: "", value: "actions", sortable: false, align:"center",width: '36px'},
        { text: "Client", value: "client"},
        { text: "ZAM", value: "zam"},
        { text: "Département", value: "code_dept"},
        { text: "Culture", value: "codeespece"},
        { text: "Débouché", value: "crop_management"},
        { text: "Date dernière acquisition.", value: "derniere_date_acquisition"},
        { text: "Statut", value: "statut"},
        { text: "Id parcelle", value: "uuid_plot"},
        { text: "Date semis", value: "date_semis"},
        { text: "Variete", value: "variete"},
        { text: "Date Calcul", value: "date_calcul"}
      ],
      type_correction: null,
      cor_preco_finale: null,
      cor_QMS_floraison: null,
      cor_QMS_acq_delta: null,
      cor_facteur_QN_acq_facteur_delta: null,
      cor_delta_QN_acq_facteur_delta: null,
      cor_sol: null,
      cor_seleted : [],
      cor_commentaire: '',
      demandesCorrections: [],
      headers_corrections: [
        { text: "", value: "actions", sortable: false, align:"center",width: '36px'},
        { text: 'Demandeur', value: 'demandeur'},
        { text: 'Date demande', value: 'date_demande'},
        { text: "Parcelle(s)", value: "parcelles"},
        { text: "Correction", value: "correction_type"},
        { text: "Valeur", value: "correction_valeur"}
      ],


      chartPrecoLivree: {
        title: {
          text: 'Répartition des doses livrées'
        },
        tooltip: {
          trigger: 'axis'
        },
        chart: {
            width: '100%' 
        },
        grid: {
          left: '8%',
          right: '10%',
          bottom: '30%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {title:"Télécharger l'image"}
          }
        },
        xAxis: {
          type: 'category',
          //boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar'
          }
        ],
        graphic: [{
          type: 'image', // Graphic element type
          id: 'logo', // Specify which graphic element of the update when updating or deleting graphic elements, if it is not required to be ignored.
          right: 'center', // Position according to the parent element (in the middle)
          bottom: '60%', // According to the parent element (0%), if the value of the bottom is 0, the BOTTOM property value can also be deleted.
          z: 0,  // laminated
          bounding: 'all', // Decide how this graphic element is positioned, the method of calculating its own enclosure
          style: {
              image: require('@/assets/logo-arvalis-color.png'), // Here, it must be noted that attention must be a picture path address at the beginning of HTTPS.
              width: 165,
              height: 32
          }
        }]
      },
      chartPrecoBrut : {
        title: {
          text: 'Répartition des doses brutes'
        },
        tooltip: {
          trigger: 'axis'
        },
        chart: {
            width: '100%' 
        },
        grid: {
          left: '8%',
          right: '10%',
          bottom: '30%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {title:"Télécharger l'image"}
          }
        },
        xAxis: {
          type: 'category',
          //boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar'
          }
        ],
        graphic: [{
          type: 'image', // Graphic element type
          id: 'logo', // Specify which graphic element of the update when updating or deleting graphic elements, if it is not required to be ignored.
          right: 'center', // Position according to the parent element (in the middle)
          bottom: '60%', // According to the parent element (0%), if the value of the bottom is 0, the BOTTOM property value can also be deleted.
          z: 0,  // laminated
          bounding: 'all', // Decide how this graphic element is positioned, the method of calculating its own enclosure
          style: {
              image: require('@/assets/logo-arvalis-color.png'), // Here, it must be noted that attention must be a picture path address at the beginning of HTTPS.
              width: 165,
              height: 32
          }
        }]
      },
      chartDateImage: {
        title: {
          text: 'Répartition des dates d\'acquisition'
        },
        tooltip: {
          trigger: 'axis'
        },
        chart: {
            width: '100%' 
        },
        grid: {
          left: '8%',
          right: '10%',
          bottom: '27%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {title:"Télécharger l'image"}
          }
        },
        xAxis: {
          type: 'time',
          minInterval: 1000 * 3600 * 24 * 15,
          maxInterval: 1000 * 3600 * 24 * 30,
          axisLabel: {
            formatter: '{dd}/{MM}',
            rotate: 30
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar'
          }
        ],
        graphic: [{
          type: 'image', // Graphic element type
          id: 'logo', // Specify which graphic element of the update when updating or deleting graphic elements, if it is not required to be ignored.
          right: 'center', // Position according to the parent element (in the middle)
          bottom: '60%', // According to the parent element (0%), if the value of the bottom is 0, the BOTTOM property value can also be deleted.
          z: 0,  // laminated
          bounding: 'all', // Decide how this graphic element is positioned, the method of calculating its own enclosure
          style: {
              image: require('@/assets/logo-arvalis-color.png'), // Here, it must be noted that attention must be a picture path address at the beginning of HTTPS.
              width: 165,
              height: 32
          }
        }]
      },
      chartStade: {
        title: {
          text: 'Répartition des stades'
        },
        tooltip: {
          trigger: 'axis'
        },
        chart: {
            width: '100%' 
        },
        grid: {
          left: '8%',
          right: '10%',
          bottom: '25%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {title:"Télécharger l'image"}
          }
        },
        xAxis: {
          type: 'category',
          //boundaryGap: false,
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            show: true,
            rotate: 30
          },
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar'
          }
        ],
        graphic: [{
          type: 'image', // Graphic element type
          id: 'logo', // Specify which graphic element of the update when updating or deleting graphic elements, if it is not required to be ignored.
          right: 'center', // Position according to the parent element (in the middle)
          bottom: '60%', // According to the parent element (0%), if the value of the bottom is 0, the BOTTOM property value can also be deleted.
          z: 0,  // laminated
          bounding: 'all', // Decide how this graphic element is positioned, the method of calculating its own enclosure
          style: {
              image: require('@/assets/logo-arvalis-color.png'), // Here, it must be noted that attention must be a picture path address at the beginning of HTTPS.
              width: 165,
              height: 32
          }
        }]
      },
      chartClient: {
        title: {
          text: 'Répartition des parcelles selon les clients'
        },
        tooltip: {
          trigger: 'axis'
        },
        chart: {
            width: '100%' 
        },
        grid: {
          left: '8%',
          right: '10%',
          bottom: '30%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {title:"Télécharger l'image"}
          }
        },
        series: [
          {
            data: [],
            type: 'pie'
          }
        ],
        graphic: [{
          type: 'image', // Graphic element type
          id: 'logo', // Specify which graphic element of the update when updating or deleting graphic elements, if it is not required to be ignored.
          right: 'center', // Position according to the parent element (in the middle)
          bottom: '60%', // According to the parent element (0%), if the value of the bottom is 0, the BOTTOM property value can also be deleted.
          z: 0,  // laminated
          bounding: 'all', // Decide how this graphic element is positioned, the method of calculating its own enclosure
          style: {
              image: require('@/assets/logo-arvalis-color.png'), // Here, it must be noted that attention must be a picture path address at the beginning of HTTPS.
              width: 165,
              height: 32
          }
        }]
      },
      chartPrecoClient: {
        title: {
          text: 'Préconisation moyenne par client'
        },
        tooltip: {
          trigger: 'axis'
        },
        chart: {
            width: '100%' 
        },
        grid: {
          left: '8%',
          right: '10%',
          bottom: '30%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {title:"Télécharger l'image"}
          }
        },
        xAxis: {
          type: 'category',
          //boundaryGap: false,
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            show: true,
            rotate: 30
          },
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar'
          }
        ],
        graphic: [{
          type: 'image', // Graphic element type
          id: 'logo', // Specify which graphic element of the update when updating or deleting graphic elements, if it is not required to be ignored.
          right: 'center', // Position according to the parent element (in the middle)
          bottom: '60%', // According to the parent element (0%), if the value of the bottom is 0, the BOTTOM property value can also be deleted.
          z: 0,  // laminated
          bounding: 'all', // Decide how this graphic element is positioned, the method of calculating its own enclosure
          style: {
              image: require('@/assets/logo-arvalis-color.png'), // Here, it must be noted that attention must be a picture path address at the beginning of HTTPS.
              width: 165,
              height: 32
          }
        }]
      },
      carte:{
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        center: [ 46.76782, 2.43129 ],
        zoom: 6,
        markers: [],
      },
    };
  },
  watch: {
    selectedEspece: function () {
        this.FilterParcelles()
        this.actualiseGraph()
    },
    selectedDebouche: function () {
        this.FilterParcelles()
        this.actualiseGraph()
    },
    selectedZAM: function () {
        this.FilterParcelles()
        this.actualiseGraph()
    },
    selectedClient: function () {
        this.FilterParcelles()
        this.actualiseGraph()
    },
    selectedStatut: function () {
        this.FilterParcelles()
        this.actualiseGraph()
    },
    selectedDepartement: function () {
        this.FilterParcelles()
        this.actualiseGraph()
    },
    choixSelected: function () {
      //appelle la function dont le nom est stocké dans le store
      if (this.choixSelected != "") {
        this[this.choixSelected]();
      }
    },
    showCorriger: function () {
      //appelle la function dont le nom est stocké dans le store
      if (this.showCorriger == true) {
        this.getDemandesCorrections();
      }
    },

  },

  computed: {
    checkDroitAdmin(){
      return this.$store.getters['user/checkDroit']('ADMIN','lecture')
    },
    choixSelected() {
      return this.$store.state.choix.choixSelected;
    },
    meanPrecoLivree() {
      // Filter out items with null values
      const filtered_parcelles_qn = this.parcelles_qn.filter(item => item.preco_livree !== null);

      // Calculate the total and mean
      const total = filtered_parcelles_qn.reduce((sum, item) => sum + parseFloat(item.preco_livree), 0);
      const mean = filtered_parcelles_qn.length > 0 ? total / filtered_parcelles_qn.length : 0;
      return mean.toFixed(2);
    }
  },

  async mounted() {
    if ((this.$store.getters['qn/ParcellesList']()).length == 0) {
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      this.parcelles_qn = await parcellesQnService.getInfosParcelles();
      this.$store.commit('qn/defineParcellesList', {
        parcelles: this.parcelles_qn
      });
      this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
    } else {
      this.parcelles_qn = this.$store.getters['qn/ParcellesList']()
    }

    //STORE
    this.listeZAM = this.$store.getters['qn/DefineListSelect']('zam');
    //this.listeEspece = this.$store.getters['qn/DefineListSelect']('codeespece');
    this.listeDebouche = this.$store.getters['qn/DefineListSelect']('crop_management');
    this.listeClient = this.$store.getters['qn/DefineListSelect']('client');
    this.listeStatut = this.$store.getters['qn/DefineListSelect']('statut');
    this.listeDepartement = this.$store.getters['qn/DefineListSelect']('code_dept');

    this.actualiseGraph()

  },
  

  methods: {
    async FilterParcelles() {
      this.parcelles_qn = this.$store.getters['qn/FilterParcelles'](this.selectedZAM,this.selectedEspece,this.selectedDebouche, this.selectedClient, this.selectedStatut, this.selectedDepartement)

      this.nbParcelles = this.parcelles_qn.length
    },


    actualiseGraph(){
      var calculGraphPrecoLivree = this.repartitionPreco('preco_livree')
      this.chartPrecoLivree.xAxis.data = calculGraphPrecoLivree.category
      this.chartPrecoLivree.series[0].data = calculGraphPrecoLivree.counts

      var calculGraphPrecoBrut = this.repartitionPreco('sortie_precoBrute')
      this.chartPrecoBrut.xAxis.data = calculGraphPrecoBrut.category
      this.chartPrecoBrut.series[0].data = calculGraphPrecoBrut.counts

      this.chartDateImage.series[0].data = this.repartitionDateImage()

      var calculGraphStade = this.repartitionStade()
      this.chartStade.xAxis.data = calculGraphStade.category
      this.chartStade.series[0].data = calculGraphStade.counts

      this.chartClient.series[0].data = this.repartitionClient()

      var calculGraphPrecoClient = this.repartitionPrecoClient()
      this.chartPrecoClient.xAxis.data = calculGraphPrecoClient.category
      this.chartPrecoClient.series[0].data = calculGraphPrecoClient.counts

      this.getPositionCarte()

    },

    repartitionPreco(type){
      var maxPreco = Math.max(...this.parcelles_qn.map(obj => obj[type]));
      var min = 0;
      var max = Math.ceil(maxPreco / 10) * 10;

      const categoryLimits = [];
      for (let i = min; i <= max; i += 10) {
        categoryLimits.push(i);
      }

      const categoryCounts = new Array(categoryLimits.length).fill(0);
      for (const parcelle of this.parcelles_qn) {
          const preco = parcelle[type];

          if(preco!=null){
            let categoryIndex = 0;
            while (categoryIndex < categoryLimits.length && preco >= categoryLimits[categoryIndex+1]) {
              categoryIndex++;
            }
            categoryCounts[categoryIndex]++;
          }

      }
      return {category: categoryLimits, counts: categoryCounts};

    },

    repartitionDateImage(){
      const categoryCounts = new Array();
      for (const parcelle of this.parcelles_qn) {
          const date_image_ts = parcelle.derniere_date_acquisition;

          if(date_image_ts!=null){
            var date_image = date_image_ts.split(' ')[0];
            if(categoryCounts[date_image]!=undefined){
              categoryCounts[date_image][1]+=1
            } else {
              categoryCounts[date_image] = [new Date(date_image).getTime(), 1]
            }
          }

      }
      return Object.values(categoryCounts);

    },

    repartitionStade(){
      const category = ['epi1cm', '1n', '2n', 'dfp', 'meiose', 'epiaison', 'floraison', 'maturite', 'recolte'];
      const counts = new Array(category.length + 1).fill(0);
      for (const parcelle of this.parcelles_qn) {
        counts[category.indexOf(parcelle.nom_stade_en_cours)]+=1
      }
      return {category: category, counts: counts};

    },

    repartitionClient(){
      const categoryCounts = new Array();
      for (const parcelle of this.parcelles_qn) {
          const client = parcelle.client;

          if(categoryCounts[client]!=undefined){
            categoryCounts[client]['value']+=1
          } else {
            categoryCounts[client]= {value:1, name:client}
          }

      }
      var result = Object.values(categoryCounts)
      result.sort((a, b) => b.value - a.value);
      return result;

    },

    repartitionPrecoClient(){
      
      // Step 1: Group preco by client

      const filtered_parcelles_qn = this.parcelles_qn.filter(item => item.preco_livree !== null);

      const precoByClient = this.parcelles_qn.reduce((acc, item) => {
        
        // If the client is not in the accumulator, add it with initial values
        if (!acc[item.client]) {
            acc[item.client] = { totalPreco: 0, count: 0 };
        }

        if(item.preco_livree !== null){  
          // Accumulate the total sales and count for each client
          acc[item.client].totalPreco += parseFloat(item.preco_livree);
          acc[item.client].count += 1;

        }
        return acc;
          
      }, {});

      // Step 2: Calculate the mean of preco for each client
      const category = new Array()
      const meanPrecoClient = new Array()
      for (const client in precoByClient) {
          category.push(client)
          const clientData = precoByClient[client];
          meanPrecoClient.push((clientData.totalPreco / clientData.count).toFixed(2))
      }

      return {category: category, counts: meanPrecoClient};


    },

    getPositionCarte(){
      //markers sur carte
      var array = []
      this.parcelles_qn.forEach(element => {
        if(element['latitude']!=null && element['longitude']!=null){
          array.push({
            id:element['uuid_plot'], 
            coordinates: [parseFloat(element['latitude']), parseFloat(element['longitude'])],
            imageUrl:require('@/assets/map_icon_blue.png'), 
            infobulle:'<a href="'+this.showItem(element,'href')+'" target="_blank">Voir le détail de la parcelle</a><br>Parcelle: '+element['uuid_plot']+'<br>Espèce: '+this.$store.getters['qn/NomEspeceSelonCode'](element['codeespece'])+'<br>Client: '+element['client']+'<br>Dose livrée: '+element['preco_livree']})
        }
      });
      
      this.carte.markers = array
    },

    zoomUpdated (zoom) {
      this.zoom = zoom;
    },
    centerUpdated (center) {
      this.center = center;
    },

    async getDemandesCorrections () {
      this.demandesCorrections = await parcellesQnService.getDemandeCorrections();
    },

    showItem(parcelle, type=null) {
      let route = this.$router
        .resolve({ name: 'qn-detail-parcelle', params: { id: parcelle.uuid_plot } });

      if(type=='href'){
        return route.href;
      } else {
        window.open(route.href);
      }
    },

    getNomFichier(){
      var now = new Date()
      now = getDate(now,'yyyymmdd')
      return now+'_parcellesQN.xls'
    },

    resetFiltres() {
      this.selectedEspece = []
      this.selectedZAM = []
      this.selectedClient = []
      this.selectedDebouche = []
      this.selectedDepartement = []
      this.selectedStatut = []
    },

    resetCorrections() {
      this.type_correction = null
      this.cor_commentaire = null
      this.cor_preco_finale = null
      this.cor_QMS_floraison = null
      this.cor_QMS_acq_delta = null
      this.cor_facteur_QN_acq_facteur_delta = null
      this.cor_delta_QN_acq_facteur_delta = null
      this.cor_sol = null
      this.cor_seleted = []
      this.selected_plotQN = []
    },

    nomCorrection(code_correction){
      var traduction = {preco_finale: 'Préconisation finale', QMS_floraison: 'Bimasse floraison', QMS_acq_delta: 'Bimasse acquisition', QN_acq_facteur_delta: 'QN acquisition', sol: 'Sol'}
      return traduction[code_correction]
    },

    closeChoix() {
      this.$store.dispatch("choix/unsetChoix");
    },

    verifSaveCorrections(){
      var nb_plot_cor = this.selected_plotQN.length

      if(nb_plot_cor==0){
        this.$store.dispatch('errors/setError', { message: {code:"",description:"A minima une parcelle doit être sélectionnée dans le tableau."} });
      } else {
        var text = ""
        if(this.type_correction=='QN_acq_facteur_delta'){
          text = this.nomCorrection(this.type_correction)+" (facteur: "+this.cor_facteur_QN_acq_facteur_delta+", delta: "+this.cor_delta_QN_acq_facteur_delta+")"
          if(this.cor_facteur_QN_acq_facteur_delta!=null && this.cor_delta_QN_acq_facteur_delta!=null){
            this.cor_seleted = [this.cor_facteur_QN_acq_facteur_delta,this.cor_delta_QN_acq_facteur_delta]
          }
        } else {
          text = this.nomCorrection(this.type_correction)+" ("+this['cor_'+this.type_correction]+")"
          if(this['cor_'+this.type_correction]!=null){
            this.cor_seleted = [this['cor_'+this.type_correction]]
          }
        }

        if(this.cor_seleted.length>0){
          this.$store.dispatch("choix/setChoix", {
            question: "Etes-vous sûr de vouloir appliquer la correction suivante : "+text+" à "+nb_plot_cor+" parcelle(s)?",
            choix: {
              choix1: { text: "Oui", function: "saveCorrection" },
              choix2: { text: "Annuler", function: "closeChoix" },
            },
          });
        } else {
          this.$store.dispatch('errors/setError', { message: {code:"",description:"Une correction doit être saisie"} });
        }
      }

    },
    async saveCorrection(){
      this.closeChoix()
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      var isSent = null
      var selected_uuid = this.selected_plotQN.map(item => item.uuid_plot);
      var demandeCor = {parcelles: selected_uuid, type_correction: this.type_correction, valeur_correction: this.cor_seleted, commentaire: this.cor_commentaire}
      isSent = await parcellesQnService.saveDemandeCorrections(demandeCor);

      this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
      if(isSent !== true){
          alert('Un problème est survenu.')
      } else {
        this.$store.dispatch('informations/setInfo', { message: {titre:"Enregistrement effectué",description:"La demande de correction a bien été enregistrée."} });
        this.resetCorrections()
        this.getDemandesCorrections()
      }

    },

    async validateCorrection(item){
      if(this.checkDroitAdmin){
        this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
        var result = await parcellesQnService.validationDemandeCorrection(item.id);
        if(result==true){
          this.demandesCorrections = this.demandesCorrections.map(data => {
              if(data.id === item.id) {
                  data.validation=true;
              }
              return data;
          });
          this.$store.dispatch('informations/setInfo', { message: {titre:"Validation effectuée",description:"La validation a bien été enregistrée."} });
        } else {
          this.$store.dispatch('errors/setError', { message: {code:"",description:"Un problème est survenu."} });
        }
        this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
      } else {
        this.$store.dispatch('errors/setError', { message: {code:"",description:"Vous n'avez pas les droits pour effectuer cette opération."} });
      }
    },

  }
};
</script>

<style scoped>

.map {
    height: 500px !important;
    width: 600px !important;
    position: relative !important;
    z-index:0;
 }
 .leaflet-container {
    height: 500px;
    width: 600px;
  }
 .leaflet-pane {
    z-index: 0
 }

</style>
