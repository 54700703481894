<template>
  <v-app >
    <header>
    <!-- Main nav in responsive mode -->
    <v-navigation-drawer 
      id="nav_bar"
      v-if="isLoggedIn && $vuetify.breakpoint.mdAndDown"
      app
      v-model="mainmenu"
      right
      clipped
      style="width:100%  ; height: auto;"
      :class="'color-white'" color="primary"
    >
      <v-list dense>
        <v-list-group
          v-for="nav in navigationAuthorised(navigation)"
          :key="nav.id"
          no-action
          @click.stop   
        >
          <template v-slot:activator>
            <v-list-item-content>
              <router-link tag="button" :to="{ name: nav.destination }" v-slot="{ navigate}">
                <v-list-item-title :class="'color-white'" text @click="navigate">
                  {{ $tc(nav.title) }} <v-avatar v-if="nav.title=='mainmenu.gestion' && isConsentementNonValid>=1"
                    color="red" size="25" class="ml-1">{{ isConsentementNonValid }}!</v-avatar>
                </v-list-item-title>
              </router-link>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="elem in navigationAuthorised(nav.children)"
            :key="elem.id"
            :to="{ name: elem.destination }" 
          >
            <v-list-item-content >
              <v-list-item-title  :class="'color-white'">
                {{ $tc(elem.title) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-divider />
        <v-list-item class="text-center" :to="{ name: 'profil' }"><!--USER-->
          <v-list-item-content>
            <v-list-item-title class="logoutnav text-center"  :class="'color-white'">
              <v-icon >mdi-account</v-icon>
              {{ userName }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="text-center">
          <v-list-item-content>
            <div v-for="(lang, i) in langs" :key="`Lang${i}`">
              <v-btn
                text
                x-small
                tile
                @click="changeLocale(lang)"
                :class="isActiveLang(lang)"
              >
                <span class="mx-auto" v-if="lang === 'dev'">DEV</span>
                <img
                  v-else
                  class="img-lang"
                  :src="`${baseRoute}img/icons/${lang}-32.png`"
                  width="20"
                  height="20"
                  :value="lang"
                />
              </v-btn>
            </div>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="logout" v-if="isLoggedIn"> <!--DECONNEXION-->
          <v-list-item-content>
            <v-list-item-title class="logoutnav text-center"  :class="'color-white'">
              {{ $tc("mainmenu.logout") }}
              <v-icon   >mdi-power</v-icon>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          class="text-center"
          v-if="!isLoggedIn"
          :to="{ name: 'login' }"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ $tc("mainmenu.login") }}
              <v-icon>mdi-power</v-icon>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- Main nav in responsive mode for consentement -->
    <v-navigation-drawer
      v-else
      app
      v-model="mainmenu"
      right
      clipped
      style="width:100%  ; height: auto;"
      :class="'color-white'" color="primary"
    >
      <v-list dense>
        <v-list-item 
          v-for="nav in navigationAuthorised(navigation_conseillers)"
          :key="nav.id"
          :title="nav.title"
          :class="'color-white'"
          :to="{ name: nav.destination }"
        >
          <v-list-item-title  :class="'color-white'">
            {{ $tc(nav.title) }}
          </v-list-item-title>
        </v-list-item>

        <v-divider />

        <v-list-item class="text-center">
          <v-list-item-content>
            <div v-for="(lang, i) in langs" :key="`Lang${i}`">
              <v-btn
                text
                x-small
                tile
                @click="changeLocale(lang)"
                :class="isActiveLang(lang)"
              >
                <span class="mx-auto" v-if="lang === 'dev'">DEV</span>
                <img
                  v-else
                  class="img-lang"
                  :src="`${baseRoute}img/icons/${lang}-32.png`"
                  width="20"
                  height="20"
                  :value="lang"
                />
              </v-btn>
            </div>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

    <!-- App bar -->
    <v-app-bar
      app
      height="40"
      clipped-left
      short
      elevation="3"
      class="bg-charte"
      v-if="isLoggedIn"
    >
      <v-btn icon class="hidden-lg-and-up" @click.stop="leftnav = true">
        <v-icon color="white">mdi-leaf</v-icon>
      </v-btn>

      <v-toolbar-title
        class="
          bg-charte
          px-2
          py-1
          mx-auto mx-sm-auto mx-md-3 mx-lg-0
          ml-md-0 ml-lg-n4
        "
        :class="
          $vuetify.breakpoint.mdAndDown ? 'rounded-pill px-5' : 'pl-4 w-100'
        "
      >
        <v-row  
          align="center"
          no-gutters
          :justify="$vuetify.breakpoint.mdAndDown ? 'center' : 'start'"
          
        >
          <v-col cols="auto">
            <router-link :to="{ name: 'Home' }">
              <v-img
                src="@/assets/logo-arvalis.png"
                :max-height="$vuetify.breakpoint.mdAndDown ? 24 : 32"
                contain
                :max-width="$vuetify.breakpoint.mdAndDown ? 200 : 300"
                content-class="width-130"
                :position="$vuetify.breakpoint.mdAndDown ? 'center' : 'left'"
              />
            </router-link>
          </v-col>
          <v-col cols="auto">
            <v-row>
              <v-col cols="auto" class="module-color"><div style="margin-left:10px;color:#fff">VISIOFARM</div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-toolbar-title>

      <v-toolbar-items id="nav_bar"   :key=keyForRefresh class="hidden-sm-and-down ml-auto bg-charte">
        <v-menu
          offset-y
          right
          open-on-hover
          v-for="nav in navigationAuthorised(navigation)"
          :key="nav.id"
        >
          <template v-slot:activator="{ on }">
            <router-link
              tag="button"
              :to="{ name: nav.destination }"
              v-slot="{ navigate, isActive, isExactActive }"
            >
              <v-btn
                text
                class="colorText bg-charte-light"
                :active="isActive"
                :class="[
                  isActive && 'router-link-active',
                  isExactActive && 'router-link-exact-active',
                ]"
                @click="navigate"
                v-on="on"
              >
                {{ $tc(nav.title) }}
                <!--Infobulle pour dire qu'il y a des consentements à valider-->
                <v-avatar v-if="nav.title=='mainmenu.gestion' && isConsentementNonValid>=1" color="red" size="25" class="ml-1">{{ isConsentementNonValid }}!</v-avatar>
              </v-btn>
            </router-link>
          </template>
          <v-list>
            <v-list-item
              v-for="elem in navigationAuthorised(nav.children)"
              :key="elem.id"
              exact
              dense
              :to="{ name: elem.destination }"
              active-class="white--text bg-secondary"
            >
              <v-list-item-title>
                {{ $tc(elem.title) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu><v-spacer></v-spacer>
        <v-menu open-on-hover offset-y class="profil-menu pl-20" v-if="isLoggedIn">
          <template v-slot:activator="{ on }">
            <router-link
              :to="{ name: 'profil' }"
              v-slot="{ navigate, isActive, isExactActive }"
            >
              <v-btn
                text
                x-small
                class="colorText text-none"
                :active="isActive"
                :key = "keyForRefresh"
                :class="[
                  isActive && 'router-link-active',
                  isExactActive && 'router-link-exact-active',
                ]"
                
                @click="navigate"
                v-on="on"
                
                ><v-icon color="white">mdi-account</v-icon>
                {{ userName }}
              </v-btn>
            </router-link>
          </template>
          <v-list>
            <v-list-item>
              <div v-for="(lang, i) in langs" :key="`Lang${i}`">
                <v-btn
                  text
                  x-small
                  tile
                  @click="changeLocale(lang)"
                  :class="isActiveLang(lang)"
                >
                  <span class="mx-auto" v-if="lang === 'dev'">DEV</span>
                  <img
                    v-else
                    class="img-lang"
                    :src="`${baseRoute}img/icons/${lang}-32.png`"
                    width="20"
                    height="20"
                    :value="lang"
                  />
                </v-btn>
                <v-divider />
              </div>
            </v-list-item>
            <v-list-item v-if="isLoggedIn">
              <v-btn class="bg-color-light" light text x-small @click="logout">
                <v-icon>mdi-power</v-icon>  {{ $tc("mainmenu.logout") }}
              </v-btn>
              <v-btn
                icon
                class="hidden-md-and-up"
                @click.stop="mainmenu = true"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </v-list-item>
            <v-list-item v-if="!isLoggedIn">
              <router-link
                tag="button"
                :to="{ name: 'login' }"
                v-slot="{ navigate }"
              >
                <v-btn
                  @click="navigate"
                  class="bg-color-light"
                  light
                  text
                  x-small
                >
                  <v-icon>mdi-power</v-icon> {{ $tc("mainmenu.login") }}
                </v-btn>
                <v-btn
                  icon
                  class="hidden-md-and-up"
                  @click.stop="mainmenu = true"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </router-link>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn icon class="hidden-md-and-up" @click.stop="mainmenu = true">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-toolbar-items>
      <v-btn class="hidden-md-and-up" icon @click.stop="mainmenu = true">
        <v-icon color="white">mdi-dots-vertical</v-icon>
      </v-btn>
    </v-app-bar>


    <!-- App bar for consentement -->
    <v-app-bar
      app
      height="40"
      clipped-left
      short
      elevation="3"
      class="bg-charte"
      v-else-if="!isPageLogin"
    >
      <v-btn icon class="hidden-lg-and-up" @click.stop="leftnav = true">
        <v-icon color="white">mdi-leaf</v-icon>
      </v-btn>

      <v-toolbar-title
        class="
          bg-charte
          px-2
          py-1
          mx-auto mx-sm-auto mx-md-3 mx-lg-0
          ml-md-0 ml-lg-n4
        "
        :class="
          $vuetify.breakpoint.mdAndDown ? 'rounded-pill px-5' : 'pl-4 w-100'
        "
      >
        <v-row
          no-gutters
          :justify="$vuetify.breakpoint.mdAndDown ? 'center' : 'start'"
        >
          <v-col cols="auto">
            <router-link :to="{ name: 'Home' }">
              <v-img
                src="@/assets/logo-arvalis.png"
                :max-height="$vuetify.breakpoint.mdAndDown ? 24 : 32"
                contain
                :max-width="$vuetify.breakpoint.mdAndDown ? 200 : 300"
                content-class="width-130"
                :position="$vuetify.breakpoint.mdAndDown ? 'center' : 'left'"
              />
            </router-link>
          </v-col>
        </v-row>
      </v-toolbar-title>

      <v-toolbar-items    :key=keyForRefresh class="hidden-sm-and-down ml-auto bg-charte">
        <v-menu
          offset-y
          right
          open-on-hover
          v-for="nav in navigationAuthorised(navigation_conseillers)"
          :key="nav.id"
        >
          <template v-slot:activator="{ on }">
            <router-link
              tag="button"
              :to="{ name: nav.destination }"
              v-slot="{ navigate, isActive, isExactActive }"
            >
              <v-btn
                text
                class="colorText module-bg-color"
                :active="isActive"
                :class="[
                  isActive && 'router-link-active',
                  isExactActive && 'router-link-exact-active',
                ]"
                @click="navigate"
                v-on="on"
              >
                {{ $tc(nav.title) }}</v-btn
              >
            </router-link>
          </template>
          <v-list>
            <v-list-item
              v-for="elem in navigationAuthorised(nav.children)"
              :key="elem.id"
              exact
              dense
              :to="{ name: elem.destination }"
              active-class="white--text bg-secondary"
            >
              <v-list-item-title>
                {{ $tc(elem.title) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu><v-spacer></v-spacer>

        <v-btn icon class="hidden-md-and-up" @click.stop="mainmenu = true">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-toolbar-items>
      <v-btn class="hidden-md-and-up" icon @click.stop="mainmenu = true">
        <v-icon color="white">mdi-dots-vertical</v-icon>
      </v-btn>
    </v-app-bar>
    </header>

    <v-main>
     <div class="ma-0 pa-4">
    <v-card>
      
    </v-card>

  </div>

      <div class="d-flex flex-column h-100">
        
        <router-view />
      </div>
    </v-main>

    <!--FENETRE CGU-->
      <v-dialog
        max-width="600"
        v-model="dialog_cgu"
        persistent
      >
          <v-card>
            <v-toolbar
              color="primary"
              dark
            >
              <v-toolbar-title class="flex text-center">CGU</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <div style="text-align:justify;margin-top:20px" v-html="$t('cgu')"></div>
            </v-card-text>
            <v-card-actions class="justify-end" v-if="isCguValid">
              <v-btn
                text
                @click="dialog_cgu = false"
              >Fermer</v-btn>
            </v-card-actions>
            <v-card-actions class="justify-end" v-else>
              <v-btn
                text
                @click="accept_cgu()"
              >Accepter</v-btn>
              <v-btn
                text
                @click="reject_cgu()"
              >Refuser</v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>
      <!--FIN FENETRE CGU-->


    <v-footer app class="text-center">
      <div style="margin:auto;">
        <!--<div style="width:400px">
          <img
            :src="`${baseRoute}img/casdar.png`"
            width="180"
            height="70"
          />
        </div>-->
          <span style="width:450px;font-size: 10px; display:block">Avec la contribution financière du compte d'affectation spéciale développement agricole et rural (CASDAR) géré par le ministère en charge de l'agriculture</span>
          <span>© {{ new Date().getFullYear() }}</span>
          <span v-if="isLoggedIn" class="ml-10">
            <button
                @click.stop
                v-on:click="dialog_cgu=true"
              >
                <b><u>CGU</u></b>
              
              </button>
            </span>

      </div>

    </v-footer>
    <error-modal />
    <info-modal />
    <choix-modal />
    <waiting-overlay />
    <notification-snackbar />
  </v-app>
</template>

<script>
import ErrorModal from "@/components/modals/ErrorModal.vue";
import InfoModal from "@/components/modals/InfoModal.vue";
import ChoixModal from "@/components/modals/ChoixModal.vue";
import NotificationSnackbar from "@/components/modals/NotificationSnackbar.vue";
import WaitingOverlay from "@/components/global/WaitingOverlay.vue";
import administrationConseillersService from "@/service/administrationConseillers";

export default {
  name: "App",
  components: {  ErrorModal, InfoModal, ChoixModal, NotificationSnackbar, administrationConseillersService, WaitingOverlay },
  data() {
    return {
    
      keyForRefresh: 1,
      dialog_cgu: false,
      unsubscribeAction: null,
      isLogged: false,
      mainmenu: false,
      isOpen: false,
      leftnav: false,
      baseRoute: process.env.VUE_APP_BASE_ROUTE,
      routes: [],
      navigation: [
        {
          title: "mainmenu.bilanhydrique",
          destination: "bh-tdb",
          children: []
        },
        {
          title: "mainmenu.ferti.main",
          destination: "",
          children: [
            {
              title: "mainmenu.ferti.chn",
              destination: "chn-suivi",
            },
            {
              title: "mainmenu.ferti.qn",
              destination: "qn-suivi",
            }
          ]
        },
        {
          title: "mainmenu.mildiou.main",
          destination: "mildiou-tdb",
          children: [
                {
                  title: "mainmenu.mildiou.meteo",
                  destination: "gap_meteo",
                },
                {
                  title: "mainmenu.mildiou.bsv_mildiou",
                  destination: "bsv_mildiou",
                },
                {
                  title: "mainmenu.mildiou.gestion",
                  destination: "gestion_parcelles",
                }
            ]
        },
        {
          title: "mainmenu.station.main",
          destination: "",
          children: [
            {
              title: "mainmenu.station.iot",
              destination: "meteo-tdb",
            },
            {
              title: "mainmenu.station.mf",
              destination: "meteofrance-tdb",
            }
          ]
        },
        {
          title: "mainmenu.stade.main",
          destination: "",
          children: [
            {
              title: "mainmenu.stade.mais",
              destination: "stade-tdb-mais",
            },
            {
              title: "mainmenu.stade.cap",
              destination: "stade-cap-mesures",
            },
            {
              title: "mainmenu.stade.recalage",
              destination: "recalage",
            }
          ]
        },
        {
          title: "mainmenu.administration.main",
          destination: "",
          children: [
            {
              title: "mainmenu.administration.tdb",
              destination: "admin-tdb-stade",
            },
            {
              title: "mainmenu.administration.conseillers",
              destination: "admin-conseillers",
            }
          ]
        },
        {
          title: "mainmenu.gestion",
          destination: "gestion-agris",
          children: []
        },
        {
          title: "mainmenu.help",  //
          destination: "help", 
          children: []
        }
      ],
      langs: ["fr"], //"fr", "en", "dev"
      navigation_conseillers: [
        {
          title: "menu_conseillers.compte",
          destination: "consentement-compte",
          children: []
        },
        {
          title: "menu_conseillers.consentement",
          destination: "consentement-gestion",
          children: []
        }
      ],
    };
  },
  async created() {

    if (this.isLoggedIn) {
      await this.$store.dispatch("user/fetchUser");
    }

  },
  mounted() {
    // Langue
    if (localStorage.getItem("lang")) {
      let locale = localStorage.getItem("lang");
      this.$root.$i18n.locale = locale;
      this.$vuetify.lang.current = locale;
    }
  },
  computed: {
    showAppBar() {
      return this.$route.name !== "login";
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn() ; //&& this.$store.getters["user/user"];
    },
    isCguValid() {
      return this.$store.getters["user/isCguValid"] ; //&& this.$store.getters["user/user"];
    },
    isPageLogin() {
      return this.$route.name === 'login'
    },
    userName() {
      if (this.$store.getters["user/user"] != null)
      {
         return this.$store.getters["user/user"].userName;
      }
      else 
      {
        return "";
      }
    },
    userProfils() {
      if (this.$store.getters["user/user"] != null)
      {
         return this.$store.getters["user/user"].profilsADPU;
      }
      else 
      {
        return [];
      }
    },
    isConsentementNonValid() {
      var nonValid = 0
      if(this.$store.state.user.user.externe==1){
        var externeNonValid = this.$store.getters["user/user"].externeInfos.non_valid
        if(externeNonValid!=null){
          nonValid = externeNonValid
        }
      }
      return nonValid
    },

  
  },
  watch: {
    isCguValid(val) {
      if(this.isCguValid==false){
        this.dialog_cgu = true
      }
    }
  },
  methods: {
    isActiveLang(lang) {
      if (lang === this.$root.$i18n.locale) {
        return "active-lang";
      } else {
        return "";
      }
    },
    isActiveProfil(profil) {
      if (profil.code === this.$store.state.user.currentProfil.code) {
        return "active-profil";
      } else {
        return "";
      }
    },
    logout() {
      this.mainmenu = false;
      this.leftnav = true;


      this.$store.dispatch("resetAllStates");

      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
      this.keyForRefresh += 1;
    },

    // navigationAuthorised(item) {
    //   const userRight = this.isLoggedIn ? this.$store.getters["user/currentReadRights"].map(value =>value.code): []; 
    //   let retour = [];
    //   item.forEach(element => {
    //     // Récupération de la route et des droits associés
    //     const { route } = this.$router.resolve({
    //       name: element.destination
    //     });
    //     if (route !== undefined) {
    //       const { authorize } = route.meta || {};
    //       const rules = authorize == undefined ? [] : authorize;
    //       if (rules.length == 0) {
    //         retour.push(element);
    //       } else {
    //         let intersection = rules.filter(x => userRight.includes(x));
    //         if (this.isLoggedIn && (intersection.length >0 || rules.includes("*") )){
    //           retour.push(element);
    //         }
    //       }
    //     }
    //   });
    //   return retour;
    // },

    /**
     * Afficher LES MENUS et SOUS MENU du haut selon les droits de l'utilisateur connecté 
     * @param item 
     */
    navigationAuthorised(item) {

      // Récupération des droits specifiques de l'utilisateur
      const currentRights = this.$store.getters["user/currentRights"] || [];

     // console.log('droits actuels : '+JSON.stringify(currentRights));
      //Les futurs endroits de VISIOFARM autorisé pour l'utilisateur connecté
      let acces_allowed_for_user = [];

      // Parcourchaque élément pour vérifier l'autorisation
      item.forEach(element => {
          const {route} = this.$router.resolve({
            name: element.destination,
          });

        if (route !== undefined && element.destination != "") {
          const {authorize} = route.meta || {};
          const rules = authorize == undefined ? [] : authorize;
          if (rules.length == 0) {
            acces_allowed_for_user.push(element);
          } else {
            let intersection = rules.filter((x) => currentRights.includes(x));
            if (this.isLoggedIn && (intersection.length > 0 || rules.includes("*"))) {
              acces_allowed_for_user.push(element);
            }
          }
        } else if (element.destination == "") { //si pas de destination sur onglet principal on regarde si autorisation sur sous-onglet pour afficher l'onglet principal
          var access_allowed_sous_onglet = this.navigationAuthorised(element.children)
          if(access_allowed_sous_onglet.length > 0){
            acces_allowed_for_user.push(element);
          }
        }
      });

    return acces_allowed_for_user;
},

    changeLocale(lang) {
      this.$root.$i18n.locale = lang;
      this.$vuetify.lang.current = lang;
      localStorage.setItem("lang", lang);
    },
    changeProfil(profil) {
  
      this.$store.dispatch("user/changeCurrentProfil", profil);
      //const { authorize } = this.$route.meta;

      //this.$router.push("/")
    },
    reject_cgu(){
      this.dialog_cgu = false
      this.logout();
    },
    async accept_cgu(){
      this.dialog_cgu = false
      var version_cgu = {cgu:process.env.VUE_APP_VERSION_CGU}
      var data = await administrationConseillersService.addCguValidationConseiller(this.$store.getters["user/user"].externeInfos.id_conseiller,version_cgu);
    }
  }
};
</script>
<style lang="scss" scoped>
.img-lang {
  margin: 5px 0 5px 0 !important;
}
.active-lang {
  //border: 1px solid green;
  background-color: lightgreen;
}
.active-profil {
  //border: 1px solid green;
  background-color: lightgreen;
  font-weight: bolder !important;
}
.profil-menu {
  background-color: white;
}
.nav-item-lang {
  font-size: 10px !important;
  cursor: auto;
}

.nav-item-lang a {
  color: #333333 !important;
  text-decoration: none !important;
  cursor: pointer;
  margin-left: 3px;
}

.nav-item-lang div {
  background-repeat: no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
  display: inline-block;
  background-color: #fff;
}

.nav-item-lang-fr-FR {
  background-image: url("/img/icons/fr-32.png");
}

.nav-item-lang-en-GB {
  background-image: url("/img/icons/en-32.png");
}

//MOBILE AND TABLETTE

@media only screen and (max-width: 960px) {
  #nav_bar {
    top: 40px !important;
  }

  .color-white{
    color:white !important;
  }

  /*Les icons de deconnexion et user*/
  .theme--light.v-icon {
    color:white !important;
  }

  #nav_bar .mdi-chevron-down::before {
    color:white !important;
  }

}


// HELP
#nav_bar > button:nth-last-child(5) {
  border-left:4px solid #087b7a !important;
}
#nav_bar > button:nth-last-child(5)::after {
  content: "?";
  display: inline-block;
  font-size: 1rem;
  font-weight: bolder;
  position: relative;
  margin-left:5px;
}

</style>