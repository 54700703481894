<template>
  <div>
    <v-container style="margin-top: 10px">

      <v-row class="mb-5">
        <v-col cols="3">
          <v-select
              v-model="selectedDate"
              :items="dates_calcul"
              item-text="date_calcul"
              menu-props="auto"
              label="Date de calcul"
              hide-details
              prepend-icon="mdi-calendar-month"
              single-line
              v-on:change="reloadParcelles()"
              class="pt-0"
          ></v-select>
        </v-col>
        <v-col cols="2">
          <div style="text-align:center;">Comparé à</div>
        </v-col>
        <v-col cols="3">
            <v-select
                v-model="selectedDate2"
                :items="dates_calcul"
                item-text="date_calcul"
                menu-props="auto"
                label="Date de calcul"
                hide-details
                prepend-icon="mdi-calendar-month"
                single-line
                v-on:change="reloadParcelles2()"
                class="pt-0"
            ></v-select>
          </v-col>
      </v-row>

      <h3 v-on:click="showDetailParcelle = !showDetailParcelle">
        Détail de la parcelle
          <v-icon small class="mr-2" v-if="!showDetailParcelle">
            mdi-chevron-down
          </v-icon>
          <v-icon small class="mr-2" v-else>
            mdi-chevron-up
          </v-icon> </h3>
      <div v-if="showDetailParcelle" style="margin-bottom:20px;">
        <v-row>
          <v-col cols="5">
            <v-card class="mt-5 pl-5 pt-2 pb-2">
              <div class="parcelle-card">
                <div><span class="parcelle-card-title">Date calcul :</span><span class="parcelle-card-val small">{{parcelle.calcul_end_timestamp}}</span><span class="parcelle-card-val small">{{parcelle2.calcul_end_timestamp}}</span></div>
                <div><span class="parcelle-card-title">ZAM :</span><span class="parcelle-card-val">{{parcelle.zam}}</span></div>
                <div><span class="parcelle-card-title">Client :</span><span class="parcelle-card-val">{{parcelle.client}}</span></div>
                <div><span class="parcelle-card-title">Ingénieur régional :</span><span class="parcelle-card-val">{{parcelle.ingenieur_regional}}</span></div>
                <div><span class="parcelle-card-title">Exploitation :</span><span class="parcelle-card-val">{{parcelle.exploitation}}</span></div>
                <div><span class="parcelle-card-title">Lieu-dit :</span><span class="parcelle-card-val">{{parcelle.lieudit}}</span></div>
                <div><span class="parcelle-card-title">Code Airbus :</span><span class="parcelle-card-val">{{parcelle.code_airbus}}</span></div>
                <div><span class="parcelle-card-title">Nom parcelle :</span><span class="parcelle-card-val">{{parcelle.nom_parcelle}}</span></div>
              </div>
            </v-card>
            <v-card class="mt-5 pl-5 pt-2 pb-2">
              <v-icon small class="mr-2">
                mdi-calendar-text-outline
              </v-icon>
              STADES
              <div class="parcelle-card">
                <div><span class="parcelle-card-title">Epi 1cm :</span><span class="parcelle-card-val small">{{parcelle.epi1cm_date}}</span><span class="parcelle-card-val small">{{parcelle2.epi1cm_date}}</span></div>
                <div><span class="parcelle-card-title">1 Noeud :</span><span class="parcelle-card-val small"> {{parcelle.unnoeud_date}}</span><span class="parcelle-card-val small">{{parcelle2.unnoeud_date}}</span></div>
                <div><span class="parcelle-card-title">2 Noeuds :</span><span class="parcelle-card-val small"> {{parcelle.deuxnoeuds_date}}</span><span class="parcelle-card-val small">{{parcelle2.deuxnoeuds_date}}</span></div>
                <div><span class="parcelle-card-title">DFP :</span><span class="parcelle-card-val small"> {{parcelle.dfp_date}}</span><span class="parcelle-card-val small">{{parcelle2.dfp_date}}</span></div>
                <div><span class="parcelle-card-title">Epiaison :</span><span class="parcelle-card-val small"> {{parcelle.epiaison_date}}</span><span class="parcelle-card-val small">{{parcelle2.epiaison_date}}</span></div>
              </div>
              
            </v-card>
          </v-col>
          <v-col cols="5">
            <v-card class="mt-5 pl-5 pt-2 pb-2">
              <v-icon small class="mr-2">
                mdi-sprout
              </v-icon>
              CULTURE
              <div class="parcelle-card">
                <div><span class="parcelle-card-title">Variété :</span><span class="parcelle-card-val small"> {{parcelle.variete}}</span><span class="parcelle-card-val small">{{parcelle2.variete}}</span></div>
                <div><span class="parcelle-card-title">Débouché :</span><span class="parcelle-card-val small"> {{parcelle.crop_management}}</span><span class="parcelle-card-val small">{{parcelle2.crop_management}}</span></div>
                <div><span class="parcelle-card-title">Date semis :</span><span class="parcelle-card-val small"> {{parcelle.semis_date}}</span><span class="parcelle-card-val small">{{parcelle2.semis_date}}</span></div>
                <div><span class="parcelle-card-title">Irrigation :</span><span class="parcelle-card-val small" v-if="Object.keys(this.parcelle).length!=0 && parcelle.parcelle_irriguee==1">OUI</span><span class="parcelle-card-val small" v-else-if="Object.keys(this.parcelle).length!=0">NON</span><span class="parcelle-card-val small" v-if="Object.keys(this.parcelle2).length!=0 && parcelle2.parcelle_irriguee==1">OUI</span><span class="parcelle-card-val" v-else-if="Object.keys(this.parcelle2).length!=0">NON</span></div>
                <div><span class="parcelle-card-title">Nom du sol :</span><span class="parcelle-card-valsmall"> {{parcelle.codesolbase}}</span><span class="parcelle-card-val small">{{parcelle2.codesolbase}}</span></div>
                <v-divider></v-divider>
                <div><span class="parcelle-card-title">Précédent :</span><span class="parcelle-card-val small"> {{parcelle.nom_precedent}}</span><span class="parcelle-card-val small">{{parcelle2.nom_precedent}}</span></div>
                <div><span class="parcelle-card-title">Récolte précédent :</span><span class="parcelle-card-val small"> {{parcelle.daterecolteprecedent}}</span><span class="parcelle-card-val small">{{parcelle2.daterecolteprecedent}}</span></div>
                <div><span class="parcelle-card-title">Rdt précédent ({{parcelle.uniterendementrecolteprecedent}}) :</span><span class="parcelle-card-val small"> {{parcelle.rendementrecolteprecedent}}</span><span class="parcelle-card-val small">{{parcelle2.rendementrecolteprecedent}}</span></div>
                <div><span class="parcelle-card-title">Destination résidus :</span><span class="parcelle-card-val small"> {{parcelle.destinationresidusrecolteprecedent}}</span><span class="parcelle-card-val small">{{parcelle2.destinationresidusrecolteprecedent}}</span></div>
                <div><span class="parcelle-card-title">Reliquat forcé :</span><span class="parcelle-card-val small"> {{getReliquat(parcelle)}}</span><span class="parcelle-card-val small">{{getReliquat(parcelle2)}}</span></div>
              </div>
              
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="5">
            <v-card class="mt-5 pl-5 pt-2 pb-2">
              <v-icon small class="mr-2">
                mdi-scale-balance
              </v-icon>
              AZOTE ET BIOMASSE
              <div class="parcelle-card">
                <div><span class="parcelle-card-title">Azote aerien absorbé (kgN/ha) :</span></div>
                <div><span class="parcelle-card-title pl-10">- après assimilation :</span><span class="parcelle-card-val small" v-if="Object.keys(this.parcelle).length!=0"> {{parseFloat(parcelle.dernier_azoteaerienabsorbe_apres_assimilation).toFixed(2)}}</span><span class="parcelle-card-val small" v-if="Object.keys(this.parcelle2).length!=0">{{parseFloat(parcelle2.dernier_azoteaerienabsorbe_apres_assimilation).toFixed(2)}}</span></div>
                <div><span class="parcelle-card-title pl-10">- avant assimilation :</span><span class="parcelle-card-val small" v-if="Object.keys(this.parcelle).length!=0"> {{parseFloat(parcelle.dernier_azoteaerienabsorbe_avant_assimilation).toFixed(2)}}</span><span class="parcelle-card-val small" v-if="Object.keys(this.parcelle2).length!=0">{{parseFloat(parcelle2.dernier_azoteaerienabsorbe_avant_assimilation).toFixed(2)}}</span></div>
                <div><span class="parcelle-card-title">Biomasse aérienne (t/ha) :</span></div>
                <div><span class="parcelle-card-title pl-10">- après assimilation :</span><span class="parcelle-card-val small" v-if="Object.keys(this.parcelle).length!=0"> {{parseFloat(parcelle.dernier_biomasseaerienne_apres_assimilation).toFixed(2)}}</span><span class="parcelle-card-val small" v-if="Object.keys(this.parcelle2).length!=0">{{parseFloat(parcelle2.dernier_biomasseaerienne_apres_assimilation).toFixed(2)}}</span></div>
                <div><span class="parcelle-card-title pl-10">- avant assimilation :</span><span class="parcelle-card-val small" v-if="Object.keys(this.parcelle).length!=0"> {{parseFloat(parcelle.dernier_biomasseaerienne_avant_assimilation).toFixed(2)}}</span><span class="parcelle-card-val small" v-if="Object.keys(this.parcelle2).length!=0">{{parseFloat(parcelle2.dernier_biomasseaerienne_avant_assimilation).toFixed(2)}}</span></div>
                <div><span class="parcelle-card-title pl-10">- après ass. à j+10 :</span><span class="parcelle-card-val small" v-if="Object.keys(this.parcelle).length!=0"> {{parseFloat(parcelle.biomasseaerienne_jplus10).toFixed(2)}}</span><span class="parcelle-card-val small" v-if="Object.keys(this.parcelle2).length!=0">{{parseFloat(parcelle2.biomasseaerienne_jplus10).toFixed(2)}}</span></div>
                <div><span class="parcelle-card-title pl-10">- potentielle après ass. à j+10 :</span><span class="parcelle-card-val small" v-if="Object.keys(this.parcelle).length!=0"> {{parseFloat(parcelle.biomasseaeriennepotentielle_jplus10).toFixed(2)}}</span><span class="parcelle-card-val small" v-if="Object.keys(this.parcelle2).length!=0">{{parseFloat(parcelle2.biomasseaeriennepotentielle_jplus10).toFixed(2)}}</span></div>
                <div><span class="parcelle-card-title">QN sol :</span><span class="parcelle-card-val small"> {{parcelle.qn_sol_prochaine_periode_favorable}}</span><span class="parcelle-card-val small">{{parcelle2.qn_sol_prochaine_periode_favorable}}</span></div>
                <div><span class="parcelle-card-title">Date épuisement Nsol dispo :</span><span class="parcelle-card-val small"> {{parcelle.date_epuisement_n_sol}}</span><span class="parcelle-card-val small">{{parcelle2.date_epuisement_n_sol}}</span></div>
              </div>
              
            </v-card>
          </v-col>
          <v-col cols="5">
            <v-card class="mt-5 pl-5 pt-2 pb-2">
              <v-icon small class="mr-2">
                mdi-script-text-outline
              </v-icon>
              VARIABLES AFFICHEES SUR LE CONSEIL
              <div class="parcelle-card">
                <div><span class="parcelle-card-title">Dose préconisée :</span><span class="parcelle-card-val small"> {{parcelle.dose_preconisee}}</span><span class="parcelle-card-val small">{{parcelle2.dose_preconisee}}</span></div>
                <div><span class="parcelle-card-title">Apport prévu? :</span><span class="parcelle-card-val small"> {{parcelle.Preco_declenchement}}</span><span class="parcelle-card-val small">{{parcelle2.Preco_declenchement}}</span></div>
                <div><span class="parcelle-card-title">Date franchissement INN min :</span><span class="parcelle-card-val small"> {{parcelle.date_franchissement_inn_min}}</span><span class="parcelle-card-val small">{{parcelle2.date_franchissement_inn_min}}</span></div>
                <div><span class="parcelle-card-title">Date deb prochaine période fav. :</span><span class="parcelle-card-val small"> {{parcelle.date_debut_prochaine_periode_favorable}}</span><span class="parcelle-card-val small">{{parcelle2.date_debut_prochaine_periode_favorable}}</span></div>
                <div><span class="parcelle-card-title">Date fin prochaine période fav. :</span><span class="parcelle-card-val small"> {{parcelle.date_fin_prochaine_periode_favorable}}</span><span class="parcelle-card-val small">{{parcelle2.date_fin_prochaine_periode_favorable}}</span></div>
                <div><span class="parcelle-card-title">Date dernier apport :</span><span class="parcelle-card-val small"> {{parcelle.date_dernier_apport}}</span><span class="parcelle-card-val small">{{parcelle2.date_dernier_apport}}</span></div>
                <div><span class="parcelle-card-title">Dose dernier apport :</span><span class="parcelle-card-val small"> {{parcelle.dose_dernier_apport}}</span><span class="parcelle-card-val small">{{parcelle2.dose_dernier_apport}}</span></div>
                <div><span class="parcelle-card-title">Pluie depuis dernier apport :</span><span class="parcelle-card-val small" v-if="Object.keys(this.parcelle).length!=0"> {{Math.round(parcelle.spluie_depuis_dernier_apport)}}</span><span class="parcelle-card-val small" v-if="Object.keys(this.parcelle2).length!=0">{{Math.round(parcelle2.spluie_depuis_dernier_apport)}}</span></div>
              </div>
              
            </v-card>
            <v-card class="mt-5 pl-5 pt-2 pb-2">
              <v-icon small class="mr-2">
                mdi-satellite-variant
              </v-icon>
              IMAGES SATELLITES
              <div class="parcelle-card">
                <div><span class="parcelle-card-title">Date dernière image utilisée :</span><span class="parcelle-card-val small"> {{parcelle.date_derniere_image_utilisee}}</span><span class="parcelle-card-val small">{{parcelle2.date_derniere_image_utilisee}}</span></div>
                <div><span class="parcelle-card-title">Date dernière image LAI :</span><span class="parcelle-card-val small" v-if="Object.keys(this.parcelle).length!=0 && parcelle.date_derniere_image_lai!=null"> {{parcelle.date_derniere_image_lai.acquisition_date}}</span></div>
                <div><span class="parcelle-card-title">Date dernière image Chl :</span><span class="parcelle-card-val small" v-if="Object.keys(this.parcelle).length!=0 && parcelle.date_derniere_image_chl!=null"> {{parcelle.date_derniere_image_chl.acquisition_date}}</span></div>
              </div>
              
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <v-card class="mt-5 pl-5 pt-2 pb-2">
              <v-icon small class="mr-2">
                mdi-script-text-outline
              </v-icon>
              APPORTS 
              <div class="parcelle-card" style="margin-bottom:10px;margin-right:5px;">
                Dates d'apports prévus :
                <v-data-table
                  :headers="headers_livraison_conseil"
                  :items="parcelle.livraison_conseil"
                  item-key="date_apport_prevu"
                  dense
                  hide-default-footer
                >
                  <template v-slot:[`item.conseil_jm9`]="{ item }">{{ item.conseil_jm9.dose }}<br>{{ item.conseil_jm9.date }}</template>
                  <template v-slot:[`item.conseil_jm2`]="{ item }">{{ item.conseil_jm2.dose }}<br>{{ item.conseil_jm2.date }}</template>
                </v-data-table>
                <br><br>Apports minéraux réalisés:
                <template v-if="Object.keys(this.parcelleMesures).length!=0">
                  <v-simple-table
                    fixed-header
                    height=""
                    style="width:80%"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Date
                          </th>
                          <th class="text-left">
                            Dose (kgN/ha)
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="apports=getInfosApports()">
                        <tr
                          v-for="apport in apports"
                          :key="apport.date"
                        >
                          <td style="height:12px">{{ apport.date }}</td>
                          <td style="height:12px">{{ apport.dose }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
                <template v-else>Aucun</template>
              </div>

              <div class="parcelle-card">
                Irrigations:
                <template v-if="Object.keys(this.parcelle).length!=0">
                  <v-simple-table
                    fixed-header
                    height=""
                    style="width:80%"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Date
                          </th>
                          <th class="text-left">
                            Dose (mm)
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="irrigations=getInfosIrrig('tab')">
                        <tr
                          v-for="irrig in irrigations"
                          :key="irrig.date"
                        >
                          <td style="height:12px">{{ irrig.date }}</td>
                          <td style="height:12px">{{ irrig.dose }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
                <template v-else>Aucun</template>
              </div>
              
            </v-card>
          </v-col>
          <v-col cols="5">
            <v-card class="mt-5 pl-5 pt-2 pb-2">
              <v-icon small class="mr-2">
                mdi-calculator-variant-outline
              </v-icon>
              INFORMATIONS CALCUL
              <div class="parcelle-card">
                <div><span class="parcelle-card-title">WARNINGS :</span><br><div style="font-size:12px;">{{parcelle.warnings}}</div></div>
                <div><span class="parcelle-card-title">ERRORS :</span><br><div style="font-size:12px;">{{parcelle.errors}}</div></div>
              </div>
              
            </v-card>
          </v-col>
        </v-row>
        <v-btn small color="info" class="mt-2 white--text">
          <download-excel :data="parcelles" :fields="excel_fields">
              Export Excel 
              <v-icon small class="mr-2">
                mdi-download
              </v-icon>
          </download-excel>
        </v-btn>
      </div>

      <h3 v-on:click="showCalendriers= !showCalendriers">
          Calendrier
          <v-icon small class="mr-2" v-if="!showCalendriers">
            mdi-chevron-down
          </v-icon>
          <v-icon small class="mr-2" v-else>
            mdi-chevron-up
          </v-icon> </h3>
      <div v-if="showCalendriers" style="margin-bottom:20px;margin-top:20px;">
        <v-row>
          <v-col cols="8"><v-chart class="chart" style="height:250px;" :option="chartOptionsHeatMapApportsPrevus"/></v-col>
        </v-row>
        <v-row>
          <v-col cols="8"><v-chart class="chart" style="height:250px;" :option="chartOptionsHeatMap"/></v-col>
        </v-row>
        
      </div>


      <h3 v-on:click="showGraphiques = !showGraphiques">
          Graphiques
          <v-icon small class="mr-2" v-if="!showGraphiques">
            mdi-chevron-down
          </v-icon>
          <v-icon small class="mr-2" v-else>
            mdi-chevron-up
          </v-icon> </h3>
      <div v-if="showGraphiques" style="margin-bottom:20px;margin-top:20px;">
        <v-row>
          <v-col cols="8"><v-chart class="chart" :option="chartOptions"/></v-col>
          <v-col cols="4" style="font-size:12px;text-align:justify;">
            <div><b>QN sol disponible (stock d'azote dans le sol) :</b> Lorsque le stock est vide, la courbe est à 0. Attention la plante n'est pas forcément carencée si le stock est vide. C'est l'analyse des courbes de nutrition qui permet de détecter les carences d'azote dans la plante.</div>
            <br><div><b>Déficit N plante par rapport à la trajectoire optimale (niveau de nutrition azoté relatif) :</b> Lorsqu'il est négatif la plante manque d'azote. S'il est positif, cela signifie que la plante est en confort azoté. Si la courbe orange est en-dessous de la courbe bleue, c'est à dire que le niveau de nutrition azotée de la plante est inférieur au niveau minimum tolérable, alors cela pourrait générer des pertes de rendement.</div>
            <br><div><b>Déficit tolérable (niveau de nutrition azotée minimal) :</b> Niveau de carence que la plante peut subir sans perte de rendement. Réaliser un apport avant de franchir la date limite si les conditions météo le permettent.</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8"><v-chart class="chart" :option="chartOptionsLAI"/></v-col>
        </v-row>
        <v-row>
          <v-col cols="8"><v-chart class="chart" :option="chartOptionsNabs"/></v-col>
        </v-row>
        <v-row>
          <v-col cols="8"><v-chart class="chart" :option="chartOptionsINN"/></v-col>
        </v-row>
        <v-row>
          <v-col cols="8"><v-chart class="chart" :option="chartOptionsDeficit"/></v-col>
        </v-row>
        <v-row>
          <v-col cols="8"><v-chart class="chart" :option="chartOptionsDelta"/></v-col>
        </v-row>
        
      </div>


      <div style="margin-bottom:20px;">
        <h3 v-on:click="showValidation = !showValidation">
          Validation/Corrections 
          <v-icon small class="mr-2" v-if="!showValidation">
            mdi-chevron-down
          </v-icon>
          <v-icon small class="mr-2" v-else>
            mdi-chevron-up
          </v-icon> </h3>
        <div v-if="showValidation">
          <v-card style="padding:10px;margin: 10px 0px;">
            Informations complémentaires sur la parcelle :
            <div>RU Sol : {{parcelle.sol_reserve_utile}}</div>
            <div>Profondeur Sol : {{parcelle.sol_profondeur}}</div>
            <div>Mesures Sol :
              <table class="simple-table">
                <thead><tr><th>Grandeur</th><th>Date</th><th>Horizon</th><th>Valeur</th></tr></thead>
                <tbody v-for="element in getInfoSolMesures(parcelle.sol_mesures)" :key="element.grandeur+element.date+element.bas+element.haut"><tr><td>{{element.grandeur}}</td><td>{{element.date}}</td><td>{{element.haut}} - {{element.bas}}</td><td>{{element.valeur}}</td></tr></tbody>
              </table>
            </div>
          </v-card>
          <div>Proposition de corrections (dernière version) : 
            <div v-if="this.document_validation.length>0">
              <a :href="'data:@file/html;base64,'+this.document_validation[0]['base64']" :download="this.document_validation[0]['nom_fichier']+'.html'">{{this.document_validation[0]['nom_fichier']}}</a>
            </div>
            <div v-else>
              <span>AUCUN</span>
            </div>
          </div>
          <div>Masque utilisé : <span v-if="parcelle.masque_correctif_calcul!=null" style="font-weight:bold">{{truncate_masque_correctif_calcul(parcelle.masque_correctif_calcul)}}</span><span style="font-weight:bold" v-else>AUCUN</span></div>
          <div>Masque à venir : <span v-if="parcelle.masque_correctif_a_venir!=null" style="font-weight:bold">{{truncate_masque_correctif_calcul(parcelle.masque_correctif_a_venir)}}</span><span style="font-weight:bold" v-else>AUCUN</span></div>
          <v-btn 
            v-if="parcelle.masque_correctif_calcul && parcelle.masque_correctif_calcul.length>limit_longueur_text"
            small
            @click="limit_longueur_text=1000000"
            type="submit"
          >
            Voir tout
          </v-btn>
          <br>
          <div class="mt-10">

            <div v-if="parcelle.flag!=null">
              <v-icon :color="getInfoValidation(parcelle,'color')">
                mdi-alert
              </v-icon>
              <span :style="'width:400px;color: ' + getInfoValidation(parcelle,'color')" v-html="getInfoValidation(parcelle,'phrase')" :color="getInfoValidation(parcelle,'color')"></span>
            </div>
            
            <!--<v-btn
              color="info"
              class="mt-2 white--text"
              small
              @click="saveChoixCorrection"
              type="submit"
            >
              Corriger la parcelle
            </v-btn>-->
            
            <div class="mt-7">Dates des dernières corrections : {{ parcelle.corrections }}</div>
            
            <v-btn
              color=""
              class="mt-2"
              small
              @click="afficheHistorique = !afficheHistorique"
              type="submit"
            >Afficher l'historique
            </v-btn>
            <v-btn small color="info" class="mt-2 white--text">
              <download-excel :data="parcelle.tab_historique" :fields="excel_fields_historique">
                Exporter l'historique
                <v-icon small class="mr-2">
                  mdi-download
                </v-icon>
            </download-excel>
            </v-btn>
            <v-row v-if="afficheHistorique">
              <v-col cols="8">
                <v-data-table
                :headers="headers_historique"
                :items="parcelle.tab_historique"
                item-key="date"
                dense
                >
                  <template v-slot:[`item.flag_affiche`]="{ item }">
                    <span v-if="item.flag!=null">
                      <v-icon color="red">
                        mdi-flag-variant
                      </v-icon>
                      ({{ item.flag.stade_repere }})
                      <v-tooltip bottom v-if="item.flag!=null">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small class="mr-2" v-bind="attrs" v-on="on">
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span style="width:400px;">{{item.flag.indicateurs}}</span>
                      </v-tooltip>
                    </span>
                  </template>
                  <template v-slot:[`item.correction_affiche`]="{ item }">
                    <span v-if="item.correction!=null">
                      <v-icon >
                        mdi-wrench
                      </v-icon>
                      Levier : {{item.correction.levier}} / Niveau : {{item.correction.niveau}} / ecart_qn_jour_j : {{item.correction.ecart_qn_jour_j}}
                    </span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            

          </div>
        </div>

      </div>

    </v-container>

  </div>
</template>

<script>
import downloadExcel from "vue-json-excel";
import parcellesChnService from "@/service/parcellesChn";
import VChart from "vue-echarts";
import { required, digits, email, max, regex, min, max_value, min_value, is_not } from 'vee-validate/dist/rules'
import { extend, setInteractionMode } from 'vee-validate'

extend('required', {
  ...required,
  message: '{_field_} ne peut pas être vide',
})
extend('min', min);
extend('max', max);
extend('max_value', max_value);
extend('min_value', min_value);
extend('is_not', is_not);


export default {
  name: "DetailParcelle_CHN",
  components: {
    downloadExcel,
    parcellesChnService,
    VChart,
  },
  data() {
    return {
      showValidation : false,
      showDetailParcelle : false,
      showGraphiques : true,
      showCalendriers : true,
      document_validation: [],
      //type_correction : null,
      validation_correction : null,
      //niveau_correction : null,
      limit_longueur_text : 400,
      liste_correction : [
        {nom_BDD : 'Frein externe LAI', nom_interface : 'LAI'},
        {nom_BDD : 'RU', nom_interface : 'Réserve utile'},
        {nom_BDD : 'Date stade E1C', nom_interface : 'Décalage épi 1cm'},
        {nom_BDD : 'Reliquat N', nom_interface : 'Reliquat'},
        {nom_BDD : 'NeoStock', nom_interface : 'Néostock'}
      ],
      headers_livraison_conseil: [
        { text: "Date prévue", value: "date_apport_prevu", width: '115px'},
        { text: "1ère livraison", value: "conseil_jm9", width: '210px'},
        { text: "2ème livraison", value: "conseil_jm2"}
      ],
      afficheHistorique: false,
      headers_historique: [
        { text: "Date", value: "date", width: '110px'},
        { text: "Flag", value: "flag_affiche", width: '210px'},
        { text: "Correction", value: "correction_affiche"}
      ],
      excel_fields_historique: {
        date: 'date',
        flag_stade_repere: 'flag.stade_repere',
        correction_levier: 'correction.levier',
        correction_niveau: 'correction.niveau',
        correction_ecart_qn_jour_j: 'correction.ecart_qn_jour_j'
      },
      scenarii: {},
      parcelle : {},
      parcelleMesures : {},
      delta_evolution : {},
      //dernieresValeursSol : {},
      parcelle2 : {},
      dates_calcul: [],
      selectedDate: null,
      selectedDate2: null,
      parcelles:[],
      excel_fields: {
        uuid_plot: 'uuid_plot',
        uuid_user: 'uuid_user',
        service: 'service',
        creation_timestamp: 'creation_timestamp',
        last_update_timestamp: 'last_update_timestamp',
        calcul_start_timestamp: 'calcul_start_timestamp',
        calcul_end_timestamp: 'calcul_end_timestamp',
        farmer: 'farmer',
        technician: 'technician',
        codeespece: 'codeespece',
        codebasesol: 'codebasesol',
        sol_profondeur: 'sol_profondeur',
        sol_reserve_utile: 'sol_reserve_utile',
        sol_profondeur_drains: 'sol_profondeur_drains',
        bilanhydrique: 'bilanhydrique',
        no3_eau_irrigation: 'no3_eau_irrigation',
        longitude: 'longitude',
        latitude: 'latitude',
        altitude: 'altitude',
        optiontoutfrequentiel: 'optiontoutfrequentiel',
        crop_management: 'crop_management',
        zam: 'zam',
        variete: 'variete',
        semis_date: 'semis_date',
        semis_densite: 'semis_densite',
        semis_profondeur: 'semis_profondeur',
        pasture: 'pasture',
        scheduled_interventions: 'scheduled_interventions',
        contexte_chn: 'contexte_chn',
        bc_varietal: 'bc_varietal',
        parcelle_irriguee: 'parcelle_irriguee',
        dfp_date: 'dfp_date',
        validite: 'validite',
        _semis_date: '_semis_date',
        codesolbase: 'codesolbase',
        departement: 'departement',
        epi1cm_date: 'epi1cm_date',
        numanomalie: 'numanomalie',
        numvalidite: 'numvalidite',
        reserveutile: 'reserveutile',
        unnoeud_date: 'unnoeud_date',
        codeprecedent: 'codeprecedent',
        epiaison_date: 'epiaison_date',
        site_ppv1code: 'site_ppv1code',
        dateleveecipan: 'dateleveecipan',
        floraison_date: 'floraison_date',
        horizon1_csurn: 'horizon1_csurn',
        horizon2_csurn: 'horizon2_csurn',
        deuxnoeuds_date: 'deuxnoeuds_date',
        libelleanomalie: 'libelleanomalie',
        codeculturecipan: 'codeculturecipan',
        profondeurmaximale: 'profondeurmaximale',
        profondeurdesdrains: 'profondeurdesdrains',
        datedestructioncipan: 'datedestructioncipan',
        daterecolteprecedent: 'daterecolteprecedent',
        quantiteresiduscipan: 'quantiteresiduscipan',
        destinationresiduscipan: 'destinationresiduscipan',
        teneurazoteresiduscipan: 'teneurazoteresiduscipan',
        rendementrecolteprecedent: 'rendementrecolteprecedent',
        spluie_depuis_dernier_apport: 'spluie_depuis_dernier_apport',
        horizon1_tauxmatiereorganique: 'horizon1_tauxmatiereorganique',
        horizon2_tauxmatiereorganique: 'horizon2_tauxmatiereorganique',
        quantiteresidusrecolteprecedent: 'quantiteresidusrecolteprecedent',
        destinationresidusrecolteprecedent: 'destinationresidusrecolteprecedent',
        teneurazoteresidusrecolteprecedent: 'teneurazoteresidusrecolteprecedent',
        profondeurincorporationresiduscipan: 'profondeurincorporationresiduscipan',
        profondeurincorporationresidusrecolteprecedent: 'profondeurincorporationresidusrecolteprecedent',
        LAI_jplus10: 'LAI_jplus10',
        date_jplus10: 'date_jplus10',
        module_urgence: 'module_urgence',
        dose_preconisee: 'dose_preconisee',
        Preco_declenchement: 'Preco_declenchement',
        date_epuisement_n_sol: 'date_epuisement_n_sol',
        biomasseaerienne_jplus10: 'biomasseaerienne_jplus10',
        milieu_contextualisation: 'milieu_contextualisation',
        azoteaerienabsorbe_jplus10: 'azoteaerienabsorbe_jplus10',
        periode_favorable_etat_5_j: 'periode_favorable_etat_5_j',
        date_franchissement_inn_min: 'date_franchissement_inn_min',
        trajectoire_inn_contextualisation: 'trajectoire_inn_contextualisation',
        qn_sol_prochaine_periode_favorable: 'qn_sol_prochaine_periode_favorable',
        biomasseaeriennepotentielle_jplus10: 'biomasseaeriennepotentielle_jplus10',
        deficit_prochaine_periode_favorable: 'deficit_prochaine_periode_favorable',
        date_fin_prochaine_periode_favorable: 'date_fin_prochaine_periode_favorable',
        date_debut_prochaine_periode_favorable: 'date_debut_prochaine_periode_favorable',
        date_deficit_prochaine_periode_favorable: 'date_deficit_prochaine_periode_favorable',
        deficit_tolerable_prochaine_periode_favorable: 'deficit_tolerable_prochaine_periode_favorable',
        date_dernier_apport: 'date_dernier_apport',
        dose_dernier_apport: 'dose_dernier_apport',
      },
      chartOptions: {
        title: {
          text: 'Etat de nutrition azotée'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['QN sol disponible', 'Déficit N plante par rapport à la trajectoire optimale', 'Déficit tolérable', 'Stades', 'Apports réalisés', 'Apports prévus'],
          y: 'bottom'
        },
        grid: {
          left: '8%',
          right: '4%',
          bottom: '25%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
        },
        yAxis: [{
            type: 'value',
            name: 'Qté d\'azote - QN (kgN/ha)',
            nameRotate: 90,
            nameLocation: 'center',
            nameGap: 30,
          },
          {
            type: 'value',
            name: 'QN sol disponible (kgN/ha)',
            nameRotate: 90,
            nameLocation: 'center',
            nameGap: 30,
        }],
        series: [
          {
            name: 'Déficit tolérable',
            type: 'line',
            data: []
          },
          {
            name: 'Déficit N plante par rapport à la trajectoire optimale',
            type: 'line',
            data: [],
            lineStyle: {color: '#ffa500'},
            itemStyle:{color:'#ffa500'},
          },
          {
            name: 'QN sol disponible',
            type: 'line',
            data: [],
            lineStyle: {color: '#ff0055'},
            itemStyle:{color:'#ff0055'},
            yAxisIndex: 1,
          },
          {
            name: 'Stades',
            type: "line",
            markLine: {
              symbol:"none",
              data: []
            },
            lineStyle: {color: '#33ce29de'},
            itemStyle:{color:'#33ce29de'},
          },
          {
            name: 'Apports réalisés',
            type: 'bar',
            data: [],
            itemStyle: {color: '#b6b3b3'},
            yAxisIndex: 1,
            barWidth:3
          },
          {
            name: 'Apports prévus',
            type: 'scatter',
            data: [],
            itemStyle: {color: '#616161'},
            tooltip: {
              show: true,
              trigger: 'item',
              formatter: function (a) {
                return a.value[2] 
              }
            },
            z:10
          }
        ],
        dataZoom: [
          {
            show: true,
            xAxisIndex: [0, 1],
            type: 'slider',
            top: '80%',
            start: 0,
            end: 100
          }
        ],
        graphic: [{
          type: 'image', // Graphic element type
            id: 'logo', // Specify which graphic element of the update when updating or deleting graphic elements, if it is not required to be ignored.
            right: 'center', // Position according to the parent element (in the middle)
            bottom: '55%', // According to the parent element (0%), if the value of the bottom is 0, the BOTTOM property value can also be deleted.
            z: 0,  // laminated
            bounding: 'all', // Decide how this graphic element is positioned, the method of calculating its own enclosure
            style: {
                image: require('@/assets/logo-arvalis-color.png'), // Here, it must be noted that attention must be a picture path address at the beginning of HTTPS.
                width: 165,
                height: 32
            }
        }]
      },
      chartOptionsLAI: {
        title: {
          text: 'Cinétique LAI'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['LAI', 'LAI potentiel', 'Stades', 'LAI satellite'],
          y: 'bottom'
        },
        grid: {
          left: '8%',
          right: '4%',
          bottom: '25%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
        },
        yAxis: [{
            type: 'value',
            name: 'Indice',
            nameRotate: 90,
            nameLocation: 'center',
            nameGap: 30,
            axisLine : {
              lineStyle : {color: '#AAB7B8'}
            },
            splitLine: {
                lineStyle: {
                    color: '#AAB7B8'
                }
            }
          }],
        series: [
          {
            name: 'LAI',
            type: 'line',
            data: [],
            lineStyle: {color: '#0066CC'},
            itemStyle:{color:'#0066CC'},
          },
          {
            name: 'LAI potentiel',
            type: 'line',
            data: [],
            lineStyle: {color: '#8CC6FF'},
            itemStyle:{color:'#8CC6FF'},
          },
          {
            name: 'Stades',
            type: "line",
            markLine: {
              symbol:"none",
              data: []
            },
            lineStyle: {color: '#33ce29de'},
            itemStyle:{color:'#33ce29de'},
          },
          {
            name: 'LAI satellite',
            type: 'scatter',
            data: [],
            lineStyle: {color: '#ffa500'},
            itemStyle:{color:'#ffa500'},
          }
        ],
        dataZoom: [
          {
            show: true,
            xAxisIndex: [0, 1],
            type: 'slider',
            top: '80%',
            start: 0,
            end: 100
          }
        ],
        graphic: [{
          type: 'image', // Graphic element type
            id: 'logo', // Specify which graphic element of the update when updating or deleting graphic elements, if it is not required to be ignored.
            right: 'center', // Position according to the parent element (in the middle)
            bottom: '55%', // According to the parent element (0%), if the value of the bottom is 0, the BOTTOM property value can also be deleted.
            z: 0,  // laminated
            bounding: 'all', // Decide how this graphic element is positioned, the method of calculating its own enclosure
            style: {
                image: require('@/assets/logo-arvalis-color.png'), // Here, it must be noted that attention must be a picture path address at the beginning of HTTPS.
                width: 165,
                height: 32
            }
        }]
      },
      chartOptionsNabs: {
        title: {
          text: 'Cinétique Nabs'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['Stades', 'Nabs potentiel', 'Nabs'],
          y: 'bottom'
        },
        grid: {
          left: '8%',
          right: '4%',
          bottom: '25%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
        },
        yAxis: [{
            type: 'value',
            name: 'Indice',
            nameRotate: 90,
            nameLocation: 'center',
            nameGap: 30,
            axisLine : {
              lineStyle : {color: '#AAB7B8'}
            },
            splitLine: {
                lineStyle: {
                    color: '#AAB7B8'
                }
            }
          }],
        series: [
          {
            name: 'Stades',
            type: "line",
            markLine: {
              symbol:"none",
              data: []
            },
            lineStyle: {color: '#33ce29de'},
            itemStyle:{color:'#33ce29de'},
          },
          {
            name: 'Nabs potentiel',
            type: 'line',
            data: [],
            lineStyle: {color: '#f7e639'},
            itemStyle:{color:'#f7e639'}
          },
          {
            name: 'Nabs',
            type: 'line',
            data: [],
            lineStyle: {color: '#ee6c27'},
            itemStyle:{color:'#ee6c27'}
          },
        ],
        dataZoom: [
          {
            show: true,
            xAxisIndex: [0, 1],
            type: 'slider',
            top: '80%',
            start: 0,
            end: 100
          }
        ],
        graphic: [{
          type: 'image', // Graphic element type
            id: 'logo', // Specify which graphic element of the update when updating or deleting graphic elements, if it is not required to be ignored.
            right: 'center', // Position according to the parent element (in the middle)
            bottom: '55%', // According to the parent element (0%), if the value of the bottom is 0, the BOTTOM property value can also be deleted.
            z: 0,  // laminated
            bounding: 'all', // Decide how this graphic element is positioned, the method of calculating its own enclosure
            style: {
                image: require('@/assets/logo-arvalis-color.png'), // Here, it must be noted that attention must be a picture path address at the beginning of HTTPS.
                width: 165,
                height: 32
            }
        }]
      },
      chartOptionsINN: {
        title: {
          text: 'Cinétique INN'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['Stades', 'INN'],
          y: 'bottom'
        },
        grid: {
          left: '8%',
          right: '4%',
          bottom: '25%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
        },
        yAxis: [{
            type: 'value',
            name: 'Indice',
            nameRotate: 90,
            nameLocation: 'center',
            nameGap: 30,
            axisLine : {
              lineStyle : {color: '#AAB7B8'}
            },
            splitLine: {
                lineStyle: {
                    color: '#AAB7B8'
                }
            }
          }],
        series: [
          {
            name: 'Stades',
            type: "line",
            markLine: {
              symbol:"none",
              data: []
            },
            lineStyle: {color: '#33ce29de'},
            itemStyle:{color:'#33ce29de'},
          },
          {
            name: 'INN',
            type: 'line',
            data: [],
            lineStyle: {color: '#ffa500'},
            itemStyle:{color:'#ffa500'},
          }
        ],
        dataZoom: [
          {
            show: true,
            xAxisIndex: [0, 1],
            type: 'slider',
            top: '80%',
            start: 0,
            end: 100
          }
        ],
        graphic: [{
          type: 'image', // Graphic element type
            id: 'logo', // Specify which graphic element of the update when updating or deleting graphic elements, if it is not required to be ignored.
            right: 'center', // Position according to the parent element (in the middle)
            bottom: '55%', // According to the parent element (0%), if the value of the bottom is 0, the BOTTOM property value can also be deleted.
            z: 0,  // laminated
            bounding: 'all', // Decide how this graphic element is positioned, the method of calculating its own enclosure
            style: {
                image: require('@/assets/logo-arvalis-color.png'), // Here, it must be noted that attention must be a picture path address at the beginning of HTTPS.
                width: 165,
                height: 32
            }
        }]
      },
      chartOptionsDeficit: {
        title: {
          text: 'Déficit en eau du sol'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['RU racinaire', 'RFU racinaire', 'Déficit en eau du sol', 'Pluies', 'Irrigation', 'Stades'],
          y: 'bottom'
        },
        grid: {
          left: '8%',
          right: '4%',
          bottom: '20%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value',
          name: 'mm',
          nameRotate: 90,
          nameLocation: 'center',
          nameGap: 30,
          inverse:true
        },
        series: [
          {
            name: 'RU racinaire',
            type: 'line',
            data: [],
            lineStyle: {color: '#000'},
            showSymbol:false,
            itemStyle:{color:'#000'},
          },
          {
            name: 'RFU racinaire',
            type: 'line',
            data: [],
            lineStyle: {color: '#ff0000'},
            showSymbol:false,
            itemStyle:{color:'#ff0000'},
          },
          {
            name: 'Déficit en eau du sol',
            type: 'line',
            data: [],
            lineStyle: {color: '#0066cc'},
            showSymbol:false,
            itemStyle:{color:'#0066cc'},
          },
          {
            name: 'Pluies',
            type: 'bar',
            data: [],
            itemStyle: {color: '#62b0fe'},
            z:5
          },
          {
            name: 'Irrigation',
            type: 'bar',
            data: [],
            itemStyle: {color: '#ffaf35'}
          },
          {
            name: 'Stades',
            type: "line",
            markLine: {
              symbol:"none",
              data: []
            },
            lineStyle: {color: '#33ce29de'},
            itemStyle:{color:'#33ce29de'},
          },
        ],
        dataZoom: [
          {
            show: true,
            xAxisIndex: [0, 1],
            type: 'slider',
            top: '80%',
            start: 0,
            end: 100
          }
        ],
        graphic: [{
          type: 'image', // Graphic element type
          id: 'logo', // Specify which graphic element of the update when updating or deleting graphic elements, if it is not required to be ignored.
          right: '10%', // Position according to the parent element (in the middle)
          bottom: '70%', // According to the parent element (0%), if the value of the bottom is 0, the BOTTOM property value can also be deleted.
          z: 10,  // laminated
          bounding: 'all', // Decide how this graphic element is positioned, the method of calculating its own enclosure
          style: {
              image: require('@/assets/logo-arvalis-color.png'),
              width: 165,
              height: 32
          }
        }]
      },
      chartOptionsDelta: {
        title: {
          text: 'Cinétique Delta n'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['Delta'],
          y: 'bottom'
        },
        grid: {
          left: '8%',
          right: '4%',
          bottom: '25%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
        },
        yAxis: [{
            type: 'value',
            name: 'Delta (après-avant assimilation)',
            nameRotate: 90,
            nameLocation: 'center',
            nameGap: 30
          }],
        series: [
          {
            name: 'Delta',
            type: 'line',
            data: [],
            lineStyle: {color: '#0066CC'},
            itemStyle:{color:'#0066CC'},
          }
        ],
        dataZoom: [
          {
            show: true,
            xAxisIndex: [0, 1],
            type: 'slider',
            top: '80%',
            start: 0,
            end: 100
          }
        ],
        graphic: [{
          type: 'image', // Graphic element type
            id: 'logo', // Specify which graphic element of the update when updating or deleting graphic elements, if it is not required to be ignored.
            right: 'center', // Position according to the parent element (in the middle)
            bottom: '55%', // According to the parent element (0%), if the value of the bottom is 0, the BOTTOM property value can also be deleted.
            z: 0,  // laminated
            bounding: 'all', // Decide how this graphic element is positioned, the method of calculating its own enclosure
            style: {
                image: require('@/assets/logo-arvalis-color.png'), // Here, it must be noted that attention must be a picture path address at the beginning of HTTPS.
                width: 165,
                height: 32
            }
        }]
      },
      chartOptionsHeatMap:{
          title: {
          top: 30,
          left: 'center',
          text: 'Périodes favorables'
        },
        tooltip: {
          show: true,
          formatter: function (a) {
              return 'Date : ' + a.value[0] + '<br>Période favorable : ' + a.value[1]
            },
          },
        visualMap: [{
          pieces: [
            {min: 0, max:0, label: 'Défavorable', color: 'red', opacity:0.7},
            {min: 1, max:1, label: 'Favorable', color: 'green'},

          ],
          orient: 'horizontal',
          left: 'center',
          top : 80
        }],
        calendar: {
          top: 130,
          left: 30,
          right: 30,
          cellSize: ['auto', 13],
          range: [new Date().getFullYear()+'-01-01',new Date().getFullYear()+'-06-30'],
          itemStyle: {
            borderWidth: 0.5
          },
          yearLabel: { show: true },
          dayLabel: {
            firstDay:1, 
            nameMap : ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa']
          },
          monthLabel: {
            nameMap : ['Jan', 'Fev', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Dec']
          },
        },
        series: {
          type: 'heatmap',
          coordinateSystem: 'calendar',
          data: []
        }
      },
      chartOptionsHeatMapApportsPrevus:{
          title: {
          top: 30,
          left: 'center',
          text: 'Apports azotés'
        },
        tooltip: {
          show: true,
          formatter: function (a) {
              var info = 'Date : ' + a.value[0]
              if(a.value[2] != null){
                info += '<br>Apport réalisé : ' + a.value[2]
              }
              if(a.value[3] != null && a.value[3] != ''){
                info += '<br>Conseils : ' + a.value[3]
              }
              return info
            },
            textStyle: {width:350},
            extraCssText: "width:350px; white-space:pre-wrap;"
        },
        visualMap: [{
          dimension: 1,
          pieces: [{
              min: 0,
              max: 10,
              label: 'Apports prévus',
              color: 'green',
              opacity: 0.7
            }
          ],
          orient: 'horizontal',
          left: 'center',
          top : 80
        }],
        calendar: {
          top: 130,
          left: 30,
          right: 30,
          cellSize: ['auto', 13],
          range: [new Date().getFullYear()+'-01-01',new Date().getFullYear()+'-12-31'],
          itemStyle: {
            borderWidth: 0.5
          },
          yearLabel: { show: true },
          dayLabel: {
            firstDay:1, 
            nameMap : ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa']
          },
          monthLabel: {
            nameMap : ['Jan', 'Fev', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil', 'Aout', 'Sept', 'Oct', 'Nov', 'Dec']
          },
        },
        series: {
          type: 'heatmap',
          coordinateSystem: 'calendar',
          data: [],
          label: {
            show: true,
            color: "black",
            opacity: 1,
            fontWeight : "bold",
            formatter: (param) => {
                if (param.data[2] != null) {
                  return 'A';
                } else {
                  return '';
                } 
            }
          },
        }
      },
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },

  },
  watch: {
    parcelle: function(val) {
      if(val.length!=0){
        this.parcelles = [val, this.parcelle2];
        this.chartOptions.series[3].markLine.data = this.getTabStadesGraph(val,'nutrition_azotee')
        this.chartOptionsLAI.series[2].markLine.data = this.getTabStadesGraph(val,'nutrition_azotee')
        this.chartOptionsNabs.series[0].markLine.data = this.getTabStadesGraph(val,'nutrition_azotee')
        this.chartOptionsINN.series[0].markLine.data = this.getTabStadesGraph(val,'nutrition_azotee')
                
        this.chartOptionsDeficit.series[5].markLine.data = this.getTabStadesGraph(val,'deficit_eau')
      }
    

    },
    parcelle2: function(val) {
      this.parcelles = [this.parcelle, val];
    },
    parcelleMesures: function(val) {
      this.chartOptions.xAxis.data = this.getTabChartDaily('date'); //.toString().replace('1950','2020');
      this.chartOptions.series[0].data = this.getTabChartDaily('deltaqn_opt_inf');//val.deltaqn_opt_inf;
      this.chartOptions.series[1].data = this.getTabChartDaily('deltaqn_opt_plante');//val.deltaqn_opt_plante;
      this.chartOptions.series[2].data = this.getTabChartDaily('sn_sol_disponible');//val.sn_sol_disponible;
      this.chartOptions.series[4].data = this.getTabChartDaily('apportsmineraux');
      this.chartOptions.series[5].data = this.getTabChartApportsPrevus();
      this.chartOptionsHeatMapApportsPrevus.series.data = this.getTabChartApportsPrevusEtRealises();

      this.chartOptionsDeficit.xAxis.data = this.getTabChartDaily('date');
      this.chartOptionsDeficit.series[0].data = this.getTabChartDaily('ruracinaire');//val.ruracinaire;
      this.chartOptionsDeficit.series[1].data = this.getTabChartDaily('rfuracinaire');//val.rfuracinaire;
      this.chartOptionsDeficit.series[2].data = this.getTabChartDaily('deficiteausol');//val.deficiteausol;
      this.chartOptionsDeficit.series[3].data = this.getTabChartDaily('pluie');//val.cumul_pluies;
      this.chartOptionsDeficit.series[4].data = this.getTabChartDaily('irrigation');

      this.chartOptionsLAI.xAxis.data = this.getTabChartDaily('date');
      this.chartOptionsLAI.series[0].data = this.getTabChartDaily('lai');
      if(this.parcelleMesures.daily['laipotentiel']!=undefined){this.chartOptionsLAI.series[1].data = this.getTabChartDaily('laipotentiel')}
      this.chartOptionsLAI.series[3].data = this.getTabChartSatellite('LAI_satellite');

      this.chartOptionsINN.xAxis.data = this.getTabChartDaily('date');
      this.chartOptionsINN.series[1].data = this.getTabChartDaily('inn');
      
      this.chartOptionsNabs.xAxis.data = this.getTabChartDaily('date');
      this.chartOptionsNabs.series[1].data = this.getTabChartDaily('azoteaerienabsorbe_avant_assimilation','avant_assimilation');
      this.chartOptionsNabs.series[2].data = this.getTabChartDaily('azoteaerienabsorbe');

      this.chartOptionsHeatMap.series.data = this.getTabChartHeatMap();
    }
  },
  async mounted() {
    //recuperation des dates avant recherche des donnees de la parcelle
    this.dates_calcul = await parcellesChnService.getListeDatesCalcul(this.$route.params.id);
    this.selectedDate = this.dates_calcul[0].date_calcul;
    
    //recuperation des infos sol avant recherche des donnees de la parcelle car le chargement des donnees parcelles enclenche l'initialisation des valeurs pour les masques or il faut les donnees sol
    //this.dernieresValeursSol = await parcellesChnService.getLastSolMeasures(this.$route.params.id);
    
    this.parcelle = await parcellesChnService.getInfosParcelles(this.selectedDate,this.$route.params.id);
    this.parcelle = this.parcelle[0];
    this.parcelleMesures = await parcellesChnService.getMesuresParcelles(this.selectedDate,this.$route.params.id);

    this.document_validation = await parcellesChnService.getDocumentValidation(this.$route.params.id);

    //this.scenarii = await parcellesChnService.getScenariiCorrection(this.$route.params.id);
     
    this.delta_evolution = await parcellesChnService.getDeltaEvolution(this.$route.params.id);
    this.chartOptionsDelta.xAxis.data = this.getTabChartDelta('calcul_end_timestamp');
    this.chartOptionsDelta.series[0].data = this.getTabChartDelta('delta_n');



  },
  methods: {
    truncate_masque_correctif_calcul(masque){
      if(masque!=null && masque.length>this.limit_longueur_text){
        masque = masque.substring(0, this.limit_longueur_text)+'...'
      }
      return masque
    },
    async reloadParcelles() {
      this.parcelle = [];
      this.parcelle = await parcellesChnService.getInfosParcelles(this.selectedDate,this.$route.params.id);
      this.parcelle = this.parcelle[0];
      this.parcelleMesures = await parcellesChnService.getMesuresParcelles(this.selectedDate,this.$route.params.id);
    },
    async reloadParcelles2() {
      this.parcelle2 = [];
      this.parcelle2 = await parcellesChnService.getInfosParcelles(this.selectedDate2,this.$route.params.id);
      this.parcelle2 = this.parcelle2[0];
    },
    getTabStadesGraph(infos_parcelle,type_graph){
      position_lab = 'end'
      if(type_graph=='nutrition_azotee'){
        var position_lab = 'start'
      }
      var tab = [
        { name: 'E1C', xAxis: infos_parcelle.epi1cm_date, lineStyle: {
                  color: "#33ce29de",
                  type: 'solid',
                  width: 3}, label:{show:true,formatter: '{b}', rotate:90, position:position_lab,align:'left', offset:[7,-12]}},
        { name: '1N', xAxis: infos_parcelle.unnoeud_date, lineStyle: {
                  color: "#33ce29de",
                  type: 'solid',
                  width: 3}, label:{show:true,formatter: '{b}', rotate:90, position:position_lab,align:'left', offset:[7,-12]}},
        { name: '2N', xAxis: infos_parcelle.deuxnoeuds_date, lineStyle: {
                  color: "#33ce29de",
                  type: 'solid',
                  width: 3}, label:{show:true,formatter: '{b}', rotate:90, position:position_lab,align:'left', offset:[7,-12]}},
        { name: 'DFP', xAxis: infos_parcelle.dfp_date, lineStyle: {
                  color: "#33ce29de",
                  type: 'solid',
                  width: 3}, label:{show:true,formatter: '{b}', rotate:90, position:position_lab,align:'left', offset:[7,-12]}},
        { name: 'Epi', xAxis: infos_parcelle.epiaison_date, lineStyle: {
                  color: "#33ce29de",
                  type: 'solid',
                  width: 3}, label:{show:true,formatter: '{b}', rotate:90, position:position_lab,align:'left', offset:[7,-12]}}
      ]
      return tab
    },
    getInfosIrrig(type){
      var interventions = this.parcelle.interventions
      var irrigations = []

      if(interventions!=null){
        interventions = JSON.parse(interventions)
        interventions.map(obj => {
          if(obj.nom == 'irrigation'){
            if(type=='chart'){
              irrigations[obj.date] = obj.dose
            } else {
              irrigations.push = {date : obj.date, dose: obj.dose}
            }
            
          }
        });
      }

      return irrigations
    },
    getInfosApports(){
      var apports = []

      var tab_dates=this.parcelleMesures.daily.date;
      var tab_data = this.parcelleMesures.daily['apportsmineraux']
      
      tab_dates.map(function(value, key) {
        if(tab_data[key]!=0){
          apports.push({date : value, dose: tab_data[key]})
        }
      });

      return apports
    },
    getTabChartSatellite(libelle_info){
      var array=[]

      if(this.parcelleMesures[libelle_info]['Date'] != undefined){
        var tab_dates=this.parcelleMesures[libelle_info]['Date'];
        var tab_valeur=this.parcelleMesures[libelle_info]['Valeur'];
        tab_dates.forEach(function callback(value, index) {
          array.push([value,tab_valeur[index]]);
        });
      }
      
      return array
    },
    getTabChartDaily(libelle_info, type=null){
      var array=[]

      var tableToUse = null
      var tab_dates = null
      if(type == 'avant_assimilation'){
        tableToUse = this.parcelleMesures.daily_avant_assimilation
        tab_dates=tableToUse.date_avant_assimilation;
      } else {
        tableToUse = this.parcelleMesures.daily
        tab_dates=tableToUse.date;
      }

    
      var date_semis = this.parcelle.semis_date
      var tab_data = [];

      if(libelle_info == 'irrigation'){
          var tab_irrigations = this.getInfosIrrig('chart')
      } else if(libelle_info == 'deficiteausol'){
        tab_data = [];
        for(let i = 0; i < tableToUse['ruracinaire'].length; i++){
            const el = Math.max(tableToUse['ruracinaire'][i] - tableToUse['etatreserveracinaire'][i],0);
            tab_data[i] = el;
        }
      } else {
        tab_data = tableToUse[libelle_info]
      }

      tab_dates.map(function(value, key) {
        //conservation des donnees uniquement apres semis
        if(new Date(value)>=new Date(date_semis)){
          if(libelle_info!='irrigation'){
            //alert(libelle_info+key+tab_data[key])
            array.push(tab_data[key])
          } else {
            if(tab_irrigations[value]!=undefined){
              array.push(tab_irrigations[value])
            } else {
              array.push(0)
            }
          }
        }
      });

      return array
    },
    getTabChartDelta(libelle_info){
      var array=[]

      this.delta_evolution.map(function(value, key) {
        array.push(value[libelle_info])
      });

      return array
    },
    getTabChartApportsPrevus(){
      var tab = this.parcelle.livraison_conseil.map(element => {
        var infobulle = 'Date apport prévu : ' + element.date_apport_prevu
        if(element.conseil_jm9.dose != null){
          infobulle += '<br>L1 : ' + element.conseil_jm9.dose + ' (' + element.conseil_jm9.date + ')'
        }
        if(element.conseil_jm2.dose != null){
          infobulle += '<br>L2 : ' + element.conseil_jm2.dose + ' (' + element.conseil_jm2.date + ')'
        }
        return [element.date_apport_prevu, 0, infobulle]
      });
      return tab
    },
    getTabChartApportsPrevusEtRealises(){
     
      var apports_realises = this.getInfosApports()
      var apports_prevus = this.parcelle.livraison_conseil.map(element => {
        var info_conseil = ''
        if(element.conseil_jm9.dose != null){
          info_conseil += 'L1 : ' + element.conseil_jm9.dose + ' (' + element.conseil_jm9.date + ')'
        }
        if(element.conseil_jm2.dose != null){
          info_conseil += '<br>L2 : ' + element.conseil_jm2.dose + ' (' + element.conseil_jm2.date + ')'
        }
        return {'prevu':1, 'date': element.date_apport_prevu, 'info_conseil' : info_conseil}
      });

      // Combine unique dates from both arrays
        const allDates = Array.from(new Set([
          ...apports_realises.map(item => item.date),
          ...apports_prevus.map(item => item.date)
        ]));

        // Merge data for each date
        const mergedArray = allDates.map(date => {
          // Find matching object in array1
          const item1 = apports_realises.find(item => item.date === date);
          // Find matching object in array2
          const item2 = apports_prevus.find(item => item.date === date);

          // Merge the data for the current date
          return {
            date: date,
            dose: item1 ? item1.dose : null, // Set to null if no match found
            prevu: item2 ? item2.prevu : null, // Set to null if no match found
            info_conseil: item2 ? item2.info_conseil : null // Set to null if no match found
          };
        });

      var tab = mergedArray.map(element => {
        var valeur_case = (element.prevu == 1)? 5 : -1;
        return [element.date, valeur_case, element.dose, element.info_conseil]
      });
      
      return tab
    },
    getTabChartHeatMap(){
      var array=[]

      var tab_dates=this.parcelleMesures.daily.date;

      //A SUPPRIMER POUR LA VRAIE CAMPAGNE
      //tab_dates = tab_dates.map(x => x.replace('1950','2020'))
      //tab_dates = tab_dates.map(x => x.replace('1949','2019'))
      //this.chartOptionsHeatMap.calendar.range = [(new Date().getFullYear()-2)+'-01-01',(new Date().getFullYear()-2)+'-06-30']
      //FIN A SUPPRIMER

      var tab_periodes_fav = this.parcelleMesures.daily.favorable

      tab_dates.map(function(value, key) {
        array.push([value,tab_periodes_fav[key]])
      });

      return array
    },
    /*async saveCorrection(){
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      if(this.type_correction!=null && this.niveau_correction!=null){
        var masque = await parcellesChnService.postMasque(this.parcelle.uuid_plot,{levier:this.type_correction,niveau:this.niveau_correction});
        if(masque != 0){
          this.parcelle.masque_correctif_a_venir = masque
          this.$store.dispatch('informations/setInfo', { message: {titre:"Enregistrement effectué",description:"Le masque a bien été enregistré. Il sera pris en compte lors du prochian calcul. Vous pouvez le vérifier en comparant le masque utilisé et à venir."} });
        }
      }
      this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
    },*/

    /*async saveChoixCorrection(){
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      var demande_correction = await parcellesChnService.postChoixCorrection(this.parcelle.uuid_plot);
      this.$store.dispatch('informations/setInfo', { message: {titre:"Enregistrement effectué",description:"La demande de correction a bien été enregistrée. La parcelle va être recalculée."} });
      
      this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
    },*/
    /*getReliquatDate(date){
      var index = null;
      index = this.dernieresValeursSol.date.findIndex((element) => element == date);

      var infos = {
        reliquat030: this.dernieresValeursSol.ssnk030[index],
        reliquat3060: this.dernieresValeursSol.ssnk3060[index],
        reliquat6090: this.dernieresValeursSol.ssnk6090[index]
      }

      return infos
    },*/
    getInfoSolMesures(sol_mesures){
      var infos = []
      
      if(sol_mesures!=null){
        sol_mesures = JSON.parse(sol_mesures)
        sol_mesures.forEach(element => infos.push({date: element.date, grandeur: element.grandeur, valeur: element.valeur, bas: element.bas, haut: element.haut}))
      }

      infos.sort(
        function(a, b) {          
            if (a.grandeur === b.grandeur) {
              // Date is only important when grandeur are the same
              return new Date(b.date) - new Date(a.date);
            }
            return a.grandeur > b.grandeur ? 1 : -1;
        });

      return infos

    },

    getInfoValidation(item,type){
      var color = 'transparent';
      var phrase = '';
      if(item.flag!=null && item.flag_valide==false){
        color = 'red'
        phrase = "Parcelle détectée comme étant à problème le "+item.flag.date+" (stade repère "+item.flag.stade_repere+")";
      } else if(item.flag!=null && item.flag_valide==true){
        color = 'green'
        phrase = "Parcelle corrigée après détection d'un problème le "+item.flag.date+" (stade repère "+item.flag.stade_repere+")";
      }

      if(type=='phrase'){
        return phrase;
      } else {
        return color;
      }
      
    },

    getReliquat(parcelle){
      var reliquat = [];
      if(parcelle.sol_mesures!=null){
        var sol_mesures = JSON.parse(parcelle.sol_mesures)
        sol_mesures.map(obj => {
          
          if(obj.grandeur == 'reliquatn-no3' || obj.grandeur == 'reliquatn-nh4'){
            if(reliquat.length == 0){
              reliquat.push({'date':obj.date, 'valeur':obj.valeur, 'forcage':0})
            } else {
              reliquat.forEach(objR => {
                if (objR.date === obj.date) {
                  objR.valeur += obj.valeur;
                }
              });
            }
               
          }

          if(obj.grandeur == 'forcagereliquatsazotes'){
            if(reliquat.length == 0){
              reliquat.push({'date':obj.date, 'valeur':obj.valeur, 'forcage':obj.valeur})
            } else {
              reliquat.forEach(objR => {
                if (objR.date === obj.date) {
                  objR.forcage = obj.valeur;
                }
              });
            }    
          }


        });
      }

      //on ne garde que le reliquat forcés de la date la plus proche
      const reliquat_forced_date_desc = reliquat
        .filter(obj => obj.forcage === 1)
        .sort((a, b) => new Date(b.date) - new Date(a.date)); //order desc

      if(reliquat_forced_date_desc.length > 0){
        return reliquat_forced_date_desc[0].valeur+" ("+reliquat_forced_date_desc[0].date+")"
      } else {
        return null
      }


    }

  }
  
  
};
</script>

<style scoped>
  .chart {
    height: 470px;
  }
</style>