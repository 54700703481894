<template>
  <v-container>
    <div class="mb-15">
      <v-row>
        <v-col>
          <v-switch
              v-model="demandesUtilisateur"
              label="Mes demandes"
              class="pa-3"
          ></v-switch>
        </v-col>
        <v-col>
          <v-text-field
            v-model="searchWord"
            append-icon="mdi-magnify"
            label="Recherche par mot clé"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :search="searchWord"
        sort-by="id"
        :sort-desc=true
        :loading="!dataloaded"
        loading-text="Chargement des données...."
        :headers="headers"
        :items="liste_demandes_recalage_affiches"
        item-key="id"
        class="my-awesome-table elevation-1"
        dense
        fixed-header
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon v-if="deletePossible(item)" small class="mr-2" @click="deleteItem(item)">
              mdi-delete
          </v-icon>
          <v-icon v-else small class="mr-2">
              mdi-circle-small
          </v-icon>
          <v-tooltip bottom max-width="350">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small :color="getColorValidation(item,'gestion')" class="mr-2" @click="validateRecalage(item,'gestion')" v-bind="attrs" v-on="on">
                mdi-check
              </v-icon>
            </template>
            <span v-html="getDefinitionValidation(item,'gestion')"></span>
          </v-tooltip>
          <v-tooltip bottom max-width="350">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small :color="getColorValidation(item,'integration')" class="mr-2" v-bind="attrs" v-on="on">
                mdi-check-circle-outline
              </v-icon>
            </template>
            <span v-html="getDefinitionValidation(item,'integration')"></span>
          </v-tooltip>
          <v-icon small class="mr-2" @click="duplicateRecalage(item)">
            mdi-content-duplicate
          </v-icon>
          <v-icon small class="mr-2" @click="modifyRecalage(item)">
            mdi-pencil
          </v-icon>
          <v-tooltip bottom max-width="650" v-if="item.warnings!='' && item.warnings!=null">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-2" color="red" v-bind="attrs" v-on="on">
                mdi-alert-outline
              </v-icon>
            </template>
            <span v-html="item.warnings"></span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.typerecalage`]="{ item }">
          <div v-if="item.ref_recalagecorrige!=null">{{ item.typerecalage }} [{{ item.ref_recalagecorrige }}]</div>
          <div v-else>{{ item.typerecalage }}</div>
        </template>

      </v-data-table>
      <v-btn small color="info" class="mt-2 mb-50 white--text">
        <download-excel :data="liste_demandes_recalage_actives" :fields="excel_fields" :name="getNomFichier()">
          Export Excel 
          <v-icon small class="mr-2">
            mdi-download
          </v-icon>
        </download-excel>
      </v-btn>
      <v-btn small color="info" class="mt-2 mr-2 mb-50 white--text float-right" @click="infosRecalage=null,sendDataFormRecalage()" :disabled='disabledRecalage'>
        <v-icon small class="mr-2">
          mdi-plus
        </v-icon>
        Nouvelle demande
      </v-btn>
      <v-btn v-if="checkDroitSupRecalage" small color="info" class="mt-2 mr-2 mb-50 white--text float-right" @click="integrerRecalages()">
        Intégrer les recalages validés
      </v-btn>
      <v-btn v-if="checkDroitSupRecalage" small color="info" class="mt-2 mr-2 mb-50 white--text float-right">
        <download-excel :fetch="getFichierRecalagesStades">
          Fichier recalage
          <v-icon small class="mr-2">
            mdi-download
          </v-icon>
        </download-excel>
      </v-btn>

    </div>

    <recalageFormComponent v-if="demandeRecalage" v-on:reloadRecalage="loadRecalage" :infosRecalage="infosRecalage" :key="componentKey"/>

    <div v-if="checkDroitAdmin">
      <h3 v-on:click="showVerifFichierRecalage = !showVerifFichierRecalage">
        Vérification fichier recalage
        <v-icon small class="mr-2" v-if="!showVerifFichierRecalage">
          mdi-chevron-down
        </v-icon>
        <v-icon small class="mr-2" v-else>
          mdi-chevron-up
        </v-icon>
      </h3>

      <div v-if="showVerifFichierRecalage" >
        <v-row>
          <v-col cols="6">
            <v-file-input label="Fichier csv recalage" outlined dense v-model="fichierRecalage"></v-file-input>
          </v-col>
          <v-col cols="3">
            <v-btn small color="info" class="mt-2 mr-2 mb-50 white--text float-right" @click="readFichierRecalages()">
              Vérifier fichier
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div v-if="result_verifFichierRecalage.length!=0">
              <v-data-table
                :headers="headers_verifFichierRecalage"
                :items="result_verifFichierRecalage"
                item-key="line"
                dense
                fixed-header
                :hide-default-footer="true"
                disable-pagination
              />
            </div>
            <div v-if="verifFichierRecalage_fini==true && result_verifFichierRecalage.length==0">
              Le fichier est valide
            </div>
          </v-col>
          
        </v-row>
      </div>
    </div>

    <h3 v-on:click="showRecalageArchives = !showRecalageArchives">
      Recalages archivés
      <v-icon small class="mr-2" v-if="!showRecalageArchives">
        mdi-chevron-down
      </v-icon>
      <v-icon small class="mr-2" v-else>
        mdi-chevron-up
      </v-icon>
    </h3>

    <div v-if="showRecalageArchives" >
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="searchWordArchive"
          append-icon="mdi-magnify"
          label="Recherche"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :search="searchWordArchive"
        sort-by="id"
        :sort-desc=true
        :loading="!dataloaded"
        loading-text="Chargement des données...."
        :headers="headers"
        :items="liste_demandes_recalage_archives_affiches"
        item-key="id"
        class="my-awesome-table elevation-1"
        dense
        fixed-header
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom max-width="350">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small :color="getColorValidation(item,'gestion')" class="mr-2" v-bind="attrs" v-on="on">
                mdi-check
              </v-icon>
            </template>
            <span v-html="getDefinitionValidation(item,'gestion')"></span>
          </v-tooltip>
          <v-tooltip bottom max-width="350">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small :color="getColorValidation(item,'integration')" class="mr-2" v-bind="attrs" v-on="on">
                mdi-check-circle-outline
              </v-icon>
            </template>
            <span v-html="getDefinitionValidation(item,'integration')"></span>
          </v-tooltip>
          <v-icon small class="mr-2" @click="duplicateRecalage(item)">
            mdi-content-duplicate
          </v-icon>
          <v-icon small class="mr-2" @click="modifyRecalage(item)">
            mdi-pencil
          </v-icon>
          <v-tooltip bottom max-width="650" v-if="item.warnings!='' && item.warnings!=null">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-2" color="red" v-bind="attrs" v-on="on">
                mdi-alert-outline
              </v-icon>
            </template>
            <span v-html="item.warnings"></span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.typerecalage`]="{ item }">
          <div v-if="item.ref_recalagecorrige!=null">{{ item.typerecalage }} [{{ item.ref_recalagecorrige }}]</div>
          <div v-else>{{ item.typerecalage }}</div>
        </template>

      </v-data-table>
    </div>



    <h3 v-on:click="showRecalageServeur = !showRecalageServeur">
      Recalages en place
      <v-icon small class="mr-2" v-if="!showRecalageServeur">
        mdi-chevron-down
      </v-icon>
      <v-icon small class="mr-2" v-else>
        mdi-chevron-up
      </v-icon>
    </h3>

    <div v-if="showRecalageServeur" >

      <v-card style="padding:15px;margin-top: 20px;">
        Analyse des recalages en place
        <v-row>
          <v-col cols="3">
            <v-radio-group v-model="selectedTypeRecalage" row v-on:change="loadCalendrierAnalyseRecalage_all()">
              <v-radio label="montaison" value="montaison"></v-radio>
              <v-radio label="epiaison" value="epiaison"></v-radio>
            </v-radio-group>

            <v-autocomplete
              v-model="selectedDepartementsCompare"
              :items="sortDepartements"
              item-text="departement"
              item-value="departement"
              menu-props="auto"
              label="Departement(s)"
              chips
              dense
              multiple
              v-on:change="loadCalendrierAnalyseRecalage_all()"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div v-if="tableau_analyse_recalages.length>0" style="width: 100%; overflow-x: auto;">
              <h4>Recalages en production</h4>
              <table border="1" style="border-collapse: collapse;">
                <thead>
                  <tr>
                    <th style="padding:4px;">Culture</th>
                    <th style="padding:4px;">Dép.</th>
                    <th style="padding:4px;">Spécificités</th>
                    <th style="padding:3px;font-size: smaller;" v-for="period in periods" :key="period.start" v-html="period.name"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="line in tableau_analyse_recalages" :key="line.val">
                    <td>{{ line.culture }}</td>
                    <td>{{ line.departement }}</td>
                    <td v-html="line.specificite"></td>
                    <!-- Generate correct columns for each task using the custom periods -->
                    <template v-for="period in line.val">
                      <td :colspan="period.duree" v-if="period.valeur" :key="period.valeur" :style="{ backgroundColor: getTableauAnalyseColor(period.valeur) }" style="text-align:center">{{period.valeur }}</td>
                      <td v-else :colspan="period.duree" :key="period.valeur"></td> <!-- Empty cell for unassigned periods -->
                    </template>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div v-if="tableau_analyse_recalages_potentiel.length>0" style="width: 100%; overflow-x: auto;">
              <h4>Recalages saisis</h4>
              <v-icon small class="mr-2">mdi-alert-outline</v-icon> : Recalages non validés pour le moment
              <table border="1" style="border-collapse: collapse;">
                <thead>
                  <tr>
                    <th style="padding:4px;">Culture</th>
                    <th style="padding:4px;">Dép.</th>
                    <th style="padding:4px;">Spécificités</th>
                    <th style="padding:3px;font-size: smaller;" v-for="period in periods" :key="period.start" v-html="period.name"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="line in tableau_analyse_recalages_potentiel" :key="line.val">
                    <td>{{ line.culture }}</td>
                    <td>{{ line.departement }}</td>
                    <td v-html="line.specificite"></td>
                    <!-- Generate correct columns for each task using the custom periods -->
                    <template v-for="period in line.val">
                      <td :colspan="period.duree" v-if="period.valeur" :key="period.valeur" :style="{ backgroundColor: getTableauAnalyseColor(period.valeur) }" style="text-align:center">{{period.valeur }}<v-icon small class="mr-2" v-if="period.validated!==true">mdi-alert-outline</v-icon></td>
                      <td v-else :colspan="period.duree" :key="period.valeur"></td> <!-- Empty cell for unassigned periods -->
                    </template>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>

      </v-card>

      <recalageVerifComponent class="mt-15"/>

      <div style="margin-top:20px;">
        <v-data-table
          :items="recalagesServeur"
          :headers="headersRecalagesServeur"
        />
      </div>
    </div>

    <!--CLEMENT-->
    <div v-if="checkDroitAdmin">
      <h3 v-on:click="showDerniersRecalages = !showDerniersRecalages">
        Affichage des recalages actifs des 7 derniers jours (admin)
        <v-icon small class="mr-2" v-if="!showDerniersRecalages">
          mdi-chevron-down
        </v-icon>
        <v-icon small class="mr-2" v-else>
          mdi-chevron-up
        </v-icon>
      </h3>
      <div v-if="showDerniersRecalages" >
        <v-row>
          <v-col cols="3" offset="1">
            <v-btn small color="info" style="padding:3px;"
              @click="launchAfficherDerniersRecalages()">
              <v-icon small class="mr-2">
                mdi-table-eye
              </v-icon>
              Afficher le tableau <br>
              des recalages actifs
            </v-btn>
            <br><br>
            <v-btn v-if="table_recalage_created" small color="info"
              @click="confirmSendMail()">
              <v-icon small class="mr-2">
                mdi-email-arrow-right-outline
              </v-icon>
              Envoyer les <br>rapports par mail
            </v-btn>
          </v-col>
          <v-col cols="5">
            <table id="table-recalage-histo">
              <!--FUTUR TAB de recalage des 7 derniers jours -->
            </table>
          </v-col>
        </v-row>
      </div>
    </div>
    <!--FIN Ajouts CC-->

  </v-container>
</template>

<script>
import recalageFormComponent from "@/components/stade/Recalage_Stade_Form.vue";
import recalageVerifComponent from "@/components/stade/Recalage_Stade_Verif.vue";
import downloadExcel from "vue-json-excel";
import recalageService from "@/service/recalage";
import { getDate, csvToJSON } from "@/helpers/functions";
//import VChart from "vue-echarts";

export default {
  name: "Recalage_Stade",
  components: {
    recalageFormComponent,
    recalageVerifComponent,
    downloadExcel,
    recalageService,
    getDate,
    csvToJSON
  },
  data() {
    return {
      login: this.$store.getters['user/login'],
      utilisateur_droits_sup_recalages: ['danbik', 'kammob','godonl','eybald','perinc','garcce'],
      componentKey: 0,
      recalagesServeur: [],
      showRecalageArchives: false,
      showRecalageServeur: false,
      showDerniersRecalages: false,
      showVerifFichierRecalage: false,
      tableAnalyse: '',
      tableAnalyse2: '',
      selectedDepartement: null,
      selectedDepartement2: null,
      selectedTypeRecalage: null,
      droits_recalage: null,
      disabledRecalage: true,
      demandeRecalage: false,
      demandesUtilisateur: true,
      fichier_recalages: [],
      liste_demandes_recalage_affiches: [],
      liste_demandes_recalage_archives_affiches: [],
      liste_demandes_recalage_actives: [],
      liste_demandes_recalage_archives: [],
      liste_demandes_recalage_actives_utilisateur: [],
      liste_demandes_recalage_archives_utilisateur: [],
      dataloaded: false,
      searchWord: "",
      searchWordArchive: "",
      table_recalage_created : false,
      fichierRecalage: null,
      headers_verifFichierRecalage: [
        { text: 'Ligne', value: 'line'},
        { text: 'Errors', value: 'error'},
        { text: 'Warnings', value: 'warning'}
      ],
      result_verifFichierRecalage: [],
      verifFichierRecalage_fini: false,
      headers: [
        //colonnes non visibles mais permettant de faire une recherche sur le contenu
        { text: 'Ref recalage corrigé ', value: 'ref_recalagecorrige', align: ' d-none' },
        //colonnes visibles
        { text: "", value: "actions", sortable: false, width: '180px' },
        { text: 'Id', value: 'id'},
        { text: 'Culture', value: 'culture'},
        { text: 'Stade', value: 'stade'},
        { text: 'Départements', value: 'departements'},
        { text: 'Debut semis', value: 'datedebut', width:'100px'},
        { text: 'Fin semis', value: 'datefin', width:'100px'},
        { text: 'Stations', value: 'stations'},
        { text: 'Sols P.', value: 'typesolspanoramix'},
        { text: 'Sols Arvalis', value: 'typesolsarvalis'},
        { text: 'PRA', value: 'nom_pra'},
        { text: 'Demandeur', value: 'demandeur'},
        { text: 'Préco. montaison', value: 'precomontaison'},
        { text: 'Préco. épiaison', value: 'precoepiaison'},
        { text: 'Variétés', value: 'varietes'},
        { text: 'Décalage', value: 'jourdecalage'},
        { text: 'Type demande', value: 'typerecalage'},
        { text: 'Commentaire', value: 'commentaire', width:'250px'},
        { text: 'Date demande', value: 'date_demande'}
        ],
      headersRecalagesServeur: [
        { text: 'Année', value: 'annee'},
        { text: 'Espèce', value: 'espece'},
        { text: 'Décalage E1C', value: 'decalage_epi1cm'},
        { text: 'Décalage épaison', value: 'decalage_epiaison'},
        { text: 'Départements', value: 'departements'},
        { text: 'Date debut', value: 'debut_semis'},
        { text: 'Date fin', value: 'fin_semis'},
        { text: 'Montaison', value: 'montaison'},
        { text: 'Epiaison', value: 'epiaison'},
        { text: 'Type sol', value: 'type_sol'},
        { text: 'Code sol', value: 'code_sol'},
        { text: 'Station', value: 'station_meteo'},
        { text: 'PRA', value: 'PRA'},
        { text: 'Régional', value: 'regional'},
        { text: 'Commentaires', value: 'commentaires'}
      ],
      infosRecalage: null,
      excel_fields: {
        'ID':'id',
        'Type recalage':'typerecalage',
        'Ref recalage corrigé':'ref_recalagecorrige',
        'Stade':'stade',
        'Campagne':'campagne',
        'Date début':'datedebut',
        'Date fin':'datefin',
        'Type sol Panoramix':'typesolspanoramix',
        'Type sol Arvalis':'typesolsarvalis',
        'Départements':'departements',
        'Stations':'stations',
        'PRA':'pra',
        'PRA Nom':'nom_pra',
        'Culture':'culture',
        'Variétés':'varietes',
        'Variétés selon préco':'varietes_selon_preco',
        'Jour décalage':'jourdecalage',
        'Demandeur':'demandeur',
        'Date demande':'date_demande',
        'Préco montaison':'precomontaison',
        'Préco épiaison':'precoepiaison',
        'Commentaire':'commentaire',
        'Hors périmètre IR':'horsperimetre_ir'
      },
      listeRecalagesApiStades: [],
      selectedDepartementsCompare: [],


      periods: [
        { start: '09-01', end: '09-05', year: new Date().getFullYear()-1, name: '01-05<br>sept.', num: 1 },
        { start: '09-06', end: '09-10', year: new Date().getFullYear()-1, name: '06-10<br>sept.', num: 2 },
        { start: '09-11', end: '09-15', year: new Date().getFullYear()-1, name: '11-15<br>sept.', num: 3 },
        { start: '09-16', end: '09-20', year: new Date().getFullYear()-1, name: '16-20<br>sept.', num: 4 },
        { start: '09-21', end: '09-25', year: new Date().getFullYear()-1, name: '21-25<br>sept.', num: 5 },
        { start: '09-26', end: '09-30', year: new Date().getFullYear()-1, name: '26-30<br>sept.', num: 6 },
        { start: '10-01', end: '10-05', year: new Date().getFullYear()-1, name: '01-05<br>oct.', num: 7 },
        { start: '10-06', end: '10-10', year: new Date().getFullYear()-1, name: '06-10<br>oct.', num: 8 },
        { start: '10-11', end: '10-15', year: new Date().getFullYear()-1, name: '11-15<br>oct.', num: 9 },
        { start: '10-16', end: '10-20', year: new Date().getFullYear()-1, name: '16-20<br>oct.', num: 10 },
        { start: '10-21', end: '10-25', year: new Date().getFullYear()-1, name: '21-25<br>oct.', num: 11 },
        { start: '10-26', end: '10-31', year: new Date().getFullYear()-1, name: '26-31<br>oct.', num: 12 },
        { start: '11-01', end: '11-05', year: new Date().getFullYear()-1, name: '01-05<br>nov.', num: 13 },
        { start: '11-06', end: '11-10', year: new Date().getFullYear()-1, name: '06-10<br>nov.', num: 14 },
        { start: '11-11', end: '11-15', year: new Date().getFullYear()-1, name: '11-15<br>nov.', num: 15 },
        { start: '11-16', end: '11-20', year: new Date().getFullYear()-1, name: '16-20<br>nov.', num: 16 },
        { start: '11-21', end: '11-25', year: new Date().getFullYear()-1, name: '21-25<br>nov.', num: 17 },
        { start: '11-26', end: '11-30', year: new Date().getFullYear()-1, name: '26-30<br>nov.', num: 18 },
        { start: '12-01', end: '12-05', year: new Date().getFullYear()-1, name: '01-05<br>dec.', num: 19 },
        { start: '12-06', end: '12-10', year: new Date().getFullYear()-1, name: '06-10<br>dec.', num: 20 },
        { start: '12-11', end: '12-15', year: new Date().getFullYear()-1, name: '11-15<br>dec.', num: 21 },
        { start: '12-16', end: '12-20', year: new Date().getFullYear()-1, name: '16-20<br>dec.', num: 22 },
        { start: '12-21', end: '12-25', year: new Date().getFullYear()-1, name: '21-25<br>dec.', num: 23 },
        { start: '12-26', end: '12-31', year: new Date().getFullYear()-1, name: '26-31<br>dec.', num: 24 },
        { start: '01-01', end: '01-05', year: new Date().getFullYear(), name: '01-05<br>janv.', num: 25 },
        { start: '01-06', end: '01-10', year: new Date().getFullYear(), name: '06-10<br>janv.', num: 26 },
        { start: '01-11', end: '01-15', year: new Date().getFullYear(), name: '11-15<br>janv.', num: 27 },
        { start: '01-16', end: '01-20', year: new Date().getFullYear(), name: '16-20<br>janv.', num: 28 },
        { start: '01-21', end: '01-25', year: new Date().getFullYear(), name: '21-25<br>janv.', num: 29 },
        { start: '01-26', end: '01-31', year: new Date().getFullYear(), name: '26-31<br>janv.', num: 30 }
      ],
      tableau_analyse_recalages: [],
      tableau_analyse_recalages_potentiel: [],
    };
  },
  async mounted() {
    this.loadRecalage()

    if((this.$store.getters['recalage/DroitsRecalage']())==null){
      this.droits_recalage = await recalageService.getDroitsRecalage();
      this.$store.commit('recalage/defineDroitsRecalage', {droits:this.droits_recalage})
    } else {
      this.droits_recalage = this.$store.getters['recalage/DroitsRecalage']()
    }

    if(this.droits_recalage.length!=0){
      this.disabledRecalage=false
    }

    this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
    this.recalagesServeur = await recalageService.recalageServeur();
    this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });

  },

  watch: {
    demandesUtilisateur: function(val) {
      if(val==true){
        this.liste_demandes_recalage_affiches = this.liste_demandes_recalage_actives_utilisateur
        this.liste_demandes_recalage_archives_affiches = this.liste_demandes_recalage_archives_utilisateur
      } else {
        this.liste_demandes_recalage_affiches = this.liste_demandes_recalage_actives
        this.liste_demandes_recalage_archives_affiches = this.liste_demandes_recalage_archives
      }
    },
    choixSelected: function () {
      //appelle la function dont le nom est stocké dans le store
      if (this.choixSelected != "") {
        this[this.choixSelected]();
      }
    },
    showRecalageServeur: function() {
      this.getRecalagesApiStades()
    }
  },

  computed: {
    checkDroitAdmin(){
      return this.$store.getters['user/checkDroit']('ADMIN','lecture')
    },
    checkDroitSupRecalage(){
      return this.utilisateur_droits_sup_recalages.includes(this.login)
    },
    choixSelected() {
      return this.$store.state.choix.choixSelected;
    },
    sortDepartements() {
      return this.listeRecalagesApiStades.slice().sort((a, b) => a.departement.localeCompare(b.departement));
    },
  },


  methods: {
    async loadRecalage(){
      let liste_demandes_recalage_tous = await recalageService.getDemandesRecalageStades();
      this.dataloaded=true

      this.liste_demandes_recalage_archives = liste_demandes_recalage_tous.filter(demande => demande.p_archive === true);
      this.liste_demandes_recalage_actives = liste_demandes_recalage_tous.filter(demande => demande.p_archive !== true);
      this.liste_demandes_recalage_actives_utilisateur = this.liste_demandes_recalage_actives.filter(demande => demande.codeagent_demandeur == this.login);
      this.liste_demandes_recalage_archives_utilisateur = this.liste_demandes_recalage_archives.filter(demande => demande.codeagent_demandeur == this.login);
      
      this.liste_demandes_recalage_affiches = this.liste_demandes_recalage_actives_utilisateur
      this.liste_demandes_recalage_archives_affiches = this.liste_demandes_recalage_archives_utilisateur

      this.demandeRecalage = false
    },
    duplicateRecalage(item){
      item.idRef=null
      this.infosRecalage=item
      this.sendDataFormRecalage(item)
    },
    modifyRecalage(item){
      item.idRef=item.id
      this.infosRecalage=item
      this.sendDataFormRecalage(item)
    },
    sendDataFormRecalage(){
      this.demandeRecalage=false
      this.componentKey += 1;
      this.demandeRecalage=true
    },
    getNomFichier(){
      var now = new Date()
      now = getDate(now,'yyyymmdd')

      return now+'_export_demandes_recalages'
    },
    async validateRecalage(item,type){
      if(this.checkDroitSupRecalage){
        this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
        var result = await recalageService.validationRecalageStade(item.id,type);
        this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
        if(result==true){
          this.$store.dispatch('informations/setInfo', { message: {titre:"Validation effectuée",description:"La validation a bien été enregistrée."} });
        } else {
          this.$store.dispatch('errors/setError', { message: {code:"",description:"Un problème est survenu."} });
        }
      }
    },
    getColorValidation(item,type){
      var color = 'red'
      if((item.validation_gestion==true && type=='gestion') || (item.validation_integration==true && type=='integration')){
        color = 'green'
      }

      return color
    },
    getDefinitionValidation(item,type){
      var text = ''
      if(item.validation_gestion==true && type=='gestion'){
        text = 'Recalage validé'
      } else if (item.validation_gestion==null && type=='gestion'){
        text = 'Recalage en attente de validation'
      } else if (item.validation_integration==true && type=='integration'){
        text = 'Recalage intégré'
      } else if (item.validation_integration==null && type=='integration'){
        text = 'Recalage en attente d\'intégration'
      }

      return text
    },
    deletePossible(item) {
      var result = false
      if((item.codeagent_demandeur==this.login || this.checkDroitSupRecalage) && item.validation_gestion!==true){
        result = true
      }
      return result;
    },

    createTabDerniersRecalages(retour_data) {
      const table = document.getElementById("table-recalage-histo");

      if(retour_data.length==0){
        table.innerHTML = 'Aucun recalage récent';
      } else {
        let arr_obj_culture_dept = retour_data;
        const arr_data_culture_dept = arr_obj_culture_dept.map(obj => Object.values(obj));
    
        // Déclarations du contenu des cellules du tableau
          let cellText = '';

        //Nbr de lignes à construire
          const nbr_ligne = arr_data_culture_dept.length;
        //Nbr de cellules à construire
          const nbr_cell = arr_data_culture_dept[0].length;
        //Libellés des entêtes
          const libel_headers = Object.keys(arr_obj_culture_dept[0]);
        
        //Création des lignes
        for (let ligne = -1; ligne < nbr_ligne; ligne++) {
          const row = document.createElement("tr");
          
          //Création des cellules
          for (let col = 0; col < nbr_cell; col++) {
            const cell = document.createElement("td");

            if(ligne == -1 ){//Entête du tableau
              cell.classList.add("header_tab");
              if (col == 0){cellText = 'Code Culture';}
              if (col == 1){cellText = 'Code Departement';}
              if (col == 2){cellText = 'Libellé Culture';}

            } else if(ligne >-1){ // TT le Contenu du tableau
              cellText = document.createTextNode(arr_data_culture_dept[ligne][col]).nodeValue 
            }

            cell.appendChild(document.createTextNode(cellText))
            row.appendChild(cell);
          }
          table.appendChild(row);
          this.table_recalage_created = true;
          table.style.borderCollapse = "collapse";
        }
      }

    },

    async launchAfficherDerniersRecalages(){
      // Obtention des emails
      var data_reponse = await recalageService.listeDerniersRecalages();

      this.createTabDerniersRecalages(data_reponse);

    },

    async deleteItem(item){
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      var result = await recalageService.deleteRecalage(item.id);
      this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
      if(result == true){
        this.$store.dispatch('informations/setInfo', { message: {titre:"Suppression",description:'Recalage supprimé'} });
        //actualisation tableau
        this.loadRecalage()
      } else {
        this.$store.dispatch('errors/setError', { message: {code:'',description:'Une erreur est survenue'} });
      }
    },

    closeChoix() {
      this.$store.dispatch("choix/unsetChoix");
    },

    async launchEnvoyerMail(){
      // Obtention des emails
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      var data_reponse = await recalageService.envoyerMail();
      this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
      this.closeChoix()
    },

    confirmSendMail(){
      this.$store.dispatch("choix/setChoix", {
        question: "Etes-vous sûr de vouloir envoyer les mails ?",
        choix: {
          choix1: { text: "Oui", function: "launchEnvoyerMail" },
          choix2: { text: "Non", function: "closeChoix" },
        },
      });
    },

    readFichierRecalages(){
      this.verifFichierRecalage_fini = false
      if (window.FileReader) {  
        var reader = new FileReader();
        
        // Use the javascript reader object to load the contents
        // of the file in the v-model prop
        reader.readAsBinaryString(this.fichierRecalage);
        reader.onload = () => {
          //this.data = reader.result;
        }
        reader.onload = () => {

          var csv = reader.result;
          var parse_csv = csvToJSON(csv);
          this.verifFichierRecalages(JSON.stringify(parse_csv));
        
        }
        
        reader.onerror = function (evt) {
          if (evt.target.error.name == "NotReadableError") {
            alert("Le fichier ne peut pas être lu !");
          }
        };
      } else {
        alert('FileReader n\'est pas comptabible avec votre navigateur.');
      }
    },

    async verifFichierRecalages(json){
      var result = await recalageService.verifFichierRecalages(json);
      this.verifFichierRecalage_fini = true
      if(result.length!=0){
        this.result_verifFichierRecalage = result;
      }
    },

    async integrerRecalages(){
      if(this.checkDroitSupRecalage){
        this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
        var result = await recalageService.integrerRecalages();
        this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
        if(result['http_code']!=200){
          this.$store.dispatch('errors/setError', { message: {code:'',description:'Une erreur est survenue'} });
        } else {
          this.$store.dispatch('informations/setInfo', { message: {titre:"Intégration réussie",description:'Recalages intégrées dans l\'API Recalages'} });
          this.loadRecalage()
        }
      }
    },

    async getRecalagesApiStades(){
        this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
        this.listeRecalagesApiStades = await recalageService.getRecalagesApiStades();
        this.listeRecalagesApiStades.forEach(recal => {
          var recal_detail = ''
          if (recal.precomontaison !== '') {
            recal_detail += 'montaison : ' + recal.precomontaison;
          }
          if (recal.precoepiaison !== '') {
            recal_detail += 'epiaison : ' + recal.precoepiaison;
          }
          if (recal.pra !== '') {
            recal_detail += '<br>PRA : ' + recal.pra;
          }
          if (recal.station !== '') {
            recal_detail += '<br>station : ' + recal.station;
          }
          if (recal.sol_panoramix !== '') {
            recal_detail += '<br>sols P. : ' + recal.sol_panoramix;
          }
          if (recal.code_sol !== '') {
            recal_detail += '<br>sols A. : ' + recal.code_sol;
          }
          if (recal.varietes !== '' && recal.precomontaison=='' && recal.precoepiaison=='') {
            recal_detail += '<br>variétés : ' + recal.varietes;
          }

          recal.recal_detail = recal_detail
        })

        this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
    },

    async getFichierRecalagesStades(){
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      this.fichier_recalages = await recalageService.getFichierRecalagesStades();
      this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
      return this.fichier_recalages.data
    },

    loadCalendrierAnalyseRecalage_all(){
      this.loadCalendrierAnalyseRecalage()
      this.loadCalendrierAnalyseRecalage_potentiel()
    },

    loadCalendrierAnalyseRecalage(){
      
      if(this.selectedDepartementsCompare.length > 0 && this.selectedTypeRecalage!=null){
        var resultat_tableau = []

        var typeRecalage = ''
        if(this.selectedTypeRecalage == 'montaison'){
          typeRecalage = 'Epi 1 cm'
        } else if(this.selectedTypeRecalage == 'epiaison'){
          typeRecalage = 'Epiaison'
        }

        //on ne conserve que les recalages du type renseigné
        var tabRecalages = this.listeRecalagesApiStades.filter(recalage => {
          return (
            recalage.type_recalage == typeRecalage && this.selectedDepartementsCompare.includes(recalage.departement)
          );
        });

        var listeDep = [...new Set(tabRecalages.map(recal => recal['departement']))]
        var listeCulture = [...new Set(tabRecalages.map(recal => recal['culture']))]


        listeCulture.forEach( culture => {
          listeDep.forEach( dep => {

            //on ne conserve que les recalages correspondant (culture + dep)
            var tabRecalagesDepCult = tabRecalages.filter(recalage => {
              return (
                recalage.culture == culture && recalage.departement == dep
              );
            });

            var listeRecalDetail = [...new Set(tabRecalagesDepCult.map(recal => recal['recal_detail']))]

            listeRecalDetail.forEach( recalDetail => {

              //on ne conserve que les recalages correspondant (culture + dep)
              var tabRecalagesDepCultSpecifique = tabRecalagesDepCult.filter(recalage => {
                return (
                  recalage.recal_detail == recalDetail
                );
              });

              var resultDepCultSpecifique = []
              var val_recalage = null
              var compteur = 0
              this.periods.forEach( period => {
                var start_date = period.year+'-'+period.start
                //on verify si une date de debut de semis correspond à celle de la période
                var recalDebutIsPeriod = tabRecalagesDepCultSpecifique.find(recal => {
                  return recal.date_semis_debut == start_date
                })

                if(recalDebutIsPeriod){

                  if(val_recalage==null){
                    if(compteur != 0){
                      resultDepCultSpecifique.push({duree: compteur, valeur: val_recalage})
                      compteur = 0
                    }
                    val_recalage = recalDebutIsPeriod.valeur_recalage
                  }

                  if(recalDebutIsPeriod.valeur_recalage==val_recalage){                   
                    compteur += 1
                  } else {
                    resultDepCultSpecifique.push({duree: compteur, valeur: val_recalage})
                    compteur = 1
                    val_recalage = recalDebutIsPeriod.valeur_recalage
                  } 
                } else {
                  if(val_recalage == null){
                    compteur += 1
                  } else {
                    resultDepCultSpecifique.push({duree: compteur, valeur: val_recalage})
                    compteur = 1
                    val_recalage = null
                  }

                }

              });
              resultDepCultSpecifique.push({duree: compteur, valeur: val_recalage})

              resultat_tableau.push({culture: culture, departement: dep, specificite: recalDetail, val:resultDepCultSpecifique})

            });
  

          });
        });

        const resultat_tableau_groupe = resultat_tableau.reduce((acc, curr) => {
            // Find if an object with the same culture, specificite and val already exists

            const existingIndex  = acc.findIndex(item => 
                item.culture === curr.culture && item.specificite === curr.specificite && JSON.stringify(item.val) === JSON.stringify(curr.val)
            );

            if (existingIndex !== -1) {
                // Concatenate departement values
                acc[existingIndex].departement += `, ${curr.departement}`;
            } else {
                // Add a new entry if not found
                acc.push({ ...curr });
            }

            return acc;
        }, []);


        resultat_tableau_groupe.sort((a, b) => {
          // First compare by culture (ascending)
          if (a.culture > b.culture) return 1;
          if (a.culture < b.culture) return -1;

          // If culture is the same, compare by dep (ascending)
          if (a.departement > b.departement) return 1;
          if (a.departement < b.departement) return -1;

          return 0; // If both culture and dep are equal
        });

        this.tableau_analyse_recalages = resultat_tableau_groupe;

      }
      
    },

    loadCalendrierAnalyseRecalage_potentiel(){
      
      if(this.selectedDepartementsCompare.length > 0 && this.selectedTypeRecalage!=null){
        var resultat_tableau = []

        var typeRecalage = ''
        if(this.selectedTypeRecalage == 'montaison'){
          typeRecalage = 'Epi 1 cm'
        } else if(this.selectedTypeRecalage == 'epiaison'){
          typeRecalage = 'Epiaison'
        }

        //on éclate les départements
        const data = this.liste_demandes_recalage_actives.flatMap(recal => {
          const departements = recal.departements.split(',').map(d => d.trim());

          var recal_detail = ''
          if (recal.precomontaison !== '') {
            recal_detail += 'montaison : ' + recal.precomontaison;
          }
          if (recal.precoepiaison !== '') {
            recal_detail += 'epiaison : ' + recal.precoepiaison;
          }
          if (recal.pra !== '') {
            recal_detail += '<br>PRA : ' + recal.pra;
          }
          if (recal.stations !== '') {
            recal_detail += '<br>station : ' + recal.stations;
          }
          if (recal.typesolspanoramix !== '') {
            recal_detail += '<br>sols P. : ' + recal.typesolspanoramix;
          }
          if (recal.typesolsarvalis !== '') {
            recal_detail += '<br>sols A. : ' + recal.typesolsarvalis;
          }
          if (recal.varietes !== '' && recal.precomontaison=='' && recal.precoepiaison=='') {
            recal_detail += '<br>variétés : ' + recal.varietes;
          }

          return departements.map(dept => ({
            ...recal, // Spread all properties of the original object
            departement: dept, // Overwrite the departement property
            recal_detail: recal_detail
          }));
        });

        //on ne conserve que les recalages du type renseigné
        var tabRecalages = data.filter(recalage => {
          return (
            recalage.stade == typeRecalage && this.selectedDepartementsCompare.includes(recalage.departement)
          );
        });

        var listeDep = [...new Set(tabRecalages.map(recal => recal['departement']))]
        var listeCulture = [...new Set(tabRecalages.map(recal => recal['culture']))]


        listeCulture.forEach( culture => {
          listeDep.forEach( dep => {

            //on ne conserve que les recalages correspondant (culture + dep)
            var tabRecalagesDepCult = tabRecalages.filter(recalage => {
              return (
                recalage.culture == culture && recalage.departement == dep
              );
            });

            var listeRecalDetail = [...new Set(tabRecalagesDepCult.map(recal => recal['recal_detail']))]

            listeRecalDetail.forEach( recalDetail => {
              //on ne conserve que les recalages correspondant (culture + dep)
              var tabRecalagesDepCultSpecifique = tabRecalagesDepCult.filter(recalage => {
                return (
                  recalage.recal_detail == recalDetail
                );
              });

              var resultDepCultSpecifique = []
              var val_recalage = null
              var compteur = 0
              var end_date = null;
              this.periods.forEach( period => {

                if(end_date == null){

                  var start_date = period.year+'-'+period.start
                  //on verify si une date de debut de semis correspond à celle de la période
                  var recalDebutIsPeriod = tabRecalagesDepCultSpecifique.find(recal => {
                    return recal.datedebut == start_date
                  })

                  if(recalDebutIsPeriod){
                    if(compteur != 0){
                      resultDepCultSpecifique.push({duree: compteur, valeur: val_recalage, validated: true})
                      val_recalage = null
                      compteur = 0
                    }

                    var num_debut_period = this.periods.find(obj => period.year+'-'+obj.start === start_date)?.num;
                    end_date = recalDebutIsPeriod.datefin
                    var num_fin_period = this.periods.find(obj => period.year+'-'+obj.end === end_date)?.num;
                    compteur = num_fin_period - num_debut_period +1

                    val_recalage = recalDebutIsPeriod.jourdecalage
                    resultDepCultSpecifique.push({duree: compteur, valeur: val_recalage, validated: recalDebutIsPeriod.validation_gestion})
                    compteur = 0
                    val_recalage = null
                  } else {
                    if(val_recalage == null){
                      compteur += 1
                    }
                  }
                }

                if(end_date == period.year+'-'+period.end){
                  end_date = null;
                }


              });
              resultDepCultSpecifique.push({duree: compteur, valeur: val_recalage, validated: true})

              resultat_tableau.push({culture: culture, departement: dep, specificite: recalDetail, val:resultDepCultSpecifique})

            });
  

          });
        });

        const resultat_tableau_groupe = resultat_tableau.reduce((acc, curr) => {
            // Find if an object with the same culture, specificite and val already exists

            const existingIndex  = acc.findIndex(item => 
                item.culture === curr.culture && item.specificite === curr.specificite && JSON.stringify(item.val) === JSON.stringify(curr.val)
            );

            if (existingIndex !== -1) {
                // Concatenate departement values
                acc[existingIndex].departement += `, ${curr.departement}`;
            } else {
                // Add a new entry if not found
                acc.push({ ...curr });
            }

            return acc;
        }, []);


        resultat_tableau_groupe.sort((a, b) => {
          // First compare by culture (ascending)
          if (a.culture > b.culture) return 1;
          if (a.culture < b.culture) return -1;

          // If culture is the same, compare by dep (ascending)
          if (a.departement > b.departement) return 1;
          if (a.departement < b.departement) return -1;

          return 0; // If both culture and dep are equal
        });

        this.tableau_analyse_recalages_potentiel = resultat_tableau_groupe;

      }
      
    },

    getTableauAnalyseColor(value){

      const clampedValue = Math.max(-10, Math.min(10, value));

      // If value is less than or equal to -10, return red
      if (clampedValue <= -10) return 'rgb(255, 0, 0)'; // Pure red
      // If value is greater than or equal to 10, return green
      if (clampedValue >= 10) return 'rgb(0, 255, 0)';  // Pure green
      // If value is exactly 0, return white
      if (clampedValue === 0) return 'rgb(255, 255, 255)'; // Pure white

      // For values between -10 and 0, interpolate between red and white
      if (clampedValue < 0) {
        const ratio = (clampedValue + 10) / 10; // Normalize to [0, 1]
        const red = Math.round(255 * (1 - ratio)); // Red decreases
        const greenBlue = Math.round(255 * ratio); // Green and blue increase to 255 (white)
        return `rgb(255, ${greenBlue}, ${greenBlue})`;
      }

      // For values between 0 and 10, interpolate between white and green
      if (clampedValue > 0) {
        const ratio = clampedValue / 10; // Normalize to [0, 1]
        const green = Math.round(255 * ratio); // Green increases
        const redBlue = Math.round(255 * (1 - ratio)); // Red and blue decrease from 255 (white)
        return `rgb(${redBlue}, 255, ${redBlue})`;
      }



    },


  }
};
</script>

<style>
.analyseRecalage{
  border-collapse: collapse;
}

.analyseRecalage thead > tr > th {
  padding: 5px;
  text-align: center;
  border: 1px solid black;
}

.analyseRecalage tbody > tr > td {
  padding: 5px;
  text-align: center;
  border: 1px solid black;
}
/* STYLE TABLEAU RECAP RECALAGE */
#table-recalage-histo th, #table-recalage-histo td {
  color:black;
  padding:0.5rem;
  text-align: center;
  border:1px solid black;
  border-collapse: collapse;
}

#table-recalage-histo tr:first-child , #table-recalage-histo td:first-child {
  font-size: 0.7rem;
  font-weight: bolder;
}

#table-recalage-histo .header_tab{
  background-color: rgb(153, 153, 153) !important ;
  border-bottom: 1px solid dimgray;
}


</style>
