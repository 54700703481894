import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth';
import errors from './errors';
import informations from './informations';
import choix from './choix';
import notificationSnackbar from './notificationSnackbar';
import user from './user';

import chn from './chn';
import consentement from './consentement';
import mildiou from './mildiou';
import station from './station';
import bilanhydrique from './bilanhydrique';
import filter from './filter';
import recalage from './recalage';
import general from './general';
import cap from './cap';
import qn from './qn';


Vue.use(Vuex)

const modules = {
  auth, errors, informations, choix, notificationSnackbar, user, chn, consentement, mildiou,station, bilanhydrique, filter, recalage, cap, qn, general
}

export default new Vuex.Store({
  modules: modules,
  state: {
  },
  mutations: {
  },
  actions: {
    resetAllStates() {
      for (let module in modules) {
        let methodName = module + "/reset";
        if (this._actions[methodName]) {
          this.dispatch(methodName);
        }
      }
    }
  }
})
