<template>
  <div>
    <v-container style="margin-top: 10px">
      <div id="form" style="text-align: center;">
          <v-row>
            <v-col cols="3" style="padding:0.5rem">
              <v-select style="padding:0.5rem" v-model="selectedEspece" :items="listeEspece" item-text="name"
                item-value="code" menu-props="auto" label="Espèce" hide-details prepend-icon="mdi-sprout" single-line
                chips multiple dense>
              </v-select>
            </v-col>
            <v-col cols="2" style="padding:0.5rem">
              <v-autocomplete style="padding:0.5rem" v-model="selectedVariete" :items="listeVariete" item-value="value"
                item-text="value" menu-props="auto" label="Variété" hide-details prepend-icon="mdi-sprout" single-line
                multiple chips dense>
              </v-autocomplete>
            </v-col>

            <v-col cols="2" style="padding:0.5rem">
              <v-autocomplete style="padding:0.5rem" v-model="selectedMontaison" :items="listePrecoMontaison" item-value="value"
                item-text="value" menu-props="auto" label="Précocité montaison" hide-details prepend-icon="mdi-clock-time-ten-outline" single-line
                multiple chips dense>
              </v-autocomplete>
            </v-col>

            <v-col cols="2" style="padding:0.5rem">
              <v-autocomplete style="padding:0.5rem" v-model="selectedEpiaison" :items="listePrecoEpiaison" item-value="value"
                item-text="value" menu-props="auto" label="Précocité épiaison" hide-details prepend-icon="mdi-clock-time-two-outline" single-line
                multiple chips dense>
              </v-autocomplete>
            </v-col>
            
            <v-col cols="2" style="padding:0.5rem">
              <v-autocomplete style="padding:0.5rem" v-model="selectedDepartement" item-value="value" item-text="value"
                :items="listeDepartement" menu-props="auto" label="Departement" hide-details
                prepend-icon="mdi-map-marker-outline" single-line chips multiple dense></v-autocomplete>
            </v-col>
          </v-row>
  
          <v-row>
            <v-col cols="2">
              <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="date_debut" label="Date début des semis" prepend-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on" class="float w-160"></v-text-field>
                </template>
                <v-date-picker v-model="date_debut"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="date_fin" label="Date fin des semis" prepend-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on" class="float w-160"></v-text-field>
                </template>
                <v-date-picker v-model="date_fin"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3" style="padding:0.5rem">
              <v-select style="padding:0.5rem" v-model="selectedCastype" :items="listeCastype" item-text="name"
                item-value="code" menu-props="auto" label="Type de parcelles" hide-details prepend-icon="mdi-chevron-double-right" single-line
                chips multiple dense>
              </v-select>
            </v-col>
            <v-col cols="2" style="padding:0.5rem">
              <v-text-field
                v-model="codeParcelleContain"
                append-icon="mdi-magnify"
                label="Code parcelle contenant"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="2" style="padding:0.5rem">
              <v-switch
                v-model="loginOnly"
                label="Mes parcelles uniquement"
                class="pa-3"
            ></v-switch>
            </v-col>
            <v-col offset="0" cols="1">
              <v-btn style="padding:0.5rem" @click="resetFiltres()" color="info" class="h-70">
                <v-tooltip bottom max-width="350">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">
                      mdi-rotate-left
                    </v-icon>
                  </template>
                  <span>Effacer les filtres</span>
                </v-tooltip>
              </v-btn>
            </v-col>
          </v-row>
        </div>


      <h3 v-on:click="showMesParcelles = !showMesParcelles">
        Mes parcelles
        <v-icon small class="mr-2" v-if="!showMesParcelles">
          mdi-chevron-down
        </v-icon>
        <v-icon small class="mr-2" v-else>
          mdi-chevron-up
        </v-icon>
      </h3>

      <div v-if="showMesParcelles">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchWord_cap"
              append-icon="mdi-magnify"
              label="Recherche par mot clé"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

        <!--https://codepen.io/wolfpup/pen/gOadmPx-->
          <v-data-table
            :items="all_info_about_stades"
            item-key="id_plot"
            :headers="headers"
            :search="searchWord_cap"
            :items-per-page="10"
            dense
            show-select
            v-model="selected_plots"
          >
          <template v-slot:[`item.actionsxml`]="{ item }">
            <v-icon  v-if="checkCodeStation(item)" small class="mr-2" @click="generateThe3Graphs(item)">
              mdi-chart-line
            </v-icon>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="showItem(item.id_plot)">
              mdi-eye
            </v-icon>
            <v-icon v-if="proprietaireParcelle(item)" small class="mr-2" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
          </v-data-table>
        </v-card>
        <!--EXCEL-->
        <v-btn small color="info" class="mt-2 mr-5 white--text">
          <download-excel :data="all_info_about_stades" :name="getNomFichier()">
            Export Excel
            <v-icon small class="mr-2">
              mdi-download
            </v-icon>
          </download-excel>
        </v-btn>
      </div>

      <h3 v-on:click="showGenerationGraph = !showGenerationGraph">
        Génération de graphiques maladies
        <v-icon small class="mr-2" v-if="!showGenerationGraph">
          mdi-chevron-down
        </v-icon>
        <v-icon small class="mr-2" v-else>
          mdi-chevron-up
        </v-icon>
      </h3>

      <div v-if="showGenerationGraph">
        <v-btn small color="info" class="mt-2 mb-50 white--text"  @click="generateAllGraphs()" >
          <v-icon small class="mr-2">
            mdi-chart-line 
          </v-icon>
          Générer vos graphiques
        </v-btn> (parcelles sélectionnées dans le tableau)
        <div id="inputs_dates" class="row">
          <div id="inputs_dates" class="row" style="margin:12px">
            <div offset="1" cols="3">
              <v-text-field label="Rouille Jaune : Année haute" @blur="checkYearInputDate(numberValueRJHaute)" v-model="numberValueRJHaute" hide-details
                type="number" />
              <v-text-field label="Rouille Jaune : Année basse"  @blur="checkYearInputDate(numberValueRJBasse)" v-model="numberValueRJBasse" hide-details
                type="number" />
            </div>
            <div offset="1" cols="3">
              <v-text-field  label="Pietinverse : Année haute"  @blur="checkYearInputDate(numberValuePVHaute)" v-model="numberValuePVHaute" hide-details
                type="number" />
              <v-text-field label="Pietinverse : Année basse"  @blur="checkYearInputDate(numberValuePVBasse)" v-model="numberValuePVBasse" hide-details
                type="number" />
            </div>
            <div offset="1" cols="3">
              <v-text-field label="Rouille Brune : Année haute"  @blur="checkYearInputDate(numberValueRBHaute)" v-model="numberValueRBHaute" hide-details
                type="number" />
              <v-text-field label="Rouille Brune : Année basse"  @blur="checkYearInputDate(numberValueRBBasse)" v-model="numberValueRBBasse" hide-details
                type="number" />
            </div>
          </div>
        </div>
        <!--TEMP CANVAS-->
        <section id="display3Graphs" class="mt-5">
          <div class="graphMaladie" id="rouillejaune">
          <!--Futur Loaded Graph-->
          </div>
          <div class="graphMaladie" id="pietinverse">
            <!--Futur Loaded Graph-->
          </div>
          <div class="graphMaladie" id="rouillebrune">
          <!--Futur Loaded Graph-->
          </div>
        </section>
        <!--FIN TEMP CANVAS-->

        <!-- TABLEAU BSV Septoriose -->
        <br><hr style="width: 60%;text-align: center;margin: 0 auto;">
        <h4>Tableau BSV Septoriose</h4>
        <div id="septoDiv" style="margin-bottom:5px;margin-top:10px;">
          <div>
            
            <v-btn small color="info" class="mt-2 mb-50 white--text" @click="create_tab_septo()">
              <v-icon small class="mr-2">
                mdi-chart-line
              </v-icon>
              Générer tableau septoriose
            </v-btn> (parcelles sélectionnées dans le tableau) 
          </div><br>
          <div>
            <v-row v-if="table_created">
              <v-col offset="1" cols="3">
                <v-btn small color="info" class="mt-2 mb-50 white--text" @click="getImageTabSepto()">
                  <v-icon small class="mr-2">
                    mdi-chart-line
                  </v-icon>
                  Export Image
                </v-btn>
              </v-col>
            </v-row>
            <v-row id="capture-septo">
              <v-col>
            <v-row>
              <v-col offset="1" cols="10">
                <!-- FUTUR EMPLACEMENT DU TABLEAU SEPTO-->
                <table id="my-table-septo">
                </table>
              </v-col>
            </v-row>
                <v-row v-if="table_created">
                  <v-col offset="2" >
                    <span class="red" style="height:10px;padding:4px 7px;">Risque Fort +++</span>
                    <span class="orange" style="height:10px;padding:4px 7px;">Risque Modéré ++</span>
                    <span class="green" style="height:10px;padding:4px 7px;">Risque Faible --</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>
        <!--FIN tableau SEPTO-->
      </div>

      <!--  Import CSV / XLS -->
      <h3 v-on:click="showImportParcelles = !showImportParcelles">
        Import de parcelles
        <v-icon small class="mr-2" v-if="!showImportParcelles">
          mdi-chevron-down
        </v-icon>
        <v-icon small class="mr-2" v-else>
          mdi-chevron-up
        </v-icon>
      </h3>

      <div v-if="showImportParcelles" style="margin-bottom:20px;margin-top:20px;">
        <v-row>
          <v-col cols="3">
            <v-btn small color="white" class="mt-2 white--text">
              <a href="./import_parcellesPrevilis_fichierType.csv"
                download="import_parcellesPrevilis_fichierType.csv">Obtenir format csv d'import</a>
              <v-icon small class="mr-2">
                mdi-download
              </v-icon>
            </v-btn>
          </v-col>
          <v-col offset="1" cols="3">
            <div class="form-group">
              <v-file-input label="Fichier" outlined dense v-model="chosenFile"></v-file-input>

            </div>
            <p v-if="chosenFile!=null"> Le fichier actuel contient <strong>{{nbr_plot_csv}}</strong> parcelle(s).</p>
            <div>
              <v-switch
                v-model="parcelles_equipe"
                hide-details
                :label="getInfoEquipe()"
              ></v-switch>
            </div>
          </v-col>
          <v-col>
            <v-btn small color="info" class="white--text" @click="dialog=true" v-if="chosenFile!=null"> Importer
            </v-btn>
          </v-col>

        </v-row>
        <v-row>
          <v-col>
            Informations sur le remplissage du fichier :<br>
          <ul>
            <li>Colonnes : ne pas modifier les intitulés de colonnes</li>
            <li>Cas type : mettre 1 dans la colonne castype lorsqu'il s'agit de cas types, mettre 0 s'il s'agit de vraies parcelles</li>
            <li>Cultures : libellés des cultures -> ble tendre d'hiver, ble dur d'hiver, orge d'hiver, triticale</li>
            <li>Localisation : remplir soit le code station soit le code insee soit la latitude/longitude</li>
            <li>Précédent : valeurs admises -> ble tendre, ble dur, orge hiver, triticale, mais grain, mais fourrage, mais doux (le reste des libellés n'a pas d'importance)</li>
            <li>Observations : remplir la date de mesure + la mesure d'épi (si la date exacte est observée, mettre 10 en mesure d'épi)</li>
          </ul>
          <br>
          Lorsqu'on insère une parcelle avec un code_parcelle identique à une parcelle déjà créé, cela remplace les informations parcellaires (cela permet donc de modifier une parcelle).
          </v-col>
        </v-row>
          <br>
          <!--APERCU DU CSV -->
          <div>
            <v-dialog v-model="dialog">
            
              <v-card style="overflow:auto;">
                <v-toolbar color="primary" dark class="mb-5"> Aperçu du fichier à importer </v-toolbar>
                <v-card-text style="height:300px;overflow:auto;">
                   <table id="modal_table_import" style="font-size: smaller; margin:0.5rem;overflow:scroll;width:1800px!important">
                    <thead>
                      <tr>
                        <th v-for="key in parse_header" :key="key.id" @click="sortBy(key)"
                          :class="{ active: sortKey == key }">
                          {{ key | capitalize }}
                          <span class="arrow" :class="sortOrders[key] > 0 ? 'asc' : 'dsc'">
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tr v-for="csv_line in parse_csv" :key="csv_line.id">
                      <td v-for="key in parse_header" :key="key.id">
                        {{csv_line[key]}}
                      </td>
                    </tr>
                  </table> 
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn small color="info" class="mt-2 mb-50 white--text"
                    @click="launchAddPlotInApi()">
                    <v-icon small class="mr-2">
                      mdi-database-import-outline
                    </v-icon>
                    Importer les données
                  </v-btn>
                  <v-btn color="primary" text @click="dialog = false">
                    Fermer
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!--DIALOGUE FIN D IMPORT -->
          </div>
          <!--FIN APERCU DU CSV -->
      </div>
      <!-- FIN  Import CSV / XLS -->
      

      <h3 v-on:click="showAnalyse = !showAnalyse">
        Analyse
        <v-icon small class="mr-2" v-if="!showAnalyse">
          mdi-chevron-down
        </v-icon>
        <v-icon small class="mr-2" v-else>
          mdi-chevron-up
        </v-icon>
      </h3>

      <div v-if="showAnalyse" style="margin-bottom:20px;margin-top:20px;">
        <v-row>
          <v-col cols="7"><v-chart style="height:520px;" :option="chartOptions"/></v-col>
          <v-col>
            <v-switch
              v-model="clientE1C"
              label="Parcelles clients avec observation E1C"
            ></v-switch>
            <v-switch
              v-model="BSVE1C"
              label="Parcelles BSV avec observation E1C"
            ></v-switch>
            <br><v-btn small color="info" class="mt mr-5 white--text">
              <download-excel :data="all_plots_e1c" :name="getNomFichier()">
                Export Excel (clients avec obs E1C)
                <v-icon small class="mr-2">
                  mdi-download
                </v-icon>
              </download-excel>
            </v-btn>
            <br><br>
            Remarques :
            <ul>
              <li>Les filtres en haut de page sont également actifs sur les parcelles clients/BSV</li>
              <li>Les données clients doivent être prises avec précaution : il est probable que les clients mettent une observation uniquement quand le stade E1C n'est pas représentatif du terrain mais ne renseignent rien quand tout est ok.</li>
            </ul>
          </v-col>
          
        </v-row>
        <v-row>
          <v-col cols="7">
            <v-chart class="chart" style="height:350px" :option="chartOptionsStades" />
          </v-col>
        </v-row>
      </div>

    </v-container>
    
  </div>
</template>

<script>
import CommentaireComponent from "@/components/global/CommentaireSimple.vue";
import downloadExcel from "vue-json-excel";
import parcellesStadeService from "@/service/parcellesStade";
import * as echarts from 'echarts/core';
import VChart from "vue-echarts";
import { getDate } from "@/helpers/functions";
import html2canvas from 'html2canvas';

import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import parcellesStade from "../../service/parcellesStade";

export default {
  name: "Suivi_Stade_Cap",
  components: {
    CommentaireComponent,
    downloadExcel,
    parcellesStadeService,
    VChart,
    getDate

 
  },
  data() {
    return {
      droits: this.$store.state.user.user.droitsTPC,
      login : this.$store.getters['user/login'],
      showMesParcelles: true,
      showGenerationGraph: true,
      showImportParcelles: true,
      showAnalyse: true,
      dialog: false,
      dialog_api : false,
      chosenFile: null,
      searchWord_cap: '',
      selected_plots: [],
      all_info_about_stades: [],
      all_plots_e1c: [],
      liste_formulaire: null,
      date_debut:'',
      date_fin:'',
      selectedVariete: [],
      selectedEspece: [],
      selectedDepartement: [],
      selectedCastype: [0,1],
      selectedEpiaison: [],
      selectedMontaison: [],
      selectedTypeClientForGraph: [],
      codeParcelleContain: null,
      listeDepartement: [],
      listeVariete: [],
      listePrecoEpiaison: [],
      listePrecoMontaison: [],
      listeEspece: [{code:17, name:'Blé tendre'},{code:40, name:'Blé dur'},{code:27, name:'Orge d\'hiver'},{code:19, name:'Triticale'}],  
      listeCastype: [{code:0, name:'Parcelles'},{code:1, name:'Cas types'}],
      headers: [
        //colonnes visibles
        { text: "", value: "actionsxml", sortable: false, align:"center",width: '36px'},
        { text: "", value: "actions", sortable: false, align:"center",width: '80px'},
        { text: "Code parcelle", value: "code_parcelle_os"},
        { text: "Cas type", value: "castype"},
        { text: "Id parcelle", value: "id_plot"},
        { text: "Code espece", value: "codeespece"},
        { text: "Date semis", value: "date_semis"},
        { text: "Variete", value: "variete"},
        { text: "Code sol", value: "codesol"},
        { text: "Labour", value: "labour"},
        { text: "Précédent", value: "code_precedent"},
        { text: "A calculer", value: "a_calculer"},
        { text: "Date calcul", value: "date_calcul"},
        { text: "Date fin prévisions", value: "date_fin_previsions",width: '100px'},
        { text: "E1C prévu", value: "stade_epi1cm_prevu",width: '100px'},
        { text: "E1C retenu", value: "stade_epi1cm",width: '100px'},
        { text: "E1C observé", value: "e1cm_observe",width: '100px'},
        { text: "Hauteur moyenne", value: "hauteur_moyenne"},
        { text: "Code station", value: "code_station"},
        { text: "Cas type", value: "castype"},
        { text: "Precocite épiaison", value: "precocite_epiaison"},
        { text: "Précocité montaison", value: "precocite_montaison"}
      ],
      parcelles_equipe: false,
      parse_header: [],
      parse_csv: [],
      sortOrders:{},
      sortKey: '',
      nbr_plot_csv:0,
      message_api:'',
      array_message_erreur_api:[],
      arr_data_base_64:[],
      arr_data_plot_foreach_plot : [],
      arr_all_data_plot_foreach_plot: [],
      arr_data_septo:[],
      arr_correspondances : [],
      loginOnly: true,
      table_created:false,
      arr_stations: [],
      clientE1C : false,
      BSVE1C : false,

      numberValueRJHaute : 2014,
      numberValueRJBasse : 2006,
      numberValuePVHaute : 2001,
      numberValuePVBasse : 1996,
      numberValueRBHaute : 2007,
      numberValueRBBasse : 2003,

      chartOptions: {
        title: {
          text: 'Comparaison des dates calculées et observées'
        },
        legend: {
          data: ['-5 jours','+5 jours'],
          y: 'bottom'
        },
        grid: {
          left: '8%',
          right: '4%',
          bottom: '10%',
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        tooltip: {
          trigger: 'item',
          show: true,
          formatter: function (a) {
              return 'Date retenue : ' + getDate(a.value[0],'dd/mm/yyyy') + '<br>Date prévue : ' + getDate(a.value[1],'dd/mm/yyyy') + '<br>Date observée : ' + getDate(a.value[5],'dd/mm/yyyy') + ' ('+a.value[6]+'mm)' + '<br>Variété : ' + a.value[2] + '<br>Précocité montaison : ' + a.value[3] + '<br>Opérateur : ' + a.value[4] + '<br>'
            },
            textStyle: {width:350},
            extraCssText: "width:350px; white-space:pre-wrap;"
          },
        xAxis: {
            type: 'time',
            axisLabel: {
              formatter: '{yy}-{MM}-{dd}',
              showMinLabel:true,
              rotate: 35
            },
            splitLine: {
              show: true
            },
            name: 'E1C retenu',
            nameLocation: 'middle',
            nameGap: 50
        },
        yAxis: {
          type: 'time',
          axisLabel: {
            formatter: '{yy}-{MM}-{dd}',
            showMinLabel:true,
          },
          splitLine: {
            show: true
          },
          name: 'E1C prévu',
          nameLocation: 'middle',
          nameGap: 70
        },
        series: [
          {
            type: "line",
            data: [],
            lineStyle: {
              color: 'red'
            },
            showSymbol: false,
          },
          {
            name: "-5 jours",
            type: "line",
            lineStyle: {
              color: 'orange',
              type: 'dashed'
            },
            itemStyle: {
              color: 'orange',
            },
            showSymbol: false,
            data: [],
          },
          {
            name: "+5 jours",
            type: "line",
            lineStyle: {
              color: 'orange',
              type: 'dashed'
            },
            itemStyle: {
              color: 'orange',
            },
            showSymbol: false,
            data: [],
          },
          {
            name: "Comparaison",
            symbolSize: 5,
            data: [],
            type: 'scatter',
            z: 10
          }
        ],
        graphic: [{
          type: 'image', // Graphic element type
            id: 'logo', // Specify which graphic element of the update when updating or deleting graphic elements, if it is not required to be ignored.
            left: '20%', // Position according to the parent element (in the middle)
            bottom: '70%', // According to the parent element (0%), if the value of the bottom is 0, the BOTTOM property value can also be deleted.
            z: 0,  // laminated
            bounding: 'all', // Decide how this graphic element is positioned, the method of calculating its own enclosure
            style: {
                image: require('@/assets/logo-arvalis-color.png'), // Here, it must be noted that attention must be a picture path address at the beginning of HTTPS.
                width: 165,
                height: 32
            }
        }]
      },

      chartOptiXMLRouilleJ: {
          backgroundColor: '#F4F9F9',
          renderer: 'svg', // must use SVG mode
          ssr: true, // enable SSR
          width: 320, // need to specify height and width
          height: 180,
          animation: false,

          title: {
            text: '',
            textAlign: "auto",
            textStyle: {
              fontSize: 12
            }
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['Année N', 'Année N-1', 'Année Haute', 'Année Basse'],
            bottom: '15%'
          },
          grid: {
            left: '5%',
            right: '5%',
            bottom: '5%',
            containLabel: true
          },

          getConnectedDataURL: {
            // Exporting format, can be either png, or jpeg
            type: 'svg',
            // Background color of exporting image
            backgroundColor: 'white',
          },

          xAxis: {
            type: 'time',
            boundaryGap: false,
            axisLabel: {
              formatter: (function (value) {
                let label;
                var date = new Date(value)
                var month = parseInt(date.getMonth()) + 1
                if (month < 10) {
                  month = '0' + month
                }
                label = date.getDate() + "/" + month;
                return label;
              })
            }
          },
          yAxis: {
            type: 'log', //Pour avoir en Logarithm => 'log',
            max: 3,
            nameRotate: 90,
            nameLocation: 'center',
            nameGap: 30,
            inverse: false,
            axisLine: {
              lineStyle: {
                color: '#000'
              }
            },
            splitLine: {
              lineStyle: {
                color: '#000'
              }
            }
          },
          series: [{
              name: 'Année N',
              type: 'line',
              data: [],
              lineStyle: {
                color: 'black'
              },
              showSymbol: false,
              itemStyle: {
                color: 'black'
              },
              z: 4
            },
            {
              name: 'Année N-1',
              type: 'line',
              data: [],
              lineStyle: {
                color: 'blue'
              },
              showSymbol: false,
              itemStyle: {
                color: 'blue'
              },
              z: 3
            },
            {
              name: 'Année Haute',
              type: 'line',
              data: [],
              lineStyle: {
                color: 'red'
              },
              showSymbol: false,
              itemStyle: {
                color: 'red'
              },
              z: 2
            },
            {
              name: 'Année Basse',
              type: 'line',
              data: [],
              lineStyle: {
                color: 'green'
              },
              showSymbol: false,
              itemStyle: {
                color: 'green'
              },
              z: 1
            }
          ],

          graphic: [{
            type: 'image',
            id: 'logo',
            right: '50%',
            bottom: '70%',
            z: 0, // laminated
            bounding: 'all',
            style: {
              image: require('@/assets/logo-arvalis-color.png'),
              width: 100,
              height: 20
            }
          }]
        },
        chartOptiXMLPv: {
          backgroundColor: '#F4F9F9',
          renderer: 'svg', // must use SVG mode
          ssr: true, // enable SSR
          width: 320, // need to specify height and width
          height: 180,
          animation: false,

          title: [{
            textAlign: "auto",
            text: "",
            textStyle: {
              fontSize: 12
            }
          },{
            textAlign: "auto",
            bottom: "2%",
            text: "",
            textStyle: {
              fontSize: 12,
              color: "grey"
            }
          }],
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['Année N', 'Année N-1', 'Année Haute', 'Année Basse', 'E1C'],
            bottom: '15%'
          },
          grid: {
            left: '8%',
            right: '10%',
            bottom: '30%',
            containLabel: true
          },

          getConnectedDataURL: {
            // Exporting format, can be either png, or jpeg
            type: 'svg',
            // Background color of exporting image, use backgroundColor in option by default.
            backgroundColor: 'white',
          },

          xAxis: {
            type: 'time',
            boundaryGap: false,
            axisLabel: {
              formatter: (function (value) {
                let label;
                var date = new Date(value)
                var month = parseInt(date.getMonth()) + 1
                if (month < 10) {
                  month = '0' + month
                }
                label = date.getDate() + "/" + month;
                return label;
              })
            }
          },
          yAxis: {
            type: 'value', //Pour avoir en Logarithm => 'log'

            nameRotate: 90,
            nameLocation: 'center',
            nameGap: 30,
            inverse: false,
            axisLine: {
              lineStyle: {
                color: '#000'
              }
            },
            splitLine: {
              lineStyle: {
                color: '#000'
              }
            }
          },
          series: [{
              name: 'Année N',
              type: 'line',
              data: [],
              lineStyle: {
                color: 'black'
              },
              showSymbol: false,
              itemStyle: {
                color: 'black'
              },
              z: 4
            },
            {
              name: 'Année N-1',
              type: 'line',
              data: [],
              lineStyle: {
                color: 'blue'
              },
              showSymbol: false,
              itemStyle: {
                color: 'blue'
              },
              z: 3
            },
            {
              name: 'Année Haute',
              type: 'line',
              data: [],
              lineStyle: {
                color: 'red'
              },
              showSymbol: false,
              itemStyle: {
                color: 'red'
              },
              z: 2
            },
            {
              name: 'Année Basse',
              type: 'line',
              data: [],
              lineStyle: {
                color: 'green'
              },
              showSymbol: false,
              itemStyle: {
                color: 'green'
              },
              z: 1
            },
            {
              name: 'E1C',
              type: 'scatter',
              symbolSize: 8,
              itemStyle: {
                color: 'grey'
              },
              z: 5,
              data: []
            }
          ],

          graphic: [{
            type: 'image',
            id: 'logo',
            right: '55%',
            bottom: '70%',
            z: 0, // laminated
            bounding: 'all',
            style: {
              image: require('@/assets/logo-arvalis-color.png'),
              width: 100,
              height: 20
            }
          }]
        },
        chartOptiXMLRouilleB: {
          backgroundColor: '#F4F9F9',
          renderer: 'svg', // must use SVG mode
          ssr: true, // enable SSR
          width: 320, // need to specify height and width
          height: 180,
          animation: false,

          title: {
            textAlign: "auto",
            text: "",
            textStyle: {
              fontSize: 12
            }
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['Année N', 'Année N-1', 'Année Haute', 'Année Basse'],
            bottom: '15%'
          },
          grid: {
            left: '8%',
            right: '10%',
            bottom: '30%',
            containLabel: true
          },

          getConnectedDataURL: {
            // Exporting format, can be either png, or jpeg
            type: 'svg',
            // Background color of exporting image, use backgroundColor in option by default.
            backgroundColor: 'white',
          },

          xAxis: {
            type: 'time',
            boundaryGap: false,
            axisLabel: {
              formatter: (function (value) {
                let label;
                var date = new Date(value)
                var month = parseInt(date.getMonth()) + 1
                if (month < 10) {
                  month = '0' + month
                }
                label = date.getDate() + "/" + month;
                return label;
              })
            }
          },
          yAxis: {
            type: 'value', //Pour avoir en Logarithm => 'log'

            nameRotate: 90,
            nameLocation: 'center',
            nameGap: 30,
            inverse: false,
            axisLine: {
              lineStyle: {
                color: '#000'
              }
            },
            splitLine: {
              lineStyle: {
                color: '#000'
              }
            }
          },
          series: [{
              name: 'Année N',
              type: 'line',
              data: [],
              lineStyle: {
                color: 'black'
              },
              showSymbol: false,
              itemStyle: {
                color: 'black'
              },
              z: 4
            },
            {
              name: 'Année N-1',
              type: 'line',
              data: [],
              lineStyle: {
                color: 'blue'
              },
              showSymbol: false,
              itemStyle: {
                color: 'blue'
              },
              z: 3
            },
            {
              name: 'Année Haute',
              type: 'line',
              data: [],
              lineStyle: {
                color: 'red'
              },
              showSymbol: false,
              itemStyle: {
                color: 'red'
              },
              z: 2
            },
            {
              name: 'Année Basse',
              type: 'line',
              data: [],
              lineStyle: {
                color: 'green'
              },
              showSymbol: false,
              itemStyle: {
                color: 'green'
              },
              z: 1
            }
          ],
      
        graphic: [{
          type: 'image', 
          id: 'logo', 
          right: '55%', 
          bottom: '70%',
          z: 0,  // laminated
          bounding: 'all', 
          style: {
              image: require('@/assets/logo-arvalis-color.png'), 
              width: 100,
              height: 20
          }
        }]
      },
      chartOptionsStades: {
          title: {
            text: 'Répartition des parcelles selon leurs stades (%)'
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['Stade epi 1cm','Stade 1N', 'Stade 2N','Stade DFP','Stade épiaison','Stade floraison','Stade maturité'],
            y: 'bottom',
            selected: {
              'Stade 1N': true,
              'Stade 2N': true,
              'Stade DFP': true,
              'Stade épiaison': true,
              'Stade floraison': true,
              'Stade maturité': true,
              'Stade epi 1cm': true
            }
          },
          grid: {
            left: '8%',
            right: '10%',
            bottom: '30%',
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {title:"Télécharger l'image"}
            }
          },
          xAxis:  {
              type: 'category',
              boundaryGap:false,
              data: []
          },
          yAxis: {
            type: 'value',
            nameRotate: 90,
            nameLocation: 'center',
            nameGap: 30,
            inverse:false,
            axisLine : {
              lineStyle : {color: '#000'}
            },
            splitLine: {
                lineStyle: {
                    color: '#000'
                }
            }
          },
          series: [
             {
              name: 'Stade epi 1cm',
              type: 'bar',
              data: [],
              lineStyle: {color: 'rgb(0,10,0)'},
              showSymbol:false,
              itemStyle: {color: 'rgb(0,10,0)'},
            },
            {
              name: 'Stade 1N',
              type: 'bar',
              data: [],
              lineStyle: {color: 'rgb(0,35,0)'},
              showSymbol:false,
              itemStyle: {color: 'rgb(0,35,0)'},
            },
            {
              name: 'Stade 2N',
              type: 'bar',
              data: [],
              lineStyle: {color: 'rgb(0,90,0)'},
              showSymbol:false,
              itemStyle: {color: 'rgb(0,90,0)'},
            },
            {
              name: 'Stade DFP',
              type: 'bar',
              data: [],
              lineStyle: {color: 'rgb(0,145,0)'},
              showSymbol:false,
              itemStyle: {color: 'rgb(0,145,0)'},
            },
            {
              name: 'Stade épiaison',
              type: 'bar',
              data: [],
              lineStyle: {color: 'rgb(0,180,0)'},
              showSymbol:false,
              itemStyle: {color: 'rgb(0,180,0)'},
            },
            {
              name: 'Stade floraison',
              type: 'bar',
              data: [],
              lineStyle: {color: 'rgb(0,210,0)'},
              showSymbol:false,
              itemStyle: {color: 'rgb(0,210,0)'},
            },
            {
              name: 'Stade maturité',
              type: 'bar',
              data: [],
              lineStyle: {color: 'rgb(0,225,0)'},
              showSymbol:false,
              itemStyle: {color: 'rgb(0,225,0)'},
            },
            
          ],
          dataZoom: [
            {
              show: true,
              xAxisIndex: [0, 1],
              type: 'slider',
              top: '75%',
              start: 0,
              end: 100
            }
          ],
          graphic: [{
            type: 'image', // Graphic element type
            id: 'logo', // Specify which graphic element of the update when updating or deleting graphic elements, if it is not required to be ignored.
            right: 'center', // Position according to the parent element (in the middle)
            bottom: '60%', // According to the parent element (0%), if the value of the bottom is 0, the BOTTOM property value can also be deleted.
            z: 0,  // laminated
            bounding: 'all', // Decide how this graphic element is positioned, the method of calculating its own enclosure
            style: {
                image: require('@/assets/logo-arvalis-color.png'), // Here, it must be noted that attention must be a picture path address at the beginning of HTTPS.
                width: 165,
                height: 32
            }
          }]
        },

    };
  },

  watch: {
    selectedEspece: function () {
      this.FilterParcelles()
    },
    selectedVariete: function () {
      this.FilterParcelles()
    },
    selectedDepartement: function () {
      this.FilterParcelles()
    },
    date_debut: function () {
      this.FilterParcelles()
    },
    date_fin: function () {
      this.FilterParcelles()
    },
    loginOnly: function() {
      this.FilterParcelles()
    },
    selectedCastype: function() {
      this.FilterParcelles()
    },
    selectedMontaison: function() {
      this.FilterParcelles()
    },
    selectedEpiaison: function() {
      this.FilterParcelles()
    },
    codeParcelleContain: function() {
      this.FilterParcelles()
    },
    message_api: function(){
      this.resultat_message(this.message_api)
    },
    chosenFile: function(){
      this.viewCSV()
    },
    clientE1C: function() {
      this.actualizePlotsForGraph()
    },
    BSVE1C: function() {
      this.actualizePlotsForGraph()
    },

  },

  computed: {

  },

  async mounted() {
    if ((this.$store.getters['cap/ParcellesListArvalis']()).length == 0) {
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      this.all_info_about_stades = await parcellesStadeService.getInfos('arvalis');
      this.$store.commit('cap/defineParcellesListArvalis', {
        stades: this.all_info_about_stades
      });
      this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
    } else {
      this.all_info_about_stades = this.$store.getters['cap/ParcellesListArvalis']()
    }

    this.FilterParcelles();
    //STORE
    this.listeVariete = this.$store.getters['cap/DefineListSelect']('variete','arvalis');
    this.listeDepartement = this.$store.getters['cap/DefineListSelect']('code_dept','arvalis');
    this.listePrecoEpiaison = this.$store.getters['cap/DefineListSelect']('precocite_epiaison','arvalis');
    this.listePrecoMontaison = this.$store.getters['cap/DefineListSelect']('precocite_montaison','arvalis');

  },

  filters: {
    capitalize: function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  },

  methods: {
    async getE1CClient(){
      if ((this.$store.getters['cap/ParcellesListClientE1C']()).length == 0) {
        this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
        this.all_plots_e1c = await parcellesStadeService.getInfos('clients_e1c');
        this.$store.commit('cap/defineParcellesListClientE1C', {
          stades: this.all_plots_e1c
        });
        this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
      } else {
        this.all_plots_e1c = this.$store.getters['cap/ParcellesListClientE1C']()
      }
    },
    actualizePlotsForGraph(){
      this.selectedTypeClientForGraph = []
      if(this.clientE1C==false && this.BSVE1C==false){
        this.all_plots_e1c = []
      } else {
        this.getE1CClient()
        if(this.clientE1C==false && this.BSVE1C==true){
          this.selectedTypeClientForGraph = ['BSV']
        } else if (this.clientE1C==true && this.BSVE1C==false){
          this.selectedTypeClientForGraph = ['Hors-BSV']
        }
      }
      this.FilterParcelles();
    },
    async actualiseGraph() {
      var tab_comparaison_dates=[];
      var tab_all_dates=[];
      var allDataPlot =this.all_info_about_stades.concat(this.all_plots_e1c);

      allDataPlot.map(function(data) {
        //on se focalise sur les parcelles de l'année ayant des observations
        if(data.stade_epi1cm!=null && data.stade_epi1cm_prevu!=null && data.e1cm_observe!=null && new Date(data.stade_epi1cm).getTime()>=new Date(new Date().getFullYear(), 0, 1).getTime()){
          var a = new Date(data.stade_epi1cm)
          var b = new Date(data.stade_epi1cm_prevu)
          var color_precocite = {'1':' #0fff37','1.5':' #0fff37','2':' #29e331','2.5':' #29e331','3':' #42c62b','3.5':' #42c62b','4':' #5caa25','4.5':' #5caa25','5':'#758e1f','5.5':'#758e1f','6':'#8f7118','6.5':'#8f7118','7':'#a85512','7.5':'#a85512','8':'#c2390c','8.5':'#c2390c','9':'#db1c06'}
          tab_comparaison_dates.push({name: 'point',value:[a,b,data.variete,data.precocite_montaison,data.nom_operateur,new Date(data.e1cm_observe),data.hauteur_moyenne],itemStyle:{color:color_precocite[data.precocite_montaison]}});
          tab_all_dates.push(a);
          tab_all_dates.push(b);
        }    
      });

      this.chartOptions.series[3].data=tab_comparaison_dates; 

      var dateMin = new Date(Math.min(...tab_all_dates))
      var dateMin2 = new Date(dateMin) //obligé de créer nouvelle date pour que les opération suivante ne modifient pas la valeur initiale
      var dateMinDecal = new Date(dateMin2.setDate(dateMin2.getDate() - 5))
      var dateMax = new Date(Math.max(...tab_all_dates))
      var dateMax2 = new Date(dateMax) //obligé de créer nouvelle date pour que les opération suivante ne modifient pas la valeur initiale
      var dateMaxDecal = new Date(dateMax2.setDate(dateMax2.getDate() + 5))

      this.chartOptions.series[0].data=[[dateMinDecal,dateMinDecal],[dateMaxDecal,dateMaxDecal]];
      this.chartOptions.series[1].data=[[dateMin,dateMinDecal],[dateMaxDecal,dateMax]];
      this.chartOptions.series[2].data=[[dateMinDecal,dateMin],[dateMax,dateMaxDecal]];

      this.chartOptionsStades.xAxis.data = this.getTabChartDate();
      this.chartOptionsStades.series[0].data = this.getTabChartCountOf('stade_epi1cm');
      this.chartOptionsStades.series[1].data = this.getTabChartCountOf('stade_1n');
      this.chartOptionsStades.series[2].data = this.getTabChartCountOf('stade_2n');
      this.chartOptionsStades.series[3].data = this.getTabChartCountOf('stade_dfp');
      this.chartOptionsStades.series[4].data = this.getTabChartCountOf('stade_epiaison');
      this.chartOptionsStades.series[5].data = this.getTabChartCountOf('stade_floraison');
      this.chartOptionsStades.series[6].data = this.getTabChartCountOf('stade_maturite');
    },


    /**
     * Fonction servant à générer TT LES graphiques de TT les parcelles du CODE AGRI 
     */
    generateAllGraphs() {
        //Générer 3 graphs par parcelles
        this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
        var nbr_parcelles = this.selected_plots.length // ? Plusieurs parcelles à générer
        for (let i = 0; i < nbr_parcelles; i++) {

          var div_all_graphs = document.getElementById('display3Graphs');
          div_all_graphs.style.display = 'flex';

          let item_to_send = this.selected_plots[i];
          if(this.checkCodeStation(item_to_send)){
            this.generateGraphRouilleJaune(item_to_send);
            this.generateGraphPv(item_to_send);
            this.generateGraphRouilleBrune(item_to_send);
          }

        }
        this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });

      },

      /**
       * Fonction servant à générer LES 3 graphiques de LA parcelle cliquée
       */
      generateThe3Graphs(item) {
        var div_all_graphs = document.getElementById('display3Graphs');
        div_all_graphs.style.display = 'flex';
        let item_to_send = item;
        this.generateGraphRouilleJaune(item_to_send);
        this.generateGraphPv(item_to_send);
        this.generateGraphRouilleBrune(item_to_send);
      },

      /**
       * Fonction servant à générer le graphique rouille jaune de la parcelle cliquée
       */
      generateGraphRouilleJaune(item) {
        if (!this.checkCodeStation(item)) {
          alert('Code Station non conforme');
        }
        var data_item = {
          variete: item.variete,
          id_plot: item.id_plot,
          code_parcelle_os: item.code_parcelle_os,
          latitude: item.x,
          longitude: item.y,
          code_espece: item.codeespece,
          codesol: item.codesol,
          date_semis: item.date_semis,
          maladie: 'rouillejaune',
          code_station: item.code_station,
    
          annee_haute : this.numberValueRJHaute,
          annee_basse : this.numberValueRJBasse,

          annee_demandee: new Date().getFullYear(), //Prendre l'année
          annee_prec_demandee: (new Date().getFullYear())-1, //Prendre l'année precedente
          data_img: ''
        };

        this.arr_data_plot_foreach_plot.push(data_item);

        // ⁡⁢⁢⁢Données à récu⁡⁢⁢⁢perer de la parcelle⁡
        var data = {
          data_to_xml: data_item
        };

        // ⁡⁣⁢⁡⁢⁢⁢Obtention du resultat backofficeaggroboxxml<prq valeur>⁡⁡
        var data_from_xml_json = parcellesStadeService.generate_graph_rouille_jaune(data);

        // ⁡⁢⁢⁢Transformer les données dans un format utilisable par la suite⁡
        Promise.resolve(data_from_xml_json).then(value => {

          this.chartOptiXMLRouilleJ.series[0].data=[]
          this.chartOptiXMLRouilleJ.series[1].data=[]
          this.chartOptiXMLRouilleJ.series[2].data=[]
          this.chartOptiXMLRouilleJ.series[3].data=[]

          let retour_data = value;

          //Verifier le nombre de parcelle dans le retour XML
          var nbr_parcelle_xml = retour_data.surface.length;
          let NomStation = retour_data.NomStation;

          var TabDate = []
          var TabDateValeur = []
          for (let i = 0; i < nbr_parcelle_xml; i++) {
            //⁡⁢⁢⁢Pour chaque parcelle => obtenir l'ID de la parcelle
            TabDate = retour_data.date[i]
            TabDateValeur = []
            var month = ''
            var year = new Date().getFullYear()
            TabDate.forEach(function callback(valueDate, index){
              month = parseInt(valueDate.substr(valueDate.length - 2))
              if(month>=9){
                valueDate = new Date ((year-1)+'-'+month+'-'+valueDate.substr(0,2))
              } else {
                valueDate = new Date (year+'-'+month+'-'+valueDate.substr(0,2))
              }
              if(i == 0){// Correspond à l'année N qui doit s'arreter à la date du jour
                if(valueDate<new Date() && retour_data.surface[i][index]!=0){
                  TabDateValeur.push([valueDate,retour_data.surface[i][index]])
                }
              }else{// Correspond aux autres années [N-1, Haute, Basse]
                if(retour_data.surface[i][index]!=0){
                  TabDateValeur.push([valueDate,retour_data.surface[i][index]])
                }
              }

            })
            this.chartOptiXMLRouilleJ.series[i].data = TabDateValeur;
          }


          //⁡⁢⁢⁢ Faire les graphs avec les données précedements obtenue⁡s
          var plot_name = data_item.code_parcelle_os;
          if(data_item.code_parcelle_os.includes('__')){
            plot_name = data_item.code_parcelle_os.split('__')[1]
          }
          
          this.chartOptiXMLRouilleJ.title.text = ` Rouille Jaune - Parcelle : ${plot_name},\n Station ${NomStation} - Date de semis ${item.date_semis.slice(0,5)},\n Année Haute ${data_item.annee_haute} - Année Basse ${data_item.annee_basse}`;

          this.showGraphXML = true;

          this.graphToIMG(data_item);
        })

      },

      checkCodeStation(item) {

        if (item.code_station !== null) {
          return true
        }else{
          return false
        }
      },

      checkNoDuplicateBase64(current_base_64) {
        let check = this.arr_data_base_64.indexOf(current_base_64)
        if (check == -1) {
          return true
        } else {
          return false
        }
      },

      /**
       * Fonction servant à générer le graphique PV de la parcelle cliquée
       */
      generateGraphPv(item) {
        if (!this.checkCodeStation(item)) {
          alert('Code Station non conforme');
        }
        var data_item = {
          variete: item.variete,
          id_plot: item.id_plot,
          code_parcelle_os: item.code_parcelle_os,
          latitude: item.x,
          longitude: item.y,
          code_espece: item.codeespece,
          codesol: item.codesol,
          date_semis: item.date_semis,
          maladie: 'pietinverse',
          code_station: item.code_station,

          annee_haute : this.numberValuePVHaute,
          annee_basse : this.numberValuePVBasse,

          annee_demandee: new Date().getFullYear(), //Prendre l'année
          annee_prec_demandee: (new Date().getFullYear())-1, //Prendre l'année precedente
          data_img: ''
        };

        this.arr_data_plot_foreach_plot.push(data_item);

        // ⁡⁢⁢⁢Données à récu⁡⁢⁢⁢perer de la parcelle⁡
        var data = {
          data_to_xml: data_item
        };

        // ⁡⁣⁢⁡⁢⁢⁢Obtention du resultat backofficeaggroboxxml<prq valeur>⁡⁡
        var data_from_xml_json = parcellesStadeService.generate_graph_pv(data);

        // ⁡⁢⁢⁢Transformer les données dans un format utilisable par la suite⁡
        Promise.resolve(data_from_xml_json).then(value => {

          this.chartOptiXMLPv.series[0].data=[]
          this.chartOptiXMLPv.series[1].data=[]
          this.chartOptiXMLPv.series[2].data=[]
          this.chartOptiXMLPv.series[3].data=[]
          this.chartOptiXMLPv.series[4].data=[]

          let retour_data = value;

          //Verifier le nombre de parcelle dans le retour XML
          var nbr_parcelle_xml = retour_data.surface.length;
          let NomStation = retour_data.NomStation;

          var TabDate = []
          var TabDateValeur = []
          for (let i = 0; i < nbr_parcelle_xml; i++) {
            //⁡⁢⁢⁢Pour chaque parcelle => obtenir l'ID de la parcelle
            TabDate = retour_data.date[i]
            TabDateValeur = []
            var month = ''
            var year = new Date().getFullYear()
            TabDate.forEach(function callback(valueDate, index){
              month = parseInt(valueDate.substr(valueDate.length - 2))
              if(month>=9){
                valueDate = new Date ((year-1)+'-'+month+'-'+valueDate.substr(0,2))
              } else {
                valueDate = new Date (year+'-'+month+'-'+valueDate.substr(0,2))
              }
              if(i == 0){// Correspond à l'année N qui doit s'arreter à la date du jour
                if(valueDate<new Date()){
                  TabDateValeur.push([valueDate,retour_data.surface[i][index]])
                }
              }else{// Correspond aux autres années [N-1, Haute, Basse]
                TabDateValeur.push([valueDate,retour_data.surface[i][index]])
              }
            })

            this.chartOptiXMLPv.series[i].data = TabDateValeur;
          }

          //Mettre les points correspondant au stade E1C
          var tabValeurE1c = [];
          var tabIndiceTOP = [];
          for (let i = 0; i < nbr_parcelle_xml; i++) {   
            month = ''
            year = new Date().getFullYear()
            var valsE1c = retour_data.e1c[i]
            var partial_date = valsE1c[0];
            month = parseInt(partial_date.substr(partial_date.length - 2))
            
            var valueDateE1C = new Date (year+'-'+month+'-'+partial_date.substr(0,2))
            if(i == 0){// Correspond à l'année N qui doit s'arreter à la date du jour
              if(valueDateE1C<new Date()){
                tabValeurE1c.push([valueDateE1C,valsE1c[1]])
                tabIndiceTOP.push(valsE1c[1])
              } else {
                tabIndiceTOP.push(null)
              }
            } else {// Correspond aux autres années [N-1, Haute, Basse]
              tabValeurE1c.push([valueDateE1C,valsE1c[1]])
              tabIndiceTOP.push(valsE1c[1])
            }
          }
          this.chartOptiXMLPv.series[4].data = tabValeurE1c;


          //⁡⁢⁢⁢ Faire les graphs avec les données précedements obtenue⁡s
          var plot_name = data_item.code_parcelle_os;
          if(data_item.code_parcelle_os.includes('__')){
            plot_name = data_item.code_parcelle_os.split('__')[1]
          }

          this.chartOptiXMLPv.title[0].text = ` Piétin verse - Parcelle :${plot_name},\n Station ${NomStation} - Date de semis ${item.date_semis.slice(0,5)},\n Année Haute ${data_item.annee_haute} - Année Basse ${data_item.annee_basse}`;
          this.chartOptiXMLPv.title[1].text = ` INDICES TOP :\n Année Haute ${data_item.annee_haute} : ${parseFloat(tabIndiceTOP[2]).toFixed(2)} - Année Basse ${data_item.annee_basse} : ${parseFloat(tabIndiceTOP[3]).toFixed(2)} \n Année N-1 : ${parseFloat(tabIndiceTOP[1]).toFixed(2)} - Année N : ${parseFloat(tabIndiceTOP[0]).toFixed(2)}`;
          this.showGraphXML = true;

          this.graphToIMG(data_item);
        })
      },

      /**
       * Fonction servant à générer le graphique rouille brune de la parcelle cliquée
       */
      generateGraphRouilleBrune(item) {
        if (!this.checkCodeStation(item)) {
          alert('Code Station non conforme');
        }
        var data_item = {
          variete: item.variete,
          id_plot: item.id_plot,
          code_parcelle_os: item.code_parcelle_os,
          latitude: item.x,
          longitude: item.y,
          code_espece: item.codeespece,
          codesol: item.codesol,
          date_semis: item.date_semis,
          maladie: 'rouillebrune',
          code_station: item.code_station,

          annee_haute : this.numberValueRBHaute,
          annee_basse : this.numberValueRBBasse,

          annee_demandee: new Date().getFullYear(), //Prendre l'année
          annee_prec_demandee: (new Date().getFullYear())-1, //Prendre l'année precedente
          data_img: ''
        };

        this.arr_data_plot_foreach_plot.push(data_item);

        // ⁡⁢⁢⁢Données à récu⁡⁢⁢⁢perer de la parcelle⁡
        var data = {
          data_to_xml: data_item
        };

        // ⁡⁣⁢⁡⁢⁢⁢Obtention du resultat backofficeaggroboxxml<prq valeur>⁡⁡
        var data_from_xml_json = parcellesStadeService.generate_graph_rouille_brune(data);

        // ⁡⁢⁢⁢Transformer les données dans un format utilisable par la suite⁡
        Promise.resolve(data_from_xml_json).then(value => {

          this.chartOptiXMLRouilleB.series[0].data=[]
          this.chartOptiXMLRouilleB.series[1].data=[]
          this.chartOptiXMLRouilleB.series[2].data=[]
          this.chartOptiXMLRouilleB.series[3].data=[]

          let retour_data = value;

          //Verifier le nombre de parcelle dans le retour XML
          var nbr_parcelle_xml = retour_data.surface.length;
          let NomStation = retour_data.NomStation;

          var TabDate = []
          var TabDateValeur = []
          for (let i = 0; i < nbr_parcelle_xml; i++) {
            //⁡⁢⁢⁢Pour chaque parcelle => obtenir l'ID de la parcelle
            TabDate = retour_data.date[i]
            TabDateValeur = []
            var month = ''
            var year = new Date().getFullYear()
            TabDate.forEach(function callback(valueDate, index){
              month = parseInt(valueDate.substr(valueDate.length - 2))
              if(month>=9){
                valueDate = new Date ((year-1)+'-'+month+'-'+valueDate.substr(0,2))
              } else {
                valueDate = new Date (year+'-'+month+'-'+valueDate.substr(0,2))
              }
              if(i == 0){// Correspond à l'année N qui doit s'arreter à la date du jour
                if(valueDate<new Date()){
              TabDateValeur.push([valueDate,retour_data.surface[i][index]])
                }
              }else{// Correspond aux autres années [N-1, Haute, Basse]
                TabDateValeur.push([valueDate,retour_data.surface[i][index]])
              }
            })
  
            this.chartOptiXMLRouilleB.series[i].data = TabDateValeur;
          }

          //⁡⁢⁢⁢ Faire les graphs avec les données précedements obtenue⁡s
          var plot_name = data_item.code_parcelle_os;
          if(data_item.code_parcelle_os.includes('__')){
            plot_name = data_item.code_parcelle_os.split('__')[1]
          }

          this.chartOptiXMLRouilleB.title.text = ` Rouille Brune - Parcelle :${plot_name},\n Station ${NomStation} - Date de semis ${item.date_semis.slice(0,5)},\n Année Haute ${data_item.annee_haute} - Année Basse ${data_item.annee_basse}`;

          this.showGraphXML = true;

          this.graphToIMG(data_item);
        })
      },

      /**
       * Transformer le canvas en une image Data64
       */
      graphToIMG(data_item) {
        let many_graph = this.arr_data_plot_foreach_plot.length

        if (this.showGraphXML == true || many_graph != 0) {

          var myChart = echarts.init(document.getElementById(data_item.maladie));
          myChart.on('finished', () => {

          })

          var data_base64_maladie = '';

          if (data_item.maladie == "rouillejaune") {

            myChart.setOption(this.chartOptiXMLRouilleJ);
            let downloadImgSrc = myChart.getDataURL({
              pixelRatio: 1,
              backgroundColor: '#f2f2f2'
            });

            data_base64_maladie = downloadImgSrc.replace('data:', '').replace(/^.+,/, '');

          } else if (data_item.maladie == "pietinverse") {

            myChart.setOption(this.chartOptiXMLPv);
            let downloadImgSrc = myChart.getDataURL({
              pixelRatio: 1,
              backgroundColor: '#f2f2f2'
            });

            data_base64_maladie = downloadImgSrc.replace('data:', '').replace(/^.+,/, '');

          } else if (data_item.maladie == "rouillebrune") {

            myChart.setOption(this.chartOptiXMLRouilleB);
            let downloadImgSrc = myChart.getDataURL({
              pixelRatio: 1,
              backgroundColor: '#f2f2f2'
            });

            data_base64_maladie = downloadImgSrc.replace('data:', '').replace(/^.+,/, '');

          } else {

            alert('Erreur dans la génération des graphiques maladies');
          }

          if (!this.checkNoDuplicateBase64(data_base64_maladie)) {
            // console.log('1 DOUBLON DETECTE !!');
            //this.graphToIMG(data_item);
          } else {

            this.arr_data_base_64.push(data_base64_maladie);
            data_item.data_img = data_base64_maladie;
            this.arr_all_data_plot_foreach_plot.push(data_item);

            //Une fois toutes les img en DATA64 , nous passons au ZIP et TELECHARGEMENT
            if (this.arr_data_base_64.length == many_graph) {
              this.zipImgGraphGenerated(this.arr_data_base_64)
            }
          }

        }
      },

      zipImgGraphGenerated(arr_data_base_64) {

        var nbr_data_base64 = arr_data_base_64.length;
        var datas = this.arr_all_data_plot_foreach_plot;

        var zip = new JSZip(); //Créer une instance de ZIP
        //Boucler sur le nbr de parcelle/maladie necessitant de créer un graph
        for (let i = 0; i < nbr_data_base64; i++) {
          var plot_name = datas[i].code_parcelle_os;
          if(datas[i].code_parcelle_os.includes('__')){
            plot_name = datas[i].code_parcelle_os.split('__')[1]
          }

          zip.file(`${plot_name}_maladie_${datas[i].maladie}.png`, datas[i].data_img, {
            base64: true
          });
        }

        zip.generateAsync({
            type: "blob"
          })
          .then(function (content) {
            // see FileSaver.js
            saveAs(content, "graphique.zip");
          });
 
        //Vider les tab pour prochain lancement
        this.arr_data_base_64 = [];
        this.arr_data_plot_foreach_plot = [];
        this.arr_all_data_plot_foreach_plot = [];

      },

    showItem(id_plot, type = null) {
      let route = this.$router
        .resolve({
          name: 'detail-plot',
          params: {
            id: id_plot
          }
        });
      if (type == 'href') {
        return route.href;
      } else {
        window.open(route.href);
      }
    },

    proprietaireParcelle(item) {
      var result = false
      if(item.code_parcelle_os.startsWith(this.login)){
        result = true
      }
      return result;
    },

    async deleteItem(item){
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      var result = await parcellesStadeService.deletePlot(item.code_parcelle_os);
      this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
      if(result == true ){
        this.$store.dispatch('informations/setInfo', { message: {titre:"Suppression",description:'Parcelle supprimée'} });
        //suppression dans le store et récupération de la dernière version du store
        this.$store.getters['cap/DeleteParcelle'](item.id_plot,'arvalis');
        this.all_info_about_stades = this.$store.getters['cap/ParcellesListArvalis']()
      } else {
        this.$store.dispatch('errors/setError', { message: {code:'',description:'Une erreur est survenue'} });
      }
    },
  
    resultat_message(message_api){
      var message_api_erreur =  message_api.erreur;
      const myJSON = JSON.stringify(message_api_erreur);

      //Traitement et mise en forme du message d'erreur de l'api
      let res = myJSON.replace(/","/g, "<br>");
      let res1 = res.replace('{','');
      let res2 = res1.replace('}','');
      let res3 = res2.replace(/":"/g,' → ');
      let message_erreur_api = res3.replace(/"/g,'');
      
      let array_message_erreur_api = message_erreur_api.split("<br>");
    
      let nbr_erreur_api= 0
      if(array_message_erreur_api[0]==="[]"){
        nbr_erreur_api = 0
        message_erreur_api = ''
      }else{
        nbr_erreur_api = array_message_erreur_api.length;
      }
    
      var description = `<div>
        <h4>Récapitulatif de votre import :</h4><br>
      
        <h5>Parcelles : </h5>
          <p><span style="color:darkgreen">${message_api.ajout}</span> création(s) de nouvelle(s) parcelle(s)<br>
          <span style="color:darkgreen">${message_api.modif}</span> modification(s) de parcelle(s).</p><br>
        <h5>Mesures et observations </h5>
          <p><span style="color:darkgreen">${message_api.mesure}</span> ajout(s) de mesure(s)<br>
          <span style="color:darkgreen">${message_api.maj_mesure}</span> modification(s) de mesure(s)</p>
        <h5>Erreurs : </h5>
          <p><span style="color:darkred">${nbr_erreur_api}</span> erreur(s) :</p>
          <p>${message_erreur_api}</p>
      </div>`

      //Affichage du message de confirmation d'import de parcelle
      if(nbr_erreur_api==0){
        this.$store.dispatch('informations/setInfo', { message: {titre:"Import réalisé avec succés",description:description} });
      } else {
        this.$store.dispatch('informations/setInfo', { message: {titre:"Import avec erreur",description:description} });
      }

      return array_message_erreur_api;
    },

    /**
     * Verifie qu'une date est bien au format standard FR => DD/MM/YYYY
     * @param {} date_to_check 
     */
    checkDateFormatFr(date_to_check) {
      let str_date_to_check = String(date_to_check);
      str_date_to_check = str_date_to_check.replace(/\s+/g, '');
      let fr_date_regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
      let check = fr_date_regex.test(str_date_to_check)
    
      return check
    },

    /**
     * Verifier le bon format des dates que l'utilisateur a saisie dans le XLS d'import parcelle.
     */
    checkDatesBeforeImportCSV(){
      let table_import_csv = document.getElementById("modal_table_import");
      let rows = table_import_csv.querySelectorAll("tr");
      let data_table_modal = [];
      for (let ligne = 1; ligne < rows.length; ligne++) {
        let cells = rows[ligne].querySelectorAll("td");
        let rowData = [];
        for (let col = 0; col < cells.length; col++) {
          rowData.push(cells[col].textContent);
        }
        data_table_modal.push(rowData);
      }
      //console.log(data_table_modal);

      // la colonne des dates de semis
      const col_date_semis = data_table_modal.map(arr => arr[2]).filter(val => val !== undefined);
      // Boucler sur les cellules de chaque colonne précedement identifiée et verifier le format de date conforme FR
      for (let i = 0; i < col_date_semis.length; i++) {
        let cell_content = col_date_semis[i];
        //console.log(col_date_semis.length);
        if(cell_content.length != 0){
          //console.log(cell_content);
           if(!this.checkDateFormatFr(cell_content)){
            //console.log(rows[i+1]);
          // Si non conforme => Ajouter un style CSS ROUGE 
            rows[i+1].style.backgroundColor = "rgba(240, 60, 60, 0.4)";
            rows[i+1].children[1].style.color = "red";
            rows[i+1].children[1].style.fontWeight = 'bolder';
            }
          }
        }
      
      // la colonne des dates d'obs
      const col_date_obs = data_table_modal.map(arr => arr[12]).filter(val => val !== undefined);
      // Boucler sur les cellules de chaque colonne précedement identifiée et verifier le format de date conforme FR
      for (let i = 0; i < col_date_obs.length; i++) {
        let cell_content = col_date_obs[i];
        if(cell_content.length != 0){
          
           if(!this.checkDateFormatFr(cell_content)){
            // Si non conforme => Ajouter un style CSS ROUGE 
            rows[i+1].style.backgroundColor = "rgba(240, 60, 60, 0.4)";
            rows[i+1].children[10].style.color = "red";
            rows[i+1].children[10].style.fontWeight = 'bolder';
          }
        }
      }

      
    },

    /* GESTION DE L IMPORT DE CSV */
    launchAddPlotInApi() {
      var data = {csv:this.parse_csv,equipe:this.parcelles_equipe};
      this.dialog = false;

      //Afficher message retour 
      this.$store.dispatch('general/setWaitingOverlay', { loading: true, fullpage: true });
      this.message_retour = parcellesStadeService.addPlotInApi(data);

      this.dialog_api = true;    
      Promise.resolve(this.message_retour).then(value=>{
        this.$store.dispatch('general/setWaitingOverlay', { loading: false, fullpage: true });
        this.message_api=value;
      }) 
    },

    sortBy: function (key) {
      this.sortKey = key
      this.sortOrders[key] = this.sortOrders[key] * -1
    },

    /**
     * Convertir le CSV transmit en Objet JSON
     */
    csvToJSON(csv) {
      var vm = this
      var lines = csv.split("\r\n")
      var result = []
      var headers = lines[0].split(";")
      vm.parse_header = lines[0].split(";")
      lines[0].split(";").forEach(function (key) {
        vm.sortOrders[key] = 1
      })

      lines.map(function (line, indexLine) {
        if (indexLine < 1) return // Jump header line
        var obj = {}
        var currentline = line.split(";")
        headers.map(function (header, indexHeader) {
          obj[header] = currentline[indexHeader]
        })
        result.push(obj)
      })
      result.pop() // remove the last item because undefined values

      return result // JavaScript object
    },

    /**
     *   Lire le fichier importé et s'assurer de sa bonne lecture avant lancement du traintement
     */
    viewCSV() {   
      if (window.FileReader) {  
        var reader = new FileReader();
        
        // Use the javascript reader object to load the contents
        // of the file in the v-model prop
        reader.readAsBinaryString(this.chosenFile);
        reader.onload = () => {
          this.data = reader.result;
        }
        reader.onload = () => {

          var csv = reader.result;
          this.parse_csv = this.csvToJSON(csv);
          this.nbr_plot_csv = this.parse_csv.length;
        
        }
        this.checkDatesBeforeImportCSV()
        reader.onerror = function (evt) {
          if (evt.target.error.name == "NotReadableError") {
            alert("Le fichier ne peut pas être lu !");
          }
        };
      } else {
        alert('FileReader n\'est pas comptabible avec votre navigateur.');
      }
    },
  /* FIN GESTION DE L IMPORT DE CSV */

    resetFiltres() {
      this.selectedDepartement = []
      this.selectedEspece = []
      this.selectedVariete = []
      this.selectedCastype = [0,1]
      this.selectedMontaison = []
      this.selectedEpiaison = []
      this.loginOnly = true
      this.date_debut=''
      this.date_fin=''
      this.codeParcelleContain=null
    },

    async FilterParcelles() {
      var login = null
      if(this.loginOnly==true){
        login = this.login
      }
      this.all_info_about_stades = this.$store.getters['cap/FilterParcelles']('arvalis',this.selectedEspece,this.selectedVariete, this.selectedDepartement, null, this.date_debut, this.date_fin, login, this.selectedCastype, this.selectedMontaison, this.selectedEpiaison, this.codeParcelleContain)
      if(this.all_plots_e1c.length!=0){
        this.all_plots_e1c = this.$store.getters['cap/FilterParcelles']('clients_e1c',this.selectedEspece,this.selectedVariete, this.selectedDepartement, this.selectedTypeClientForGraph, this.date_debut, this.date_fin, login, this.selectedCastype, this.selectedMontaison, this.selectedEpiaison, this.codeParcelleContain) 
      }

      this.actualiseGraph()
    },

    /**
     * Retrouver la correspondance dans le tableau septoriose entre les elements fournis et retourner les dates (alerte) et (traitement)
     */
    check_correspondance_tab(variete, code_station, date_semis) {

      let msg_to_add = '';
      let arr_correspondances = this.arr_correspondances;
      let arr_correspondances_not_id = arr_correspondances.map(arr => arr.slice(0, arr.length - 3));//Arr plus court pour mieux comparer les éléments
      //  console.log('Je compare la variete ' + variete + ' station : ' + code_station + ' date_semis ' + date_semis);

      let found_ref;
      let check_values = [variete, code_station, date_semis];

      for (let index = 0; index < arr_correspondances.length; index++) {
        found_ref = check_values.every(value => arr_correspondances_not_id[index].includes(value));

        if (found_ref == true) { // Correspondance trouvée !
          let arr_dates = [];
          arr_dates.push([
            arr_correspondances[index][4], //  dates alerte 
            arr_correspondances[index][5]  // dates de traitement
          ])

          msg_to_add = arr_dates;
          return msg_to_add; // Renvoi les 2 dates précedente dans la cellule du tableau
        } 
        msg_to_add = "N/A"; // Aucune correspondance trouvée -> ERREUR
      }
      return msg_to_add;
    },

     /**
     * Generer la création du tableau Septoriose selon la selection dans MES PARECELLES
     */
    async create_tab_septo() {
      const table = document.getElementById("my-table-septo");

      // Supprimer l'ancien tableau présent - en purgeant les variables utilisées
      if(this.table_created){
        
        arr_septo = [];
        arr_data_dates = [];
        arr_code_station = [];
        this.arr_correspondances = [];
        arr_colSpan_attr_variete = [];
        uniq_arr_var_date_semis = [];
        arr_nbr_of_var = [];
        this.arr_stations = [];
        // Supprimer l'ancien tableau présent - en supprimer du DOM les lignes du tableau
        let components_table_septo = table.querySelectorAll('tr');
        for (let i = 0; i < components_table_septo.length; i++) {
          components_table_septo[i].remove();
        }
       
      }
      


      let arr_septo = this.selected_plots; // L'équivalent de la Selection dans "MES PARCELLES"
     
      // Classer par Ordre croissant selon le code station 
      arr_septo.sort((a, b) => a.code_station - b.code_station);

      //Séparation des données pour futur création de lignes et colonne du tableau SEPTO
      let arr_data_dates = [];//Création d'un ARR_DATA DATES contenant [Variete / Date Alerte / DateT / Date Semis] => Colonnes
      let arr_code_station = [];//Création d'un  ARR_CODE_STATION_NOM contenant [code stations / nom stations] => LIGNES 

      for (let index = 0; index < arr_septo.length; index++) {
        arr_data_dates.push([
          arr_septo[index].variete,
          arr_septo[index].septo_datealerte,
          arr_septo[index].septo_datet1,
          arr_septo[index].date_semis,
          arr_septo[index].id_plot
        ])
        arr_code_station.push(
          arr_septo[index].code_station
        );
        this.arr_correspondances.push([
          arr_septo[index].variete,
          arr_septo[index].code_station,
          arr_septo[index].date_semis,
          arr_septo[index].id_plot,
          arr_septo[index].septo_datealerte,
          arr_septo[index].septo_datet1,
        ])
      }
    
    ////////////////////////////////////////////////////////////////
      // VARIETES et DATES DE SEMIS( Futures colonnes du tableau) //
      //////////////////////////////////////////////////////////////

        // Classer par Ordre croissant selon la variété 
        arr_data_dates.sort((a, b) => a[0].localeCompare(b[0]));

        // Mises en ARRAY des VARIETE et DIFFERENTES  "DATES de SEMIS"
        let arr_var_date_semis = [];
        arr_data_dates.forEach((element) => {
            arr_var_date_semis.push([
              element[0],// variete
              element[3] // Date semis
          ]);
        });
        //Suppression des doublons de [VARIETE et DATES DE SEMIS], (qui servira à créer les colonnes uniques dans le futur tableau SEPTO)
        let uniq_arr_var_date_semis = arr_var_date_semis.filter((array, index) => {
          return index === arr_var_date_semis.findIndex((otherArray) => {
            return JSON.stringify(array) === JSON.stringify(otherArray);
          });
        });

        // Comptage des VARIETES (qui servira au futur attribut COLSPAN du tableau SEPTO)
        let count_variete = {};
        uniq_arr_var_date_semis.forEach((element) => {
          if (count_variete[element[0]]) {count_variete[element[0]]++;} else {count_variete[element[0]] = 1;}
        });
        //Création d'un tableau contenant les [ VARIETE / le precedent COMPTAGE ] => COLSPAN du futur tableau
        let arr_nbr_of_var = Object.entries(count_variete);
        let arr_colSpan_attr_variete = [];
        arr_nbr_of_var.forEach((element) => {
          let element_split = element[0].split(',');
          arr_colSpan_attr_variete.push([element_split[0], element[1]])
        })

    //////////////////////////////////////////////////////////////////////////////////////
      // DEPARTEMENT,STATION,CODE STATION(=Futures lignes du tableau)                   //
      // + DATE ALERTE  et DATE DE TRAITEMENT (=pour les cellules internes du tableau)  //
      ////////////////////////////////////////////////////////////////////////////////////

        //Suppression des doublons de code station
        let uniq_arr_code_station = [...new Set(arr_code_station)];
        //Obtention des NOMS de STATIONS ( usage de CLIMBOX)
        for (let index = 0; index < uniq_arr_code_station.length; index++) {

          let promise_nom_station = await parcellesStade.getNomStationSepto(uniq_arr_code_station[index]);
         
          this.arr_stations.push([//Création d'un tableau contenant les [departement /code station /nom de la station  + les dates ]
            uniq_arr_code_station[index].slice(0,2),  // Departement
            uniq_arr_code_station[index],             // code station
            promise_nom_station,                      // nom station
            arr_septo[index].septo_datealerte,        // date d'alerte
            arr_septo[index].septo_datet1             // date de traitement
          ]);
        }
        
    ///////////////////////////////////////////////////////////////////////////////////////////
      // Création du tableau en bouclant sur les ARRAYS crées précedement                    //
      /////////////////////////////////////////////////////////////////////////////////////////      

      // Déclarations du contenu des cellules du tableau SEPTO sur VISIOFARM
      let cellText1 = '';
      let color = '';

      // Création des lignes du tableau sur VISIOFARM
      for (let ligne = -2; ligne < this.arr_stations.length; ligne++) {
        const row = document.createElement("tr");

        // Création des cellules pour chaque ligne précedement créée
        for (let col = -2; col < uniq_arr_var_date_semis.length; col++) {
          const cell = document.createElement("td");

          // LA Cellule vide dans le coin en haut à gauche du tableau -> Mettre le Logo ARVALIS
          if (col == -2 && ligne == -1) {
            let img_arvalis = document.createElement('img');
            img_arvalis.src = require('@/assets/logo-arvalis-color.png');
            cell.classList.add("logo_tab");
            cell.appendChild(img_arvalis);
            // Les Cellules vides dans le coin supérieur gauche du tableau (=les rendre discretes)
          } else if (col == -1 && ligne == -2) {
            cellText1 = document.createTextNode(`Simulation : `+new Date().toLocaleDateString());
            cell.classList.add("logo_tab");
            cell.appendChild(cellText1);
          } else if (col == -2 && ligne == -2) {
            cell.classList.add("blank"); //Attribution d'une classe pour eviter la suppression future de ces cellules sans classes et sans contenu.
            // LA 1ère ligne en-tête des "VARIETE" en haut du tableau
          } else if (col >= 0 && ligne == -2 && col < arr_colSpan_attr_variete.length) {
            cellText1 = document.createTextNode(`Variete : ${arr_colSpan_attr_variete[col][0]}, semée le : `);
            cell.colSpan = arr_colSpan_attr_variete[col][1];
            cell.classList.add("header_tab");
            cell.appendChild(cellText1);

            // LA 1ère Colonne des "DEPARTEMENT" à gauche du tableau
          } else if (col == -2 && ligne >= 0 && ligne < this.arr_stations.length) {
            cellText1 = document.createTextNode(`Departement : ${this.arr_stations[ligne][0].slice(0,2)}`);
            cell.classList.add("aside_tab");
            cell.appendChild(cellText1);

            // Deuxième colonne : LA Cellule en-tête des STATIONS à Gauche du tableau
          } else if (col == -1 && ligne == -1) {
            cellText1 = document.createTextNode(`Station :`);
            cell.classList.add("header_tab");
            cell.appendChild(cellText1);
            // LA Colonne des "STATIONS" à Gauche du tableau
          } else if (col == -1 && ligne >= 0) {
            cellText1 = document.createTextNode(`${this.arr_stations[ligne][2]}`);
            cell.classList.add("aside_tab");
            cell.appendChild(cellText1);

            // LA 1ère Ligne en-tête AVEC la "DATE DE SEMIS"
          } else if (col >= 0 && ligne == -1) {
            cellText1 = document.createTextNode(`${uniq_arr_var_date_semis[col][1]}`);
            cell.classList.add("header_tab");
            cell.appendChild(cellText1);

            // Le Contenu de toutes les autres cellules du tableau ( devront se coloriser selons les dates)
          } else if (col >= 0 && ligne >= 0) {
            cellText1 = document.createTextNode(this.check_correspondance_tab(uniq_arr_var_date_semis[col][0], this.arr_stations[ligne][1], uniq_arr_var_date_semis[col][1])) // La cellule contiendra les date d'alerte et de traitement ( transparente pour alléger le visu sur VISIOFARM, mais permettre le debbugage / le calcul des risques / et de les conserver en export excel)
            color = this.obtainRiskSepto(cellText1,'color');
            cell.classList.add(color); // Gestion de la couleur des cellules selon les dates acquises ligne précedente
            
            var span = document.createElement('span');
            span.style = "font-size:20px;color:black;";
            span.innerHTML = this.obtainRiskSepto(cellText1,'text');

            cell.style.color = 'transparent'; // rendre invisible ces dates pour l'utilisateur sur VISIOFARM
            cell.appendChild(cellText1);
            cell.appendChild(span); // affichage sous forme de signe
            
          }
          row.appendChild(cell); //Ajouter la cellule à la ligne
        }
        table.appendChild(row); // AJouter la ligne au tableau
      }

      //VERIFICATION DE TT les éléments <td> du tableau septoriose
      const tdElements = table.getElementsByTagName('td');
      for (let i = 0; i < tdElements.length; i++) {
        const td = tdElements[i];
        // Verifier si la cellule est vide ET si ABSENCE de Classes CSS => ceci afin de la supprimmer du tableau ( car cellule inutile et risquant de casser la structure du tableau).
        if (td.textContent.trim() === '' && td.classList.length === 0) {
          td.remove();// Supprimmer la cellule 
        }
       
        if (td.textContent==='N/A'){ // Verifier les cellules contenant N/A (= absence de correspondance)
          td.classList.className="";
          td.classList.add('blank');
        }
      }
      this.table_created = true ;
    },


    /**
     * Retourne le risque de SEPTORIOSE sous la forme d'une couleur de la cellule (classe CSS)
     * @param {*} date 
     */
    obtainRiskSepto(septo_date_txt,type){
      const text_dates = septo_date_txt.nodeValue; // Convertir le "#text node" en string
      let arr_septo_date = text_dates.split(","); // obtenir un tableau des 2 dates [alerte , traitement]

      if(arr_septo_date.length>1){
        let date_now = new Date();
        date_now.setHours(0, 0, 0, 0); //on force à ne pas prendre en compte les heures, minutes, secondes,...

        let septo_datealerte = new Date(arr_septo_date[0]).getTime(); // La date d'alerte
        let septo_datet1 = new Date(arr_septo_date[1]).getTime(); // La date traitement

        // Obtenir l'écart en JOUR entre la date du JOUR et :
        let gap_day_alerte = parseInt((date_now-septo_datealerte)/(1000*60*60*24)); // [date alerte] 
        let gap_day_traitement = parseInt((date_now-septo_datet1)/(1000*60*60*24)); //  [date T]

        if (gap_day_alerte <= -1) { //Si date du jour AVANT date alerte "septo_datealerte" => VERT
          if(type=='color'){
            return 'green';
          } else {
            return '--';
          }
        } 
        else if (gap_day_traitement >= 0) { //Si date du jour A partir de la date de traitement "SEPTO DATET1" => ROUGE
          if(type=='color'){
            return 'red';
          } else {
            return '+++';
          }
        }
        else if (gap_day_traitement <0 && gap_day_alerte >= 0) { //Si date du jour entre "date alerte"  et "date-traitement - 1j" => ORANGE
          if(type=='color'){
            return 'orange';
          } else {
            return '++';
          }
        } 
      } else {
        return '&nbsp';
      }
     
    },

    /**
     * Convertir la date YYYY-MM-DD en DD/MM/YYYY
     * @param {*} date_item 
     */
    changeDateFormat(date_item){
    let splittedDate = date_item.split('-');
          if(splittedDate.count == 0){
              return null;
          }
          var year = splittedDate[0];
          var month = splittedDate[1];
          var day = splittedDate[2]; 

        let new_date_fr = day + '/' + month + '/' + year; // Format Standard Fr
        return new_date_fr;
    },

  launchAPIGetLogged() {

      // Obtention des parcelles⁡⁡
      var data_reponse = parcellesStadeService.getLogged();
      
     
      // ⁡⁢⁢⁢Transformer les données dans un format utilisable par la suite⁡
      Promise.resolve(data_reponse).then(value => {
        let retour_data = value;
        })
      },

    getNomFichier(){
        var now = new Date()
        now = getDate(now,'yyyymmdd')
        return now+'_import_previlis.xls'
      },

    checkYearInputDate(value){
      if (value.length> 0 && value>2050 || value.length> 0 &&  value <1950) {
        alert("Vous avez saisie l'année : " + value + " ,celle-ci est incorrecte.");
      } else {
      }
    },

    getTabChartDate() {
        //TT la base de donnée
        var all_data = this.all_info_about_stades;

        //récupération des dates minimum et maximum possible pour les stades
        var minEachStades = []
        minEachStades.push(new Date(this.getMin(all_data,'stade_epi1cm')))
        minEachStades.push(new Date(this.getMin(all_data,'stade_1n')))
        minEachStades.push(new Date(this.getMin(all_data,'stade_2n')))

        var maxLastStades = [];
        maxLastStades.push(new Date(this.getMax(all_data,'stade_epiaison')))
        maxLastStades.push(new Date(this.getMax(all_data,'stade_floraison')))
        maxLastStades.push(new Date(this.getMax(all_data,'stade_maturite')))

        
        //Definitions des dates MIN et MAX pour futur appel fonction
        let date_min = new Date(Math.min(...minEachStades))
        let date_max = new Date(Math.max(...maxLastStades))


        var calendar = new Array();
        var currentDate = date_min;
        //Réalisation d'un tableau de la date min à la date max
        while (currentDate <= date_max) {

            calendar.push(new Date (currentDate).toDateString('YYYY-MM-dd'));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return calendar;
    },

    getTabChartCountOf(nom_var) {
      //Récuperer les données 
      var array_of_date_from_one_stade = [];
      //TT la base de donnée
      var all_data = this.all_info_about_stades;
    
      //Recuperer uniquement les infos d'une colonne de la base de donnée
      all_data.forEach(element => {
        if (element[nom_var]!=null){
          var dt = new Date (element[nom_var]).toDateString('YYYY-MM-dd')
          if(!(dt in array_of_date_from_one_stade)){
            array_of_date_from_one_stade[dt] = 1
          } else {
            array_of_date_from_one_stade[dt] += 1
          }
        }
      });

      var calendar = this.chartOptionsStades.xAxis.data; //pour éviter de rappeler getTabChart
      var final_data = []
      calendar.forEach(element => {     
        if(!(element in array_of_date_from_one_stade)){
          final_data.push(0);
        } else {
          final_data.push(array_of_date_from_one_stade[element]/all_data.length*100);
        }
      });

      return final_data
    },

    getMin(arrayOfObject,key){
      var liste = arrayOfObject.filter(parcelle => parcelle[key] != null)
      if(liste.length>0){
        var objectMin = liste.reduce(function(prev, curr) {
            return prev[key] < curr[key] ? prev : curr;
        });
        return objectMin[key]
      } else {
        return null;
      }
      
    },

    getMax(arrayOfObject,key){
      var liste = arrayOfObject.filter(parcelle => parcelle[key] != null)
      if(liste.length>0){
        var objectMax = liste.reduce(function(prev, curr) {
            return prev[key] > curr[key] ? prev : curr;
        });
        return objectMax[key]
      } else {
        return null;
      }

    },

    async getImageTabSepto() {
      const options = {
        type: "dataURL",
      };
      const printCanvas = await html2canvas(document.querySelector("#capture-septo"), options);

      const link = document.createElement("a");
      link.setAttribute("download", "Septo_"+new Date().toLocaleDateString()+".png");
      link.setAttribute(
        "href",
        printCanvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream")
      );
      link.click();

    },

    getInfoEquipe(){
      if(this.parcelles_equipe==true){
        return "Parcelles modifiables par d'autres personnes (code parcelle qui doit être très spécifique)"
      } else {
        return "Parcelles personnelles"
      }
    }


  }

};

</script>

<style>

#display3Graphs{
  display:none;
  text-align: center;
  justify-content: space-around;
}

.graphMaladie{
  height:350px;
  width:400px;
  padding:2px;
  border-radius: 8px;
}

h3{
  margin:18px 0px 12px 0px;
  padding:0.5rem;
}

#form col{
  padding:1rem;
}

#inputs_dates .v-input{
  width:200px;
}
#inputs_dates{
  display:flex;
  text-align: center;
  justify-content: space-around;
}

/* STYLE SEPTO */
#my-table-septo, #my-table-septo th, #my-table-septo td {
  color:black;
  padding:0.5rem;
  text-align: center;
  font-size: 1px !important;
  font-weight: bold;
}

#my-table-septo tr:first-child , #my-table-septo td:first-child {
  font-size: 0.7rem;
  font-weight: bolder;
}

#my-table-septo img {
  width:100px;
}

.red{
  background-color: rgba(237, 0, 0, 0.8);
  font-weight: bolder;
}
.orange{
  background-color: rgba(255, 166, 0, 0.8);
}
.green{
  background-color: rgba(0, 128, 0, 0.8);
}

#my-table-septo .logo_tab{
  background-color: white !important ;
  font-size: 12px !important;
}
#my-table-septo .header_tab{
  background-color: rgb(153, 153, 153) !important ;
  border-bottom: 1px solid dimgray;
  font-size: 12px !important;
}
#my-table-septo .aside_tab{
  background-color: lightgrey !important ;
  border-right: 1px solid dimgray;
  font-size: 12px !important;
}

#my-table-septo .blank{
  background-color: #fff !important;
}

#septoDiv ul {
  display: inline-block;
  text-align: center;
  list-style: none; /* Removes the default bullet points */
}

#septoDiv li {
  display: inline-block;
  padding:1rem;
  font-weight: bolder;
  color:black;
}

</style>
