import Equipment from '@/models/equipment/equipment';
import Technical_Features from '@/models/equipment/technical_features';
import Measured_Features from '@/models/equipment/measured_features';
import Maintenance from '@/models/maintenance/maintenance';
import Document from '@/models/equipment/document';

import store from '@/store/index';
import { handleServiceParams, errorDescription } from "@/helpers/serviceParamsHandler";

export default {
  async getInfosConseillers() {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}conseillers`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        // we map json object to the JS object
        /*if (json.data.length>0)
        {
          const a = new Equipment(json.data[0]);
          return a
        }
        else
        {
          return null;
        }*/
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getListeRegion() {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}bsv/regions`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        // we map json object to the JS object
        /*if (json.data.length>0)
        {
          const a = new Equipment(json.data[0]);
          return a
        }
        else
        {
          return null;
        }*/
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getInfosConseillersExport() {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}conseillers/export`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getInfosConseillerById(id) {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}conseillers/${id}`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        // we map json object to the JS object
        /*if (json.data.length>0)
        {
          const a = new Equipment(json.data[0]);
          return a
        }
        else
        {
          return null;
        }*/
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getInfosConseillerBSV () {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}conseillers/bsv/infos`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        // we map json object to the JS object
        /*if (json.data.length>0)
        {
          const a = new Equipment(json.data[0]);
          return a
        }
        else
        {
          return null;
        }*/
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },


  async postConseiller(infos_conseiller) {
    const result = await
    fetch(`${process.env.VUE_APP_BASE_API}conseillers`, {
      method: 'POST',
      body: JSON.stringify(infos_conseiller),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then(response => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    }).then(json => {
      return json.data;
    }).catch((error) => {
      store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
    });
    return result;
  },

  async putConseiller(infos_conseiller) {
    const result = await
    fetch(`${process.env.VUE_APP_BASE_API}conseillers/${infos_conseiller.id_conseiller}`, {
      method: 'PUT',
      body: JSON.stringify(infos_conseiller),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then(response => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    }).then(json => {
      return json.data;
    }).catch((error) => {
      store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
    });
    return result;
  },

  async deleteConseiller(id_conseiller) {
    // get a fully desribed parcelle from API
    // URL should be a composed string from Env constant and ressource name

    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}conseillers/${id_conseiller}`, {
        method: 'DELETE',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async postConseillerAbo(id_conseiller,infos_abo) {
    const result = await
    fetch(`${process.env.VUE_APP_BASE_API}conseillers/${id_conseiller}/abos`, {
      method: 'POST',
      body: JSON.stringify(infos_abo),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then(response => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    }).then(json => {
      return json.data;
    }).catch((error) => {
      store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
    });
    return result;
  },

  async putConseillerAbo(id_abo, infos_abo) {
    const result = await
    fetch(`${process.env.VUE_APP_BASE_API}conseillers/abos/${id_abo}`, {
      method: 'PUT',
      body: JSON.stringify(infos_abo),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.getters.tokenValue}`,
        Accept: 'application/json',
      },
    }).then(response => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    }).then(json => {
      return json.data;
    }).catch((error) => {
      store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
    });
    return result;
  },

  async deleteConseillerAbo(id_abo) {
    // get a fully desribed parcelle from API
    // URL should be a composed string from Env constant and ressource name

    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}conseillers/abos/${id_abo}`, {
        method: 'DELETE',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async getInfosConseillerAgrisById(id) {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}conseillers/${id}/agriculteurs`, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          //Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        // we map json object to the JS object
        /*if (json.data.length>0)
        {
          const a = new Equipment(json.data[0]);
          return a
        }
        else
        {
          return null;
        }*/
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

  async addCguValidationConseiller(id,version_cgu) {
    const local_dossier = await
      fetch(`${process.env.VUE_APP_BASE_API}conseillers/${id}/cgu`, {
        method: 'POST',
        body: JSON.stringify(version_cgu),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${store.getters.tokenValue}`,
          Accept: 'application/json',
        },
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        store.dispatch("user/validate_cgu");
        return json.data;
      })
        .catch((error) => {
          store.dispatch('errors/setError', { message: {code:error.status,description:errorDescription(error.status)} });
        },
        );
    return local_dossier;
  },

};
