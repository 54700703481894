import Vue from 'vue';

export default {
  namespaced: true,
  state: {
     parcelles: [],
     liste_especes : [{code:17, name:'Blé tendre'},{code:40, name:'Blé dur'},{code:27, name:'Orge d\'hiver'},{code:19, name:'Triticale'}]
  },

  actions: {
 
    defineParcellesList({ commit }, payload) {
      commit("defineParcellesList", payload); 
    },
    reset: ({commit}) => {
      commit('reset'); 
    },
  },
  mutations: {

    defineParcellesList (state, payload) {
      state.parcelles = payload.parcelles
    },
    reset: (state) => {
      state.parcelles=[];
    }
  },

  getters: {
      
      FilterParcelles: (state) => (listeZAM,listeEspece,listeDebouche,listeClient, listeStatut, listeDepartement) => {

      var tab = state.parcelles

      //filtre listeZAM
      var tab2 = []
      if(listeZAM!=null && listeZAM.length>0 && tab.length>0){
        listeZAM.map(function(value) {
          tab2 = tab2.concat(tab.filter(parcelle => parcelle.zam === value))
        });
        tab = tab2
      }

      //filtre listeClient
      var tab3 = []
      if(listeClient!=null && listeClient.length>0 && tab.length>0){
        listeClient.map(function(value) {
          tab3 = tab3.concat(tab.filter(parcelle => parcelle.client === value))
        });
        tab = tab3
      }

      //filtre listeEspece
      var tab4 = []
      if(listeEspece!=null && listeEspece.length>0 && tab.length>0){
        listeEspece.map(function(value) {
          tab4 = tab4.concat(tab.filter(parcelle => parcelle.codeespece === value))
        });
        tab = tab4
      }

      //filtre listeDebouche
      var tab5 = []
      if(listeDebouche!=null && listeDebouche.length>0 && tab.length>0){
        listeDebouche.map(function(value) {
          tab5 = tab5.concat(tab.filter(parcelle => parcelle.crop_management === value))
        });
        tab = tab5
      }

      //filtre listeStatut
      var tab6 = []
      if(listeStatut!=null && listeStatut.length>0 && tab.length>0){
        listeStatut.map(function(value) {
          tab6 = tab6.concat(tab.filter(parcelle => parcelle.statut === value))
        });
        tab = tab6
      }
      

      //filtre liste departement
      var tab7 = []
      if(listeDepartement!=null && listeDepartement.length>0 && tab.length>0){

        listeDepartement.map(function(value) {
          tab7 = tab7.concat(tab.filter(stade => stade.code_dept === value))
        });
        tab = tab7
      }

      return tab
    },

    DefineListSelect: (state) => (selection)=>{
      var tab = [];
      var liste = state.parcelles

      liste.forEach(element => { 
        tab.push({value:element[selection]})
      });

      //tri
      tab.sort((a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))

      return tab
    },

    ParcellesList:(state) => ()=>{
      var tab = state.parcelles
            
      return tab
    },

    ListeEspeces:(state) => ()=>{
      var tab = state.liste_especes

      return tab
    },

    NomEspeceSelonCode:(state) => (code)=>{
      var objnom = state.liste_especes.filter(function( obj ) {
        return obj.code == code;
      });

      return objnom[0]['name']
    },

  }
};