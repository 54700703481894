<template>
  <div>
    <v-container style="margin-top: 10px">
      <h3>Détail de la parcelle</h3>
      <v-row>
        <v-col cols="5">
            <v-card class="pl-5 pt-2 pb-2">
              <v-icon small class="mr-2">
                mdi-script-text-outline
              </v-icon>
              INFORMATIONS PARCELLE
              <div class="parcelle-card">
                <div><span class="parcelle-card-title">Client :</span><span
                    class="parcelle-card-val">{{dataPlot.client}}</span>
                </div>
                <div><span class="parcelle-card-title">ZAM :</span><span
                    class="parcelle-card-val">{{dataPlot.zam}}</span>
                </div>
                <div><span class="parcelle-card-title">Département :</span><span
                    class="parcelle-card-val">{{dataPlot.code_dept}}</span>
                </div>
                <div><span class="parcelle-card-title">Espèce :</span><span
                    class="parcelle-card-val" v-if="dataPlot.codeespece">{{$store.getters['cap/NomEspeceSelonCode'](dataPlot.codeespece)}}</span>
                </div>
                <div><span class="parcelle-card-title">Variété :</span><span
                    class="parcelle-card-val">{{dataPlot.variete}}</span>
                </div>
                <div><span class="parcelle-card-title">Code parcelle :</span><span
                    class="parcelle-card-val" style="width:280px;">{{dataPlot.uuid_plot}}</span>
                </div>
                <div><span class="parcelle-card-title">Id PréviLIS :</span><span
                    class="parcelle-card-val" style="width:280px;">{{dataPlot.previlis_id}} 
                    <v-icon small class="mr-2" @click="showItem(dataPlot.previlis_id)">
                      mdi-eye
                    </v-icon>
                  </span>
                </div>
              </div>
            </v-card>

            <v-card class="pl-5 pt-2 pb-2 mt-5">
              <v-icon small class="mr-2">
                mdi-script-text-outline
              </v-icon>
              STADES
              <div class="parcelle-card">
                <div v-for="stade in liste_stades" :key="stade.code">
                  <div><span class="parcelle-card-title" :style="isStadeEnCours(stade.code)">{{stade.name}} :</span><span
                    class="parcelle-card-val">{{dataPlot['stade_'+stade.code]}}</span><span v-if="isStadeObs(stade.code)!=false"><v-icon small>mdi-eye</v-icon>{{isStadeObs(stade.code)}}</span></div>
                  <div v-if="dataPlot.nom_stade_acquisition==stade.code"><span class="parcelle-card-title"></span><span
                    class="parcelle-card-val"><v-icon>mdi-satellite-variant</v-icon>{{dataPlot.derniere_date_acquisition}}</span></div>
                </div>
                    _____________
                <div v-if="dataPlot.e1cm_observe!=null">
                  <div><span class="parcelle-card-title">Epi 1 cm réel :</span><span
                      class="parcelle-card-val">{{dataPlot.stade_epi1cm}}</span></div>   
                  <div><span class="parcelle-card-title">Epi 1 cm prévu :</span><span
                      class="parcelle-card-val">{{dataPlot.stade_epi1cm_prevu}}</span></div>
                  <div><span class="parcelle-card-title">Epi 1 cm observation :</span><span
                      class="parcelle-card-val">{{dataPlot.e1cm_observe}} ({{dataPlot.hauteur_moyenne}}mm)</span></div>
                </div>
                <div v-else>
                  Pas d'observation d'épi 1 cm
                </div>
              </div>
            </v-card>
        </v-col>
        <v-col cols="5">
          <!--
          https://www.nicolaskempf.fr/creez-vos-cartes-interactives-avec-vuejs-et-leaflet/
          https://vue2-leaflet.netlify.app/examples/custom-icons.html
          -->
          <l-map :center="carte.center" :zoom="carte.zoom" class="map" ref="map" @update:zoom="zoomUpdated"
            @update:center="centerUpdated">
            <l-tile-layer :url="carte.url">
            </l-tile-layer>
            <l-marker v-for="marker in carte.markers" :key="marker.id" :lat-lng="marker.coordinates">
              <l-icon ref="icon" :icon-url="marker.imageUrl">
              </l-icon>
            </l-marker>
          </l-map>

          <v-card class="pl-5 pt-2 pb-2 mt-5">
            <v-icon small class="mr-2">
              mdi-script-text-outline
            </v-icon>
            CALCULS QN
            <div class="parcelle-card">
              <div><span class="parcelle-card-title">Date dernière acquisition :</span><span
                  class="parcelle-card-val">{{dataPlot.derniere_date_acquisition}}</span>
              </div>
              <div><span class="parcelle-card-title">CHL :</span><span
                  class="parcelle-card-val">{{dataPlot.sortie_CHL}}</span>
              </div>
              <div><span class="parcelle-card-title">LAI :</span><span
                  class="parcelle-card-val">{{dataPlot.sortie_LAI}}</span>
              </div>
              <div><span class="parcelle-card-title">QNacq :</span><span
                  class="parcelle-card-val">{{dataPlot.sortie_QNacq}}</span>
              </div>
              <div><span class="parcelle-card-title">Préco arronfie finale :</span><span
                  class="parcelle-card-val">{{dataPlot.sortie_preco_arrondie_finale}}</span>
              </div>
              <div><span class="parcelle-card-title">Option_faible_biomasse :</span><span
                  class="parcelle-card-val">{{dataPlot.sortie_biomasseAerienneFloraison}}</span>
              </div>
              <div><span class="parcelle-card-title">Indicateur delta biomasse :</span><span
                  class="parcelle-card-val">{{dataPlot.sortie_indicateur_delta_biomasse}}</span>
              </div>
              <div><span class="parcelle-card-title">Indicateur INN acquisition:</span><span
                  class="parcelle-card-val">{{dataPlot.sortie_indicateur_inn_acquisition}}</span>
              </div>
              <div><span class="parcelle-card-title">Azote aérien absorbé floraison :</span><span
                  class="parcelle-card-val">{{dataPlot.sortie_azoteAerienAbsorbeFloraison}}</span>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <!-- FIN DETAIL PARCELLE -->

      <!--<h3>Images</h3>
      <v-row>
        <v-col cols="3">
          <v-select
            v-model="selectedImage"
            :items="liste_images"
            item-text="name"
            item-value="code"
            menu-props="auto"
            label="Image"
            hide-details
            single-line
            chips
            dense
        ></v-select>
        </v-col>
        <v-col cols="5">
          <div v-if="imageBase64!=null">
            <img  v-bind:src="'data:image/jpeg;base64,'+imageBase64"/>
          </div>
        </v-col>
      </v-row>-->

    </v-container>

  </div>

  
</template>

<script>
import downloadExcel from "vue-json-excel";
import parcellesQnService from "@/service/parcellesQn";
import VChart from "vue-echarts";
import { LMap, LTileLayer, LMarker, LIcon, LPopup } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

export default {
  name: "DetailParcelle_QN",
  components: {
    downloadExcel,
    parcellesQnService,
    VChart,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup
  },
  data() {
    return {
      droits: this.$store.state.user.user.droitsTPC, //this.$store.dispatch("user/fetchUser"), //this.$store.state.user.droitsTPC, //this.$store.state.auth.user.droits,
      dataPlot: [],
      carte: {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        center: [46.76782, 2.43129],
        zoom: 10,
        markers: [],
      },
      liste_stades : [{code: "levee", name:"Levée"},{code: "debuttallage", name:"Tallage"},{code: "epi1cm", name:"Epi 1 cm"},{code: "1n", name:"1 noeud"},{code: "2n", name:"2 noeuds"},{code: "dfp", name:"DFP"},{code: "meiose", name:"DFE"},{code: "epiaison", name:"Epiaison"},{code: "floraison", name:"Floraison"},{code: "maturite", name:"Maturité"},{code: "recolte", name:"Récolte"}],
      liste_images : [{code: "lai", name:"LAI"},{code: "chl", name:"CHL"},{code: "rasteroutput", name:"Préco"}],
      selectedImage : null,
      imageBase64 : null
    };
  },
  watch: {
    selectedImage: function () {
        this.getImage()
    },
  },
  async mounted() {
    this.dataPlot = await parcellesQnService.getInfosParcelles(this.$route.params.id);
    this.dataPlot = this.dataPlot[0];

    //MAP
    this.carte.markers = [{
      id: this.dataPlot.id,
      coordinates: [this.dataPlot.latitude, this.dataPlot.longitude],
      imageUrl: require('@/assets/map_icon_red.png')
    }]
    this.carte.center = [this.dataPlot.latitude, this.dataPlot.longitude];
  },

  methods: {
    showItem(previlis_id, type = null) {
        let route = this.$router
          .resolve({
            name: 'detail-plot',
            params: {
              id: previlis_id
            }
          });
        if (type == 'href') {
          return route.href;
        } else {
          window.open(route.href);
        }
      },

    isStadeEnCours(code_stade){
      var style=""
      if(this.dataPlot.nom_stade_en_cours==code_stade){
        style = "text-decoration:underline;"
      }

      return style
    },

    isStadeObs(code_stade) {
      var date = false
      if(this.dataPlot.stades_obs!=null && this.dataPlot.stades_obs.length>0){
        this.dataPlot.stades_obs.forEach((element) => {
          if(element.code_arvalis==code_stade){
            date = element.date
          }
        });
      }

      return date
      
    },

    //MAP
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    centerUpdated(center) {
      this.center = center;
    },

    async getImage(){
      this.imageBase64 = await parcellesQnService.getImage(this.selectedImage);
    }

  }
};
</script>

<style scoped>

.map {
    height: 380px !important;
    width: 510px !important;
    position: relative !important;
    z-index:0;
 }

</style>
